import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[matLoadingButton]',
  standalone: true,
})
export class MatLoadingButtonDirective {
  @Input() hideText = true;
  @Input() set matLoadingButton(loading: boolean) {
    this.loading = loading;
    if (this.hideText) {
      this.textHidden = loading;
    }
    this.elem.nativeElement.disabled = loading;
  }

  @HostBinding('class.mat-spinner')
  loading = false;

  @HostBinding('class.hide-btn-text')
  textHidden = false;

  @HostBinding('disabled')
  get disabled(): boolean {
    return this.loading;
  }

  constructor(private readonly elem: ElementRef<HTMLButtonElement>) {}
}
