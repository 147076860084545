<div>
  <ngx-mat-timepicker #startTimePicker [minutesGap]="5"></ngx-mat-timepicker>
  <ngx-mat-timepicker #endTimePicker [minutesGap]="5"></ngx-mat-timepicker>
  <div class="is-flex is-align-items-center flex-gap-10">
    <mat-form-field class="hour_min_field">
      <input matInput
             format="24"
             [(ngModel)]="startTime"
             (ngModelChange)="onStartTimeChange($event)"
             [ngxMatTimepicker]="startTimePicker"
             [max]="startTimeMax()"
             readonly/>
      <mat-icon matSuffix (click)="startTimePicker.open()">
        watch_later
      </mat-icon>
    </mat-form-field>
    <p>{{ "settings.calendar.date-specific-hours.add-hours-dialog.until" | translate }}</p>
    <mat-form-field class="hour_min_field">
      <input matInput
             format="24"
             [(ngModel)]="endTime"
             [min]="endTimeMin()"
             (ngModelChange)="onEndTimeChange($event)"
             [ngxMatTimepicker]="endTimePicker"
             readonly/>
      <mat-icon matSuffix (click)="endTimePicker.open()">
        watch_later
      </mat-icon>
    </mat-form-field>
    <button
      *ngIf="allowRemoval"
      class="has-margin-left-5"
      matTooltip='{{ "settings.calendar.date-specific-hours.add-hours-dialog.new-interval" | translate }}'
      matTooltipPosition="above"
      mat-icon-button
      (click)="remove.emit()"
      [disabled]="isBusy"
    >
      <mat-icon class="button-icon">clear</mat-icon>
    </button>
  </div>
</div>
