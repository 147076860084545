<h2 mat-dialog-title>{{ "office.settings.subscription.plan_start_confirmation.title" | translate }}</h2>
<mat-dialog-content>
  <p>
    {{ "office.settings.subscription.plan_start_confirmation.message" |
    translate }}
  </p>
  <div class="field has-margin-top-25">
    <label class="label2"
    >{{ "office.settings.subscription.plan_start_confirmation.coupon_label" |
      translate }}</label
    >
    <div class="control" [ngClass]="{ 'is-loading': couponIsValidating }">
      <input
        type="text"
        class="input coupon is-primary"
        placeholder="{{ 'office.settings.subscription.plan_start_confirmation.coupon_hint' | translate }}"
        [(ngModel)]="coupon"
        (ngModelChange)="verifyCoupon()"
      />
    </div>
    <p
      class="help"
      *ngIf="couponMessage"
      [ngClass]="{
              'is-danger': couponIsValid === false,
              'is-success': couponIsValid
            }"
    >
      {{ couponMessage }}
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ "office.settings.subscription.plan_start_confirmation.cancel" | translate }}
  </button>
  <button mat-flat-button color="primary" [disabled]="couponIsValidating || couponIsValid === false || isLoadingCheckoutLink"
          [matLoadingButton]="isLoadingCheckoutLink"
          [hideText]="true"
          (click)="goToCheckout()" cdkFocusInitial>
    {{ "office.settings.subscription.plan_start_confirmation.confirm" |
    translate }}
  </button>
</mat-dialog-actions>
