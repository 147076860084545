import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Profile } from '../../../../entities/profile.model';
import { BaseClass } from 'src/app/shared/base-class';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';

declare var google: any;

@Component({
  selector: 'profile-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class MapComponent extends BaseClass implements OnInit {
  constructor(
    sharedService: SharedService,
    alertService: AlertService,
    router: Router
  ) {
    super(sharedService, alertService, router);
  }

  mapWidth: number;

  @ViewChild('map', { read: ElementRef, static: true }) map: ElementRef;
  @ViewChild('popup', { read: ElementRef, static: false }) popup: ElementRef;

  _profile: Profile;

  @Input()
  set profile(profile: Profile) {
    if (!this._profile && profile.lat && profile.lng) {
      this._profile = profile;
      this.initMap();
    } else if (
      this._profile &&
      profile.lat !== this._profile.lat &&
      profile.lng !== this._profile.lng
    ) {
      this._profile = profile;
      this.initMap();
    }
  }

  @Output()
  public edit = new EventEmitter();

  initMap() {
    if (this._profile.lat && this._profile.lng) {
      try {
        let myLatLng = { lat: this._profile.lat, lng: this._profile.lng };
        new google.maps.Map(this.map.nativeElement, {
          center: myLatLng,
          zoom: 14,
          gestureHandling: 'none',
          zoomControl: false,
          disableDefaultUI: true,
          styles: [
            { elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] },
            { elementType: 'geometry.fill', stylers: [{ color: '#effbf9' }] },
            { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
            {
              elementType: 'labels.text.fill',
              stylers: [{ color: '#636d6b' }],
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [{ color: '#f5f5f5' }],
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [{ color: '#dde9e7' }],
            },
            { featureType: 'road', stylers: [{ color: '#ffffff' }] },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [{ color: '#ffffff' }],
            },
            {
              featureType: 'road',
              elementType: 'geometry.fill',
              stylers: [{ color: '#ffffff' }],
            },
            {
              featureType: 'road',
              elementType: 'geometry.stroke',
              stylers: [{ color: '#ffffff' }],
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [{ color: '#dde9e7' }],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
}
