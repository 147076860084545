<div class="columns">
  <div class="column upload-diploma">
    <ngx-file-drop
      id="filedrop"
      dropZoneClassName="icdropzone"
      contentClassName="filedropcontent"
      customstyle="icdropzone"
      (onFileDrop)="onFileDroppedDiploma($event)"
    >
      <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="fileInputDiploma"
      >
        <div
          class="file_drop has-text-centered"
          (click)="fileInputDiploma.click()"
        >
          <h1 class="title is-4">
            {{ "profile.modal.diploma.files" | translate }}
          </h1>
          <strong>{{
            "profile.modal.supporttext.draganddrop" | translate
          }}</strong>
          <div style="color: black" class="has-text-centered">
            {{ "profile.modal.supporttext.or" | translate }}
            <input
              style="display: none"
              type="file"
              (change)="onFileAddedDiploma($event)"
              #fileInputDiploma
            />
            <span style="text-decoration: underline; cursor: pointer">{{
              "profile.modal.supporttext.choose-file" | translate
            }}</span>
          </div>
          <p
            class="help is-size-6"
            style="color: #edb2a4 !important"
            *ngIf="wrongType"
          >
            {{ "profile.modal.diploma.only" | translate }}
          </p>
          <p
            class="help is-size-6"
            style="color: #edb2a4 !important"
            *ngIf="showDiplomaSizeError"
          >
            {{ "profile.modal.diploma.file-size" | translate }}
          </p>
        </div>
      </ng-template>
    </ngx-file-drop>

    <ul *ngIf="diplomas.length > 0">
      <li *ngFor="let d of diplomas" class="listitem">
        <div class="columns is-vcentered">
          <div class="tag is-warning">
            {{ d.name || (d.blob && d.blob.filename) | file_type }}
          </div>
          <div class="column">
            {{ d.name || (d.blob && d.blob.filename) | slice : 0 : 15 }}
          </div>
          <button
            [disabled]="busy"
            class="button is-small removeDiploma"
            [ngClass]="{ 'is-loading': busy == d.id }"
            (click)="removeDiploma(d.id)"
          >
            <i class="material-icons">close</i>
          </button>
        </div>
        <div>
          <mat-progress-bar
            *ngIf="diplomasProgress[d.name]"
            [color]="'primary'"
            [mode]="'determinate'"
            [value]="diplomasProgress[d.name]"
          >
          </mat-progress-bar>
        </div>
      </li>
    </ul>
  </div>

  <div class="column">
    <div class="content">
      <h3 class="modal-title">
        {{ "onboarding.boxes.upload_diplomas.title" | translate }}
      </h3>
      <p>
        {{ "profile.modal.diploma.to-be-approved" | translate }}
      </p>
      <button
        (click)="complete()"
        class="button ic-button is-rounded is-medium"
      >
        <span *ngIf="diplomas.length == 0">{{
          "profile.modal.diploma.skip" | translate
        }}</span>
        <span *ngIf="diplomas.length > 0">{{
          "profile.modal.diploma.done" | translate
        }}</span>
      </button>
    </div>
  </div>
</div>
