<h2 mat-dialog-title>{{ "home.first-inquiry-sent.title" | translate }}</h2>
<mat-dialog-content>
  <p>{{ "home.first-inquiry-sent.message"  | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ "home.first-inquiry-sent.close" | translate }}
  </button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true"  (click)="onReturnToDirectory()"  class="return-button" cdkFocusInitial>
    {{ "home.first-inquiry-sent.return-to-directory" | translate }}
  </button>
</mat-dialog-actions>
