import { Component, Input, OnInit } from '@angular/core';
import { Announcement } from 'src/app/entities/announcement';
import { AnnouncementService } from '../../services/announcement/announcement.service';
import { User } from '../../../entities/user.model';
import { AmplitudeAnalyticsService } from '../../services/analytics/amplitude-analytics.service';
import { FeaturesService } from '../../services/features/features.service';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'shared-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass],
})
export class AnnouncementsComponent implements OnInit {
  constructor(
    private announcementService: AnnouncementService,
    private analytics: AmplitudeAnalyticsService,
    private featuresService: FeaturesService
  ) {}

  @Input()
  set currentUser(user: User) {
    if (user) {
      this.loadAnnouncements();
    } else {
      this.announcement = null;
    }
  }
  announcement: Announcement;
  ngOnInit() {}

  loadAnnouncements() {
    this.announcementService.getAll().subscribe((response) => {
      this.setFirstAnnouncement(response);
    });
  }

  private setFirstAnnouncement(response) {
    this.announcement = response.map((a) => {
      a.body = a.body.replace(/(?:\r\n|\r|\n)/g, '<br>');
      return a;
    })[0];
  }

  goToPrimaryCta(announcement: Announcement) {
    this.analytics.announcementPrimaryCTAClicked({
      id: announcement.id.toString(),
      title: announcement.title,
    });

    if (
      announcement.primary_cta_destination.includes(
        'community.complicated.life'
      )
    ) {
      this.analytics.trackOpenedCommunity({
        source_page: 'dashboard',
        source_action: `announcement_${announcement.id.toString()}`,
      });
    }

    // For community launch (which is the only one that uses primary CTA for now)
    // we don't want to mark as read, but let them re-use the primary CTA for a while
    window.open(announcement.primary_cta_destination, '_blank');

    // this.announcementService
    //   .markAsRead(announcement.id)
    //   .subscribe((response) => {
    //     this.setFirstAnnouncement(response);
    //     window.open(announcement.primary_cta_destination, '_blank');
    //   });
  }

  close(announcement: Announcement) {
    this.analytics.announcementCloseClicked({
      id: announcement.id.toString(),
      title: announcement.title,
    });

    this.announcementService
      .markAsRead(announcement.id)
      .subscribe((response) => {
        this.setFirstAnnouncement(response);
      });
  }
}
