import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';
import { HomeComponent } from '../../components/home/home.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { NotFoundComponent } from 'src/app/app/not-found/not-found.component';
import { LogoutComponent } from '../logout/logout.component';
import { AuthCanActivate } from '../service/i-c-auth.service';
import { LinkAccountsComponent } from '../link-account/link-accounts.component';
import { videoAppointmentRoutingGuard } from './video-appointment-routing-guard';
import { waitingRoomRoutingGuard } from './waiting-room-routing-guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'sign-up', component: RegisterComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  // this should not be used at all anymore
  { path: 'online-session', component: LoginComponent },

  // this is the old waiting room url, and we just redirect to the new one
  {
    path: 'online-session/:slug',
    canActivate: [waitingRoomRoutingGuard],
    component: LoginComponent,
  },
  {
    path: 'video/:uid',
    canActivate: [videoAppointmentRoutingGuard],
    component: LoginComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: AuthCanActivate,
  },
  { path: '404', component: NotFoundComponent },
  // auth0 post-login callback to register user with no user id
  { path: 'link-accounts', component: LinkAccountsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
