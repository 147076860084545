import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';
import { RouterModule } from '../../../../node_modules/@angular/router';
import { AuthRoutingModule } from './auth-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { CookieModule, CookieService } from 'ngx-cookie';
import { ActionCableService } from 'angular2-actioncable';
import { LogoutComponent } from '../logout/logout.component';
import { LinkAccountsComponent } from '../link-account/link-accounts.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AuthRoutingModule,
    FormsModule,
    CookieModule.forChild(),
    ReactiveFormsModule,
    LoginComponent,
    LogoutComponent,
    ResetPasswordComponent,
    RegisterComponent,
    LinkAccountsComponent,
  ],
  providers: [CookieService, ActionCableService],
  exports: [LoginComponent, RegisterComponent],
})
export class LegacyAuthModule {}
