<section class="dashboard-teaser wrapper" *ngIf="announcement">
  <header class="dashboard-teaser-header">
    <h3 class="dashboard-teaser-title">Announcements</h3>
  </header>
  <div
    class="card"
    [ngClass]="{
      'image-background-default': announcement.template == 'default',
      'image-background-community': announcement.template == 'community'
    }"
  >
    <div class="has-padding-15 is-pulled-right">
      <button class="delete" (click)="close(announcement)"></button>
    </div>
    <div
      class="card-content content"
      [ngClass]="{
        'card-content-width-default': announcement.template == 'default',
        'card-content-width-community': announcement.template == 'community'
      }"
    >
      <div
        class="columns is-flex is-justify-content-space-between is-align-items-stretch"
      >
        <div class="column">
          <h6 class="has-margin-bottom-15">{{ announcement.title }}</h6>
          <p [innerHTML]="announcement.body"></p>
          <div
            *ngIf="
              !!announcement.primary_cta_text &&
              !!announcement.primary_cta_destination
            "
          >
            <a
              (click)="goToPrimaryCta(announcement)"
              class="button large-button-padding is-rounded is-primary is-pulled-left is-hidden-mobile"
            >
              <span>{{ announcement.primary_cta_text }}</span>
            </a>
            <div class="has-text-centered">
              <a
                (click)="goToPrimaryCta(announcement)"
                class="button large-button-padding is-rounded is-primary is-hidden-tablet has-margin-left-auto has-margin-right-auto"
              >
                <span>{{ announcement.primary_cta_text }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
