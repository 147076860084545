<div class="modal" [ngClass]="{ 'is-active': invoiceID }">
  <div class="modal-background"></div>
  <div class="modal-content">
    <a class="delete" (click)="close()"></a>
    <div class="has-text-centered" *ngIf="!invoice">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <ng-container *ngIf="invoice">
      <ng-container *ngIf="invoice.status == 'open'">
        <ng-container *ngIf="invoice.invoice_type == 'prepay'">
          <h3 class="modal-title">
            {{ "payment-modal.title-open-pre-pay" | translate }}
          </h3>
          <div class="field">
            <div class="control">
              <div class="columns is-mobile is-vcentered">
                <div class="column">
                  <h3 class="title is-5">
                    {{ invoice.invoice_items[0].name }}
                  </h3>
                </div>
                <div class="column is-narrow">
                  <h3 class="title is-3">
                    {{
                      invoice.total
                        | currency : (invoice.stripe.currency | uppercase)
                    }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="invoice.invoice_type == 'bulk'">
          <h3 class="modal-title">
            {{
              "payment-modal.title-open-pre-pay"
                | translate : { date: invoice.created_at | date : "dd.MM.yyyy" }
            }}
          </h3>
          <table class="table is-fullwidth">
            <thead>
              <th>{{ "payment-modal.header-item" | translate }}</th>
              <th class="has-text-right">
                {{ "payment-modal.header-amount" | translate }}
              </th>
              <th class="has-text-right">
                {{ "payment-modal.header-price" | translate }}
              </th>
              <th class="has-text-right">
                {{ "payment-modal.header-total" | translate }}
              </th>
            </thead>
            <tbody>
              <tr *ngFor="let item of invoice.invoice_items">
                <td>{{ item.name }}</td>
                <td class="has-text-right">{{ item.amount }}</td>
                <td class="has-text-right">
                  {{
                    item.price
                      | currency : (invoice.stripe.currency | uppercase)
                  }}
                </td>
                <td class="has-text-right">
                  {{
                    item.price * item.amount
                      | currency : (invoice.stripe.currency | uppercase)
                  }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th></th>
                <th></th>
                <th class="has-text-right">Total</th>
                <th class="has-text-right">
                  {{
                    invoice.total
                      | currency : (invoice.stripe.currency | uppercase)
                  }}
                </th>
              </tr>
            </tfoot>
          </table>
        </ng-container>

        <hr />

        <div class="buttons">
          <a
            [href]="invoice.stripe.hosted_invoice_url"
            (click)="close()"
            target="_blank"
            class="button is-primary is-rounded"
            >{{ "payment-modal.pay" | translate }}</a
          >
        </div>
      </ng-container>
      <ng-container *ngIf="invoice.status == 'paid'">
        <h3 class="modal-title">
          Invoice, {{ invoice.created_at | date : "dd.MM.yyyy" }}
        </h3>
        <p>
          {{ "payment-modal.invoice-has-been-paid" | translate }}

          <span *ngIf="currentUser.id == invoice.client.id">{{
            "payment-modal.payment-receipt-sent-to-email" | translate
          }}</span>
        </p>
        <table
          class="table is-fullwidth"
          *ngIf="invoice.invoice_type == 'bulk'"
        >
          <thead>
            <th>{{ "payment-modal.header-item" | translate }}</th>
            <th class="has-text-right">
              {{ "payment-modal.header-amount" | translate }}
            </th>
            <th class="has-text-right">
              {{ "payment-modal.header-price" | translate }}
            </th>
            <th class="has-text-right">
              {{ "payment-modal.header-total" | translate }}
            </th>
          </thead>
          <tbody>
            <tr *ngFor="let item of invoice.invoice_items">
              <td>{{ item.name }}</td>
              <td class="has-text-right">{{ item.amount }}</td>
              <td class="has-text-right">
                {{
                  item.price | currency : (invoice.stripe.currency | uppercase)
                }}
              </td>
              <td class="has-text-right">
                {{
                  item.price * item.amount
                    | currency : (invoice.stripe.currency | uppercase)
                }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th></th>
              <th class="has-text-right">Total</th>
              <th class="has-text-right">
                {{
                  invoice.total
                    | currency : (invoice.stripe.currency | uppercase)
                }}
              </th>
            </tr>
          </tfoot>
        </table>

        <div class="modal-actions">
          <button
            class="button is-rounded"
            (click)="close()"
            [routerLink]="['/office/invoices', invoice.id]"
            *ngIf="currentUser.id != invoice.client.id"
          >
            {{ "payment-modal.view-details" | translate }}
          </button>
          <a
            [href]="invoice.stripe.hosted_invoice_url"
            *ngIf="currentUser.id == invoice.client.id"
            class="button is-rounded"
            target="_blank"
            >{{ "payment-modal.view-details" | translate }}</a
          >
          <button class="button is-primary is-rounded" (click)="close()">
            {{ "payment-modal.close" | translate }}
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
