import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/entities/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { InvoiceAttentionBanner } from '../../shared/services/invoiceAttentionBanner';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceDialogComponent } from '../invoice-dialog/invoice-dialog.component';
import { OfficeNavigationComponent } from '../office-navigation/office-navigation.component';
import { InvoiceNewButtonComponent } from '../../shared/components/office/invoice-new-button/invoice-new-button.component';
import { PayoutButtonComponent } from '../../shared/components/office/payout-button/payout-button.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { SidenavComponent } from '../../frame/sidenav/sidenav.component';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { environment } from '../../../environments/environment';
import { OfficeService } from '../shared/service/office.service';

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SidenavComponent,
    NgSwitch,
    NgSwitchCase,
    RouterLink,
    PayoutButtonComponent,
    InvoiceNewButtonComponent,
    OfficeNavigationComponent,
    RouterOutlet,
    InvoiceDialogComponent,
    TranslateModule,
  ],
})
export class OfficeComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private officeService: OfficeService
  ) {}
  public currentUser: User;
  public stripeConnectAccount: any;
  public showStripeConnectEmbeddedUis: boolean;

  public invoiceAttentionBanner = InvoiceAttentionBanner.Hidden;
  INVOICEATTENTIONBANNER = InvoiceAttentionBanner;

  ngOnInit() {
    this.sharedService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });
    this.sharedService.currentInvoiceAttentionBanner().subscribe((banner) => {
      this.invoiceAttentionBanner = banner;
    });

    this.showStripeConnectEmbeddedUis = !environment.production;
    if (this.showStripeConnectEmbeddedUis) {
      // pre-load the client secret for stripe dashboards
      this.officeService.fetchStripeConnectClientSecret().subscribe();
    }
  }
  hideInvoiceOnboardingBanner() {
    this.invoiceAttentionBanner = InvoiceAttentionBanner.Hidden;
    this.sharedService.hideInvoiceOnboardingBanner();
  }
}
