<div
  [@simpleFadeAnimation]="'in'"
  class="container-fluid container-fluid-limited"
>
  <div class="columns is-marginless">
    <div class="column is-3 is-hidden-touch is-marginless">
      <app-sidenav></app-sidenav>
    </div>
    <div class="column message-col">
      <div class="container-fluid container-fluid-limited">
        <div id="ic-messages-container" class="card has-border">
          <nav class="conv-header">
            <div class="is-flex is-flex-direction-row is-align-items-center justify-space-between flex-gap-10">
              <!-- Title Section -->
              <h4 class="title is-5 has-margin-bottom-0">{{ "messaging.list.inbox" | translate }}</h4>
              <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-flex-end">
                <!-- Input and Menus -->
                <div class="control" style="flex: 1 1 auto; min-width: 100px; padding-right: 10px">
                  <input
                    type="text"
                    class="input is-rounded"
                    [placeholder]="'messaging.search' | translate"
                    (keyup)="searchKey($event)"
                    style="width: 100%;"
                  />
                </div>

                <!-- Sorting Dropdown Menu Button -->
                <button mat-icon-button [matMenuTriggerFor]="sortingMenu" aria-label="Sort options">
                  <mat-icon>sort</mat-icon>
                </button>
                <mat-menu #sortingMenu="matMenu">
                  <button mat-menu-item (click)="changeSorting('activity')">
                    <span>{{ "messaging.list.sort-activity" | translate }}</span>
                    <mat-icon *ngIf="sorting == 'activity'" style="margin-left: auto;">check</mat-icon>
                  </button>
                  <button mat-menu-item (click)="changeSorting('created')">
                    <span>{{ "messaging.list.sort-first-contact" | translate }}</span>
                    <mat-icon *ngIf="sorting == 'created'" style="margin-left: auto;">check</mat-icon>
                  </button>
                  <button mat-menu-item (click)="changeSorting('unread')">
                    <span>{{ "messaging.list.show-unread" | translate }}</span>
                    <mat-icon *ngIf="sorting == 'unread'" style="margin-left: auto;">check</mat-icon>
                  </button>
                  <button mat-menu-item (click)="changeSorting('name')">
                    <span>{{ "messaging.list.sort-name" | translate }}</span>
                    <mat-icon *ngIf="sorting == 'name'" style="margin-left: auto;">check</mat-icon>
                  </button>
                </mat-menu>

                <!-- More Options Menu Button -->
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More message list options"
                        *ngIf="currentUser && currentUser.type === 'Therapist'">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item *ngIf="!showHidden" (click)="changeShowHidden(true)">
                    <div class="is-flex align-center">
            <span
              class="feature-tooltip"
              matTooltipClass="mat-tooltip"
              [matTooltipPosition]="'above'"
              [matTooltip]="'messaging.list.show-hidden-tooltip' | translate"
            >
              <mat-icon class="mat-tooltip-trigger-icon material-symbols-outlined"
                        style="font-size: 20px !important; height: unset !important; width: unset !important;">help_outline</mat-icon>
            </span>
                      <span>{{ "messaging.list.show-hidden" | translate }}</span>
                    </div>
                  </button>
                  <button mat-menu-item *ngIf="showHidden" (click)="changeShowHidden(false)">
                    <div class="is-flex align-center">
            <span
              class="feature-tooltip"
              matTooltipClass="mat-tooltip"
              [matTooltipPosition]="'above'"
              [matTooltip]="'messaging.list.show-non-hidden-tooltip' | translate"
            >
              <mat-icon class="mat-tooltip-trigger-icon material-symbols-outlined"
                        style="font-size: 20px !important; height: unset !important; width: unset !important;">help_outline</mat-icon>
            </span>
                      <span>{{ "messaging.list.show-non-hidden" | translate }}</span>
                    </div>
                  </button>
                </mat-menu>
              </div>
            </div>
          </nav>
          <div class="columns main-columns is-gapless">
            <div class="column">
              <div class="loading" *ngIf="isLoading">
                <div class="has-text-centered" style="padding: 2rem 0">
                  <p *ngIf="!showHidden">
                    {{ "messaging.list.loading-conversations" | translate }}
                  </p>
                  <p *ngIf="showHidden">
                    {{
                      "messaging.list.loading-hidden-conversations"
                        | translate
                    }}
                  </p>
                </div>
              </div>
              <ng-container *ngIf="!isLoading">
                <div
                  *ngIf="conversations && conversations.length == 0"
                  class="has-text-centered"
                  style="padding: 2rem 0"
                >
                  <p *ngIf="!searchTerm && showHidden">
                    {{
                      "messaging.list.hidden-conversations-appear-here"
                        | translate
                    }}
                  </p>
                  <p *ngIf="!searchTerm && !showHidden">
                    {{ "messaging.list.conversations-appear-here" | translate }}
                  </p>
                  <p *ngIf="searchTerm && showHidden">
                    {{ "messaging.list.no-hidden-conversations" | translate }}
                  </p>
                  <p *ngIf="searchTerm && !showHidden">
                    {{ "messaging.list.no-conversations" | translate }}
                  </p>
                </div>
              </ng-container>
              <ng-container *ngIf="!isLoading">
                <app-conversation-preview
                  *ngFor="let c of conversations"
                  [avatarSrc]="getAvatarSrc(c)"
                  [name]="otherUser(c)?.name"
                  [message]="c.messages[0]?.body"
                  [timestamp]="c.messages[0]?.created_at"
                  [unread]="hasUnreadMessages(c)"
                  [showMatchedBadge]="c.is_result_of_match && currentUser && currentUser.type == 'Therapist'"
                  [flagged]="isOtherUserFlagged(c)"
                  [loading]="isLoading"
                  [decrypted]="c.messages[0]?.decrypted"
                  [truncateSize]="150"
                  [hasAppointment]="c.messages[0]?.appointment"
                  [hasAttachment]="c.messages[0]?.attachment"
                  [hasInvoice]="!!c.messages[0]?.invoice"
                  [isFlaggedUser]="isOtherUserFlagged(c)"
                  [otherUserDeleted]="!!otherUser(c)?.deleted_at"
                  (conversationClick)="goToConversation(c)"
                ></app-conversation-preview>
                <div
                  *ngIf="moreAvailable && !isLoading"
                  class="has-text-centered"
                >
                  <button
                    (click)="nextPage()"
                    class="button is-primary is-rounded moreLink"
                  >
                    {{ "messaging.list.load-more" | translate }}
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
