import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class GlobalTokenInterceptor implements HttpInterceptor {
  constructor(
    @Inject('AUTH0_HTTP_INTERCEPTOR')
    private auth0Interceptor: HttpInterceptor
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.auth0Interceptor.intercept(request, next);
  }
}
