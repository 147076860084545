import { Component, OnInit } from '@angular/core';
import { Invoice } from 'src/app/entities/invoice.model';
import { User } from 'src/app/entities/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { OfficeService } from '../shared/service/office.service';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { LoadingAnimationComponent } from '../../shared/components/loading-animation/loading-animation.component';
import { CurrencyPipe, NgFor, NgIf, UpperCasePipe } from '@angular/common';

@Component({
  selector: 'office-balance',
  templateUrl: './office-balance.component.html',
  styleUrls: ['./office-balance.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    LoadingAnimationComponent,
    RouterLink,
    NgFor,
    UpperCasePipe,
    CurrencyPipe,
    TranslateModule,
  ],
})
export class OfficeBalanceComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private officeService: OfficeService
  ) {}

  public currentUser: User;
  public stripeConnectAccount: any;
  public balances: any;
  public currencies: any;
  public invoices: Invoice[];
  public openInvoices: Invoice[];
  public openInvoicesSum: number = 0;

  ngOnInit() {
    this.sharedService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.officeService.stripeConnectAccount.subscribe(
      (stripeConnectAccount) => {
        if (stripeConnectAccount) {
          this.stripeConnectAccount = stripeConnectAccount;
          this.getAccountData();
          this.loadBalance();
        }
      }
    );

    this.officeService.accountBalances.subscribe((balances) => {
      if (balances) {
        this.balances = balances;
        this.currencies = Object.keys(balances);
      }
    });
    // trigger load or this component will not show anything
    this.officeService.loadStripeConnectAccount();
  }

  getAccountData() {
    this.officeService.invoices.subscribe((invoices) => {
      if (invoices) {
        this.invoices = invoices;
        this.openInvoices = invoices.filter(
          (invoice) => invoice.status == 'open'
        );
        if (this.openInvoices.length > 0) {
          this.openInvoicesSum = this.openInvoices
            .map((i) => i.total)
            .reduce((curr, acc) => curr + acc);
        } else {
          this.openInvoicesSum = 0;
        }
      }
    });
    this.officeService.getInvoices();
  }

  loadBalance() {
    this.officeService.loadAccountBalance();
  }
}
