<h2 mat-dialog-title>{{ "settings.calendar.working-hours.add-working-hours" | translate }}</h2>
<mat-dialog-content>
  <div class="is-flex is-flex-direction-column">
    <app-time-slot-picker
      [startTime]="newSlot.start_hour + ':' + newSlot.start_minute"
      [endTime]="newSlot.end_hour + ':' + newSlot.end_minute"
      [isBusy]="isBusy"
      [allowRemoval]="false"
      (startTimeChange)="updateStartTime($event)"
      (endTimeChange)="updateEndTime($event)"
    ></app-time-slot-picker>
  </div>
  <div *ngIf="slotError"  class="slot-error">{{ slotError }}</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ "common.cancel" | translate }}</button>
  <button mat-flat-button color="primary" (click)="saveSlot()" [disabled]="isBusy || slotError">{{ "common.save" | translate }}</button>
</mat-dialog-actions>
