import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/entities/user.model';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { Animation } from '../../../shared/animations/fade-animation';
import { formatAddress } from '../../../shared/helpers/stripeAddress';
import { AmplitudeAnalyticsService } from '../../../shared/services/analytics/amplitude-analytics.service';
import { FeaturesService } from '../../../shared/services/features/features.service';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';
import {
  RetentionCouponId,
  SettingsService,
} from '../service/settings.service';
import { SubscriptionService } from '../service/subscription.service';
import { FormsModule } from '@angular/forms';
import {
  CurrencyPipe,
  DatePipe,
  NgClass,
  NgFor,
  NgIf,
  PercentPipe,
} from '@angular/common';
import { SubscriptionStatus } from '../../../entities/subscription-status.model';
import { SubscriptionBilling } from '../../../entities/subscription-billing.model';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  animations: [Animation.fadeAnimation],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    FormsModule,
    PercentPipe,
    CurrencyPipe,
    DatePipe,
    TranslateModule,
  ],
})
export class SubscriptionComponent implements OnInit {
  @Input()
  public currentUser: User;

  protected readonly formatAddress = formatAddress;

  public coupon: string = '';
  public coupons: Array<any>;

  public subscriptionStateLoaded: boolean = false;
  public subscriptionStatus: SubscriptionStatus;
  public subscriptionBilling: SubscriptionBilling;
  public cancelingSubscription: boolean = false;
  public shouldCancelSubscription: boolean = false;

  public cancellingScheduledSubscriptionChange: boolean = false;
  public downgradingSubscription: boolean = false;
  public upgradeAmmountDue: number;
  public acceptingOffer: boolean = false;
  public currentDowngradeScreen: DowngradeSubscreen = null;
  public currentDowngradeReasons = new Set<string>();
  public currentDowngradeComment: string = null;
  public downgradeScreenType = DowngradeSubscreen;

  public busy: boolean;

  public isAllowedToSubscribe: boolean = false;

  constructor(
    private settingsService: SettingsService,
    private alertService: AlertService,
    private analytics: AmplitudeAnalyticsService,
    private translate: TranslateService,
    private featuresService: FeaturesService,
    public subscriptionService: SubscriptionService,
    private router: Router
  ) {}

  ngOnInit() {
    const subscribeFirstEnabled =
      this.featuresService.hasUserAccessToTrialAndEventuallyApproved(
        this.currentUser
      );
    this.isAllowedToSubscribe =
      subscribeFirstEnabled || this.currentUser.profile.profile_approved;
    this.getCoupons();
    this.getSubscription();
    this.getSubscriptionBilling();
  }

  getCoupons() {
    this.settingsService.getCoupons().subscribe((coupons) => {
      this.coupons = coupons;
    });
  }

  getSubscription() {
    this.settingsService.getSubscriptionStatus().subscribe(
      (subscription) => {
        this.subscriptionStateLoaded = true;
        this.subscriptionStatus = subscription;
        if (!this.subscriptionStatus.has_active_subscription) {
          // this should not happen, but in case, it should go to the plan picker (or show not allowed)
          if (this.isAllowedToSubscribe) {
            this.router.navigate(['home/settings/plan-picker']);
          } else {
            this.subscriptionStateLoaded = true;
          }
        }
      },
      (error) => {
        MonitoringService.captureException(error);
        this.alertService.error(
          this.translate.instant(
            'office.settings.subscription.subscription_not_found'
          )
        );
      }
    );
  }

  getSubscriptionBilling() {
    this.settingsService.getSubscriptionBilling().subscribe((billing) => {
      this.subscriptionBilling = billing;
    });
  }

  cancelSubscription() {
    this.cancelingSubscription = true;
    this.settingsService.cancelSubscription().subscribe((response) => {
      this.cancelingSubscription = false;
      this.shouldCancelSubscription = false;
      if (response.success) {
        this.alertService.success(
          this.translate.instant(
            'office.settings.subscription.subscription_cancel_success'
          )
        );
        this.subscriptionStateLoaded = false;
        this.getSubscription();
      } else {
        this.alertService.success(
          this.translate.instant(
            'office.settings.subscription.subscription_cancel_error'
          )
        );
      }
    });
  }

  downgradeSubscription() {
    this.downgradingSubscription = true;
    this.settingsService.downgradeSubscription().subscribe(
      (response) => {
        this.downgradingSubscription = false;
        this.hideDowngradeFlow();
        if (response.success) {
          this.showSubscriptionUpdateSuccess();
          this.subscriptionStateLoaded = false;
          this.getSubscription();
        } else {
          this.showSubscriptionUpdateError();
        }
      },
      () => {
        this.downgradingSubscription = false;
        this.showSubscriptionUpdateError();
      }
    );
  }

  cancelDowngradeFlow() {
    this.analytics.trackDowngradeCanceled();
    this.hideDowngradeFlow();
  }

  private hideDowngradeFlow() {
    this.currentDowngradeScreen = null;
    this.currentDowngradeReasons = new Set<string>();
    this.currentDowngradeComment = null;
  }

  showDowngradeScreen(screen: DowngradeSubscreen) {
    this.currentDowngradeScreen = screen;
    this.analytics.trackDowngradeScreenViewed({ sub_screen: screen });
  }

  confirmDowngrade() {
    this.analytics.trackDowngradeConfirmed();

    // do not show the retention coupon for yearly (the UX is bad right now as it interrupts their yearly payment)
    if (!this.subscriptionStatus.should_show_retention_coupon) {
      this.downgradeSubscription();
      return;
    }

    this.downgradingSubscription = true;
    this.settingsService.getDowngradeOffer().subscribe(
      (response) => {
        if (!response.offer) {
          this.downgradeSubscription();
        } else if (response.offer.id === 'offer_basic_3') {
          this.downgradingSubscription = false;
          this.showDowngradeScreen(DowngradeSubscreen.CouponOfferBasic3);
        } else if (response.offer.id === 'offer_basic_6') {
          this.downgradingSubscription = false;
          this.showDowngradeScreen(DowngradeSubscreen.CouponOfferBasic6);
        } else if (response.offer.id === 'offer_basic_6_low') {
          this.downgradingSubscription = false;
          this.showDowngradeScreen(DowngradeSubscreen.CouponOfferBasic6Low);
        } else {
          this.downgradingSubscription = false;
          this.alertService.error(
            'common.generic_error.message',
            'common.generic_error.title',
            false,
            undefined,
            false
          );
        }
      },
      () => {
        this.downgradeSubscription();
      }
    );
  }

  completeSurvey() {
    this.analytics.trackDowngradeSurveySubmitted({
      reasons: this.currentDowngradeReasons,
      comment: this.currentDowngradeComment,
    });
    this.showDowngradeScreen(DowngradeSubscreen.Confirmation);
  }

  acceptOffer() {
    const couponId = this.getCurrentRetentionOfferId();
    this.acceptingOffer = true;
    this.settingsService
      .downgradeToBasicUsingRetentionOffer(couponId)
      .subscribe(
        (response) =>
          this.handleRetentionOfferChangeResponse(response, couponId),
        () => this.handleRetentionOfferChangeError()
      );
  }

  private handleRetentionOfferChangeError() {
    this.acceptingOffer = false;
    this.showSubscriptionUpdateError();
  }

  private handleRetentionOfferChangeResponse(
    response: { success: boolean },
    couponType: RetentionCouponId
  ) {
    this.acceptingOffer = false;
    this.hideDowngradeFlow();
    if (response.success) {
      this.analytics.trackRetentionCouponAccepted(couponType);
      this.showSubscriptionUpdateSuccess();
      this.subscriptionStateLoaded = false;
      this.getSubscription();
    } else {
      this.showSubscriptionUpdateError();
    }
  }

  private showSubscriptionUpdateSuccess() {
    this.alertService.success(
      this.translate.instant(
        'office.settings.subscription.subscription_update_success'
      )
    );
  }

  private showSubscriptionUpdateError() {
    this.alertService.error(
      this.translate.instant(
        'office.settings.subscription.subscription_update_error'
      )
    );
  }

  rejectOffer() {
    this.analytics.trackRetentionCouponRejected(
      this.getCurrentRetentionOfferId()
    );
    this.downgradeSubscription();
  }

  toggleDowngradeReason(event) {
    const slug = event.target.id;
    const value = event.target.checked;
    if (value && !this.currentDowngradeReasons.has(slug)) {
      this.currentDowngradeReasons.add(slug);
    } else if (!value && this.currentDowngradeReasons.has(slug)) {
      this.currentDowngradeReasons.delete(slug);
    }
  }

  cancelScheduledSubscriptionChange() {
    this.cancellingScheduledSubscriptionChange = true;
    this.settingsService
      .cancelScheduledSubscriptionChange()
      .subscribe((response) => {
        this.cancellingScheduledSubscriptionChange = false;
        if (response.success) {
          this.alertService.success(
            'office.settings.subscription.subscription_change_cancel_success'
          );
          this.subscriptionStateLoaded = false;
          this.getSubscription();
        } else {
          this.alertService.error(
            'office.settings.subscription.subscription_change_cancel_error'
          );
        }
      });
  }

  showPlanChangeOptions() {
    this.analytics.trackChangePlansClicked();
    this.router.navigate(['home/settings/plan-picker']);
  }

  goToCustomerPortal() {
    window.location.href = this.subscriptionBilling.customer_portal_url;
  }

  getCurrentRetentionOfferId(): RetentionCouponId {
    switch (this.currentDowngradeScreen) {
      case this.downgradeScreenType.CouponOfferBasic6:
        return 'offer_basic_6';
      case this.downgradeScreenType.CouponOfferBasic6Low:
        return 'offer_basic_6_low';
    }
    return 'offer_basic_3';
  }
}

enum DowngradeSubscreen {
  Survey = 'survey',
  Confirmation = 'confirmation',
  CouponOfferBasic3 = 'coupon_offer_basic_3',
  CouponOfferBasic6 = 'coupon_offer_basic_6',
  CouponOfferBasic6Low = 'coupon_offer_basic_6_low',
}
