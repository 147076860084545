import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { of } from 'rxjs';
import { createNewWaitingRoomLink } from '../../shared/helpers/navigation_helper';

export const waitingRoomRoutingGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const slug = next.params.slug;
  const waitingRoomLink = createNewWaitingRoomLink(slug);
  console.log('waitingRoomLink', waitingRoomLink);
  window.location.href = waitingRoomLink;
  return of(false);
};
