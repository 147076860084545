<section class="dashboard-teaser">
  <header class="dashboard-teaser-header">
    <h3 class="dashboard-teaser-title">
      {{ "home.status.title" | translate }}
    </h3>
    <div
      class="qmarkCircle tooltip is-tooltip-multiline"
      [attr.data-tooltip]="'home.status.tooltip' | translate"
    ></div>
  </header>
  <div class="card">
    <div class="card-content" *ngIf="profile">
      <div
        class="notification is-primary"
        *ngIf="shouldShowUpdateReminder() && !editing"
      >
        <div class="columns is-paddingless">
          <div class="column">
            {{ "home.status.not-updated" | translate }}
          </div>
          <div class="column is-narrow has-text-right">
            <button class="button is-rounded is-small" (click)="editing = true">
              {{ "common.edit" | translate }}
            </button>
            <button
              class="button is-rounded is-primary is-small"
              (click)="updateStatus()"
            >
              {{ "home.status.still-ok" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="notification is-warning"
        *ngIf="hasLimitedProfile && canUpdateVisibility && !editing"
      >
        <div class="columns is-paddingless">
          <div class="column">
            {{ "home.status.limited-profile" | translate }}
          </div>
          <div class="column is-narrow has-text-right">
            <button class="button is-rounded is-small" (click)="seePlans()">
              {{ "home.status.limited-profile-cta" | translate }}
            </button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!editing">
        <div class="columns is-gapless is-mobile">
          <div class="column is-narrow">
            <div class="status-icon">
              <ng-container [ngSwitch]="profile.availability">
                <ng-container *ngSwitchCase="'not_available_for_clients'">
                  <svg
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="-"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="My-dashboard---Initial-view-(Therapists)-NEW"
                        transform="translate(-542.000000, -649.000000)"
                      >
                        <rect
                          fill="#FFFFFF"
                          x="0"
                          y="0"
                          width="1500"
                          height="3358"
                        ></rect>
                        <g id="Page" fill="#5D6872">
                          <g
                            id="Status-update"
                            transform="translate(521.000000, 583.000000)"
                          >
                            <g
                              id="Heading"
                              transform="translate(1.000000, 0.000000)"
                            >
                              <g
                                id="Status"
                                transform="translate(20.000000, 57.000000)"
                              >
                                <g id="Not-available-for-clients">
                                  <g
                                    id="Not-available-for-clients-icon"
                                    transform="translate(0.000000, 9.000000)"
                                  >
                                    <path
                                      d="M4.28,8.868 L11.7213333,8.868 L11.7213333,7.132 L4.28,7.132 L4.28,8.868 Z M8,0 C3.58133333,0 0,3.58133333 0,8 C0,12.4186667 3.58133333,16 8,16 C12.4186667,16 16,12.4186667 16,8 C16,3.58133333 12.4186667,0 8,0 L8,0 Z"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </ng-container>

                <ng-container *ngSwitchCase="'profile_hidden'">
                  <svg
                    width="21px"
                    height="18px"
                    viewBox="0 0 21 18"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="-"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="My-dashboard---Initial-view-(Therapists)-NEW"
                        transform="translate(-542.000000, -648.000000)"
                      >
                        <rect
                          fill="#FFFFFF"
                          x="0"
                          y="0"
                          width="1500"
                          height="3358"
                        ></rect>
                        <g id="Page" fill="#5D6872">
                          <g
                            id="Status-update"
                            transform="translate(521.000000, 583.000000)"
                          >
                            <g
                              id="Heading"
                              transform="translate(1.000000, 0.000000)"
                            >
                              <g
                                id="Status"
                                transform="translate(20.000000, 57.000000)"
                              >
                                <g id="Profile-hidden">
                                  <g
                                    id="Profile-hidden-icon"
                                    transform="translate(0.000000, 8.000000)"
                                  >
                                    <g>
                                      <path
                                        d="M17.377,4.7627 L14.637,7.5027 C14.733,7.8637 14.801,8.2347 14.801,8.6257 C14.801,11.0357 12.847,12.9907 10.436,12.9907 C10.046,12.9907 9.674,12.9227 9.314,12.8257 L7.241,14.8987 C8.249,15.3187 9.321,15.5837 10.436,15.5837 C16.2,15.5837 20.873,8.6257 20.873,8.6257 C20.873,8.6257 19.517,6.6207 17.377,4.7627"
                                        id="Fill-1"
                                      ></path>
                                      <path
                                        d="M6.4666,10.4197 C6.2186,9.8717 6.0716,9.2667 6.0716,8.6257 C6.0716,6.2157 8.0256,4.2617 10.4366,4.2617 C11.0776,4.2617 11.6816,4.4077 12.2306,4.6557 L6.4666,10.4197 Z M16.8866,-0.0003 L14.2476,2.6387 C13.0656,2.0567 11.7826,1.6687 10.4366,1.6687 C4.6726,1.6687 -0.0004,8.6257 -0.0004,8.6257 C-0.0004,8.6257 1.5616,10.9407 3.9856,12.9007 L1.7316,15.1547 L2.2376,15.6597 L3.8526,17.2757 L6.5506,14.5777 L8.5716,12.5567 L14.3676,6.7607 L16.8256,4.3027 L18.9726,2.1557 L19.0076,2.1207 L16.8866,-0.0003 Z"
                                        id="Fill-3"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <svg
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="-"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="My-dashboard---Initial-view-(Therapists)-NEW"
                        transform="translate(-542.000000, -649.000000)"
                      >
                        <rect
                          fill="#FFFFFF"
                          x="0"
                          y="0"
                          width="1500"
                          height="3358"
                        ></rect>
                        <g id="Page">
                          <g
                            id="Status-update"
                            transform="translate(521.000000, 583.000000)"
                          >
                            <g
                              id="Heading"
                              transform="translate(1.000000, 0.000000)"
                            >
                              <g
                                id="Status"
                                transform="translate(20.000000, 57.000000)"
                              >
                                <g id="Available-for-clients">
                                  <g
                                    id="Available-for-clients-icon"
                                    transform="translate(0.000000, 9.000000)"
                                  >
                                    <g id="Group-5">
                                      <path
                                        d="M16,8 C16,12.4186667 12.4186667,16 8,16 C3.58133333,16 0,12.4186667 0,8 C0,3.58133333 3.58133333,0 8,0 C12.4186667,0 16,3.58133333 16,8"
                                        id="Fill-1"
                                        fill="#5D6872"
                                      ></path>
                                      <path
                                        d="M6.8696,12.0294667 C6.64426667,12.0294667 6.42693333,11.9414667 6.2656,11.7841333 L3.3896,8.98546667 L4.5976,7.74413333 L6.8096,9.8948 L11.4162667,4.4628 L12.7376,5.58413333 L7.53093333,11.7241333 C7.37493333,11.9068 7.15093333,12.0174667 6.91093333,12.0294667 C6.8976,12.0294667 6.88293333,12.0294667 6.8696,12.0294667"
                                        id="Fill-3"
                                        fill="#FFFFFF"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="column">
            <h4 class="status-title">
              {{
                "home.status.availabilites." + profile.availability | translate
              }}
            </h4>
            <p>{{ profile.statusmessage }}</p>
          </div>
          <div class="column is-narrow">
            <button
              class="button is-primary is-rounded is-small"
              (click)="editing = true"
              [disabled]="shouldShowUpdateReminder() || !canUpdateVisibility"
            >
              <i class="icon icon-edit"></i>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="editing">
        <div class="field">
          <div class="control">
            <div class="select">
              <select
                [(ngModel)]="profile.availability"
                class="ng-deep ng2-timezone-picker"
                [disabled]="!canUpdateVisibility"
              >
                <option
                  *ngFor="let availability of availabilites"
                  [value]="availability"
                >
                  {{ "home.status.availabilites." + availability | translate }}
                </option>
              </select>
            </div>
          </div>

          <span *ngIf="!canUpdateVisibility" class="help has-text-danger">{{
            "home.status.make-profile-visible" | translate
          }}</span>
        </div>
        <div class="field">
          <div class="control">
            <textarea
              class="textarea-fullborder"
              [(ngModel)]="statusMessage"
              name="name"
              id="ide"
              cols="30"
              rows="3"
              maxlength="140"
            ></textarea>
            <p class="help">{{ "home.status.help-text" | translate }}</p>
          </div>
        </div>
        <div class="card-actions">
          <button class="button is-rounded" (click)="editing = false">
            {{ "common.cancel" | translate }}
          </button>
          <button
            class="button is-primary is-rounded"
            (click)="updateStatus($event)"
          >
            {{ "common.save" | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</section>
