import { Channel } from 'angular2-actioncable';
import { WebsocketService } from './../service/websocket.service';
import { Message } from './../../../entities/message.model';
import { User } from './../../../entities/user.model';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MessagingService } from '../service/messaging.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Conversation } from 'src/app/entities/conversation.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CryptoService } from '../service/crypto.service';
import { delay } from 'src/app/shared/helpers/delays.helper';
import { Animation } from 'src/app/shared/animations/fade-animation';
import {
  CurrencyPipe,
  DatePipe,
  NgClass,
  NgFor,
  NgIf,
  UpperCasePipe,
} from '@angular/common';
import { Profile } from 'src/app/entities/profile.model';
import { environment } from 'src/environments/environment';
import { FileSystemFileEntry, NgxFileDropModule } from 'ngx-file-drop';
import { DirectUpload } from 'activestorage';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { ClientsService } from '../../my-clients/service/clients.service';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { SupportService } from 'src/app/shared/services/support.service';
import { OfficeService } from 'src/app/office/shared/service/office.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { ClientProfileService } from 'src/app/client-profile/client-profile.service';
import { Connection } from 'src/app/entities/connection.model';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';
import { AmplitudeAnalyticsService } from '../../../shared/services/analytics/amplitude-analytics.service';
import { BookingFormContainerComponent } from '../../booking/booking-form-container/booking-form-container.component';
import { PaymentType } from '../../../entities/PaymentType';
import { LinkyModule } from 'ngx-linky';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentModalComponent } from '../../../shared/components/payment-modal/payment-modal.component';
import { ImageModalComponent } from '../image-modal/image-modal.component';
import { NoteModalComponent } from '../../my-clients/note-modal/note-modal.component';
import { AutosizeModule } from 'ngx-autosize';
import { FormsModule } from '@angular/forms';
import { LogAttachmentComponent } from '../log-attachment/log-attachment.component';
import { InvoiceAttachmentComponent } from '../invoice-attachment/invoice-attachment.component';
import { BookingAttachmentComponent } from '../booking-attachment/booking-attachment.component';
import { ImageAttachmentComponent } from '../image-attachment/image-attachment.component';
import { ViewportNotifier } from '../../../shared/directives/viewport-notifier';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatchedByIcComponent } from '../../../shared/components/badges/matched-by-ic/matched-by-ic.component';
import { ProfilePictureComponent } from '../../../shared/components/profile-picture/profile-picture.component';
import { SidenavComponent } from '../../../frame/sidenav/sidenav.component';
import { ConversationHeaderComponent } from '../conversation-header/conversation-header.component';
import { getProfileImageUrl } from '../../../shared/helpers/profile-image-helper';
import { Appointment } from '../../../entities/appointment.model';
import { EapClientBannerComponent } from '../eap-client-banner/eap-client-banner.component';
import { EapNotAllowedBannerComponent } from '../eap-not-allowed-banner/eap-not-allowed-banner.component';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
  animations: [Animation.fadeAnimation],
  standalone: true,
  imports: [
    SidenavComponent,
    NgxFileDropModule,
    NgIf,
    RouterLink,
    ProfilePictureComponent,
    NgClass,
    MatchedByIcComponent,
    MatTooltip,
    MatIcon,
    NgFor,
    ViewportNotifier,
    ImageAttachmentComponent,
    BookingAttachmentComponent,
    InvoiceAttachmentComponent,
    LogAttachmentComponent,
    FormsModule,
    AutosizeModule,
    BookingFormContainerComponent,
    NoteModalComponent,
    ImageModalComponent,
    PaymentModalComponent,
    UpperCasePipe,
    CurrencyPipe,
    DatePipe,
    TranslateModule,
    LinkyModule,
    ConversationHeaderComponent,
    EapClientBannerComponent,
    EapNotAllowedBannerComponent,
  ],
})
export class ConversationComponent implements OnInit, OnDestroy {
  protected readonly PaymentType = PaymentType;
  constructor(
    private router: Router,
    private messagingService: MessagingService,
    private sharedService: SharedService,
    private cryptoService: CryptoService,
    private socketService: WebsocketService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationsService,
    private clientService: ClientsService,
    private alertService: AlertService,
    private supportService: SupportService,
    private officeService: OfficeService,
    private clientProfileService: ClientProfileService,
    private analytics: AmplitudeAnalyticsService
  ) {}

  @ViewChild('messageInput', { read: ElementRef, static: false })
  messageInput: ElementRef;

  @ViewChild('fileattachment', { read: ElementRef, static: false })
  fileattachment: ElementRef;

  @ViewChild('messages', { read: ElementRef, static: false })
  messages: ElementRef;

  @ViewChild('bookingModal', { static: false })
  bookingModal: BookingFormContainerComponent;

  @ViewChild('acceptBookingButton', { static: false })
  acceptBookingButton: ElementRef;

  public selectedImageMessage: any;

  public conversation: Conversation;
  public currentUser: User;
  public otherUser: any;
  public isSupportChat: boolean = false;
  public otherUserOnlineStatus: boolean = false;
  private statusTimeout: any;

  public env = environment;
  profile: Profile;
  showMessageSettings: boolean = false;
  sendOnEnter: boolean = true;
  isLoadingMoreMessages: boolean;
  hasMoreMessages: boolean = false;

  eapNotAllowedReason: string;

  uploading: boolean = false;
  uploadProgress: number = 0;

  public message: Message;

  public isLoading: boolean = true;
  public sendingMessage: boolean = false;

  public selectedMessage: Message;

  private socketChannel: Channel;
  private userChannel: Channel;

  public showNoteModal: boolean = false;

  public currentTimezone: string =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  public showTimezone: boolean = false;

  public wantsToArchiveConversation: boolean = false;
  public wantsToReportUser: boolean = false;
  public isReportingUser: boolean = false;
  public report: any = {
    includeMessages: true,
    description: '',
  };

  public isConnected: boolean;
  public isOnline: boolean = true;

  offlineEvent: Observable<Event>;
  onlineEvent: Observable<Event>;
  onoffsubscriptions: Subscription[] = [];

  therapistConnection: Connection;

  private handleAppConnectivityChanges(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.onoffsubscriptions.push(
      this.onlineEvent.subscribe((e) => {
        this.isOnline = true;
      })
    );

    this.onoffsubscriptions.push(
      this.offlineEvent.subscribe((e) => {
        this.isOnline = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.onoffsubscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }

  ngOnInit() {
    this.handleAppConnectivityChanges();
    this.message = new Message();
    this.message.body = '';

    this.sharedService.currentUser.subscribe((user) => {
      const isFirstLoad = !this.currentUser;
      this.currentUser = user;
      if (this.currentUser) {
        this.profile = user.profile;
        this.showTimezone =
          this.currentTimezone !== this.currentUser.profile.timezone;
        this.eapNotAllowedReason = !!this.currentUser.eap_access_code
          ? this.currentUser.cannot_book_eap_session_reason
          : null;

        if (isFirstLoad) {
          this.getConversation();
        }
      }
    });
    this.loadSettings();
  }

  getConversation() {
    this.messagingService
      .getConversation(this.activatedRoute.snapshot.params.id)
      .subscribe((res) => {
        if (!res || !res['data'] || !res['data']['attributes']) {
          MonitoringService.captureMessage('Conversation not found: ', {
            extra: {
              response: res,
            },
          });
          return;
        }

        this.conversation = res['data']['attributes'];
        this.getOtherUser();
        this.isSupportChat = this.otherUser.id === environment.supportUserId;

        if (this.otherUser) {
          this.userChannel = this.socketService.getUserChannel(this.otherUser);
          this.subscribeToOtherStatus();
          if (this.currentUser.type == 'Client') {
            this.getTherapistConnection();
          }
        }
        this.decryptMessages();
        this.isLoading = false;

        this.hasMoreMessages =
          this.conversation.messages &&
          this.conversation.messages.length < this.conversation.messages_count;

        this.subscribeToChannel();

        delay(250).then(() => {
          this.scrollToBottom();
          this.notificationService.getUnreadMessageCount();
        });
      });
  }

  decryptMessages() {
    this.cryptoService
      .assertKey(this.conversation)
      .then(() => {
        this.conversation.messages.forEach((msg) => {
          if (!msg.body || msg.body.trim() == '-') {
            msg.body = '';
            msg.decrypted = true;
          }
          if (!msg.encrypted || msg.decrypted) {
            msg.decrypted = true;
            return;
          }
          this.cryptoService
            .decrypt(msg.body, this.conversation.key)
            .then((body) => {
              msg.body = body;
              msg.decrypted = true;
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onMessageChanged(messageId: number) {
    this.socketChannel.send({
      action: 'reloadMessageAction',
      m_id: messageId,
      c_id: this.conversation.id,
    });
  }

  onMessageInViewport(isVisible: boolean, message: Message) {
    if (
      isVisible &&
      message.read == false &&
      message.user_id != this.currentUser.id
    ) {
      this.messagingService
        .setMessageAsRead(this.conversation.id, message.id)
        .subscribe();
      message.read = true;
    }
  }

  private subscribeToOtherStatus() {
    this.userChannel.received().subscribe((signal) => {
      if (signal.type == 'onlinestatus') {
        this.otherUserOnlineStatus = true;
        if (this.statusTimeout) {
          clearTimeout(this.statusTimeout);
        }
        this.statusTimeout = setTimeout(() => {
          this.otherUserOnlineStatus = false;
        }, 15000);
      }
    });
  }

  private subscribeToChannel(): void {
    if (this.socketChannel && this.socketChannel.connected) {
      return;
    }
    this.socketChannel = this.socketService.subscribeToConversation(
      this.conversation,
      this.currentUser
    );

    this.socketChannel.received().subscribe((msg) => {
      if (msg.action == 'reloadMessageAction') {
        this.messagingService
          .getMessage(msg.c_id, msg.m_id)
          .subscribe((updatedMessage) => {
            new Promise<string>((resolve) => {
              if (updatedMessage.body && updatedMessage.body.trim() == '-') {
                resolve('');
              } else {
                this.cryptoService
                  .decrypt(updatedMessage.body, this.conversation.key)
                  .then((body) => resolve(body));
              }
            })
              .then((body) => {
                updatedMessage.body = body;
                updatedMessage.decrypted = true;
                this.conversation.messages.forEach((message, index) => {
                  if (message.id == updatedMessage.id) {
                    this.conversation.messages[index] = updatedMessage;
                  }
                });
              })
              .catch((err) => {
                console.log(err);
              });
          });
        return;
      }

      new Promise<string>((resolve) => {
        if (msg.body && msg.body.trim() == '-') {
          resolve('');
        } else {
          if (!msg.encrypted || msg.decrypted) {
            resolve(msg.body);
          } else {
            this.cryptoService
              .decrypt(msg.body, this.conversation.key)
              .then((body) => resolve(body));
          }
        }
      })
        .then((body) => {
          msg.body = body;
          msg.decrypted = true;
          this.conversation.messages.push(msg);
        })
        .catch((err) => {
          console.log(err);
        });
    });

    this.socketChannel.rejected().subscribe((err) => {
      MonitoringService.captureException(err);
      this.isConnected = false;
    });
    this.socketChannel.disconnected().subscribe((err) => {
      this.isConnected = false;
      this.subscribeToChannel();
    });
    this.socketChannel.connected().subscribe((err) => {
      this.isConnected = true;
    });
  }

  scrollToBottom = () => {
    try {
      delay(50).then(() => {
        if (this.messages) {
          this.messages.nativeElement.scrollTop =
            this.messages.nativeElement.scrollHeight;
        }
      });
    } catch (err) {}
  };

  getOtherUser() {
    if (!this.currentUser) {
      return;
    }
    this.otherUser =
      this.conversation.sender.id == this.currentUser.id
        ? this.conversation.recipient
        : this.conversation.sender;
  }

  wasSentYesterday(conversation: Conversation, index: number): boolean {
    let current = new Date(conversation.messages[index].created_at);
    let last = new Date(conversation.messages[index - 1].created_at);
    current.setHours(12, 0, 0, 0);
    last.setHours(12, 0, 0, 0);
    return current.getTime() - last.getTime() >= 86400000;
  }

  onKeydown(event: KeyboardEvent) {
    var sendAttempt =
      (event.key === 'Enter' && this.sendOnEnter && !event.shiftKey) ||
      (event.key === 'Enter' && !this.sendOnEnter && event.shiftKey);
    if (sendAttempt) {
      event.preventDefault();
      if (
        !this.sendingMessage &&
        (this.message.body ||
          this.message.appointment ||
          this.message.attachment)
      ) {
        this.sendMessage();
      }
    }
  }

  sendMessage() {
    this.message.user_id = this.currentUser.id;
    this.sendingMessage = true;

    new Promise((resolve) => {
      if (this.message.attachment) {
        var upload = new DirectUpload(
          this.message.attachment,
          this.env.railsBaseUrl + '/api/direct_uploads',
          this
        );
        this.uploading = true;
        upload.create((err, blob) => {
          this.uploading = false;
          if (!err) {
            resolve(blob.signed_id);
          } else {
            resolve(null);
          }
        });
      } else {
        resolve(null);
      }
    })
      .then((attachment) => {
        this.message.attachment = attachment;

        new Promise<string>((resolve, reject) => {
          if (!this.message.body) {
            resolve('');
          } else {
            this.message.body = this.message.body.trim();
            this.cryptoService
              .encrypt(this.message.body, this.conversation.key)
              .then((body) => {
                resolve(body);
              });
          }
        }).then((body) => {
          this.message.body = body;
          let message: Message = this.message;

          if (message.appointment) {
            message.appointment.timestamp = this.sharedService
              .adjustProfileTimezoneDateToUTC(
                new Date(message.appointment.timestamp)
              )
              .getTime();
          }

          this.subscribeToChannel(); //in case connection dropped
          this.socketChannel.send({
            message: message,
            conversation_id: this.conversation.id,
          });

          this.sharedService.track('message_created');
          this.message.body = '';
          this.message.appointment = null;
          this.message.attachment = null;
          this.sendingMessage = false;

          if (this.messageInput) {
            this.messageInput.nativeElement.focus();
          }
          delay(500).then(() => this.scrollToBottom());
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onFileDropped(files) {
    if (files && files.length > 0) {
      var file = files[0].fileEntry as FileSystemFileEntry;
      file.file((f: File) => {
        this.setFile([f]);
      });
    }
  }

  setFile(files) {
    if (files && files.length == 1) {
      var file: File = files[0];
      if (file.size / 1024 / 1024 > 10) {
        alert('The maximum size for attachments is 10 MB.');
      } else {
        this.sendingMessage = true;
        var that = this;
        new Promise((resolve) => {
          that.message.attachment = file;
          resolve(true);
        })
          .then(() => {
            this.sendingMessage = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  deleteMessage() {
    if (this.selectedMessage) {
      this.messagingService
        .deleteMessage(this.selectedMessage.id, this.conversation.id)
        .subscribe((response) => {
          if (this.selectedMessage) {
            this.onMessageChanged(this.selectedMessage.id);
            this.selectedMessage = null;
          }
        });
    }
  }

  removeIntent() {
    this.message.appointment = null;
    this.message.attachment = null;
    if (this.fileattachment) {
      this.fileattachment.nativeElement.value = null;
    }
  }

  public fetchMoreMessages() {
    this.isLoadingMoreMessages = true;
    let currentAmount = this.conversation.messages
      ? this.conversation.messages.length
      : 0;
    var that = this;

    this.messagingService
      .getConversationByAmount(currentAmount, this.conversation.id)
      .subscribe((res) => {
        new Promise((resolve, reject) => {
          var count = 0;
          if (!res.messages || res.messages.length == 0) {
            resolve(true);
          }
          res.messages.forEach((m: Message) => {
            this.cryptoService.assertKey(this.conversation).then(() => {
              this.cryptoService
                .decrypt(m.body, that.conversation.key)
                .then((data) => {
                  m.body = !m.encrypted ? m.body : data;
                  m.decrypted = true;
                  count++;
                  if (count == res.messages.length) {
                    resolve(true);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          });
        })
          .then(() => {
            if (res.messages) {
              this.conversation.messages = res.messages.concat(
                this.conversation.messages
              );
            }
            that.isLoadingMoreMessages = false;
            that.hasMoreMessages =
              that.conversation.messages &&
              that.conversation.messages.length < res['total'];
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }

  public showActions(message, event) {
    if (
      event.target.classList.contains('linkified') ||
      message.user_id != this.currentUser.id
    ) {
      event.stopPropagation();
      return;
    }
    this.selectedMessage = message;
  }

  public openBookingModal() {
    this.analytics.trackScheduleAppointmentStarted({
      source_page: 'messages_page',
    });
    this.bookingModal.showForNewClientBooking(this.otherUser);
  }

  public editAppointment(appointment: Appointment) {
    // this is tracked in appointment actions
    this.bookingModal.showForEditingAppointment(appointment);
  }

  public onNoteModalStateChanged(e) {
    if (!e) {
      this.showNoteModal = false;
    }
  }

  public openImageModal(message) {
    this.selectedImageMessage = message;
  }

  public onImageModalStateChanged(e) {
    if (!e) {
      this.selectedImageMessage = null;
    }
  }

  toggleMessageSettings() {
    this.showMessageSettings = !this.showMessageSettings;
    this.saveSettings();
  }

  archiveConversation = (id: number, value: boolean) => {
    this.messagingService.archiveConversation(id, value).subscribe(
      () => {
        if (value) {
          this.alertService.success(
            'messaging.conversation-hidden',
            null,
            true
          );
        } else {
          this.alertService.success(
            'messaging.conversation-un-hidden',
            null,
            true
          );
        }
        this.router.navigate(['/home/messaging/']);
      },
      () => {
        this.alertService.error(
          'common.generic_error.message',
          'common.generic_error.title'
        );
      }
    );
  };

  loadSettings() {
    this.sendOnEnter = window.localStorage.getItem('icMsgSOE') == 'true';
  }

  saveSettings() {
    window.localStorage.setItem(
      'icMsgSOE',
      this.sendOnEnter ? 'true' : 'false'
    );
  }

  parsedLog(log: string) {
    if (log) {
      try {
        return JSON.parse(log);
      } catch (e) {
        return {};
      }
    }
  }

  reportUser() {
    if (this.isReportingUser) {
      return;
    }
    this.isReportingUser = true;

    const messages = this.report.includeMessages
      ? this.conversation.messages
      : [];
    this.clientService
      .reportClient(this.otherUser, this.report.description, messages)
      .subscribe((response) => {
        if (response.status == 'ok') {
          this.alertService.success('User reported');
          this.otherUser.flagged = true;
        }
        this.isReportingUser = false;
        this.report.description = '';
        this.wantsToReportUser = false;
      });
  }
  requestSupport() {
    this.supportService.showReportModal();
  }

  createNewInvoice() {
    this.officeService.toggleCreateInvoiceModal(true);
  }

  getTherapistConnection() {
    this.clientProfileService
      .loadTherapist(this.otherUser.id)
      .subscribe((connection) => {
        this.therapistConnection = connection.data[0].attributes;
      });
  }

  protected readonly getProfileImageUrl = getProfileImageUrl;
}
