import { NgModule } from '@angular/core';
import { Routes, ExtraOptions, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'practice',
    loadChildren: () =>
      import('../client-center/client-center.module').then(
        (m) => m.ClientCenterModule
      ),
  },
  {
    path: 'home/settings/profile',
    loadChildren: () =>
      import('../client-profile/client-profile.module').then(
        (m) => m.ClientProfileModule
      ),
    data: {
      breadcrumb: {
        label: 'Client-Center',
      },
    },
  },
  {
    path: 'home/my_clients',
    loadChildren: () =>
      import('../components/my-clients/my-clients.module').then(
        (m) => m.MyClientsModule
      ),
    data: {
      breadcrumb: {
        label: 'My Clients',
      },
    },
  },
];
const options: ExtraOptions = { scrollPositionRestoration: 'top' };

@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
