<section class="dashboard-teaser">
  <header class="dashboard-teaser-header">
    <h3 class="dashboard-teaser-title">
      {{ "home.appointments-title" | translate }}
    </h3>
  </header>

  <div class="card" *ngIf="nextAppointments && currentUser">
    <div
      class="card-content appointment"
      *ngFor="let appointment of nextAppointments"
    >
      <div class="columns is-vcentered">
        <div class="column appointment-details">
          <div class="client">
            <div class="profile-picture">
              <app-profile-picture
                *ngIf="currentUser.type == 'Therapist'"
                [profile]="appointment.client.profile"
                [variant]="'thumbnail'"
              ></app-profile-picture>
              <app-profile-picture
                *ngIf="currentUser.type == 'Client'"
                [profile]="appointment.profile"
                [variant]="'thumbnail'"
              ></app-profile-picture>
            </div>
            <p class="appointment-title">
              <span *ngIf="currentUser.type == 'Therapist'">
                {{ appointment.client ? appointment.client.name : "Busy" }}
              </span>
              <span *ngIf="currentUser.type == 'Client'">
                {{
                  appointment.profile.firstname +
                    " " +
                    appointment.profile.lastname
                }}
              </span>
            </p>
          </div>
          <div class="appointment-time">
            <i class="icon icon-calendar"></i>
            <p>{{ appointment.start | date : "EE, MMMM d 'at' h:mm a" }}</p>
          </div>
        </div>
        <div class="column is-narrow" *ngIf="appointment.is_online && appointment.can_use_video">
          <shared-call-button [appointment]="appointment"></shared-call-button>
        </div>
      </div>
    </div>
    <div
      class="card-footer"
      *ngIf="showTimezoneHint && nextAppointments.length > 0"
    >
      <div class="card-footer-item">
        <p>
          {{ "onboarding.boxes.next-appointment.timezone-differs" | translate
          }}<a [routerLink]="['/home/settings']">{{
            "onboarding.boxes.next-appointment.settings" | translate
          }}</a
          >.
          {{
            "onboarding.boxes.next-appointment.appointments-timezone"
              | translate
          }}
          <strong>{{ currentUser.profile.timezone }}</strong
          >.
        </p>
      </div>
    </div>
    <div *ngIf="!nextAppointments || nextAppointments.length == 0" class="card">
      <div class="card-content content has-text-centered">
        <p>
          {{
            "onboarding.boxes.next-appointment.upcoming-appointments"
              | translate
          }}
        </p>
      </div>
    </div>
  </div>
</section>
