<form [formGroup]="requestBookingForm" class="inner-form">
  <section class="modal-card-body content f">
    <label class="label"
      >{{ "messaging.booking-modal.select-client" | translate }}:</label
    >
    <div class="field" *ngIf="clients">
      <div class="control">
        <div class="select is-fullwidth">
          <select id="client" formControlName="client" name="client">
            <option [ngValue]="null">
              {{ "calendar.select-client" | translate }}
            </option>
            <option [ngValue]="c" *ngFor="let c of clients">
              {{ c.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <p [innerHTML]="'calendar.date-specific-scheduling-hint' | translate"></p>
  </section>
  <footer class="modal-card-foot">
    <button class="button is-rounded" (click)="createBlocker()">
      {{ "messaging.booking-modal.add-block" | translate }}
    </button>
    <button
      class="button is-primary is-rounded"
      [disabled]="!requestBookingForm.controls.client.value"
      (click)="next()"
    >
      {{ "common.next" | translate }}
    </button>
  </footer>
</form>
