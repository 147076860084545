import { Component, Input } from '@angular/core';
import { TherapistOnboardingEventuallyApproveSteps } from '../../../components/home/therapist-onboarding-eventually-approve-box/therapist-onboarding-eventually-approve-steps';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-onboarding-eventually-approve-progress-bar',
  templateUrl:
    './therapists-onboarding-eventually-approve-progress-bar.component.html',
  styleUrls: [
    './therapists-onboarding-eventually-approve-progress-bar.component.scss',
  ],
  standalone: true,
  imports: [NgClass, NgIf, TranslateModule],
})
export class TherapistsOnboardingEventuallyApproveProgressBarComponent {
  constructor() {}

  @Input()
  onboardingStep: TherapistOnboardingEventuallyApproveSteps;
  ONBOARDINGSTEP = TherapistOnboardingEventuallyApproveSteps;

  @Input()
  embedded: Boolean;
}
