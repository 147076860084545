<div
  id="popover"
  [@slideInOut]="hidden ? 'out' : 'in'"
  class="popover bs-popover-bottom"
  role="tooltip"
>
  <div class="arrow is-hidden-touch"></div>
  <div class="popover-body">
    <div class="columns is-mobile">
      <div class="column progress-bar">
        <svg width="100%" height="100%" viewBox="0 0 42 42" class="donut">
          <circle
            class="donut-hole"
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="#fff"
          ></circle>
          <circle
            class="donut-ring"
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="transparent"
            stroke="#ffffff"
            stroke-width="3"
          ></circle>
          <circle
            class="donut-ring-shadow"
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="transparent"
            stroke="#ebeaea"
            stroke-width="3"
          ></circle>
          <circle
            class="donut-segment"
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="transparent"
            stroke="#ffd564"
            stroke-width="3"
            [attr.stroke-dasharray]="dashArray"
            stroke-dashoffset="25"
          ></circle>
          <text x="50%" y="50%" fill="#373736" text-anchor="middle" dy=".3em">
            {{ percentage }}%
          </text>
        </svg>
      </div>
      <div class="column content">
        <p>{{ content }}</p>
        <div class="columns is-mobile">
          <div class="column">
            <button class="button is-rounded" (click)="closeTourModal()">
              {{ "profile.modal.supporttext.button.skip" | translate }}
            </button>
          </div>
          <div class="column has-text-right prevnext">
            <button
              class="button is-half-rounded-left"
              (click)="goToPrevious()"
            >
              {{ "profile.modal.supporttext.button.back" | translate }}
            </button>
            <button
              class="button is-primary is-half-rounded-right"
              [disabled]="disableNext"
              (click)="goToNext()"
            >
              {{ "profile.modal.supporttext.button.next" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
