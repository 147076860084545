<h2 mat-dialog-title>{{ "office.settings.subscription.plan_cycle_switch_confirmation.title" |
  translate }}</h2>
<mat-dialog-content>
  <p>
    {{ (data.isAnnualSwitch ?
    "office.settings.subscription.plan_cycle_switch_confirmation.message_annual"
    :
    "office.settings.subscription.plan_cycle_switch_confirmation.message_monthly")
    | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ "office.settings.subscription.plan_cycle_switch_confirmation.cancel" |
    translate }}
  </button>
  <button mat-flat-button color="primary" [disabled]="isChangingBillingCycle"
          [matLoadingButton]="isChangingBillingCycle"
          (click)="performCycleChange()" cdkFocusInitial>
    {{ "office.settings.subscription.plan_cycle_switch_confirmation.confirm" |
      translate }}
  </button>
</mat-dialog-actions>
