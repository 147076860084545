import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/entities/user.model';
import { Invoice } from 'src/app/entities/invoice.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateModule } from '@ngx-translate/core';
import {
  CurrencyPipe,
  DatePipe,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';

@Component({
  selector: 'app-invoice-attachment',
  templateUrl: './invoice-attachment.component.html',
  styleUrls: ['./invoice-attachment.component.scss'],
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgIf,
    CurrencyPipe,
    DatePipe,
    TranslateModule,
  ],
})
export class InvoiceAttachmentComponent implements OnInit {
  constructor(private sharedService: SharedService) {}

  @Input() invoice: Invoice;
  @Input() currency: string;
  @Input() clientId: number;
  @Input() currentUser: User;

  ngOnInit() {
    console.log('InvoiceAttachmentComponent created');
  }

  payAndConfirm() {
    this.sharedService.setPayableInvoice(this.invoice.id);
  }

  viewInvoice() {
    this.payAndConfirm();
  }
}
