import { Component, OnInit } from '@angular/core';
import { ICAuthService } from '../service/i-c-auth.service';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../entities/user.model';
import { AmplitudeAnalyticsService } from '../../shared/services/analytics/amplitude-analytics.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  standalone: true,
})
export class RegisterComponent implements OnInit {
  constructor(
    private authService: ICAuthService,
    private activatedRoute: ActivatedRoute,
    private analytics: AmplitudeAnalyticsService
  ) {}

  ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      const URL = url[0].path;
      const user = new User();
      if (URL === 'register') {
        user.type = 'Therapist';
      } else {
        user.type = 'Client';
      }
      this.analytics.setUserType(user.type);
      this.activatedRoute.queryParams.subscribe(
        async (params: Record<string, any>) => {
          this.authService.goToRegistration({ ...params }).subscribe();
        }
      );
    });
  }
}
