import { Component } from '@angular/core';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { AlertService } from '../../../shared/components/alert/service/alert.service';
import { CalendarSettingsService } from '../../settings/service/calendar-settings.service';
import { SlotHelper } from '../../settings/calendar-working-hours/slot.helper';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { DatePipe, DecimalPipe, NgFor, NgIf } from '@angular/common';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import {
  MatButton,
  MatButtonModule,
  MatIconButton,
} from '@angular/material/button';
import { MatLoadingButtonDirective } from '../../../shared/directives/mat-loading-button-directive';
import { MatTooltip } from '@angular/material/tooltip';
import { MatOption } from '@angular/material/autocomplete';
import { MatFormField, MatSelect } from '@angular/material/select';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TimeSlotPickerComponent } from '../time-slot-picker/time-slot-picker.component';
import moment from 'moment';

@Component({
  selector: 'appointment-appointment-cancel-confirm-dialog',
  templateUrl: 'add-specific-working-hours-dialog.component.html',
  styleUrls: ['./add-specific-working-hours-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    DecimalPipe,
    DatePipe,
    NgIf,
    NgFor,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatIcon,
    MatButton,
    MatDialogClose,
    TranslateModule,
    MatLoadingButtonDirective,
    MatIconButton,
    MatTooltip,
    MatOption,
    MatSelect,
    MatFormField,
    NgxMatTimepickerModule,
    MatInput,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    TimeSlotPickerComponent,
  ],
})
export class AddSpecificWorkingHoursDialogComponent {
  today: Date = moment()
    .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    .toDate();
  isDateRangeSelected: boolean = false;
  isBusy: boolean = false;
  selectedDateRange: { start: Date; end: Date };
  workingHours: {
    start_hour: number;
    start_minute: number;
    end_hour: number;
    end_minute: number;
  }[] = [];

  constructor(
    private calendarSettingsService: CalendarSettingsService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<AddSpecificWorkingHoursDialogComponent>
  ) {}

  resetBlocks(): void {
    this.workingHours = [];
    this.addBlock();
  }

  addBlock(): void {
    if (this.workingHours.length < 5) {
      const initialSlot = SlotHelper.setInitialSlotTimes(this.workingHours);
      this.workingHours.push({
        start_hour: initialSlot.start_hour,
        start_minute: initialSlot.start_minute,
        end_hour: initialSlot.end_hour,
        end_minute: initialSlot.end_minute,
      });
    }
  }

  removeBlock(index: number): void {
    this.workingHours.splice(index, 1);
  }

  onDateRangeChange(event): void {
    this.selectedDateRange = event;
  }

  onDateRangeSelected(): void {
    if (this.selectedDateRange) {
      this.isDateRangeSelected = true;
      this.resetBlocks();
    }
  }

  onComplete(): void {
    this.isBusy = true;
    const availabilities =
      this.calendarSettingsService.createAvailabilitiesForDateRange(
        this.selectedDateRange[0],
        this.selectedDateRange[1],
        this.workingHours
      );

    this.calendarSettingsService
      .createDateSpecificAvailabilities(availabilities)
      .subscribe(
        () => {
          this.alertService.success(
            'settings.calendar.date-specific-hours.add-hours-dialog.availability-create-success'
          );
          this.isBusy = false;
          this.dialogRef.close(true);
        },
        (error) => {
          this.alertService.error(
            'settings.calendar.date-specific-hours.add-hours-dialog.availability-create-error'
          );
          this.isBusy = false;
        }
      );
  }

  updateStartTime(value: string, index: number): void {
    this.workingHours[index].start_hour = Number(value.split(':')[0]);
    this.workingHours[index].start_minute = Number(value.split(':')[1]);
  }

  updateEndTime(value: string, index: number): void {
    this.workingHours[index].end_hour = Number(value.split(':')[0]);
    this.workingHours[index].end_minute = Number(value.split(':')[1]);
  }
}
