<div class="mapcontainer">
  <div class="map" #map></div>
  <div id="popup" *ngIf="_profile && _profile.city">
    <div class="popover bs-popover-right" role="tooltip">
      <div class="arrow"></div>
      <div class="popover-body">
        {{ _profile.street }} {{ _profile.number }}<br />
        {{ _profile.zip }} {{ _profile.city.name_en }}
        <ng-container *ngIf="currentUser">
          <i
            id="button-profile-address"
            *ngIf="currentUser?.id == _profile?.user?.id"
            (click)="edit.emit()"
            class="icon icon-edit"
          ></i>
        </ng-container>
      </div>
    </div>
  </div>
</div>
