import { CurrencyService } from './../../shared/services/currency/currency.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { User } from 'src/app/entities/user.model';

import { OfficeService } from '../shared/service/office.service';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { StripeIbanComponent, StripeService } from 'ngx-stripe';
import { ProfileService } from 'src/app/components/profile/service/profile.service';
import { CountryService } from 'src/app/shared/services/countries/country.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MonitoringService } from '../../shared/services/monitoring/monitoring.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OfficeInvoiceSettingsComponent } from '../office-invoice-settings/office-invoice-settings.component';
import { OfficeSetupStripeLauncherComponent } from './office-setup-stripe-launcher/office-setup-stripe-launcher.component';
import { FormsModule } from '@angular/forms';
import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-office-setup',
  templateUrl: './office-setup.component.html',
  styleUrls: ['./office-setup.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    NgClass,
    OfficeSetupStripeLauncherComponent,
    OfficeInvoiceSettingsComponent,
    DatePipe,
    TranslateModule,
  ],
})
export class OfficeSetupComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private alertService: AlertService,
    private officeService: OfficeService,
    private stripeService: StripeService,
    private profileService: ProfileService,
    private countryService: CountryService,
    private currencyService: CurrencyService,
    private router: Router,
    private translationService: TranslateService
  ) {}

  @ViewChild(StripeIbanComponent, { static: false })
  public env;
  public currentUser: User;
  public showPayoutSchedule: boolean = false;
  public stripeConnectAccount: any;
  public requirementsErrorsExist = false;
  public stripeCurrentlyDueAccountLinks: any;
  public stripeEventuallyDueAccountLinks: any;
  public stripeConnectRestrictionDeadline: Date;
  public currentlyDueRequirementsExist = false;
  public eventuallyDueRequirementsExist = false;
  public hasAcceptedStripeTOS = false;
  public isCreatingAccount = false;
  public isChangingPhoneNumber = false;
  public taxrate: number;
  public countries: Array<any>;
  public currencies;
  public countrySet = false;
  public bankAccount: any;
  public invoicingAvailableForProfileCountry = false;
  private weeklyPayoutAnchorDefault = 'wednesday';
  private monthlyPayoutAnchorDefault = 15;
  public selectedPayoutScheduleInterval: string = 'manual';
  public selectedPayoutScheduleWeeklyAnchor: string =
    this.weeklyPayoutAnchorDefault;
  public selectedPayoutScheduleMonthlyAnchor = this.monthlyPayoutAnchorDefault;
  public monthlyPayoutAnchors: number[] = Array.from(
    { length: 31 },
    (_, index) => index + 1
  );
  public isDeletingAccount: boolean = false;
  public isDeletingAccountConfirmed: boolean = false;

  public isUpdatingPayoutSchedule = false;
  public isUpdatingBusinessInformation = false;

  ngOnInit() {
    this.env = environment;
    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.showPayoutSchedule = true;
        if (this.env) {
          this.countrySet = !!this.currentUser.profile.country;
          this.invoicingAvailableForProfileCountry =
            this.env.stripe_available_countries.includes(
              this.currentUser.profile.country
            );
        }

        if (this.currentUser.profile.stripe_connect_id) {
          this.officeService.loadStripeConnectAccount();
          const isOnboarding = !this.currentUser.profile.stripe_connect_active;
          this.officeService.loadCurrentlyDueStripeAccountLinks(isOnboarding);
          this.officeService.loadEventuallyDueStripeAccountLinks(isOnboarding);
          this.currentlyDueRequirementsExist =
            this.currentUser.profile.stripe_connect_has_currently_due_requirements;
          this.eventuallyDueRequirementsExist =
            this.currentUser.profile.stripe_connect_has_eventually_due_requirements;
          this.requirementsErrorsExist =
            this.currentUser.profile.stripe_connect_has_errors;
          this.stripeConnectRestrictionDeadline =
            this.currentUser.profile.stripe_connect_restriction_deadline;
        }
      } else {
        this.showPayoutSchedule = false;
      }
    });

    this.officeService.stripeCurrentlyDueAccountLinks.subscribe((links) => {
      this.stripeCurrentlyDueAccountLinks = links;
    });

    this.officeService.stripeEventuallyDueAccountLinks.subscribe((links) => {
      this.stripeEventuallyDueAccountLinks = links;
    });

    this.officeService.stripeConnectAccount.subscribe(
      (stripeConnectAccount) => {
        if (stripeConnectAccount) {
          this.stripeConnectAccount = stripeConnectAccount;
          this.selectedPayoutScheduleInterval =
            this.stripeConnectAccount.settings.payouts.schedule.interval;

          if (this.selectedPayoutScheduleInterval === 'weekly') {
            this.selectedPayoutScheduleWeeklyAnchor =
              this.stripeConnectAccount.settings.payouts.schedule.weekly_anchor;
          } else if (this.selectedPayoutScheduleInterval === 'monthly') {
            this.selectedPayoutScheduleMonthlyAnchor =
              this.stripeConnectAccount.settings.payouts.schedule.monthly_anchor;
          }

          if (!this.stripeConnectAccount.individual.phone) {
            this.isChangingPhoneNumber = true;
          }

          this.bankAccount = this.stripeConnectAccount.external_accounts.data
            .filter((a) => a.object === 'bank_account')
            .shift();
        }
      }
    );
    this.countries = this.countryService.getCountries();
    this.currencies = this.currencyService.getCurrencies();
    this.currencies.forEach((c) => {
      c.code = c.code.toLowerCase();
    });
  }

  updateStripeConnectAccount() {
    if (this.isUpdatingBusinessInformation) {
      return;
    }
    this.isUpdatingBusinessInformation = true;
    this.officeService
      .updateStripeConnectAccount(this.stripeConnectAccount)
      .subscribe((response) => {
        if (response.status && response.status == 'error') {
          this.alertService.error(response.error);
        } else {
          this.setStripeConnectAccount(response);
          this.alertService.success('office.events.account_updated');
        }
        this.isUpdatingBusinessInformation = false;
      });
  }

  private setStripeConnectAccount(account) {
    // update all in memory places where connect account data is stored
    // ideally this would be more reactive but for now this will do
    this.officeService.setStripeConnectAccount(account);
    const profile = {
      ...this.currentUser.profile,
      stripe_connect_active:
        account.charges_enabled &&
        account.payouts_enabled &&
        account.details_submitted,
      stripe_connect_has_currently_due_requirements:
        account.requirements.currently_due.length > 0,
      stripe_connect_has_eventually_due_requirements:
        account.requirements.eventually_due.length > 0,
      stripe_connect_has_errors: account.requirements.errors.length > 0,
      stripe_connect_restriction_deadline:
        account.requirements.current_deadline,
      stripe_connect_payout_interval:
        account.settings.payouts.schedule.interval,
    };

    this.sharedService.setCurrentUser(
      Object.assign({}, this.currentUser, { profile })
    );
    this.profileService.setProfile(profile);
  }

  onPayoutIntervalChanged(e) {
    this.selectedPayoutScheduleInterval = e.target.value;
  }

  hasPayoutScheduleChanges() {
    if (
      this.stripeConnectAccount.settings.payouts.schedule.interval !==
      this.selectedPayoutScheduleInterval
    ) {
      return true;
    }

    if (this.selectedPayoutScheduleInterval === 'weekly') {
      return (
        this.stripeConnectAccount.settings.payouts.schedule.weekly_anchor !==
        this.selectedPayoutScheduleWeeklyAnchor
      );
    } else if (this.selectedPayoutScheduleInterval === 'monthly') {
      return (
        this.stripeConnectAccount.settings.payouts.schedule.monthly_anchor !==
        this.selectedPayoutScheduleMonthlyAnchor
      );
    }
    return false;
  }

  updatePayoutSchedule() {
    if (this.isUpdatingPayoutSchedule) {
      return;
    }
    this.isUpdatingPayoutSchedule = true;
    this.stripeConnectAccount.settings.payouts.schedule.interval =
      this.selectedPayoutScheduleInterval;

    if (this.selectedPayoutScheduleInterval === 'weekly') {
      this.stripeConnectAccount.settings.payouts.schedule.weekly_anchor =
        this.selectedPayoutScheduleWeeklyAnchor;
    } else if (this.selectedPayoutScheduleInterval === 'monthly') {
      this.stripeConnectAccount.settings.payouts.schedule.monthly_anchor =
        this.selectedPayoutScheduleMonthlyAnchor;
    }

    this.officeService
      .updateStripeConnectAccount(this.stripeConnectAccount)
      .subscribe((response) => {
        if (response.status && response.status == 'error') {
          this.alertService.error(
            'office.settings.payout_schedule.update_error'
          );
        } else {
          this.setStripeConnectAccount(response);
          this.alertService.success(
            'office.settings.payout_schedule.update_success'
          );
          this.isUpdatingPayoutSchedule = false;
        }
      });
  }

  goToEditProfile() {
    this.router.navigate(['/home/profile']).then((r) => console.log(r));
  }

  updateSupportAddress(partialAddress: Partial<any>) {
    this.stripeConnectAccount.business_profile.support_address =
      {
        ...this.stripeConnectAccount.business_profile.support_address,
        ...partialAddress,
      } || partialAddress;
  }

  createAccount() {
    if (!this.hasAcceptedStripeTOS) {
      this.alertService.error('You need to accept the Terms of Service');
      return;
    }
    if (this.isCreatingAccount) {
      return;
    }
    this.isCreatingAccount = true;
    this.officeService
      .createStripeConnectAccount('individual')
      .subscribe((response) => {
        if (response.success === 'true') {
          // we need to create a new user object or angular will not update the views
          const profile = response.profile;
          this.sharedService.setCurrentUser({
            ...this.currentUser,
            profile,
          });
          this.isCreatingAccount = false;
          this.officeService.stripeCurrentlyDueAccountLinks.subscribe(
            (links) => {
              if (links && links.url) {
                window.location.href = links.url;
              } else {
                MonitoringService.captureMessage(
                  'No verification links after stripe account creation'
                );
              }
            }
          );
        } else {
          this.isCreatingAccount = false;
          this.translationService
            .get('invoicing.stripe-account-setup-error', {
              error: response.message,
            })
            .subscribe((m) => {
              this.alertService.error(m);
            });
        }
      });
  }

  onPayoutWeeklyAnchorChanged($event: Event) {
    this.selectedPayoutScheduleWeeklyAnchor = (
      $event.target as HTMLInputElement
    ).value;
  }
  onPayoutMonthlyAnchorChanged($event: Event) {
    this.selectedPayoutScheduleMonthlyAnchor = Number(
      ($event.target as HTMLInputElement).value
    );
  }

  deleteOfficeAccount() {
    this.isDeletingAccountConfirmed = true;
    this.officeService.deleteStripeConnectAccount().subscribe((response) => {
      if (response.success) {
        const newProfile = {
          ...this.currentUser.profile,
          stripe_connect_active: false,
        };
        this.sharedService.setCurrentUser({
          ...this.currentUser,
          profile: newProfile,
        });
        this.profileService.setProfile(newProfile);
        this.router.navigate(['/home']);
      } else {
        this.alertService.error(
          'There was a problem deleting your stripe account. If the problem persists, please contact support.'
        );
      }
    });
  }
}
