import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'languageList',
  standalone: true,
})
export class LanguageListPipe implements PipeTransform {
  constructor() {}

  transform(languages: Array<any>) {
    if (!languages) {
      return '';
    }
    return languages
      .sort(function (a, b) {
        return a.name > b.name ? 1 : -1;
      })
      .map((lang) => {
        return lang.name;
      })
      .join(', ');
  }
}
