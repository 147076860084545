import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/components/my-clients/service/clients.service';
import { Appointment } from 'src/app/entities/appointment.model';
import { Client } from 'src/app/entities/client.model';
import { InvoiceItem } from 'src/app/entities/invoice-item.model';
import { Invoice } from 'src/app/entities/invoice.model';
import { User } from 'src/app/entities/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SortingService } from 'src/app/shared/services/sorting/sorting.service';
import { OfficeService } from '../shared/service/office.service';
import { AlertService } from '../../shared/components/alert/service/alert.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { AutosizeModule } from 'ngx-autosize';
import { InvoiceUnbilledItemComponent } from './invoice-unbilled-item/invoice-unbilled-item.component';
import { FormsModule } from '@angular/forms';
import {
  CurrencyPipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  UpperCasePipe,
} from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'office-invoice-create-modal',
  templateUrl: './invoice-create-modal.component.html',
  styleUrls: ['./invoice-create-modal.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    FormsModule,
    NgFor,
    InvoiceUnbilledItemComponent,
    AutosizeModule,
    NgxMaskDirective,
    UpperCasePipe,
    CurrencyPipe,
    TranslateModule,
    MatProgressSpinner,
  ],
})
export class InvoiceCreateModalComponent implements OnInit {
  @Input() currentUser!: User;

  step = 1;
  invoice = new Invoice();
  clients: Client[] = [];
  unbilledAppointments: Appointment[] = [];
  unbilledOnlineSessions: any[] = [];
  clientEmail = '';

  isModalVisible = false;
  isSavingInvoice = false;
  canSaveInvoice = false;
  isLoadingClients = false;

  invoiceExtraItems: InvoiceItem[] = [];

  isInConversation = false;

  constructor(
    private clientService: ClientsService,
    private officeService: OfficeService,
    private sharedService: SharedService,
    private sortingService: SortingService,
    private router: Router,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.subscribeToModalVisibility();
    this.subscribeToUnbilledItems();
  }

  private subscribeToUnbilledItems() {
    this.officeService.unbilledClientAppointment.subscribe((unbilledItems) => {
      if (unbilledItems) {
        this.unbilledAppointments = unbilledItems.appointments;
        this.unbilledOnlineSessions = unbilledItems.onlinesessions;
      }
    });
  }

  private subscribeToModalVisibility() {
    this.officeService.isCreateInvoiceModalVisible.subscribe((state) => {
      if (!this.isModalVisible && state) {
        this.initializeInvoice();
        this.loadClientsAndSetInitialClient();
      }
      this.isModalVisible = state;
    });
  }

  private initializeInvoice() {
    this.invoice = new Invoice();
    this.invoiceExtraItems = [];
    this.unbilledAppointments = [];
    this.unbilledOnlineSessions = [];
  }

  private loadClientsAndSetInitialClient() {
    this.isLoadingClients = true;
    this.clientService.getClients().subscribe(
      (clients) => {
        if (clients) {
          this.clients = this.sortingService.sortAlphabetically(
            clients,
            'name'
          );
          this.setInitialClientIfInConversation();
        }
        this.isLoadingClients = false;
      },
      () => {
        this.isLoadingClients = false;
        this.alertService.error(
          'We had a problem loading your clients, please try again later',
          null,
          true
        );
        this.closeModal();
      }
    );
  }

  private setInitialClientIfInConversation() {
    if (this.isCurrentUrl(['messaging', 'clients'])) {
      this.isInConversation = true;
      const clientID = this.extractClientIdFromUrl();
      const preselectedClient = this.clients.find((c) => c.id === clientID);
      if (preselectedClient) {
        this.invoice.client = preselectedClient;
        this.onClientSelected();
      }
    } else {
      this.isInConversation = false;
      this.step = 1;
    }
  }

  private isCurrentUrl(paths: string[]): boolean {
    return paths.some((path) => this.router.url.includes(path));
  }

  private extractClientIdFromUrl(): number {
    return parseInt(this.router.url.replace(/\D/g, ''), 10);
  }

  closeModal() {
    this.isSavingInvoice = false;
    this.officeService.toggleCreateInvoiceModal(false);
  }

  onClientSelected() {
    if (this.invoice.client?.id) {
      this.officeService.getUnbilledClientAppointments(this.invoice.client.id);
      this.invoice.invoice_items = [];
      this.setStep(2);
    }
  }

  setStep(step: number) {
    this.step = step;
  }

  addAppointment(appointment: Appointment) {
    appointment.selected = true;
    const item = this.createInvoiceItemForAppointment(appointment);
    this.invoice.invoice_items.push(item);
  }

  private createInvoiceItemForAppointment(
    appointment: Appointment
  ): InvoiceItem {
    const item = new InvoiceItem();
    item.appointment = appointment;
    item.appointment_id = appointment.id;
    item.amount = 1;
    item.price = Math.round(this.currentUser.profile.price_per_session);
    item.name = `Therapie-Session on ${new Date(
      appointment.start
    ).toDateString()}`;
    return item;
  }

  removeAppointment(appointment: Appointment) {
    appointment.selected = false;
    this.invoice.invoice_items = this.invoice.invoice_items.filter(
      (invoiceItem) => invoiceItem.appointment.id !== appointment.id
    );
  }

  addOnlineSession(onlineSession: any) {
    onlineSession.selected = true;
    const item = this.createInvoiceItemForOnlineSession(onlineSession);
    this.invoice.invoice_items.push(item);
  }

  private createInvoiceItemForOnlineSession(onlineSession: any): InvoiceItem {
    const item = new InvoiceItem();
    item.online_session_id = onlineSession.id;
    item.amount = 1;
    item.price = Math.round(this.currentUser.profile.price_per_session);
    item.name = `Therapie-Video Call on ${new Date(
      onlineSession.created_at
    ).toDateString()}`;
    return item;
  }

  removeOnlineSession(onlineSession: any) {
    onlineSession.selected = false;
    this.invoice.invoice_items = this.invoice.invoice_items.filter(
      (invoiceItem) => invoiceItem.online_session_id !== onlineSession.id
    );
  }

  addExtraItem() {
    const invoiceItem = new InvoiceItem();
    invoiceItem.amount = 1;
    this.invoiceExtraItems.push(invoiceItem);
  }

  removeExtraItem(index: number) {
    this.invoiceExtraItems.splice(index, 1);
  }

  saveInvoice() {
    if (this.isSavingInvoice) {
      return;
    }

    if (!this.invoice.client) {
      if (!this.clientEmail) {
        this.alertService.error(
          'invoicing.modal.steps.save-invoice-error-no-client',
          'invoicing.modal.steps.save-invoice-error-title'
        );
        this.setStep(1);
        return;
      }
      this.invoice.client_email = this.clientEmail;
    }

    this.isSavingInvoice = true;

    if (this.invoiceExtraItems.length > 0) {
      this.invoice.invoice_items = this.invoice.invoice_items.concat(
        this.invoiceExtraItems
      );
    }

    this.officeService.createInvoice(this.invoice).subscribe(
      (response) => this.handleInvoiceCreationResponse(response),
      (response) => this.handleInvoiceCreationError(response)
    );
  }

  private handleInvoiceCreationResponse(response: any) {
    if (response.status === 'error') {
      this.alertService.error(
        response.message,
        'invoicing.modal.steps.save-invoice-error-title'
      );
      this.isSavingInvoice = false;
      return;
    }
    this.router.navigate(['office', 'invoices', response.invoice.id]);
    this.closeModal();
  }

  private handleInvoiceCreationError(response: any) {
    this.alertService.error(
      response.error.message,
      'invoicing.modal.steps.save-invoice-error-title'
    );
    this.isSavingInvoice = false;
  }
}
