import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { User } from '../../../entities/user.model';

@Component({
  selector: 'app-eap-not-allowed-banner',
  templateUrl: './eap-not-allowed-banner.component.html',
  styleUrls: ['./eap-not-allowed-banner.component.scss'],
  standalone: true,
  imports: [TranslateModule, NgIf, MatTooltip, MatIcon],
})
export class EapNotAllowedBannerComponent implements OnInit {
  @Input() currentUser: User;
  eapNotAllowedReason: string = null;
  constructor() {}

  ngOnInit(): void {
    this.eapNotAllowedReason = !!this.currentUser.eap_access_code
      ? this.currentUser.cannot_book_eap_session_reason
      : null;
  }
}
