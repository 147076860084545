<div class="str-chat str-chat-channel messaging str-chat__channel str-chat__theme-light" *ngIf="!isActiveChannel && !isError">
  <div class="str-chat__loading-channel">
    <div class="str-chat__loading-channel-header">
      <div class="str-chat__loading-channel-header-avatar"></div>
      <div class="str-chat__loading-channel-header-end">
        <div class="str-chat__loading-channel-header-name"></div>
        <div class="str-chat__loading-channel-header-info"></div>
      </div>
    </div>
    <div class="str-chat__loading-channel-message-list">
      <div class="str-chat__loading-channel-message">
        <div class="str-chat__loading-channel-message-avatar"></div>
        <div class="str-chat__loading-channel-message-end">
          <div class="str-chat__loading-channel-message-sender"></div>
          <div class="str-chat__loading-channel-message-last-row">
            <div class="str-chat__loading-channel-message-text"></div>
            <div class="str-chat__loading-channel-message-date"></div>
          </div>
        </div>
      </div>
      <div class="str-chat__loading-channel-message">
        <div class="str-chat__loading-channel-message-avatar"></div>
        <div class="str-chat__loading-channel-message-end">
          <div class="str-chat__loading-channel-message-sender"></div>
          <div class="str-chat__loading-channel-message-last-row">
            <div class="str-chat__loading-channel-message-text"></div>
            <div class="str-chat__loading-channel-message-date"></div>
          </div>
        </div>
      </div>
      <div class="str-chat__loading-channel-message">
        <div class="str-chat__loading-channel-message-avatar"></div>
        <div class="str-chat__loading-channel-message-end">
          <div class="str-chat__loading-channel-message-sender"></div>
          <div class="str-chat__loading-channel-message-last-row">
            <div class="str-chat__loading-channel-message-text"></div>
            <div class="str-chat__loading-channel-message-date"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="str-chat__loading-channel-message-input-row">
      <div class="str-chat__loading-channel-message-input"></div>
      <div class="str-chat__loading-channel-message-send"></div>
    </div>
  </div>
</div>
