import { Profile } from './profile.model';
import { Appointment } from './appointment.model';
import { Coupon } from './coupon.model';
import { Abilities } from './abilities.model';

export class User {
  email: string;
  password: string;
  profile_id: number;
  password_confirmation: string;
  privateKey: string;
  publicKey: string;
  verified: boolean;
  country_code: string;
  id: number;
  external_id: string;
  analytics_user_id: string;
  name: string;
  type: 'Therapist' | 'Client';
  last_appointment: Appointment;
  profile: Profile;
  payment: boolean;
  confirmed_at: Date;
  email_confirmed: boolean;
  deleted_at: Date;
  coupon: Coupon;
  diplomas: any[];
  locale: string;
  is_feature_tester: boolean;
  created_at: Date;
  invite_cookie: string;
  websocket_token: string;
  blocked: boolean;
  flagged: boolean;
  eap_access_code: string;
  has_eap_access_code: boolean; // This is used in the conversation object to determine if the user has access to the EAP. Ideally we would use a different model here
  cannot_book_eap_session_reason: string;
  can_book_couples_eap_session: boolean;
  abilities: Abilities;
  marketing_email_optin: boolean;
  application_status:
    | 'not_started'
    | 'survey_completed'
    | 'invite_sent'
    | 'accepted'
    | 'rejected'
    | 'lost';
}
