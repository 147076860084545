import { Directive, Output, EventEmitter } from '@angular/core';
@Directive({
  selector: '[onInit]',
  standalone: true,
})
export class OnInitDirective {
  @Output('onInit') onInit: EventEmitter<any> = new EventEmitter();
  ngOnInit() {
    this.onInit.emit(0);
  }
}
