

export function extractErrorMessage(error: any, genericMessageKey: string): string {
  if (error.error instanceof ErrorEvent) {
    // Client-side or network error
    return error.error.message;
  } else if (error.error && typeof error.error === 'object') {
    // The backend returned an unsuccessful response code.
    // Extracting error message from server response
    return error.error.error || genericMessageKey;
  } else {
    // Generic fallback for other types of errors
    return genericMessageKey;
  }
}
