<div class="card" *ngIf="shouldShowNavigation">
  <div class="tabs">
    <div
      class="tab"
      [routerLink]="['/office/dashboard']"
      routerLinkActive="is-selected"
    >
      {{ "office.mainmenu.balance" | translate }}
    </div>
    <div
      class="tab"
      [routerLink]="['/office/invoices']"
      routerLinkActive="is-selected"
    >
      {{ "office.mainmenu.invoices" | translate }}
    </div>
    <div
      class="tab"
      *ngIf="showStripeConnectEmbeddedUIs"
      [routerLink]="['/office/connect']"
      routerLinkActive="is-selected"
    >
      {{ "office.mainmenu.connect" | translate }} 🧪
    </div>
    <div
      class="tab"
      [routerLink]="['/office/settings']"
      routerLinkActive="is-selected"
    >
      {{ "office.mainmenu.settings" | translate }}
    </div>
  </div>
  <div
    class="card-content"
    *ngIf="shouldShowBalance || shouldShowAccountStatus"
  >
    <office-balance *ngIf="shouldShowBalance"></office-balance>
    <div class="account-status" *ngIf="shouldShowAccountStatus && user">
      <ng-container *ngIf="user.profile.stripe_connect_active == false">
        <span>{{ "office.account_pending" | translate }}</span>
      </ng-container>
      <ng-container *ngIf="user.profile.stripe_connect_active == true">
        <svg
          width="16px"
          height="16px"
          viewBox="0 0 16 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="-"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="My-dashboard-(Therapists)-My-Office---Account-settings"
              transform="translate(-542.000000, -237.000000)"
            >
              <rect
                fill="#FFFFFF"
                x="0"
                y="0"
                width="1500"
                height="3073"
              ></rect>
              <g
                id="Account-active"
                transform="translate(542.000000, 234.000000)"
              >
                <g
                  id="Available-for-clients-icon"
                  transform="translate(0.000000, 3.000000)"
                >
                  <g id="Group-5">
                    <path
                      d="M16,8 C16,12.4186667 12.4186667,16 8,16 C3.58133333,16 0,12.4186667 0,8 C0,3.58133333 3.58133333,0 8,0 C12.4186667,0 16,3.58133333 16,8"
                      id="Fill-1"
                      fill="#5D6872"
                    ></path>
                    <path
                      d="M6.8696,12.0294667 C6.64426667,12.0294667 6.42693333,11.9414667 6.2656,11.7841333 L3.3896,8.98546667 L4.5976,7.74413333 L6.8096,9.8948 L11.4162667,4.4628 L12.7376,5.58413333 L7.53093333,11.7241333 C7.37493333,11.9068 7.15093333,12.0174667 6.91093333,12.0294667 C6.8976,12.0294667 6.88293333,12.0294667 6.8696,12.0294667"
                      id="Fill-3"
                      fill="#FFFFFF"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <span>{{ "office.account_active" | translate }}</span>
      </ng-container>
    </div>
  </div>
</div>
