<div
  [@simpleFadeAnimation]="'in'"
  class="container-fluid container-fluid-limited"
>
  <div class="columns is-marginless">
    <div class="column is-3 is-hidden-touch">
      <app-sidenav></app-sidenav>
    </div>
    <div class="column">
      <nav class="mobile-navbar">
        <h3 class="subtitle is-4 page-title">
          {{ "my-favorite-therapists.title" | translate }}
        </h3>
      </nav>

      <div *ngIf="loaded">
        <div *ngIf="isError; else favoritesList">
          <div class="card gray-border-top">
            <div
              class="card-content is-flex justify-center align-center"
              style="min-height: 200px"
            >
              <div class="notification is-error">
                <p>
                  {{ "my-favorite-therapists.loading-error" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <ng-template #favoritesList>
          <div *ngIf="therapists.length > 0; else noFavorites">
            <div class="flex-container">
              <div *ngFor="let therapist of therapists" class="flex-item">
                <app-favorite-therapist-card
                  [therapist]="therapist"
                  [isFavorite]="favorites.has(therapist.id)"
                  (addFavorite)="addFavorite($event)"
                  (removeFavorite)="removeFavorite($event)"
                  class="pointer"
                  (click)="navigateToProfile(therapist)"
                ></app-favorite-therapist-card>
              </div>
            </div>
          </div>

          <ng-template #noFavorites>
            <div class="card gray-border-top">
              <div
                class="card-content empty-flex-container"
                style="height: 200px"
              >
                <h1>
                  {{ "my-favorite-therapists.no-favorites-title" | translate }}
                </h1>
                <p>
                  {{
                    "my-favorite-therapists.no-favorites-message" | translate
                  }}
                </p>
                <a
                  (click)="navigateToDirectory()"
                  class="button is-primary is-rounded"
                  >{{
                    "my-favorite-therapists.no-favorites-cta" | translate
                  }}</a
                >
              </div>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</div>
