<div
  [@simpleFadeAnimation]="'in'"
  class="container-fluid container-fluid-limited"
>
  <div class="columns is-marginless">
    <div class="column is-3 is-hidden-touch is-marginless">
      <app-sidenav></app-sidenav>
    </div>
    <div class="column">
      <div class="columns is-marginless">
        <div class="column">
          <h3 class="subtitle is-4">{{ "waiting_room.title" | translate }}</h3>
          <!-- LINK -->
          <div class="box">
            <h4 class="heading">
              {{ "videocenter.waiting_room_link" | translate }}
            </h4>
            <div class="waiting-room-instructions">
              <div class="steps">
                <div class="step-item">
                  <div class="step-marker">
                    <span class="icon"
                    ><i class="material-icons lightgray">link</i></span>
                  </div>
                  <div class="step-details">
                    <p class="step-title">{{ "videocenter.waiting_room_instructions_1.title" | translate }}</p>
                    <p>{{ "videocenter.waiting_room_instructions_1.text" | translate }}</p>
                  </div>
                </div>
                <div class="step-item">
                  <div class="step-marker">
                    <span class="icon"
                    ><i class="material-icons lightgray">call</i></span>
                  </div>
                  <div class="step-details">
                    <p class="step-title">{{ "videocenter.waiting_room_instructions_2.title" | translate }}</p>
                    <p>{{ "videocenter.waiting_room_instructions_2.text" | translate }}</p>
                  </div>
                </div>
                <div class="step-item">
                  <div class="step-marker">
                    <span class="icon"
                    ><i class="material-icons lightgray">add</i></span>
                  </div>
                  <div class="step-details">
                    <p class="step-title">{{ "videocenter.waiting_room_instructions_3.title" | translate }}</p>
                    <p>{{ "videocenter.waiting_room_instructions_3.text" | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
            <article>
              <div
                class="waiting-room-link input is-rounded"
                *ngIf="currentUser && currentUser.profile"
              >
                <input class="input" readonly [value]="waitingRoomLink" />
                <button
                  (click)="copyToClipboard()"
                  class="button is-rounded is-primary"
                  [disabled]="!hasAccess"
                >
                  {{ "videocenter.copy_link" | translate }}
                </button>
              </div>
            </article>
          </div>
          <div class="overlay"  *ngIf="showUpgradeCallout">
            <div class="box content">
              <div class="inner-content">
                <p>{{ "videocenter.upgrade.content" | translate }}</p>
                <button
                  class="button is-primary is-rounded"
                  [routerLink]="['/home/settings/subscription']"
                >
                  {{ "videocenter.upgrade.cta" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="overlay" *ngIf="showJoinBetaCallout">
            <div class="box content">
              <div class="inner-content">
                <p>{{ "videocenter.need_beta.content" | translate }}</p>
                <button
                  class="button is-primary is-rounded"
                  (click)="openCommunityBetaPost()"
                >
                  {{ "videocenter.need_beta.cta" | translate }}
                </button>
              </div>
            </div>
          </div>
          <!-- WAITING  -->
          <hr />
          <div class="box"  *ngIf="hasAccess">
            <h4 class="heading">
              {{ "call.in_waiting_room" | translate }}
            </h4>
            <div class="has-margin-bottom-15">
            <p>
              {{ "call.clients_waiting_1" | translate }}
              <strong>{{ waitingSessions.length }}</strong>
              {{ "call.clients_waiting_2" | translate }}
            </p>
            </div>
            <article
              class="media patient has-mar"
              *ngFor="let session of waitingSessions"
            >
              <figure class="media-left">
                <p class="image is-64x64">
                  <app-profile-picture></app-profile-picture>
                </p>
              </figure>
              <div class="media-content">
                <p>
                  <strong>{{ session.display_name }}</strong>
                </p>
                <p>
                  {{ "call.joined_time_ago" | translate : { time: moment(session.created_at).fromNow(true)} }}
                </p>
              </div>
              <button
                class="button is-rounded is-primary"
                (click)="joinSession(session)"
              >
                {{ "call.join_session" | translate }}
              </button>
            </article>
            <p *ngIf="waitingSessions.length == 0">
              {{ "call.patients_will_appear" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
