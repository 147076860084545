import { Injectable } from '@angular/core';
import { GenericHttpService } from './Generic HTTP/generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceStatisticsService {
  constructor(private http: GenericHttpService) {}

  getOverview(range: any) {
    return this.http.post('/office/invoices/stats', {
      sd: range[0],
      ed: range[1],
    });
  }

  getOpenInvoices() {
    return this.http.get('/office/invoices/open');
  }
}
