<section class="modal-card-body">
  <div class="columns">
    <div class="column">
      <div class="box schedule-calendar">
        <label class="label">{{
          "messaging.booking-modal.date-and-time" | translate
        }}</label>
        <form [formGroup]="requestBookingForm">
          <owl-date-time-inline
            [stepMinute]="5"
            [firstDayOfWeek]="1"
            formControlName="appointmentDate"
            [min]="today"
          ></owl-date-time-inline>
          <label class="label has-text-left">{{
            "messaging.booking-modal.duration" | translate
          }}</label>
          <div class="duration">
            <p class="control has-icons-right">
              <input class="input" formControlName="duration" mask="009" />
              <span class="icon is-small is-right">{{
                "common.min" | translate
              }}</span>
            </p>
          </div>
        </form>
      </div>
    </div>
    <div class="column">
      <label class="label">{{
        "messaging.booking-modal.other-appointments" | translate
      }}</label>
      <ng-container *ngIf="!isLoadingOtherAppointments">
        <ng-container *ngIf="otherAppointments?.length > 0">
          <div class="has-margin-bottom-15" *ngFor="let appointment of otherAppointments">
            <span class="heading"
              >{{ appointment.start | date : "shortTime" }} – {{
                appointment.end | date : "shortTime"
              }}</span
            >
            <p *ngIf="appointment.client">
              {{ appointment.client.name }}
            </p>
            <p *ngIf="!appointment.client && appointment.note">
              {{ appointment.note }}
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="otherAppointments?.length == 0">
          <p>
            <i>{{ "messaging.booking-modal.no-appointments" | translate }}</i>
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isLoadingOtherAppointments">
        <button class="button is-text is-loading"></button>
      </ng-container>
    </div>
  </div>
</section>

<footer class="modal-card-foot">
  <button class="button is-rounded" (click)="cancel()">
    {{ "messaging.cancel" | translate }}
  </button>
  <button class="button is-primary is-rounded" (click)="next()">
    {{ "messaging.booking-modal.next" | translate }}
  </button>
</footer>
