import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ICCalendarEventTitleFormatter extends CalendarEventTitleFormatter {
  constructor(private translate: TranslateService) {
    super();
  }

  monthTooltip(event: CalendarEvent): string {
    return this.tooltip(event);
  }

  weekTooltip(event: CalendarEvent): string {
    return this.tooltip(event);
  }

  dayTooltip(event: CalendarEvent): string {
    return this.tooltip(event);
  }

  tooltip(event: CalendarEvent): string {
    if (event['status'] == 'pending') {
      return this.translate.instant('calendar.tooltip-pending', {
        person: event.title,
      });
    }
    return event['status'] == 'external'
      ? event.title + ' ' + this.translate.instant('calendar.tooltip-external')
      : event.title;
  }
}
