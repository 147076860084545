<div class="modal" [ngClass]="{ 'is-active': isModalActive }" *ngIf="note">
  <div class="modal-background"></div>
  <div class="modal-card">
    <button class="delete" aria-label="close" (click)="closeModal()"></button>
    <header class="modal-card-head">
      <p class="modal-card-title">
        <span *ngIf="note.id">{{
          "clientcenter.notes.update_note" | translate
        }}</span>
        <span *ngIf="!note.id">{{
          "clientcenter.notes.create_note" | translate
        }}</span>
      </p>
    </header>

    <!-- å -->
    <section class="modal-card-body content" *ngIf="note">
      <div class="field">
        <label class="label">{{
          "profile.modal.supporttext.title" | translate
        }}</label>
        <div class="control">
          <input class="input" type="text" autofocus [(ngModel)]="note.title" />
        </div>
      </div>
      <div class="field">
        <label class="label">{{
          "clientcenter.notes.template" | translate
        }}</label>
        <div class="select">
          <select
            [(ngModel)]="note.template"
            (ngModelChange)="onTemplateChange()"
          >
            <option
              *ngFor="let template of noteTemplateKeys"
              [value]="template"
            >
              {{
                "clientcenter.notes.templates." + noteTemplate[template]
                  | translate
              }}
            </option>
          </select>
        </div>
      </div>
      <div class="field">
        <label class="label">{{ "my-clients.note" | translate }}</label>
        <div class="control">
          <textarea
            name="body"
            placeholder="Your Note"
            id="body"
            cols="30"
            rows="10"
            [(ngModel)]="note.body"
            class="textarea auth_input"
          ></textarea>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot">
      <button
        class="button is-rounded is-primary is-outlined"
        (click)="closeModal()"
      >
        {{ "common.back" | translate }}
      </button>
      <button
        class="button is-rounded is-primary"
        (click)="update()"
        [ngClass]="{ 'is-loading': isSaving }"
      >
        {{ "office.invoice.actions.save" | translate }}
      </button>
    </footer>
  </div>
</div>
