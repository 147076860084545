import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Message } from 'src/app/entities/message.model';
import { NgIf } from '@angular/common';
import { getFileType } from '../../../office/invoice-create-modal/filetype_helper';

@Component({
  selector: 'message-image-attachment',
  templateUrl: './image-attachment.component.html',
  styleUrls: ['./image-attachment.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class ImageAttachmentComponent implements OnInit {
  @Input()
  message: Message;

  @Output()
  imageClicked: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  doEmbed(url) {
    switch (getFileType(this.getS3Filename(url)).toLowerCase()) {
      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'gif':
        return true;
      default:
        return false;
    }
  }

  getS3Filename(url: string): string | null {
    const decodedUrl = decodeURIComponent(url);
    const parts = decodedUrl.split('/');
    if (parts.length === 0) {
      return null;
    }

    const lastSegment = parts[parts.length - 1];
    const filename = lastSegment.split('?')[0];
    return filename || null;
  }

  openModal() {
    this.imageClicked.emit(this.message);
  }
}
