import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { User } from 'src/app/entities/user.model';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { SafePipe } from '../../../shared/pipes/safe.pipe';
import { FormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AutosizeModule } from 'ngx-autosize';
import { NgIf } from '@angular/common';

const shareLinks = {
  twitter: 'https://twitter.com/home?status=',
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
};

@Component({
  selector: 'social-widgets',
  templateUrl: './social-widgets.component.html',
  styleUrls: ['./social-widgets.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    AutosizeModule,
    AngularSvgIconModule,
    FormsModule,
    TranslateModule,
    SafePipe,
  ],
})
export class SocialWidgetsComponent implements OnInit {
  currentUser: User;

  @ViewChild('linkField', { read: ElementRef, static: false })
  linkField: ElementRef;

  badgeFrameWhite: String;
  @ViewChild('badgeFieldWhite', { read: ElementRef, static: false })
  badgeFieldWhite: ElementRef;

  badgeFrameGreen: String;
  @ViewChild('badgeFieldGreen', { read: ElementRef, static: false })
  badgeFieldGreen: ElementRef;

  contactLabel: String;
  contactEmbed: String;
  @ViewChild('contactField', { read: ElementRef, static: false })
  contactField: ElementRef;

  profileEmbed: String;
  @ViewChild('profileEmbedField', { read: ElementRef, static: false })
  profileEmbedField: ElementRef;
  profileEmbedStyle: String = 'p';
  @ViewChild('profileEmbedContainer', { read: ElementRef, static: false })
  profileEmbedContainer: ElementRef;
  lang: string = 'en';

  constructor(
    private sharedService: SharedService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.sharedService.currentUser.subscribe((user) => {
      this.currentUser = user;

      this.lang = user.locale ? user.locale : 'en';

      if (
        user &&
        user.profile &&
        user.profile.profile_approved &&
        user.profile.slug &&
        user.profile.city
      ) {
        this.badgeFrameWhite =
          '<iframe src="' +
          environment.baseUrl +
          '/embed/' +
          user.profile.slug +
          '/badge/w" frameborder="0" width="198" height="48"></iframe>';
        this.badgeFrameGreen =
          '<iframe src="' +
          environment.baseUrl +
          '/embed/' +
          user.profile.slug +
          '/badge/g" frameborder="0" width="198" height="48"></iframe>';
        this.contactLabel =
          'Send ' + user.profile.firstname + ' a secure message';

        this.profileEmbedStyle = 'p';
        this.setProfileEmbed();
        this.setContactEmbed();
      }
    });
  }

  setContactEmbed() {
    this.contactEmbed =
      '<div class="itscomplicated-contact" style="width:100%;min-width:310px;" data-label="' +
      this.contactLabel +
      '"></div>\n<script type="text/javascript" src="' +
      environment.baseUrl +
      '/api/embed/' +
      this.currentUser.profile.slug +
      '/message"></script>';
  }

  setProfileEmbed() {
    this.profileEmbed = `<div class="itscomplicated-card" style="width:100%;min-width:310px;max-width:1023px"></div>
<script type="text/javascript" src="${environment.baseUrl}/api/embed/${this.currentUser.profile.slug}/card"></script>`;
    var self = this;
    setTimeout(function () {
      self.reloadEmbedScript();
    }, 0);
  }

  copy(tag) {
    let el;
    switch (tag) {
      case 'badgeFieldWhite':
        el = this.badgeFieldWhite.nativeElement;
        break;
      case 'badgeFieldGreen':
        el = this.badgeFieldGreen.nativeElement;
        break;
      case 'contactField':
        el = this.contactField.nativeElement;
        break;
      case 'profileEmbedField':
        el = this.profileEmbedField.nativeElement;
        break;
      case 'linkField':
        el = this.linkField.nativeElement;
        break;
    }

    if (!el) {
      return;
    }

    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      var editable = el.contentEditable;
      var readOnly = el.readOnly;
      el.contentEditable = 'true';
      el.readOnly = 'false';
      var range = document.createRange();
      range.selectNodeContents(el);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      el.setSelectionRange(0, 999999);
      el.contentEditable = editable;
      el.readOnly = readOnly;
    } else {
      el.select();
    }
    document.execCommand('copy');
    el.blur();

    this.alertService.success('Code copied to clipboard');
  }

  reloadEmbedScript() {
    const scripts = <HTMLScriptElement[]>(
      this.profileEmbedContainer.nativeElement.getElementsByTagName('script')
    );
    const scriptsInitialLength = scripts.length;
    for (let i = 0; i < scriptsInitialLength; i++) {
      const script = scripts[i];
      const scriptCopy = <HTMLScriptElement>document.createElement('script');
      scriptCopy.type = script.type ? script.type : 'text/javascript';
      if (script.innerHTML) {
        scriptCopy.innerHTML = script.innerHTML;
      } else if (script.src) {
        scriptCopy.src = script.src;
      }
      scriptCopy.async = false;
      script.parentNode.replaceChild(scriptCopy, script);
    }
  }

  shareTo(network) {
    window.open(shareLinks[network] + this.currentUser.profile.link);
  }
}
