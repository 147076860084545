import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CalendarIntegrationService {
  constructor(private http: GenericHttpService) {}

  getAll = (): Observable<any> => this.http.get('/calendars/integrations');

  delete = (id: string): Observable<any> =>
    this.http.delete('/calendars/integrations/' + id);

  integrateGoogleCalendar = (params): Observable<any> =>
    this.http.post('/calendars/callback/google', params);

  reauthorize = (params): Observable<any> =>
    this.http.post('/calendars/callback/google', params);

  updateGoogleCalendarSelection = (
    id: string,
    selections: Array<any>
  ): Observable<any> =>
    this.http.put('/calendars/integrations/' + id, { selections: selections });

  integrateCalendarFromURL = (url): Observable<any> =>
    this.http.post('/calendars/integrations', { url: url });
}
