const now = new Date();

const daysAgo = function (days: number) {
  var n = new Date(now);
  n.setDate(n.getDate() - days);
  return n;
};

export const fakeStats = {
  data: [
    {
      label: 'search',
      data: [41, 26, 29, 27, 23, 36, 33, 26, 31, 18, 38, 19],
      hidden: false,
    },
    {
      label: 'profile_visit',
      data: [9, 3, 5, 4, 9, 5, 10, 6, 7, 8, 7, 5],
      hidden: false,
    },
    {
      label: 'contact',
      data: [0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0],
      hidden: false,
    },
  ],
  days: [
    daysAgo(12),
    daysAgo(11),
    daysAgo(10),
    daysAgo(9),
    daysAgo(8),
    daysAgo(7),
    daysAgo(6),
    daysAgo(5),
    daysAgo(4),
    daysAgo(3),
    daysAgo(2),
    daysAgo(1),
  ],
  previous: { search: 365, profile_visit: 71, contact: 1 },
};
