import { Component, Input, OnInit } from '@angular/core';
import { Profile } from 'src/app/entities/profile.model';
import { InvoiceStatisticsService } from 'src/app/shared/services/invoice-statistics.service';
import { Invoice } from 'src/app/entities/invoice.model';
import { OfficeService } from 'src/app/office/shared/service/office.service';
import { environment } from '../../../../environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingAnimationComponent } from '../../../shared/components/loading-animation/loading-animation.component';
import { RouterLink } from '@angular/router';
import { InvoiceNewButtonComponent } from '../../../shared/components/office/invoice-new-button/invoice-new-button.component';
import { PayoutButtonComponent } from '../../../shared/components/office/payout-button/payout-button.component';
import {
  CurrencyPipe,
  DatePipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  UpperCasePipe,
} from '@angular/common';

@Component({
  selector: 'app-invoice-statistics-box',
  templateUrl: './invoice-statistics-box.component.html',
  styleUrls: ['./invoice-statistics-box.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    PayoutButtonComponent,
    InvoiceNewButtonComponent,
    RouterLink,
    LoadingAnimationComponent,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    NgClass,
    UpperCasePipe,
    CurrencyPipe,
    DatePipe,
    TranslateModule,
  ],
})
export class InvoiceStatisticsBoxComponent implements OnInit {
  constructor(
    private officeService: OfficeService,
    private invoiceService: InvoiceStatisticsService
  ) {}

  balances: any;
  currencies: Array<string>;
  openInvoices: Array<Invoice>;
  openInvoicesSum: 0;
  STRIPECONNECTONBOARDINGSTATE = StripeConnectOnboardingState;
  stripeConnectOnboardingState: StripeConnectOnboardingState;
  public env = environment;

  @Input()
  profile: Profile;

  ngOnInit() {
    if (this.profile.stripe_connect_active) {
      this.officeService.accountBalances.subscribe((balances) => {
        if (balances) {
          this.balances = balances;
          this.currencies = Object.keys(this.balances);
        }
      });
      this.officeService.loadAccountBalance();
      this.getOpenInvoices();
    }
    this.setStripeConnectOnboardingState();
  }

  private setStripeConnectOnboardingState() {
    if (this.profile.stripe_connect_active) {
      this.stripeConnectOnboardingState = StripeConnectOnboardingState.Hidden;
    } else if (!this.profile.stripe_connect_id) {
      this.stripeConnectOnboardingState =
        StripeConnectOnboardingState.RequiresSetup;
    } else {
      if (this.profile.stripe_connect_has_errors) {
        this.stripeConnectOnboardingState = StripeConnectOnboardingState.Errors;
      } else if (this.profile.stripe_connect_has_currently_due_requirements) {
        this.stripeConnectOnboardingState =
          StripeConnectOnboardingState.RequiresMoreInfo;
      } else {
        this.stripeConnectOnboardingState =
          StripeConnectOnboardingState.WaitingForApproval;
      }
    }
  }

  getOpenInvoices() {
    this.invoiceService.getOpenInvoices().subscribe((invoices) => {
      this.openInvoices = invoices;
      if (this.openInvoices.length > 0) {
        this.openInvoicesSum = invoices
          .map((i) => i.total)
          .reduce((curr, acc) => curr + acc);
      } else {
        this.openInvoicesSum = 0;
      }
    });
  }
}

enum StripeConnectOnboardingState {
  RequiresSetup,
  Errors,
  RequiresMoreInfo,
  WaitingForApproval,
  Hidden,
}
