<h2 mat-dialog-title>{{ "booking.confirm_for_client_dialog.title" | translate }}</h2>
<mat-dialog-content>
  <p>{{ "booking.confirm_for_client_dialog.text" | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ "booking.confirm_for_client_dialog.cancel" | translate }}
  </button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
    {{ "booking.confirm_for_client_dialog.confirm" | translate }}
  </button>
</mat-dialog-actions>
