import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';
import { SubscriptionService } from '../service/subscription.service';
import { AlertService } from '../../../shared/components/alert/service/alert.service';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { MatLoadingButtonDirective } from '../../../shared/directives/mat-loading-button-directive';

@Component({
  selector: 'plan-winback-confirm-dialog',
  templateUrl: 'plan-winback-confirm-dialog.html',
  styleUrls: ['./plan-winback-confirm-dialog.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgClass,
    FormsModule,
    NgIf,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    TranslateModule,
    DatePipe,
    MatLoadingButtonDirective,
  ],
})
export class PlanWinbackConfirmDialog {
  public isPerformingWinback: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { paymentPlanId: string; planName: string; date: number },
    public dialogRef: MatDialogRef<PlanWinbackConfirmDialog>,
    public settingsService: SettingsService,
    public subscriptionService: SubscriptionService,
    public alertService: AlertService
  ) {}
  onCancel(): void {
    this.dialogRef.close();
  }

  performWinback() {
    this.isPerformingWinback = true;
    this.subscriptionService
      .schedulePlanChangeAtEndOfBillingCycle(this.data.paymentPlanId)
      .subscribe((response) => {
        this.isPerformingWinback = false;
        if (response.success) {
          this.alertService.success(
            'office.settings.subscription.plan_winback_confirmation.success'
          );
          this.dialogRef.close(true);
        } else {
          MonitoringService.captureMessage('Error performing winback', {
            extra: response,
          });
          this.alertService.error(
            'office.settings.subscription.plan_winback_confirmation.error'
          );
        }
      });
  }
}
