<ng-container *ngIf="items">
  <div class="ic-dropdown">
    <div class="ic-dropdown-input">
      <div class="tags are-medium">
        <span *ngFor="let item of _selectedItems" class="tag is-medium"
          >{{ item.name }}
          <button class="delete is-small" (click)="remove(item)"></button>
        </span>
        <input
          class="input is-primary"
          type="text"
          (keydown)="onKeyDown($event)"
          (ngModelChange)="onInputChange($event)"
          [(ngModel)]="search"
          placeholder="{{ placeholder }}"
        />
      </div>
    </div>
    <p class="error" *ngIf="showError">{{ error }}</p>
    <ul class="suggestions" id="items_list">
      <ng-container *ngFor="let item of items">
        <li
          *ngIf="shouldDisplay(item)"
          id="{{ item.name }}"
          (click)="add(item)"
        >
          {{ item.name }}
        </li>
      </ng-container>
    </ul>
  </div>
</ng-container>
