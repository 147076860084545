import {
  DatePipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import { CryptoService } from 'src/app/components/messaging/service/crypto.service';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  CalendarCommonModule,
  CalendarDayModule,
  CalendarEventTitleFormatter,
  CalendarMonthModule,
  CalendarView,
  CalendarWeekModule,
} from 'angular-calendar';
import { Appointment } from '../../entities/appointment.model';
import { CalendarService } from './service/calendar.service';
import { Client } from '../../entities/client.model';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { BaseClass } from '../../shared/base-class';
import { SharedService } from '../../shared/services/shared.service';
import { AlertService } from '../../shared/components/alert/service/alert.service';
import { Animation } from 'src/app/shared/animations/fade-animation';
import { MessagingService } from '../messaging/service/messaging.service';
import { ICCalendarEventTitleFormatter } from './calendar.eventtitle.provider.formatter';
import { User } from 'src/app/entities/user.model';
import {
  TranslateModule,
  TranslateService,
  TranslationChangeEvent,
} from '@ngx-translate/core';
import { VideoService } from 'src/app/shared/video/video.service';
import { CookieService } from 'ngx-cookie';
import { AmplitudeAnalyticsService } from '../../shared/services/analytics/amplitude-analytics.service';
import { BookingFormContainerComponent } from '../booking/booking-form-container/booking-form-container.component';
import { PaymentType } from '../../entities/PaymentType';
import { PaymentModalComponent } from '../../shared/components/payment-modal/payment-modal.component';
import { AppointmentActionsComponent } from '../booking/appointment-actions/appointment-actions.component';
import { AppointmentStatusMessageComponent } from '../booking/appointment-status-message/appointment-status-message.component';
import { MatIcon } from '@angular/material/icon';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { SidenavComponent } from '../../frame/sidenav/sidenav.component';
import { AppointmentMarkEapCompletedDialog } from '../booking/appointment-mark-eap-completed-dialog/appointment-mark-eap-completed-dialog';
import { MatDialog } from '@angular/material/dialog';
import { AddSpecificWorkingHoursDialogComponent } from './add-specific-working-hours/add-specific-working-hours-dialog.component';

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./calendar.component.scss'],
  animations: [Animation.fadeAnimation],
  templateUrl: './calendar.component.html',
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: ICCalendarEventTitleFormatter,
    },
  ],
  standalone: true,
  imports: [
    SidenavComponent,
    NgClass,
    NgIf,
    RouterLink,
    CalendarCommonModule,
    NgSwitch,
    NgSwitchCase,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule,
    NgFor,
    DragAndDropModule,
    BookingFormContainerComponent,
    MatIcon,
    AppointmentStatusMessageComponent,
    AppointmentActionsComponent,
    PaymentModalComponent,
    DatePipe,
    TranslateModule,
  ],
})
export class CalendarComponent extends BaseClass implements OnInit {
  private readonly lastCalendarViewCookieKey = 'ic_calendarView';
  protected readonly PaymentType = PaymentType;

  @ViewChild('modalContent', { static: false })
  modalContent: TemplateRef<any>;

  @ViewChild('bookingModal', { static: false })
  bookingModal: BookingFormContainerComponent;

  view: CalendarView = CalendarView.Week;

  status: any = {
    inquiry: {
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    },
    pending: {
      primary: '#FDF1BA',
      secondary: '#FDF1BA',
    },
    confirmed: {
      primary: 'rgb(116, 208, 191)',
      secondary: '#ecf8f6',
    },
    external: {
      primary: '#cccccc',
      secondary: '#eaeaea',
    },
    blocker: {
      primary: '#cccccc',
      secondary: '#eaeaea',
    },
  };

  CalendarView = CalendarView;
  clients: Client[];
  event: Appointment;
  modal: boolean;
  detailModal: boolean;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events: any[] = [];

  currentUser: User;
  busy: boolean = false;

  locale: string;

  activeDayIsOpen: boolean = false;

  screenWidth: any;

  constructor(
    private calendarService: CalendarService,
    private router: Router,
    private sharedService: SharedService,
    private alertService: AlertService,
    private messageService: MessagingService,
    private cryptoService: CryptoService,
    private route: ActivatedRoute,
    private translationService: TranslateService,
    private videoService: VideoService,
    private cookieService: CookieService,
    private analytics: AmplitudeAnalyticsService,
    public dialog: MatDialog
  ) {
    super(sharedService, alertService, router);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    super.ngOnInit();

    this.locale = this.translationService.currentLang;
    const lastCalendarView = this.cookieService.get('ic_calendarView');
    switch (lastCalendarView) {
      case 'week':
        this.view = CalendarView.Week;
        break;
      case 'month':
        this.view = CalendarView.Month;
        break;
      case 'day':
        this.view = CalendarView.Day;
        break;
      default:
        this.view = CalendarView.Week;
    }

    this.translationService.onLangChange.subscribe(
      (event: TranslationChangeEvent) => {
        this.locale = event.lang;
      }
    );

    this.route.queryParams.subscribe((params) => {
      if (params.date) {
        this.viewDate = params.date;
        this.view = CalendarView.Day;
      } else {
        if (window.innerWidth < 768) {
          this.view = CalendarView.Month;
        }
      }
    });

    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.getAppointments();
      }
    });
  }

  switchToViewAndRemember(view) {
    this.view = view;
    this.cookieService.put(this.lastCalendarViewCookieKey, this.view);
  }

  getAppointments() {
    const isTherapist = this.currentUser.type == 'Therapist';

    const appointmentLoader = isTherapist
      ? this.calendarService.getAppointments()
      : this.calendarService.getClientAppointments();

    appointmentLoader.subscribe((apps) => {
      this.events = [];
      apps.forEach((app) => {
        app.start = this.sharedService.adjustUTCDateToProfileTimezone(
          app.start
        );
        app.end = this.sharedService.adjustUTCDateToProfileTimezone(app.end);

        const event = {
          color: this.status[app.status],
          title: isTherapist
            ? app.therapist_facing_title
            : `${app.profile.firstname} ${app.profile.lastname}`,
          note: '',
          start: app.start,
          end: app.end,
          status: app.status,
          draggable: false,
          id: app.id,
          client: app.client,
          profile: app.profile,
          uid: app.uid,
          is_online: app.is_online,
          duration: app.duration,
          payment_type: app.payment_type,
          can_use_video: app.can_use_video,
          invoice: app.invoice,
        };
        this.events.push(event);
      });
      this.refresh.next(new Date());
    });
  }

  /**
   * Navigate to calendar
   * component and open up correct date.
   *
   * @param day The day clicked
   */
  public dayClicked(day: any) {
    this.addEvent(day.date, true);
  }

  /**
   * Open modal for appointment
   * creation.
   *
   * @param event Hour clicked.
   *
   */
  hourClicked(event: any) {
    this.addEvent(event.date);
  }

  addEvent(date: any, timeNotSet = false) {
    if (
      !this.currentUser.abilities.can_create_appointments ||
      !this.bookingModal
    ) {
      return;
    }
    this.event = new Appointment(date);
    let utcdate = this.sharedService
      .adjustProfileTimezoneDateToUTC(this.event.start)
      .getTime();

    this.analytics.trackScheduleAppointmentStarted({
      source_page: 'calendar_page',
    });

    this.bookingModal.showForNewEventBooking(utcdate, timeNotSet);
  }

  eventChanged(eventId: number) {
    this.closeViewAppointmentModal();
  }

  /**
   * Open up event modal.
   *
   * @param event Appointment clicked
   *
   */
  handleEvent(event: Appointment) {
    if (event.status == 'external') {
      return;
    }
    this.event = event;
    this.detailModal = true;
  }

  /**
   * Delete specified appointment.
   *
   */
  deleteBlocker = () => {
    this.busy = true;
    this.calendarService.deleteBlocker(this.event).subscribe((res) => {
      this.alertService.success('Blocker removed');
      this.getAppointments();
      this.detailModal = false;
      this.sharedService.track('appointment_canceled');
      this.busy = false;
    });
  };

  public editAppointment(appointment: Appointment) {
    this.bookingModal.showForEditingAppointment(appointment);
    this.detailModal = false;
  }

  closeViewAppointmentModal(): void {
    this.detailModal = false;
    this.event = null;
    this.getAppointments();
  }

  onAppointmentConfigured($event: Partial<Appointment>) {
    // we just edited or added a new appointment, lets reload all the appointments
    this.detailModal = false;
    this.event = null;
    this.getAppointments();
  }
}
