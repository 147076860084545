import { Injectable } from '@angular/core';
import { default as currencies } from './currencies.json';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private currencies = currencies;

  constructor() {}

  public getCurrencies() {
    return this.currencies;
  }

  public lookup(code: string) {
    return this.currencies.find(
      (c) => c.code.toLowerCase() == code.toLowerCase()
    );
  }
}
