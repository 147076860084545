import { Component, OnInit } from '@angular/core';
import { Animation } from 'src/app/shared/animations/fade-animation';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ReferralService } from './service/referral.service';
import { User } from 'src/app/entities/user.model';
import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ReferralModalComponent } from './referral-modal/referral-modal.component';
import { SidenavComponent } from '../../frame/sidenav/sidenav.component';
import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss'],
  animations: [Animation.fadeAnimation],
  standalone: true,
  imports: [
    NgIf,
    SidenavComponent,
    NgFor,
    NgClass,
    ReferralModalComponent,
    DatePipe,
    TranslateModule,
  ],
})
export class ReferralsComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private translateService: TranslateService,
    private referralService: ReferralService
  ) {}

  currentUser: User;
  referrals: Array<any>;
  navigator: any;
  env = environment;
  totalEarned: number = 0;
  shareUrl: string = '';
  showMore: boolean = false;

  ngOnInit() {
    this.navigator = navigator;
    this.referralService.getReferrals();
    this.sharedService.currentUser.subscribe((user) => {
      this.currentUser = user;
      if (this.currentUser) {
        this.shareUrl =
          this.env.baseUrl +
          (this.translateService.defaultLang === 'en'
            ? ''
            : '/' + this.translateService.defaultLang) +
          '/join/' +
          this.currentUser.coupon.name;
      }
    });
    this.referralService.referrals.subscribe((referrals) => {
      if (referrals) {
        this.referrals = referrals;
        if (this.referrals.length > 0) {
          this.totalEarned = this.referrals
            .map((r) => (r.reward > 0 ? 1 : 0))
            .reduce((prev, curr) => prev + curr, 0);
        }
      }
    });
  }

  showEmailModal() {
    this.referralService.toogleEmailModal(true);
  }

  showShareButton() {
    return this.navigator.share;
  }

  share() {
    if (this.navigator.share && this.currentUser.coupon) {
      this.navigator.share({
        title: "It's Complicated",
        text:
          "I'm using It's Complicated to manage and grow my practice. Use my couponcode " +
          this.currentUser.coupon.name +
          ' to one month free',
        url: this.shareUrl,
      });
    }
  }
}
