import { User } from 'src/app/entities/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Animation } from 'src/app/shared/animations/fade-animation';
import { Router } from '@angular/router';
import { Channel } from 'stream-chat';
import {
  ChannelService,
  ChatClientService,
  DefaultStreamChatGenerics,
  StreamChatModule,
} from 'stream-chat-angular';
import { getOtherMemberIfOneToOneChannel } from '../../../shared/helpers/stream_chat_helper';
import { StreamConversationPreviewComponent } from '../../../shared/components/chat/stream-conversation-preview/stream-conversation-preview.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  AsyncPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgTemplateOutlet,
} from '@angular/common';
import { SidenavComponent } from '../../../frame/sidenav/sidenav.component';
import { ConversationPreviewComponent } from '../../../shared/components/chat/conversation-preview/conversation-preview.component';
import { debounceTime, Observable, Subject, Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FeaturesService } from '../../../shared/services/features/features.service';
import { MessageListComponent } from '../message-list/message-list.component';
import { MessageListStreamComponent } from '../message-list-stream/message-list-stream.component';

@Component({
  selector: 'app-message-list-wrapper',
  templateUrl: './message-list-wrapper.component.html',
  styleUrls: ['./message-list-wrapper.component.scss'],
  standalone: true,
  imports: [MessageListComponent, MessageListStreamComponent, NgIf],
})
export class MessageListWrapperComponent {
  isUsingGetstreamChat: boolean = false;
  constructor(featureService: FeaturesService) {
    this.isUsingGetstreamChat = featureService.isUsingGetStreamChat();
  }
}
