import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import {
  NgxMatTimepickerComponent,
  NgxMatTimepickerDirective,
} from 'ngx-mat-timepicker';
import { FormsModule } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-time-slot-picker',
  templateUrl: './time-slot-picker.component.html',
  styleUrls: ['./time-slot-picker.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatButton,
    MatIcon,
    NgxMatTimepickerComponent,
    FormsModule,
    NgxMatTimepickerDirective,
    MatTooltip,
    TranslateModule,
    MatIconButton,
    MatInput,
    NgIf,
  ],
})
export class TimeSlotPickerComponent {
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() isBusy: boolean;
  @Input() allowRemoval: boolean = true;
  @Output() startTimeChange = new EventEmitter<string>();
  @Output() endTimeChange = new EventEmitter<string>();
  @Output() remove = new EventEmitter<void>();

  onStartTimeChange(event: string) {
    this.startTimeChange.emit(event);
  }

  onEndTimeChange(event: string) {
    this.endTimeChange.emit(event);
  }

  startTimeMax(): string {
    // Parse the time string to a Date object
    const parts = this.endTime.split(':');
    const date = new Date();
    date.setHours(parseInt(parts[0], 10), parseInt(parts[1], 10) - 5);

    // Format the Date object back to a string in HH:mm format
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  endTimeMin(): string {
    // Parse the time string to a Date object
    const parts = this.startTime.split(':');
    const date = new Date();
    date.setHours(parseInt(parts[0], 10), parseInt(parts[1], 10) + 5);

    // Format the Date object back to a string in HH:mm format
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
}
