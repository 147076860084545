import { Component, Input } from '@angular/core';
import { OnboardingStep } from 'src/app/components/home/onboarding-box/onboarding-box.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'onboarding-progress-bar',
  templateUrl: './onboarding-progress-bar.component.html',
  styleUrls: ['./onboarding-progress-bar.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, TranslateModule],
})
export class OnboardingProgressBarComponent {
  constructor() {}

  @Input()
  onboardingStep: OnboardingStep;
  ONBOARDINGSTEP = OnboardingStep;

  @Input()
  embedded: Boolean;
}
