import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { fakeStats } from './fakeStats';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(private http: GenericHttpService, private _http: HttpClient) {}

  saveInsight = (slug, action): Observable<any> =>
    this.http.post(
      '/profile/insight/' + slug,
      { action: action },
      { headers: headers }
    );

  getInsights = (dates: Array<string>): Observable<any> =>
    this.http.post(
      '/profile/insights',
      { sd: dates[0], ed: dates[1] },
      { headers: headers }
    );

  getLikes = (slug): Observable<any> =>
    this.http.get('/profile/insight/' + slug, { headers: headers });

  getFakeStats = (): Observable<any> => of(fakeStats);
}
