<section class="dashboard-teaser">
  <header>
    <h3 class="dashboard-teaser-title">{{ title }}</h3>
  </header>

  <div [ngClass]="{ card: true, 'is-warning': isWarning }">
    <div class="card-content">
      <div class="columns">
        <div [ngClass]="{ column: true, 'is-warning': isWarning }">
          <p>{{ message }}</p>
        </div>
      </div>

      <div class="columns">
        <div class="column has-text-centered">
          <a
            [ngClass]="{
              button: true,
              'is-primary': !isWarning,
              'is-rounded': true,
              'is-warning': isWarning
            }"
            href="{{ stripeUrl }}"
            >{{ action }}</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
