import { Component, OnInit } from '@angular/core';
import { OfficeService } from '../shared/service/office.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { User } from 'src/app/entities/user.model';
import moment from 'moment';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe, NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-invoice-amount',
  templateUrl: './invoice-amount.component.html',
  styleUrls: ['./invoice-amount.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, RouterLink, CurrencyPipe, TranslateModule],
})
export class InvoiceAmountComponent implements OnInit {
  constructor(
    private officeService: OfficeService,
    private sharedService: SharedService,
    private router: Router
  ) {}

  public amounts: any;
  public currentUser: User;
  public isDropdownVisible: boolean = false;
  public lastmonth: string = '';
  public thismonth: string = '';

  ngOnInit(): void {
    this.officeService.getMonthlyEarnings().subscribe((amount) => {
      this.amounts = amount;
    });

    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.thismonth = moment()
          .locale(this.currentUser.locale)
          .format('MMMM');
        this.lastmonth = moment()
          .locale(this.currentUser.locale)
          .subtract(1, 'month')
          .format('MMMM');
      }
    });
  }

  toggleDropDown = () => {
    if (window.innerWidth <= 1023) {
      this.router.navigate(['/office/invoices']);
    } else {
      this.isDropdownVisible = !this.isDropdownVisible;
    }
  };
}
