import { Component } from '@angular/core';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'appointment-confirm-for-client-dialog',
  templateUrl: 'appointment-confirm-for-client-dialog.html',
  styleUrls: ['./appointment-confirm-for-client-dialog.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    TranslateModule,
  ],
})
export class AppointmentConfirmForClientDialog {
  constructor(
    public dialogRef: MatDialogRef<AppointmentConfirmForClientDialog>
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
