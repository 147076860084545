import { CryptoService } from './../service/crypto.service';
import { User } from 'src/app/entities/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { MessagingService } from 'src/app/components/messaging/service/messaging.service';
import { Component, OnInit } from '@angular/core';
import { Conversation } from 'src/app/entities/conversation.model';
import { Animation } from 'src/app/shared/animations/fade-animation';
import { formatConversations } from 'src/app/shared/helpers/format_conversations.helper';
import { Router } from '@angular/router';
import { TimeAgoPipe } from '../../../time-ago.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ProfilePictureComponent } from '../../../shared/components/profile-picture/profile-picture.component';
import { NgClass, NgFor, NgIf, SlicePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SidenavComponent } from '../../../frame/sidenav/sidenav.component';
import { WebsocketService } from '../service/websocket.service';
import { Channel } from 'angular2-actioncable';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';
import { ConversationPreviewComponent } from '../../../shared/components/chat/conversation-preview/conversation-preview.component';
import { getProfileImageUrl } from '../../../shared/helpers/profile-image-helper';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss'],
  animations: [Animation.fadeAnimation],
  standalone: true,
  imports: [
    SidenavComponent,
    FormsModule,
    NgIf,
    NgFor,
    NgClass,
    ProfilePictureComponent,
    SlicePipe,
    TranslateModule,
    TimeAgoPipe,
    ConversationPreviewComponent,
    MatIcon,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatIconButton,
    MatTooltip,
  ],
})
export class MessageListComponent implements OnInit {
  constructor(
    private messageService: MessagingService,
    private sharedService: SharedService,
    private cryptoService: CryptoService,
    private router: Router,
    private websocketsService: WebsocketService
  ) {}

  public environment = environment;
  public isLoading: boolean = true;
  public moreAvailable: boolean = false;
  private userChannel: Channel;

  truncateSize: number = 60;

  public currentUser: User;
  public conversations: Conversation[] = [];
  public currentPage: number = 0;

  public searchTerm: string;
  public sorting: string = 'name';
  public showHidden: boolean = false;

  ngOnInit() {
    this.sorting = window.localStorage.getItem('icconsort') || 'activity';
    this.sharedService.currentUser.subscribe((user) => {
      if (!this.currentUser) {
        this.currentUser = user;
        this.getConversations(1);
        this.subscribeToNewMessages();
      }
      this.currentUser = user;
    });
  }

  resetSearch() {
    this.searchTerm = null;
    this.conversations = [];
    this.getConversations(1);
  }

  refresh() {
    this.conversations = [];
    this.getConversations(1);
  }

  getConversations(page = 0) {
    this.currentPage = page;
    if (page === 0) {
      this.conversations = [];
    }
    this.messageService
      .getConversations(page, 5, this.sorting, this.showHidden)
      .subscribe((res) => {
        const newConversations = formatConversations(res.conversations.data);
        newConversations.forEach((newConversation) => {
          if (
            !this.conversations.some(
              (existingConversation) =>
                existingConversation.id === newConversation.id
            )
          ) {
            this.conversations.push(newConversation);
          }
        });
        this.moreAvailable = this.conversations.length < res.count;
        this.isLoading = false;
        this.decryptLastMessages(newConversations);
      });
  }

  decryptLastMessages(conversations: Conversation[]) {
    conversations.forEach((conversation) => {
      if (
        !conversation ||
        !conversation.messages ||
        !conversation.messages[0]
      ) {
        return;
      }

      const msg = conversation.messages[0];

      if (!msg.encrypted || msg.decrypted) {
        msg.decrypted = true;
        return;
      }

      this.cryptoService.assertKey(conversation).then(() => {
        this.cryptoService
          .decrypt(msg.body, conversation.key)
          .then((message) => {
            msg.body = message;
            msg.decrypted = true;
          });
      });
    });
  }

  hasUnreadMessages(conversation: Conversation): boolean {
    if (!conversation || !this.currentUser) {
      return false;
    }
    const messages = conversation.messages
      .sort((a, b) => a.created_at.getTime() - b.created_at.getTime())
      .reverse();
    const otherUser = this.otherUser(conversation);
    return messages.some((m) => !m.read && m.user_id === otherUser.id);
  }

  otherUser(conversation: Conversation) {
    if (!this.currentUser || !conversation.sender || !conversation.recipient) {
      return null;
    }
    return this.currentUser.id === conversation.recipient.id
      ? conversation.sender
      : conversation.recipient;
  }

  isOtherUserFlagged(conversation: Conversation): boolean {
    return this.otherUser(conversation)?.flagged;
  }

  goToConversation(conversation: Conversation) {
    const otherUser = this.otherUser(conversation);
    if (!otherUser) {
      MonitoringService.captureMessage('No other user found in conversation', {
        extra: {
          conversation,
        },
      });
      return;
    }
    this.router.navigate(['/home/messaging', otherUser.id]);
  }

  searchKey(event: KeyboardEvent) {
    if (event.key.toLowerCase() === 'enter') {
      this.doSearch((event.target as HTMLInputElement).value);
    }
  }

  changeSorting(newSorting: string) {
    this.sorting = newSorting;
    this.isLoading = true;
    window.localStorage.setItem('icconsort', this.sorting);
    if (this.searchTerm) {
      this.doSearch(this.searchTerm);
    } else {
      this.resetSearch();
    }
  }

  changeShowHidden(shouldShowHidden: boolean) {
    this.showHidden = shouldShowHidden;
    this.isLoading = true;
    this.resetSearch();
  }

  doSearch(term: string) {
    if (!term) {
      this.resetSearch();
      return;
    }
    this.searchTerm = term;
    this.isLoading = true;

    this.messageService
      .searchConversations(term, this.sorting)
      .subscribe((res) => {
        this.conversations = [];
        const foundConversations = formatConversations(res.conversations.data);
        foundConversations.forEach((newConversation) => {
          if (
            !this.conversations.some(
              (existingConversation) =>
                existingConversation.id === newConversation.id
            )
          ) {
            this.conversations.push(newConversation);
          }
        });
        this.moreAvailable = false;
        this.decryptLastMessages(foundConversations);
        this.isLoading = false;
      });
  }

  nextPage() {
    this.getConversations(this.currentPage + 1);
  }

  getAvatarSrc(conversation: Conversation): string {
    const otherUser = this.otherUser(conversation);
    return !otherUser || otherUser.flagged
      ? null
      : getProfileImageUrl(otherUser.profile_id, 'thumbnail');
  }

  private subscribeToNewMessages() {
    if (!this.currentUser) {
      return;
    }
    this.userChannel = this.websocketsService.getUserChannel(this.currentUser);
    this.userChannel.received().subscribe(
      (data) => {
        if (data.signal === 'new_message') {
          // Reload conversations when a new message arrives
          this.resetSearch();
        }
      },
      (error) => {
        console.error('Error receiving new messages:', error);
      }
    );
  }
}
