import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(private http: HttpClient) {}

  matchingPasswords(password: string, password_confirmation: string): boolean {
    return password == password_confirmation ? true : false;
  }

  passwordHasCapital(password: string): boolean {
    if (password) {
      let length = password.length;
      for (var i = 0; i < length; i++) {
        if (/[A-Z]/.test(password.charAt(i))) {
          return true;
        }
      }
    }
  }

  passwordHasNumber(password: string): boolean {
    return /\d/.test(password);
  }

  fullPasswordValidation(password: string): boolean {
    return this.passwordHasCapital(password) &&
      this.passwordHasNumber(password) &&
      password.length >= 8
      ? true
      : false;
  }

  getUserCountry(): Observable<any> {
    return this.http.get('https://ipapi.co/json');
  }
}
