<div class="container-fluid container-fluid-limited" *ngIf="currentUser">
  <div class="columns is-marginless">
    <div class="column is-3 is-hidden-touch is-hidden-print">
      <app-sidenav></app-sidenav>
    </div>
    <div class="column">
      <ng-container [ngSwitch]="invoiceAttentionBanner">
        <div
          class="notification is-warning"
          *ngSwitchCase="INVOICEATTENTIONBANNER.AccountIncomplete"
        >
          <button
            class="delete"
            (click)="hideInvoiceOnboardingBanner()"
          ></button>
          <h6 class="title">Your account is incomplete</h6>
          <p>Please finish setting up your invoicing account below.</p>
        </div>
        <div
          class="notification is-warning"
          *ngSwitchCase="INVOICEATTENTIONBANNER.AccountWillBeRestrictedSoon"
        >
          <h6 class="title">Your account will be restricted soon</h6>
          <p>
            Please provide more information below to keep your account verified.
          </p>
        </div>
      </ng-container>
      <div class="columns is-marginless is-mobile">
        <div class="column is-narrow">
          <h3
            class="subtitle is-4 cursor-pointer"
            [routerLink]="['/office/dashboard']"
          >
            {{ "navigation.office" | translate }}
          </h3>
        </div>
        <div class="column menu-column">
          <div
            class="buttons"
            *ngIf="currentUser.profile.stripe_connect_active"
          >
            <shared-office-payout-button
              *ngIf="
                currentUser.profile.stripe_connect_payout_interval === 'manual'
              "
            ></shared-office-payout-button>
            <shared-invoice-new-button></shared-invoice-new-button>
          </div>
        </div>
      </div>
      <office-navigation
        [currentUser]="currentUser"
        [showStripeConnectEmbeddedUIs]="showStripeConnectEmbeddedUis"
      ></office-navigation>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<office-invoice-dialog></office-invoice-dialog>
