export function getFileType(filename: string): string {
  if (!filename) {
    return '?';
  }
  const re = /(?:\.([^.]+))?$/;
  const res = re.exec(filename);
  if (res[1]) {
    return res[1].toLowerCase();
  }
  return '?';
}
