import { Component } from '@angular/core';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'appointment-appointment-cancel-confirm-dialog',
  templateUrl: 'appointment-cancel-confirm-dialog.html',
  styleUrls: ['./appointment-cancel-confirm-dialog.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    TranslateModule,
  ],
})
export class AppointmentCancelConfirmDialog {
  constructor(public dialogRef: MatDialogRef<AppointmentCancelConfirmDialog>) {}
}
