<section class="dashboard-teaser">
  <header class="dashboard-teaser-header">
    <div class="columns is-mobile align-center">
      <div class="column invoice-title">
        <h3 class="dashboard-teaser-title">
          {{ "home.office.title" | translate }}
        </h3>
        <div
          class="qmarkCircle tooltip is-tooltip-multiline"
          data-tooltip="Overview of your invoices and accounting within the platform"
        ></div>
      </div>
      <div class="column is-narrow" *ngIf="profile.stripe_connect_active">
        <shared-office-payout-button
          *ngIf="profile.stripe_connect_payout_interval === 'manual'"
        ></shared-office-payout-button>
        <shared-invoice-new-button></shared-invoice-new-button>
      </div>
    </div>
  </header>
  <div
    class="card cursor-pointer"
    [routerLink]="
      profile.stripe_connect_active &&
      !profile.stripe_connect_has_currently_due_requirements
        ? ['/office/dashboard']
        : ['/office/settings']
    "
  >
    <div class="card-content">
      <ng-container *ngIf="profile.stripe_connect_active">
        <shared-loading-animation
          *ngIf="!balances || !openInvoices"
        ></shared-loading-animation>
        <nav class="level balance" *ngIf="balances && openInvoices">
          <div class="level-item has-text-left">
            <div>
              <p class="balance-label">
                {{ "home.office.unpaid-invoices" | translate }} ({{
                  openInvoices.length
                }})
              </p>
              <p class="balance">
                {{
                  openInvoicesSum | currency : (profile.currency | uppercase)
                }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-left">
            <svg
              width="14px"
              height="22px"
              viewBox="0 0 14 22"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="-"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="My-dashboard---Initial-view-(Therapists)-NEW"
                  transform="translate(-759.000000, -881.000000)"
                  stroke="#EBEAEA"
                  stroke-width="3"
                >
                  <g id="Page">
                    <g
                      id="Account-balance"
                      transform="translate(519.000000, 798.000000)"
                    >
                      <g
                        id="Totals"
                        transform="translate(58.000000, 70.000000)"
                      >
                        <polyline
                          id="Stroke-1"
                          transform="translate(188.182500, 24.358500) rotate(-180.000000) translate(-188.182500, -24.358500) "
                          points="193.365 15 183 24.3575369 193.365 33.717"
                        ></polyline>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="balance-label">
                {{ "home.office.processing" | translate }}
              </p>
              <p class="balance" *ngFor="let currency of currencies">
                {{
                  balances[currency].pending / 100
                    | currency : (currency | uppercase)
                }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-left">
            <svg
              width="14px"
              height="22px"
              viewBox="0 0 14 22"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="-"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="My-dashboard---Initial-view-(Therapists)-NEW"
                  transform="translate(-759.000000, -881.000000)"
                  stroke="#EBEAEA"
                  stroke-width="3"
                >
                  <g id="Page">
                    <g
                      id="Account-balance"
                      transform="translate(519.000000, 798.000000)"
                    >
                      <g
                        id="Totals"
                        transform="translate(58.000000, 70.000000)"
                      >
                        <polyline
                          id="Stroke-1"
                          transform="translate(188.182500, 24.358500) rotate(-180.000000) translate(-188.182500, -24.358500) "
                          points="193.365 15 183 24.3575369 193.365 33.717"
                        ></polyline>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="balance-label">
                {{ "home.office.account-balance" | translate }}
              </p>
              <p class="balance" *ngFor="let currency of currencies">
                {{
                  balances[currency].available / 100
                    | currency : (currency | uppercase)
                }}
              </p>
            </div>
          </div>
        </nav>
        <ng-container *ngIf="profile.stripe_connect_restriction_deadline">
          <div class="is-divider"></div>
          <p class="has-text-centered">
            {{ "home.office.restriction_upcoming_1" | translate }}
          </p>
          <p class="has-text-centered has-text-danger is-size-3">
            {{
              profile.stripe_connect_restriction_deadline | date : "dd.MM.yyyy"
            }}
          </p>
          <p class="has-text-centered">
            {{ "home.office.restriction_upcoming_2" | translate }}
          </p>
        </ng-container>
      </ng-container>
      <div
        class="columns is-vcentered"
        *ngIf="
          !profile.stripe_connect_active && stripeConnectOnboardingState != null
        "
      >
        <ng-container [ngSwitch]="stripeConnectOnboardingState">
          <div
            class="column"
            *ngSwitchCase="STRIPECONNECTONBOARDINGSTATE.RequiresSetup"
          >
            <p>
              {{ "home.office.please-activate-invoicing" | translate }}
            </p>
          </div>
          <div
            class="column"
            *ngSwitchCase="STRIPECONNECTONBOARDINGSTATE.WaitingForApproval"
          >
            <p>
              {{ "home.office.waiting_for_approval" | translate }}
            </p>
          </div>
          <div
            class="column"
            *ngSwitchCase="STRIPECONNECTONBOARDINGSTATE.RequiresMoreInfo"
          >
            <p>
              {{ "home.office.requires_more_info" | translate }}
            </p>
          </div>
          <div
            class="column"
            *ngSwitchCase="STRIPECONNECTONBOARDINGSTATE.Errors"
          >
            <p>
              {{ "home.office.error_requires_action" | translate }}
            </p>
          </div>
        </ng-container>
        <div
          *ngIf="
            stripeConnectOnboardingState ==
            STRIPECONNECTONBOARDINGSTATE.WaitingForApproval
          "
          class="column has-text-right is-narrow"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <g><rect fill="none" height="24" width="24" /></g>
            <g>
              <path
                fill="#74D0BF"
                d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5z"
              />
            </g>
          </svg>
        </div>
        <div
          *ngIf="
            stripeConnectOnboardingState !=
            STRIPECONNECTONBOARDINGSTATE.WaitingForApproval
          "
          class="column has-text-right is-narrow"
        >
          <button
            [ngClass]="{
              button: true,
              'is-rounded': true,
              'is-primary':
                stripeConnectOnboardingState ==
                STRIPECONNECTONBOARDINGSTATE.RequiresMoreInfo,
              'is-danger':
                stripeConnectOnboardingState ==
                STRIPECONNECTONBOARDINGSTATE.Errors
            }"
            [routerLink]="['/office/settings']"
          >
            <span class="icon">
              <svg
                width="15px"
                height="17px"
                viewBox="0 0 15 17"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="-"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Activate-invoicing-button"
                    transform="translate(-16.000000, -8.000000)"
                    [style.fill]="
                      stripeConnectOnboardingState ==
                        STRIPECONNECTONBOARDINGSTATE.RequiresMoreInfo ||
                      stripeConnectOnboardingState ==
                        STRIPECONNECTONBOARDINGSTATE.Errors
                        ? '#FFFFFF'
                        : '#74D0BF'
                    "
                  >
                    <g id="Invoice-icon">
                      <g transform="translate(16.000000, 8.000000)">
                        <g>
                          <polygon
                            id="Fill-1"
                            points="9.4923 11.5436 9.4923 16.5076 14.4563 11.5436"
                          ></polygon>
                          <path
                            d="M0,0.0002 L0,16.5072 L8.692,16.5072 L8.692,11.5432 L8.692,10.7432 L9.492,10.7432 L14.456,10.7432 L14.456,0.0002 L0,0.0002 Z M3.459,3.5932 L3.459,3.1472 L10.958,3.1472 L10.958,4.0392 L3.459,4.0392 L3.459,3.5932 Z M3.459,6.7182 L3.459,6.2722 L10.958,6.2722 L10.958,7.1642 L3.459,7.1642 L3.459,6.7182 Z M3.459,9.8432 L3.459,9.3972 L6.375,9.3972 L6.375,9.8432 L6.375,10.2892 L3.459,10.2892 L3.459,9.8432 Z"
                            id="Fill-2"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <span>
              {{
                stripeConnectOnboardingState ==
                STRIPECONNECTONBOARDINGSTATE.RequiresSetup
                  ? ("home.office.activate-invoicing" | translate)
                  : ("home.office.get-verified" | translate)
              }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
