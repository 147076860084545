import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Conversation } from '../../../entities/conversation.model';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { User } from '../../../entities/user.model';
import { SharedService } from '../../services/shared.service';
import { conversationSearchPipe } from '../../pipes/conversations_search.pipe';
import { TimeAgoPipe } from '../../../time-ago.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgClass, NgFor, NgIf, SlicePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { formatConversations } from '../../helpers/format_conversations.helper';
import { FirstInquirySentDialog } from '../../../components/home/first-inquiry-sent-dialog/first-inquiry-sent-dialog';
import { createDirectoryLink } from '../../helpers/navigation_helper';
import { CryptoService } from '../../../components/messaging/service/crypto.service';
import { MessagingService } from '../../../components/messaging/service/messaging.service';
import { WebsocketService } from '../../../components/messaging/service/websocket.service';
import { Channel } from 'angular2-actioncable';
import { ConversationPreviewComponent } from '../chat/conversation-preview/conversation-preview.component';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { getProfileImageUrl } from '../../helpers/profile-image-helper';

@Component({
  selector: 'app-latest-messages',
  templateUrl: './latest-messages.component.html',
  styleUrls: ['./latest-messages.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    NgClass,
    RouterLink,
    SlicePipe,
    TranslateModule,
    TimeAgoPipe,
    conversationSearchPipe,
    ConversationPreviewComponent,
    ProfilePictureComponent,
  ],
})
export class LatestMessagesComponent implements OnInit, OnDestroy {
  hasError: boolean;
  currentUser: User;
  userChannel: Channel;
  inquirySent: boolean;

  @Input() conversations: Conversation[];
  @Input() showFakeTemplate: boolean;
  @Input() showTime: boolean = true;
  @Input() truncateSize: number = 150;
  @Input() showViewAll: boolean;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private messagingService: MessagingService,
    private cryptoService: CryptoService,
    private activatedRoute: ActivatedRoute,
    private webSocketService: WebsocketService
  ) {}

  ngOnInit() {
    this.sharedService.currentUser.subscribe((user) => {
      this.currentUser = user;
      if (this.currentUser) {
        this.getLatestConversations();
        this.userChannel = this.webSocketService.getUserChannel(user);
        this.userChannel.received().subscribe((data) => {
          if (data.signal === 'new_message') {
            // Reload conversations when a new message arrives
            this.getLatestConversations();
          }
        });
      } else {
        this.userChannel.unsubscribe();
      }
    });

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params) {
        if (params['inquiry_sent'] === '1') {
          this.inquirySent = true;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.userChannel) {
      this.userChannel.unsubscribe();
    }
  }

  private getLatestConversations() {
    this.messagingService.getConversations(1, 3, 'activity').subscribe(
      (res) => {
        this.conversations = formatConversations(res.conversations.data);
        this.cryptoService.decryptLastMessage(this.conversations);

        // if they just sent their first message, give them a warmer welcome
        if (this.conversations.length === 1 && this.inquirySent) {
          this.dialog.open(FirstInquirySentDialog, {
            data: {
              directoryLink: createDirectoryLink(this.currentUser.locale),
            },
            width: '80vw',
            maxWidth: '600px',
          });
        }
      },
      (error) => {
        this.hasError = true;
      }
    );
  }

  hasUnreadMessages(conversation: Conversation): boolean {
    if (!conversation || !this.currentUser) {
      return false;
    }
    const messages = conversation.messages
      .sort((a, b) => a.created_at.getTime() - b.created_at.getTime())
      .reverse();
    const otherUser = this.getOtherUser(conversation);
    return messages.find((m) => !m.read && m.user_id === otherUser.id) != null;
  }

  getAvatarSrc(conversation: Conversation): string {
    const otherUser = this.getOtherUser(conversation);
    return !otherUser || otherUser.flagged
      ? null
      : getProfileImageUrl(otherUser.profile_id, 'thumbnail');
  }

  getOtherUserName(conversation: Conversation): string {
    const otherUser = this.getOtherUser(conversation);
    return otherUser.flagged ? 'Flagged User' : otherUser.name;
  }

  getLatestMessage(conversation: Conversation): string {
    return conversation.messages[0].body;
  }

  getLatestMessageTimestamp(conversation: Conversation): Date {
    return conversation.messages[0].created_at;
  }

  isUserFlagged(conversation: Conversation): boolean {
    return this.getOtherUser(conversation).flagged;
  }

  isMessageDeleted(conversation: Conversation): boolean {
    return conversation.messages[0].deleted;
  }

  isMessageDecrypted(conversation: Conversation): boolean {
    return conversation.messages[0].decrypted;
  }

  private getOtherUser(conversation: Conversation): any {
    return this.currentUser.id === conversation.recipient.id
      ? conversation.sender
      : conversation.recipient;
  }

  goToConversation(conversation: Conversation) {
    console.log('conversation', conversation);
    const otherUser = this.getOtherUser(conversation);
    this.router.navigate(['/home/messaging', otherUser.id]);
  }
}
