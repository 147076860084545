import { Component, Input, OnInit } from '@angular/core';
import {
  ChannelService,
  DefaultStreamChatGenerics,
  StreamChatModule,
} from 'stream-chat-angular';
import { Channel } from 'stream-chat';
import { TranslateModule } from '@ngx-translate/core';
import { NgForOf, NgIf, SlicePipe } from '@angular/common';
import { ConversationPreviewComponent } from '../chat/conversation-preview/conversation-preview.component';
import { SharedService } from '../../services/shared.service';
import { User } from '../../../entities/user.model';
import { StreamConversationPreviewComponent } from '../chat/stream-conversation-preview/stream-conversation-preview.component';
import { getOtherMemberIfOneToOneChannel } from '../../helpers/stream_chat_helper';
import { MonitoringService } from '../../services/monitoring/monitoring.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FirstInquirySentDialog } from '../../../components/home/first-inquiry-sent-dialog/first-inquiry-sent-dialog';
import { createDirectoryLink } from '../../helpers/navigation_helper';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-latest-messages-v2',
  templateUrl: './latest-messages-stream.component.html',
  styleUrls: ['./latest-messages-stream.component.scss'],
  standalone: true,
  imports: [
    StreamChatModule,
    TranslateModule,
    NgForOf,
    ConversationPreviewComponent,
    SlicePipe,
    StreamConversationPreviewComponent,
    NgIf,
    RouterLink,
  ],
})
export class LatestMessagesStreamComponent implements OnInit {
  channels: Channel<DefaultStreamChatGenerics>[] = [];
  currentUser: User;
  @Input() showTime: boolean = true;
  @Input() truncateSize: number = 150;
  @Input() showViewAll: boolean;

  private inquirySent: boolean = false;
  private welcomeDialogShown: boolean = false;

  constructor(
    private sharedService: SharedService,
    private channelService: ChannelService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.sharedService.currentUser.subscribe((user) => {
      this.currentUser = user;
      this.activatedRoute.queryParams.subscribe((params: any) => {
        if (params) {
          if (params['inquiry_sent'] === '1') {
            this.inquirySent = true;
            this.checkForWelcomeDialog();
          }
        }
      });

      if (this.currentUser) {
        this.resetChannelServiceToDefault();
        this.channelService.channels$.subscribe((channels) => {
          if (channels) {
            this.channels = channels.slice(0, 3);
            this.checkForWelcomeDialog();
          }
        });
      } else {
        this.channels = [];
      }
    });
  }

  checkForWelcomeDialog() {
    if (
      this.channels.length === 1 &&
      this.inquirySent &&
      !this.welcomeDialogShown
    ) {
      this.welcomeDialogShown = true;
      this.dialog.open(FirstInquirySentDialog, {
        data: {
          directoryLink: createDirectoryLink(this.currentUser.locale),
        },
        width: '80vw',
        maxWidth: '600px',
      });
    }
  }

  resetChannelServiceToDefault() {
    this.channelService.reset();

    const filters: any = {
      type: 'messaging',
      members: { $in: [this.currentUser.external_id] },
    };

    this.channelService.init(filters, { last_message_at: -1 }, null, false);
  }

  goToConversation(channel: Channel<DefaultStreamChatGenerics>) {
    const otherUser = getOtherMemberIfOneToOneChannel(
      this.currentUser,
      channel
    );
    if (!otherUser) {
      MonitoringService.captureMessage(
        'Could not find other user in one to one channel',
        {
          extra: {
            currentUser: this.currentUser,
            conversation: channel,
          },
        }
      );
      return;
    }
    this.router.navigate(['/home/messagingV2', otherUser.id]);
  }
}
