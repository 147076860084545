import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { of } from 'rxjs';
import { inject } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { CalendarService } from '../../components/calendar/service/calendar.service';
import { AlertService } from '../../shared/components/alert/service/alert.service';
import { VideoService } from '../../shared/video/video.service';
import { MonitoringService } from '../../shared/services/monitoring/monitoring.service';

export const videoAppointmentRoutingGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const uid = next.params.uid;
  const calendarService = inject(CalendarService);
  const alertService = inject(AlertService);
  const videoService = inject(VideoService);

  return calendarService.getAppointmentByUid(uid).pipe(
    map((appointment) => {
      if (!appointment || appointment.status !== 'accepted') {
        MonitoringService.captureException(
          'Appointment not found or not accepted'
        );
        alertService.error(
          'common.generic_error.message',
          'common.generic_error.title',
          true
        );
        return true; // allow navigation to home
      }
      const callSlug = videoService.generateAppointmentCallSlug(
        appointment,
        appointment.profile.id
      );
      videoService.navigateToGetstreamVideoCall(callSlug, true);
      return false; // prevent navigation
    }),
    catchError((error) => {
      MonitoringService.captureException(error);
      alertService.error(
        'common.generic_error.message',
        'common.generic_error.title',
        true
      );
      return of(true); // allow navigation to home
    })
  );
};
