<section class="dashboard-teaser">
  <header>
    <h3 class="dashboard-teaser-title">
      {{ "office.settings.taxrates.title" | translate }}
    </h3>
  </header>
  <div class="card content">
    <div class="card-content">
      <p>{{ "office.settings.taxrates.copy" | translate }}</p>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>{{ "office.settings.taxrates.description" | translate }}</th>
          <th>{{ "office.settings.taxrates.percentage" | translate }}</th>
          <th>{{ "office.settings.taxrates.is_default" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let taxrate of taxrates">
          <td>{{ taxrate.display_name }}</td>
          <td>{{ taxrate.percentage }}%</td>
          <td>
            <label
              class="switch"
              [ngClass]="{ 'is-disabled': isDefaultRate(taxrate) }"
            >
              <input
                name="isDefault"
                (change)="onSetDefault($event.target.checked, taxrate)"
                type="checkbox"
                [checked]="isDefaultRate(taxrate)"
                [disabled]="isDefaultRate(taxrate)"
              />
              <span class="slider round"></span>
            </label>
          </td>
        </tr>
        <tr>
          <td>
            <div class="field">
              <div class="control">
                <input
                  type="text"
                  class="input"
                  [(ngModel)]="newTaxRate.name"
                  placeholder="e.g. VAT"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="field">
              <div class="control has-icons-right">
                <input
                  type="text"
                  class="input"
                  mask="99.00"
                  [dropSpecialCharacters]="false"
                  [(ngModel)]="newTaxRate.percentage"
                  suffix="%"
                  placeholder="16.5%"
                />
              </div>
            </div>
          </td>
          <td colspan="1">
            <button
              class="button is-primary is-rounded is-small"
              [ngClass]="{ 'is-loading': isSavingTaxrate }"
              (click)="saveTaxrate()"
            >
              {{ "office.settings.taxrates.add" | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <p *ngIf="!currentUser.profile.default_taxrate">
      {{ "office.settings.taxrates.no_default" | translate }}
    </p>
  </div>
</section>
