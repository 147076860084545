<div>
  <h3 class="subtitle is-4 is-hidden-touch">
    {{ "programs.title" | translate }}
  </h3>

  <div
    class="card has-border"
    [@simpleFadeAnimation]="'in'"
  >
    <div class="card-content">
      <div class="is-flex is-align-items-center  flex-gap-15">
        <mat-icon>star</mat-icon>
        <h4 class="subtitle is-4">
                <span>{{
                    "programs.subtitle" | translate
                  }}</span>
        </h4>
      </div>

      <hr/>
      <div class="is-flex is-align-items-center has-margin-bottom-15 flex-gap-15">
        <mat-icon>join_inner</mat-icon>
        <h5 class="subtitle is-3 ">
                <span>{{
                    "programs.matching.title" | translate
                  }}</span>
        </h5>
      </div>
      <div class="content" style="position: relative">
        <p>
          {{
            "programs.matching.description" | translate
          }}
        </p>
        <div *ngIf="profile && profile.matching_program_status == 'active'">
          <div class="status-holder">
            <div
              [innerHTML]=" 'programs.status_message' | translate : { status: 'programs.status.active' | translate } "></div>
            <div class="status-circle status-green"></div>
          </div>
          <p [innerHTML]=" 'programs.matching.status_description.active' | translate"></p>
          <button
            class="button is-primary is-rounded"
            [ngClass]="{ 'is-loading': isUpdatingMatchingProgram }"
            [disabled]="isUpdatingWorkplaceProgram"
            (click)="pauseMatchingProgram()"
          >
            {{ "programs.cta_pause" | translate }}
          </button>
        </div>
        <div *ngIf="profile && profile.matching_program_status == 'paused'">
          <div class="status-holder">
            <div
              [innerHTML]=" 'programs.status_message' | translate : { status: 'programs.status.paused' | translate } "></div>
            <div class="status-circle status-yellow"></div>
          </div>
          <p [innerHTML]=" 'programs.matching.status_description.paused' | translate"></p>

          <button
            class="button is-primary is-rounded"
            [ngClass]="{ 'is-loading': isUpdatingMatchingProgram }"
            [disabled]="isUpdatingWorkplaceProgram"
            (click)="resumeMatchingProgram()"
          >
            {{ "programs.cta_resume" | translate }}
          </button>
        </div>
        <div *ngIf="profile && (profile.matching_program_status == 'not_active'  || !profile.matching_program_status)">
          <div class="status-holder">
            <div
              [innerHTML]=" 'programs.status_message' | translate : { status: 'programs.status.inactive' | translate } "></div>
            <div class="status-circle status-red"></div>
          </div>
          <p *ngIf="profile.has_limited_profile"
             [innerHTML]=" 'programs.matching.status_description.inactive_no_sub' | translate"></p>
          <p *ngIf="!profile.has_limited_profile"
             [innerHTML]=" 'programs.matching.status_description.inactive_not_enrolled' | translate"></p>
        </div>
      </div>
      <hr/>

      <div class="is-flex is-align-items-center has-margin-bottom-15 flex-gap-15">
        <mat-icon>corporate_fare</mat-icon>
        <h5 class="subtitle is-3 ">
                <span>{{
                    "programs.workplace.title" | translate
                  }}</span>
        </h5>
      </div>
      <div class="content">
        <p>
          {{
            "programs.workplace.description" | translate
          }}
        </p>
        <div *ngIf="profile && profile.eap_program_status == 'active'">
          <div class="status-holder">
            <div
              [innerHTML]=" 'programs.status_message' | translate : { status: 'programs.status.active' | translate } "></div>
            <div class="status-circle status-green"></div>
          </div>
          <p [innerHTML]=" 'programs.workplace.status_description.active' | translate"></p>
          <button
            class="button is-primary is-rounded"
            [ngClass]="{ 'is-loading': isUpdatingWorkplaceProgram }"
            [disabled]="isUpdatingMatchingProgram"
            (click)="pauseWorkplaceProgram()"
          >
            {{ "programs.cta_pause" | translate }}
          </button>
        </div>
        <div *ngIf="profile && profile.eap_program_status == 'paused'">
          <div class="status-holder">
            <div
              [innerHTML]=" 'programs.status_message' | translate : { status: 'programs.status.paused' | translate } "></div>
            <div class="status-circle status-yellow"></div>
          </div>
          <p [innerHTML]=" 'programs.workplace.status_description.paused' | translate"></p>
          <button
            class="button is-primary is-rounded"
            [ngClass]="{ 'is-loading': isUpdatingWorkplaceProgram }"
            [disabled]="isUpdatingMatchingProgram"
            (click)="resumeWorkplaceProgram()"
          >
            {{ "programs.cta_resume" | translate }}
          </button>
        </div>
        <div *ngIf="profile && (profile.eap_program_status == 'not_active'  || !profile.eap_program_status)">
          <div class="status-holder">
            <div
              [innerHTML]=" 'programs.status_message' | translate : { status: 'programs.status.inactive' | translate } "></div>
            <div class="status-circle status-red"></div>
          </div>
          <p *ngIf="profile.has_limited_profile"
             [innerHTML]=" 'programs.workplace.status_description.inactive_no_sub' | translate"></p>
          <p *ngIf="!profile.has_limited_profile"
             [innerHTML]=" 'programs.workplace.status_description.inactive_not_enrolled' | translate"></p>
        </div>
      </div>
      <hr/>
      <div class="content">
        <p [innerHTML]="'programs.footer' | translate">
        </p>
      </div>
    </div>
  </div>
</div>
