<div class="card">
  <div class="card-content">
    <div class="columns profile-columns">
      <div class="picture-and-availability">
        <app-profile-picture
          [profile_id]="therapist.profile.id"
          [variant]="'profile'"
        ></app-profile-picture>
        <div class="has-text-centered mt-2">
          <span
            [ngClass]="
              therapist.profile.is_available
                ? 'available-dot'
                : 'unavailable-dot'
            "
            class="status-dot"
          ></span>
          <span class="availability">
            {{
              (therapist.profile.is_available
                ? "my-favorite-therapists.available"
                : "my-favorite-therapists.unavailable"
              ) | translate
            }}
          </span>
        </div>
      </div>
      <div class="profile-text-content">
        <div class="name-and-favorite">
          <h4 class="therapist-name">{{ therapist.name }}</h4>
          <div
            class="favorite-action"
            (click)="$event.stopPropagation(); toggleFavorite(therapist)"
          >
            <div class="favorite-action-icon">
              <svg
                *ngIf="isFavorite; else notFavoriteIcon"
                viewBox="0 0 20 20"
                width="20px"
                height="20px"
                fill="#4cc2ac"
                stroke="#4cc2ac"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 15.832 17.5 L 10 14.164 L 4.168 17.5 L 4.168 4.168 C 4.168 3.244 4.912 2.5 5.836 2.5 L 14.166 2.5 C 15.089 2.5 15.832 3.244 15.832 4.168 L 15.832 17.5 Z"
                />
              </svg>
              <ng-template #notFavoriteIcon>
                <svg
                  viewBox="0 0 20 20"
                  width="20px"
                  height="20px"
                  fill="none"
                  stroke="#4cc2ac"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M 15.832 17.5 L 10 14.164 L 4.168 17.5 L 4.168 4.168 C 4.168 3.244 4.912 2.5 5.836 2.5 L 14.166 2.5 C 15.089 2.5 15.832 3.244 15.832 4.168 L 15.832 17.5 Z"
                  />
                </svg>
              </ng-template>
            </div>
          </div>
        </div>

        <p class="therapist-jobtitle">{{ therapist.profile.jobtitle }}</p>
        <div class="is-flex-grow-1">
          <p class="professional-statement">
            {{ therapist.profile.professional_statement }}
          </p>
        </div>
        <div class="price-and-location">
          <div
            *ngIf="
              therapist.profile.showPrice && therapist.profile.price_per_session
            "
            class="price"
          >
            <span class="price-value">
              {{
                therapist.profile.price_per_session
                  | currency : (therapist.profile.currency | uppercase)
              }}</span
            >
            <span class="price-suffix">/session</span>
          </div>
          <span class="location">
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="21"
              viewBox="0 0 23 21"
            >
              <path
                fill="#4CC2AC"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 21C14.5 21 20 12.2242 20 7.875C20 3.52576 16.4183 0 12 0C7.58172 0 4 3.52576 4 7.875C4 12.2242 9.5 21 12 21ZM12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z"
              />
            </svg>
            <span>{{ therapist.profile.city.name }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
