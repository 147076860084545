<h2 mat-dialog-title>{{ "booking.mark_eap_appointment_completed_dialog.title" | translate }}</h2>
<mat-dialog-content>
  <div class="has-margin-y-20">
    <p>{{ "booking.mark_eap_appointment_completed_dialog.instructions" | translate }}</p>
  </div>
  <label class="label">{{ "booking.mark_eap_appointment_completed_dialog.online_offline_label" | translate }}</label>
  <div class="buttons">
    <button
      class="button is-primary is-rounded"
      [disabled]="isBusy"
      [ngClass]="{
          'is-outlined': wasOnline !== true
        }"
      (click)="setWasOnline(true)"
    >
      {{ "booking.mark_eap_appointment_completed_dialog.online" | translate }}
    </button>
    <button
      class="button is-primary is-rounded"
      [disabled]="isBusy"
      [ngClass]="{
          'is-outlined': wasOnline !== false
        }"
      (click)="setWasOnline(false)"
    >
      {{ "booking.mark_eap_appointment_completed_dialog.in_person" | translate }}
    </button>
  </div>
  <label
    class="label has-margin-bottom-15">{{ "booking.mark_eap_appointment_completed_dialog.language_label" | translate }}</label>
  <mat-form-field>
    <mat-select [(value)]="language" [disabled]="isBusy">
      <mat-option *ngFor="let language of languages" [value]="language.code">
        {{ "booking.mark_eap_appointment_completed_dialog." + language.label | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <label
    class="label has-margin-bottom-15">{{ "booking.mark_eap_appointment_completed_dialog.topics_label" | translate }}</label>
  <div style="display: flex; flex-direction: row; gap: 4px">
    <div>
      <!-- First element -->
      <mat-form-field>
        <mat-select [(value)]="topic" [disabled]="isBusy">
          <mat-option *ngFor="let topic of topics" [value]="topic.value">
            {{ "booking.mark_eap_appointment_completed_dialog.topics." + topic.language_key | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div style="flex: 1;">
      <mat-form-field *ngIf="topic === 'other'">
        <input matInput [maxlength]="30"
               [placeholder]="'booking.mark_eap_appointment_completed_dialog.custom_topic_placeholder' | translate"
               [(ngModel)]="customTopic" [disabled]="isBusy">
      </mat-form-field>
    </div>
  </div>

  <div class="has-margin-top-25">
    <p>{{ "booking.mark_eap_appointment_completed_dialog.before_complete_text" | translate }}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="isBusy">
    {{ "booking.mark_eap_appointment_completed_dialog.cancel" | translate }}
  </button>
  <button mat-flat-button color="primary" [disabled]="language == undefined || wasOnline == undefined || topic == undefined || isBusy"
          [matLoadingButton]="isBusy"
          [hideText]="true"
          (click)="onCloseSession()"
          cdkFocusInitial>
    {{ "booking.mark_eap_appointment_completed_dialog.confirm" | translate }}
  </button>
</mat-dialog-actions>
