<div id="onboardingbox" class="card" *ngIf="shouldDisplay()">
  <div class="buttons is-right">
    <a class="delete" *ngIf="canClose()">
      (click)="setIgnoreProfile()" title="{{ "onboarding.ignore_title" |
      translate }}" >
    </a>
  </div>
  <app-onboarding-eventually-approve-progress-bar
    *ngIf="_currentUser"
    [onboardingStep]="onboardingStep"
    [embedded]="true"
  >
  </app-onboarding-eventually-approve-progress-bar>
  <svg-icon
    class="plant-image is-hidden-touch"
    name="plant-with-cup"
  ></svg-icon>
  <h2 class="onboardingbox-title" [ngSwitch]="onboardingStep">
    <span *ngSwitchCase="ONBOARDINGSTEP.fill_out_profile">
      {{ "onboarding.intro_trial_and_eventually_approved.unfinished_profile" |
      translate : { name: _currentUser.profile.firstname } }}
    </span>
    <span *ngSwitchCase="ONBOARDINGSTEP.subscribing">
      {{ "onboarding.intro_trial_and_eventually_approved.finished_profile" |
      translate : { name: _currentUser.profile.firstname } }}
    </span>
    <span *ngSwitchCase="ONBOARDINGSTEP.waiting_for_approval">
      {{ "onboarding.intro_trial_and_eventually_approved.in_review" | translate
      : { name: _currentUser.profile.firstname } }}
    </span>
    <span *ngSwitchCase="ONBOARDINGSTEP.done">
      {{ "onboarding.intro_trial_and_eventually_approved.done" | translate : {
      name: _currentUser.profile.firstname } }}
    </span>
  </h2>
  <div class="columns is-multiline">
    <div class="column" *ngIf="!_currentUser.email_confirmed">
      <div class="box">
        <h5 class="title is-6">
          {{ "onboarding.boxes.verify_email.title" | translate }}
        </h5>
        <p>{{ "onboarding.boxes.verify_email.text" | translate }}</p>
        <button
          (click)="resendWelcomeMail()"
          [disabled]="requestedMail"
          class="button is-outlined is-small is-primary is-rounded"
        >
          {{ "onboarding.boxes.verify_email.action" | translate }}
        </button>
      </div>
    </div>
    <div
      class="column"
      *ngIf="
        !_currentUser.profile.profile_completed &&
        !_currentUser.profile.profile_approved
      "
    >
      <div class="box">
        <h5 class="title is-6">
          {{ "onboarding.boxes.finish_profile.title" | translate }}
        </h5>
        <p>{{ "onboarding.boxes.finish_profile.text" | translate }}</p>
        <button
          routerLink="/home/profile"
          class="button is-outlined is-small is-primary is-rounded"
        >
          {{ "onboarding.boxes.finish_profile.action" | translate }}
        </button>
      </div>
    </div>
    <div
      class="column"
      *ngIf="
        _currentUser.profile.diplomas.length == 0 &&
        !_currentUser.profile.profile_approved
      "
    >
      <div class="box">
        <h5 class="title is-6">
          {{ "onboarding.boxes.upload_diplomas.title" | translate }}
        </h5>
        <p>{{ "onboarding.boxes.upload_diplomas.text" | translate }}</p>
        <button
          (click)="showUploadModal = true"
          class="button is-outlined is-small is-primary is-rounded"
        >
          {{ "onboarding.boxes.upload_diplomas.action" | translate }}
        </button>
      </div>
    </div>
    <div
      class="column"
      *ngIf="
        _currentUser.profile.profile_completed &&
        _currentUser.profile.profile_approved &&
        !_currentUser.profile.calendar_enabled &&
        !_currentUser.profile.ignoreCalendar
      "
    >
      <div class="box">
        <h5 class="title is-6">
          {{ "onboarding.boxes.calendar.title" | translate }}
        </h5>
        <p>{{ "onboarding.boxes.calendar.text" | translate }}</p>
        <button
          routerLink="/home/settings/calendar"
          class="button is-outlined is-small is-primary is-rounded"
        >
          {{ "onboarding.boxes.calendar.action" | translate }}
        </button>
        <button
          (click)="setIgnoreCalendar()"
          class="button is-outlined is-small is-warning is-rounded has-text-black ignore"
        >
          {{ "onboarding.ignore" | translate }}
        </button>
      </div>
    </div>
    <div class="column" *ngIf="subscriptions.length == 0">
      <div class="box">
        <h5 class="title is-6">
          {{ "onboarding.boxes.subscription_trial.title" | translate }}
        </h5>
        <p>
          {{ "onboarding.boxes.subscription_trial.text" | translate : { name:
          _currentUser.profile.firstname } }}
        </p>
        <button
          routerLink="/home/settings/subscription"
          class="button is-outlined is-small is-primary is-rounded"
        >
          {{ "onboarding.boxes.subscription_trial.action" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div
    class="modal modal-fx-slideBottom"
    [ngClass]="{ 'is-active': showUploadModal }"
    *ngIf="_currentUser"
  >
    <div class="modal-background" (click)="showUploadModal = false"></div>
    <div class="modal-content">
      <app-upload-modal
        [profile]="_currentUser.profile"
        (done)="onDiplomasUploaded($event)"
        [user]="_currentUser"
      >
      </app-upload-modal>
    </div>
  </div>
</div>
