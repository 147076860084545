<ng-container *ngIf="doEmbed(message.file_attachment)">
  <a class="" [href]="message.file_attachment" target="_blank">
    <img [src]="message.file_attachment" />
  </a>
  <a class="link" target="_blank" [href]="message.file_attachment">{{
    getS3Filename(message.file_attachment)
  }}</a>
</ng-container>

<ng-container *ngIf="!doEmbed(message.file_attachment)">
  <div class="is-flex is-not-embedable">
    <i class="icon icon-file"></i>
    <a class="link" target="_blank" [href]="message.file_attachment">{{
      getS3Filename(message.file_attachment)
    }}</a>
  </div>
</ng-container>
