import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Appointment } from '../../../entities/appointment.model';
import { CalendarService } from '../../calendar/service/calendar.service';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
@Component({
  selector: 'app-booking-form-select-date',
  templateUrl: './booking-form-select-date.component.html',
  styleUrls: ['./booking-form-select-date.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxMaskDirective,
    NgIf,
    NgFor,
    DatePipe,
    TranslateModule,
  ],
})
export class BookingFormSelectDateComponent implements OnInit, OnDestroy {
  @Input() requestBookingForm: FormGroup;
  @Input() defaultAppointmentDuration: number;
  @Input() onNext: () => void;
  @Input() onCancel: () => void;

  public isLoadingOtherAppointments = false;
  public otherAppointments: Array<Appointment>;
  public today: Date;
  subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private calendarService: CalendarService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscription =
      this.requestBookingForm.controls.appointmentDate.valueChanges
        .pipe(
          map((date: Date) => {
            // Convert to a string in the format YYYY-MM-DD so we only reload on date changes
            return date.toISOString().split('T')[0];
          }),
          distinctUntilChanged()
        )
        .subscribe(() => {
          this.reloadAppointmentList();
        });

    // load the inital appointments
    this.reloadAppointmentList();
  }

  ngOnDestroy() {
    // Remember to unsubscribe when the component gets destroyed to prevent memory leaks
    this.subscription.unsubscribe();
  }
  public reloadAppointmentList(): void {
    this.isLoadingOtherAppointments = true;
    // for some reason I cannot get change detection to work properly here. I think its related to the forms
    this.cdr.detectChanges();
    this.calendarService
      .getAppointmentsForDay(
        this.requestBookingForm.controls.appointmentDate.value
      )
      .subscribe((response) => {
        this.otherAppointments = response;
        this.isLoadingOtherAppointments = false;
        this.cdr.detectChanges();
      });
  }

  next() {
    this.onNext();
  }

  cancel() {
    this.onCancel();
  }
}
