import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogTitle,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { TimeSlotPickerComponent } from '../time-slot-picker/time-slot-picker.component';
import { MatButton } from '@angular/material/button';
import { SlotHelper } from '../../settings/calendar-working-hours/slot.helper';
import { NgIf } from '@angular/common';
import { WeeklyAvailabilitySlot } from '../../../entities/availability-slot.model';

@Component({
  selector: 'app-add-regular-working-hours-dialog',
  templateUrl: './add-regular-working-hours-dialog.component.html',
  styleUrls: ['./add-regular-working-hours-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    TranslateModule,
    TimeSlotPickerComponent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    NgIf,
  ],
})
export class AddRegularWorkingHoursDialogComponent {
  newSlot: WeeklyAvailabilitySlot;
  isBusy = false;
  slotError: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<AddRegularWorkingHoursDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const validSlotTimes = SlotHelper.setInitialSlotTimes(data.currentSlots);
    this.newSlot = {
      ...validSlotTimes,
      weekday: data.weekday,
    };
    this.validateSlot();
  }

  updateStartTime(event: string) {
    const [hour, minute] = event.split(':');
    this.newSlot.start_hour = Number(hour);
    this.newSlot.start_minute = Number(minute);
    this.validateSlot();
  }

  updateEndTime(event: string) {
    const [hour, minute] = event.split(':');
    this.newSlot.end_hour = Number(hour);
    this.newSlot.end_minute = Number(minute);
    this.validateSlot();
  }

  validateSlot() {
    if (SlotHelper.isSlotOverlapping(this.newSlot, this.data.currentSlots)) {
      this.slotError = 'The selected slot overlaps with an existing slot.';
    } else {
      this.slotError = null;
    }
  }

  saveSlot() {
    this.dialogRef.close(this.newSlot);
  }
}
