<div class="columns is-centered">
  <div class="column is-one-third">
    <div class="box">
      <section class="section">
        <header class="mb-6" [ngSwitch]="errorType">
          <h1
            class="title is-danger"
            *ngSwitchCase="linkAccountErrorType.account_mismatch"
          >
            {{ "link_accounts.title_account_mismatch" | translate }}
          </h1>
          <h1 class="title" *ngSwitchCase="undefined">
            {{ "link_accounts.title" | translate }}
          </h1>
        </header>
        <form (ngSubmit)="onSubmit()">
          <div class="mb-5">
            <ng-container [ngSwitch]="errorType">
              <h2
                class="subtitle"
                *ngSwitchCase="linkAccountErrorType.account_mismatch"
              >
                {{
                  "link_accounts.message_account_mismatch"
                    | translate : { target_email: email }
                }}
                *
              </h2>
              <h2 class="subtitle" *ngSwitchCase="undefined">
                {{ "link_accounts.message" | translate }} *
              </h2>
            </ng-container>
            <div class="field">
              <div class="control mb-5">
                <label class="radio">
                  <input
                    type="radio"
                    name="linkOption"
                    value="Link"
                    required
                    [(ngModel)]="linkOption"
                    #linkInput="ngModel"
                  />
                  {{ "link_accounts.choice_link_account" | translate }}
                </label>
                <div class="subtitle is-6 ml-4 mt-2">
                  {{ "link_accounts.choice_link_account_details" | translate }}
                </div>
              </div>
              <div class="control">
                <label class="radio">
                  <input
                    type="radio"
                    name="linkOption"
                    value="DoNotLink"
                    required
                    [(ngModel)]="linkOption"
                    #linkInput="ngModel"
                  />
                  {{ "link_accounts.choice_do_not_link_account" | translate }}
                </label>
                <div class="subtitle is-6 ml-4 mt-2">
                  {{
                    "link_accounts.choice_do_not_link_account_details"
                      | translate
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <button
                class="button is-primary is-medium is-fullwidth"
                type="submit"
                [disabled]="linkInput.invalid"
              >
                {{ "link_accounts.form_submit" | translate }}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
</div>
