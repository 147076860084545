import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  private referralsSource = new BehaviorSubject<Array<any>>(null);
  referrals = this.referralsSource.asObservable();

  private showEmailModalSource = new BehaviorSubject<boolean>(null);
  showEmailModal = this.showEmailModalSource.asObservable();

  constructor(
    private http: GenericHttpService,
    private cookieService: CookieService
  ) {}

  toogleEmailModal(flag: boolean) {
    this.showEmailModalSource.next(flag);
  }

  sendInvite(email: string, message: string): Observable<any> {
    return this.http.post('/referrals', { email: email, message: message });
  }

  getReferrals() {
    return this.http.get('/referrals').subscribe((ref) => {
      this.referralsSource.next(ref);
    });
  }

  updateReferralStatus() {
    const inviteReference = this.cookieService.get('ic_invite') || false;
    const couponReference = this.cookieService.get('ic_coupon') || false;
    if (!inviteReference && !couponReference) {
      return;
    }
    this.http
      .post('/referrals/status', {
        invite: inviteReference,
        coupon: couponReference,
      })
      .subscribe((response) => {
        if (response.status == 'ok') {
          this.cookieService.remove('ic_coupon');
          this.cookieService.remove('ic_invite');
        }
      });
  }
}
