import { environment } from './../../../../environments/environment';
import { ProfileService } from './../../profile/service/profile.service';
import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CalendarService } from '../../calendar/service/calendar.service';
import {
  CalendarCommonModule,
  CalendarEventTitleFormatter,
  CalendarMonthModule,
  CalendarView,
} from 'angular-calendar';
import { ICCalendarEventTitleFormatter } from '../../calendar/calendar.eventtitle.provider.formatter';
import { Router, RouterLink } from '@angular/router';
import { User } from 'src/app/entities/user.model';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { LoadingAnimationComponent } from '../../../shared/components/loading-animation/loading-animation.component';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-calendar-overview',
  templateUrl: './calendar-overview.component.html',
  styleUrls: ['./calendar-overview.component.scss'],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: ICCalendarEventTitleFormatter,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    LoadingAnimationComponent,
    CalendarCommonModule,
    CalendarMonthModule,
    NgFor,
    NgClass,
    FormsModule,
    TranslateModule,
  ],
})
export class CalendarOverviewComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private calendarService: CalendarService,
    private router: Router,
    private profileService: ProfileService
  ) {}

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: any[];
  user: User;
  env: any = environment;
  showSubscribe: boolean = false;

  ngOnInit() {
    this.sharedService.currentUser.subscribe((user) => {
      // user is null on logout
      if (user) {
        this.user = user;
        this.getAppointments(this.user.type === 'Client');
      }
    });
  }

  getAppointments(isClient: boolean) {
    this.calendarService.getAppointments().subscribe((appointments) => {
      this.events = [];
      appointments.forEach((app) => {
        if (app.status == 'external') {
          return;
        }

        this.events.push({
          title: isClient
            ? app.client_facing_title
            : app.therapist_facing_title,
          note: app.note,
          start: new Date(app.start),
          end: new Date(app.end),
          draggable: false,
          id: app.id,
          client_id: app.client ? app.client.id : null,
          status: app.status,
          notified_at: app.notified_at,
        });
      });
    });
  }

  public dayClicked(day: any) {
    this.router.navigate(['/home/calendar'], {
      queryParams: { date: day.date },
    });
  }

  onEnableICSChange(value: boolean) {
    this.user.profile.calendar_feed_enabled = value;
    this.profileService.updateItem(this.user.profile, this.user.id);
  }
}
