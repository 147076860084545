<section
  [@simpleFadeAnimation]="'in'"
  *ngIf="profile.id && currentUser"
  class="container-fluid container-fluid-limited"
>
  <div class="columns profile-sections has-padding-y-20">
    <div class="column is-4">
      <aside>
        <section
          class="profile-section profile-image"
          id="profile-image"
          [ngClass]="{
            editing: modal == 'profile-image',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <ng-container
            *ngIf="modal !== 'profile-image' && profile?.images?.length == 0"
          >
            <div class="profile-image-upload-placeholder">
              <button
                *ngIf="currentUser?.id == profile?.user?.id"
                (click)="openModal('profile-image')"
                class="button is-outlined is-rounded is-small"
              >
                {{ "profile.upload-button" | translate }}
              </button>
            </div>
          </ng-container>

          <div
            *ngIf="modal != 'profile-image' && profile?.images?.length > 0"
            [ngClass]="{
              editing: modal == 'profile-image',
              onboarding: profile && !profile.profile_approved
            }"
          >
            <div class="columns is-mobile">
              <div
                class="column is-10-tablet is-offset-1-tablet is-offset-2-touch is-8-touch"
              >
                <app-carousel
                  id="profile_image"
                  [profile]="profile"
                ></app-carousel>
              </div>
            </div>
            <p>
              {{ profile.images.length }}
              {{ profile.images.length > 1 ? "Photos" : "Photo" }}
              <i
                id="button-profile-image"
                *ngIf="currentUser?.id == profile?.user?.id"
                (click)="openModal('profile-image')"
                class="icon icon-edit"
              ></i>
            </p>
          </div>

          <ng-container *ngIf="modal == 'profile-image'">
            <app-photo-uploader
              (hideTourModal)="setTourModal($event)"
              [profile]="profile"
              [currentUser]="currentUser"
            ></app-photo-uploader>
          </ng-container>
        </section>
        <app-tour-modal
          *ngIf="modal == 'profile-image'"
          [hidden]="tourModalHidden"
          [disableNext]="previewImage"
          [progress]="progress"
          (close)="modal = ''"
          (next)="setTourItem($event)"
          content="{{ 'profile.modal.supporttext.content' | translate }}"
        ></app-tour-modal>
        <!-- PROFILE IMAGE END -->

        <!-- MOBILE DETAILS -->
        <div class="has-text-centered is-hidden-tablet">
          <h1 class="profile-headline">
            {{ profile?.firstname }} {{ profile?.lastname }}
          </h1>
          <h2 class="subtitle is-4">
            <!-- prettier-ignore -->
            {{profile?.jobtitle ? profile.jobtitle : ("profile.modal.supporttext.title" | translate)}}
            <i
              id="button-profile-title"
              *ngIf="currentUser?.id == profile?.user?.id"
              (click)="openModal('profile-title')"
              class="icon icon-edit"
            ></i>
          </h2>
        </div>

        <!-- MOBILE DETAILS -->

        <!-- GENDER START -->
        <section
          class="profile-section profile-gender"
          id="profile-gender"
          [ngClass]="{
            editing: modal == 'profile-gender',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <div *ngIf="modal != 'profile-gender'">
            <div class="dropdown" [ngClass]="{ 'is-active': showGender }">
              <div
                (click)="toggleGenderDropdown()"
                class="is-flex selected_gender"
              >
                <span aria-haspopup="true" aria-controls="dropdown-menu"
                  >{{ "profile.gender.label" | translate }}:
                  {{
                    "profile.gender." +
                      (profile?.gender ? profile.gender : "select") | translate
                  }}</span
                >
                <i
                  id="button-profile-gender"
                  *ngIf="currentUser?.id == profile?.user?.id"
                  (click)="openModal('profile-gender')"
                  class="icon icon-edit"
                  [ngClass]="{ required: !profile?.gender }"
                ></i>
              </div>
            </div>
          </div>
          <ng-container *ngIf="modal == 'profile-gender'">
            <h4 class="profile-section-title is-flex">
              {{ "profile.gender.label" | translate }}
            </h4>
            <input
              class="is-checkradio gender"
              type="radio"
              id="male"
              name="gender"
              value="male"
              (change)="changeGender('male')"
              [attr.checked]="profile.gender === 'male' ? '' : null"
            />
            <label class="gender" for="male">{{
              "profile.gender.male" | translate
            }}</label
            ><br />
            <input
              class="is-checkradio gender"
              type="radio"
              id="female"
              name="gender"
              value="female"
              (change)="changeGender('female')"
              [attr.checked]="profile.gender === 'female' ? '' : null"
            />
            <label class="gender" for="female">{{
              "profile.gender.female" | translate
            }}</label
            ><br />
            <input
              class="is-checkradio gender"
              type="radio"
              id="other"
              name="gender"
              value="other"
              (change)="changeGender('other')"
              [attr.checked]="profile.gender === 'other' ? '' : null"
            />
            <label class="gender" for="other">{{
              "profile.gender.other" | translate
            }}</label>
          </ng-container>
        </section>
        <app-tour-modal
          *ngIf="modal == 'profile-gender'"
          [progress]="progress"
          (close)="modal = ''"
          content="{{ 'profile.modal.supporttext.gender' | translate }}"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-image')"
        ></app-tour-modal>
        <!-- GENDER END -->

        <!-- Identity START -->
        <section
          class="profile-section profile-identity"
          id="profile-identity"
          [ngClass]="{
            editing: modal == 'profile-identity',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <div *ngIf="modal != 'profile-identity'">
            <div class="dropdown" [ngClass]="{ 'is-active': showIdentity }">
              <div
                (click)="toggleIdentityDropdown()"
                class="is-flex selected_identity"
              >
                <span aria-haspopup="true" aria-controls="dropdown-menu">
                  {{ "profile.identity.label" | translate }}:
                  <ng-container *ngIf="profile.identities.length > 0">
                    <span *ngFor="let id of profile.identities">
                      {{ "profile.identity." + id | translate }},
                    </span>
                  </ng-container>
                  <ng-container *ngIf="profile.identities?.length == 0">
                    <span>
                      {{ "profile.identity.select" | translate }}
                    </span>
                  </ng-container>
                </span>
                <i
                  id="button-profile-identity"
                  *ngIf="currentUser?.id == profile?.user?.id"
                  (click)="openModal('profile-identity')"
                  class="icon icon-edit"
                ></i>
              </div>
            </div>
          </div>
          <ng-container *ngIf="modal == 'profile-identity'">
            <h4 class="profile-section-title is-flex">
              {{ "profile.identity.label" | translate }}
            </h4>
            <ng-container *ngFor="let indentity of identities">
              <input
                class="is-checkradio identity"
                type="checkbox"
                [id]="indentity.slug"
                name="identity[]"
                (change)="changeIdentity(indentity.slug)"
                [checked]="hasIdentity(indentity.slug)"
              />
              <label class="identity" [for]="indentity.slug">{{
                indentity.name
              }}</label
              ><br />
            </ng-container>
          </ng-container>
        </section>
        <app-tour-modal
          id="skip"
          *ngIf="modal == 'profile-identity'"
          [progress]="progress"
          (close)="modal = ''"
          content="{{ 'profile.modal.supporttext.identity' | translate }} {{
            'profile.modal.supporttext.skip-identity' | translate
          }}"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-gender')"
        ></app-tour-modal>
        <!-- Identity END -->

        <!-- PRICE START -->
        <section
          class="profile-section profile-price has-margin-top-15"
          id="profile-price"
          [ngClass]="{
            editing: modal == 'profile-price',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <div *ngIf="modal != 'profile-price'">
            <p class="profile-price-mobile">
              <span class="profile-price-number"
                >{{
                  profile?.price_per_session ? profile.price_per_session : "0"
                }}
                {{ findCurrency(profile?.currency).symbol }}</span
              >/{{ "profile.session" | translate }}
              <i
                id="button-profile-price"
                *ngIf="currentUser?.id == profile?.user?.id"
                (click)="openModal('profile-price')"
                class="icon icon-edit"
                [ngClass]="{ required: !profile?.price_per_session }"
              ></i>
            </p>
          </div>
          <div
            id="profile-price"
            *ngIf="modal == 'profile-price'"
            (onInit)="focus(price_per_session_input)"
          >
            <h4 class="profile-section-title price-title">
              {{ "profile.modal.supporttext.price" | translate }}
            </h4>
            <div class="columns price-col is-mobile">
              <div class="column is-3 field">
                <input
                  #price_per_session_input
                  placeholder="80"
                  min="0"
                  step="1"
                  type="number"
                  [(ngModel)]="profile.price_per_session"
                  class="input is-rounded is-default is-medium has-text-right"
                />
              </div>
              <div class="column is-9 field">
                <div class="control">
                  <div class="select is-medium">
                    <select
                      *ngIf="currencies"
                      (change)="currencyChange($event)"
                    >
                      <option
                        *ngFor="let currency of currencies"
                        [value]="currency.code"
                        [selected]="currency.code == profile?.currency"
                      >
                        {{ currency.symbol }} ({{ currency.name }})
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns price-col is-mobile is-vcentered">
              <div class="column is-3 has-text-right">
                <label class="switch">
                  <input
                    name="showPrice"
                    (change)="onShowPriceChange($event.target.checked)"
                    [(ngModel)]="profile.showPrice"
                    type="checkbox"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div class="column is-9 has-text-left">
                <span class="is-size-5">{{
                  "profile.show-in-directory" | translate
                }}</span>
              </div>
            </div>
            <div
              *ngFor="let offer of profile?.session_offers; let i = index"
              class="offer-wrapper padding-mobile"
            >
              <hr />
              <div class="mobile-box">
                <div class="columns price-col is-mobile title-col">
                  <div class="column field">
                    <input
                      (change)="updateOffer(offer)"
                      placeholder="Service (e.g. couple session)"
                      type="text"
                      [(ngModel)]="offer.title"
                      class="input service-input is-medium"
                    />
                  </div>
                </div>
                <div class="columns price-col is-mobile">
                  <div class="column is-3 field">
                    <input
                      (change)="updateOffer(offer)"
                      placeholder="80"
                      min="0"
                      step="1"
                      type="number"
                      [(ngModel)]="offer.price"
                      class="input is-default is-medium has-text-right"
                    />
                  </div>
                  <div class="column is-9 field">
                    <div class="control">
                      <div class="select is-medium">
                        <select
                          *ngIf="currencies"
                          (change)="updateOffer(offer)"
                          [(ngModel)]="offer.currency"
                        >
                          <option
                            *ngFor="let currency of currencies"
                            [value]="currency.code"
                            [selected]="currency.code == offer.currency"
                          >
                            {{ currency.symbol }} ({{ currency.name }})
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="delete"
                  [disabled]="activeTransmission"
                  [ngClass]="{ 'is-loading': activeTransmission }"
                  (click)="removeOffer(offer.id)"
                >
                  {{ "common.delete" | translate }}
                </button>
              </div>
            </div>
            <div class="has-text-centered">
              <button
                class="button is-primary is-outlined is-rounded add-price-button"
                [disabled]="activeTransmission"
                [ngClass]="{ 'is-loading': activeTransmission }"
                (click)="newOffer()"
              >
                {{ "profile.modal.supporttext.alt-price" | translate }}
              </button>
            </div>
          </div>
        </section>
        <app-tour-modal
          *ngIf="modal == 'profile-price'"
          [progress]="progress"
          (close)="modal = ''"
          content="{{ 'profile.modal.supporttext.insert-price' | translate }}"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-identity')"
        ></app-tour-modal>
        <!-- PRICE END -->

        <!-- MESSAGE START -->
        <section
          [ngClass]="{ 'is-hidden': currentUser?.id == profile?.user?.id }"
          class="profile-section profile-message"
          id="profile-message"
        >
          <div class="columns is-mobile is-multiline">
            <div class="column is-6-touch is-12-desktop">
              <button
                id="button-profile-message"
                (click)="messageModal = true"
                class="button ic-button is-fullwidth is-rounded is-medium"
              >
                {{ "common.send-a-message" | translate }}
              </button>
            </div>
            <div class="column is-6-touch is-12-desktop">
              <button
                class="button ic-button is-outlined is-rounded is-medium is-fullwidth"
              >
                <span class="icon icon-forward"></span
                >{{ "profile.send-to-friend" | translate }}
              </button>
            </div>
          </div>
        </section>
        <!-- MESSAGE END -->

        <!-- <h2 class="subtitle is-6"><i class="icon icon-edit">ss_time</i>&nbsp; Usually responds within 24 hours</h2> -->

        <div class="has-text-centered">
          <button
            class="button is-primary is-rounded is-fullwidth"
            (click)="diplomaWindowShown = false; showDiplomaWindow = true"
          >
            {{ "onboarding.boxes.upload_diplomas.title" | translate }}
          </button>
        </div>

        <div class="has-text-centered settingsbutton">
          <button
            class="button is-rounded is-fullwidth is-outlined"
            (click)="showDirectorySettings()"
          >
            {{ "profile.directory-settings" | translate }}
          </button>
        </div>
      </aside>
    </div>

    <div class="column is-7 is-offset-1">
      <main>
        <h1 class="profile-headline is-hidden-mobile">
          {{ profile?.firstname }} {{ profile?.lastname }}
        </h1>

        <!-- TITLE START -->
        <section
          class="profile-section profile-title"
          id="profile-title"
          [ngClass]="{
            editing: modal == 'profile-title',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <div *ngIf="modal != 'profile-title'" class="is-hidden-mobile">
            <h2 class="subtitle is-4">
              <!-- prettier-ignore -->
              {{ profile?.jobtitle ? profile.jobtitle : ("profile.modal.supporttext.title" | translate)}}
              <i
                id="button-profile-title"
                *ngIf="currentUser?.id == profile?.user?.id"
                (click)="openModal('profile-title')"
                class="icon icon-edit"
                [ngClass]="{ required: !profile?.jobtitle }"
              ></i>
            </h2>
          </div>
          <div *ngIf="modal == 'profile-title'" class="padding-mobile">
            <h4
              class="profile-section-title is-flex is-flex-direction-row is-align-items-center"
            >
              {{
                "profile.modal.supporttext.select-your-jobtitle" | translate
              }}
            </h4>
            <div class="control is-expanded has-padding-10">
              <ng-select [items]="jobTitlesAll"
                         bindLabel="name"
                         bindValue="name"
                         [groupBy]="groupByFn"
                         [groupValue]="groupValueFn"
                         [(ngModel)]="profile.jobtitle"
                         [clearable]="false">
                <ng-template ng-optgroup-tmp let-item="item">
                  <span style="vertical-align: middle;">{{item.name}}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <span>{{item.name}}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </section>
        <app-tour-modal
          *ngIf="modal == 'profile-title'"
          [progress]="progress"
          (close)="modal = ''"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-price')"
          content="{{ 'profile.modal.supporttext.offical-title' | translate }}"
        ></app-tour-modal>
        <!-- TITLE END -->

        <!-- RATINGS START -->
        <section class="profile-section profile-rating is-hidden" id="profile-rating">
          <button
            [disabled]="disableLikes"
            (click)="addLike()"
            *ngIf="profile"
            class="button is-rounded"
          >
            <span id="like"></span>{{ "common.like" | translate }} {{ likes }}
          </button>
        </section>
        <!-- RATINGS END -->

        <!-- CATEGORY START -->
        <section
          id="profile-category"
          class="profile-section profile-category"
          [ngClass]="{
            editing: modal == 'profile-category',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <ng-container
            *ngIf="showCategoryLabels && modal != 'profile-category'"
          >
            <div class="columns">
              <div class="column">
                <span
                  class="category-badge"
                  *ngFor="let cat of profile.categories"
                  >{{ cat.name }}</span
                >
                <i
                  id="button-profile-category"
                  *ngIf="
                    currentUser?.id == profile?.user?.id &&
                    profile.categories?.length > 0
                  "
                  (click)="openModal('profile-category')"
                  class="icon icon-edit"
                ></i>
              </div>
            </div>
          </ng-container>

          <div *ngIf="modal == 'profile-category'" class="padding-mobile">
            <h4 class="profile-section-title">
              {{ "profile.spec-title" | translate }}
            </h4>
            <app-dropdown-input
              [placeholder]="'e.g. Crisis & Trauma'"
              [error]="'You can only add 7 specialities'"
              [initItems]="profile.categories"
              [items]="categories"
              [limit]="7"
              [profile]="profile"
              [key]="'categories'"
              (componentDone)="modal = 'profile-map'"
            ></app-dropdown-input>
          </div>

          <ng-container
            *ngIf="
              profile.categories?.length == 0 && modal != 'profile-category'
            "
          >
            <button
              id="button2-profile-category"
              *ngIf="currentUser?.id == profile?.user?.id"
              (click)="openModal('profile-category')"
              class="button is-outlined is-medium tag-button"
            >
              <i class="icon icon-tag"></i>{{ "profile.spec-help" | translate }}
            </button>
          </ng-container>
        </section>
        <app-tour-modal
          *ngIf="modal == 'profile-category'"
          [progress]="progress"
          (close)="modal = ''"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-title')"
          content="{{ 'profile.modal.supporttext.specialise' | translate }}"
        ></app-tour-modal>
        <!-- CATEGORY END -->

        <!-- ADDRESS START -->
        <section
          id="profile-map"
          class="profile-section profile-map"
          [ngClass]="{
            editing: modal == 'profile-map',
            'no-shadow': modal == 'profile-map',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <ng-container *ngIf="!profile?.city && modal != 'profile-map'">
            <profile-map [profile]="profile" style="width: 100%"></profile-map>
            <button
              *ngIf="currentUser?.id == profile?.user?.id"
              id="button-profile-map"
              (click)="openModal('profile-map')"
              class="button is-outlined is-medium location-button"
            >
              <i class="icon icon-location"></i>
              {{ "profile.add-location" | translate }}
            </button>
          </ng-container>

          <div class="profile-map-overlay" *ngIf="modal == 'profile-map'"></div>

          <div
            *ngIf="modal == 'profile-map'"
            class="profile-map-input-container"
          >
            <div class="field has-addons">
              <div class="control is-expanded">
                <input
                  class="input addressinput"
                  type="text"
                  [(ngModel)]="geosearch"
                  placeholder="{{
                    'profile.modal.supporttext.placeholder.location' | translate
                  }}"
                  (change)="searchAddress()"
                />
              </div>
              <div class="control">
                <div class="button" (click)="searchAddress()">
                  {{ "common.search" | translate }}
                </div>
              </div>
            </div>
            <div *ngIf="geosearching" class="modal is-active">
              <div class="modal-content geo_results">
                <div
                  class="button is-loading"
                  *ngIf="georesults === null"
                ></div>
                <div *ngIf="georesults !== null && georesults.length == 0">
                  {{ "profile.address-error" | translate }}
                </div>
                <div *ngIf="georesults !== null && georesults.length > 0">
                  <ul class="">
                    <li>
                      <h5 class="title is-5">
                        {{ "profile.select-address-title" | translate }}
                      </h5>
                    </li>
                    <li>
                      <div
                        (click)="setAddress(address)"
                        class="button is-rounded is-small is-fullwidth"
                        *ngFor="let address of georesults"
                      >
                        {{ address.display_name }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <div
                    class="button is-rounded is-outlined is-danger is-small"
                    (click)="georesults = null; geosearching = false"
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="(profile?.street || profile?.city) && modal != 'profile-map'"
            class="columns"
          >
            <profile-map
              (edit)="openModal('profile-map')"
              style="width: 100%"
              [profile]="profile"
            ></profile-map>
            <!-- <i id="button-profile-about" *ngIf="currentUser?.id == profile?.user?.id" (click)="openModal('profile-map')" class="icon icon-edit"></i> -->
          </div>
        </section>
        <app-tour-modal
          *ngIf="modal == 'profile-map'"
          [progress]="progress"
          (close)="modal = ''"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-category')"
          content="{{ 'profile.modal.supporttext.location' | translate }}"
        ></app-tour-modal>
        <!-- ADDRESS END -->

        <!-- ADDRESS MOBILE START -->
        <section
          id="profile-map-mobile"
          class="profile-section profile-map-mobile"
          [ngClass]="{
            editing: modal == 'profile-map',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <ng-container *ngIf="modal != 'profile-map'">
            <h4 class="profile-section-title">
              {{ "profile.address" | translate }}
              <i
                id="button-profile-address-mobile"
                *ngIf="
                  currentUser?.id == profile?.user?.id && modal != 'profile-map'
                "
                (click)="openModal('profile-map')"
                class="icon icon-edit"
              ></i>
            </h4>
            <p>
              {{ profile.street ? profile.street : "" }}
              {{ profile.number ? profile.number + "," : "" }}
              {{ profile.zip ? profile.zip + " " : "" }}
              {{ profile.city ? profile.city.name : "" }}
            </p>
          </ng-container>

          <ng-container *ngIf="modal == 'profile-map'">
            <h4 class="profile-section-title">
              {{ "profile.your-address" | translate }}
            </h4>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input
                  class="input addressinput"
                  type="text"
                  [(ngModel)]="geosearch"
                  placeholder="{{
                    'profile.modal.supporttext.location' | translate
                  }}"
                  (change)="searchAddress()"
                />
              </div>
              <div class="control">
                <div class="button" (click)="searchAddress()">
                  {{ "common.search" | translate }}
                </div>
              </div>
            </div>
            <div *ngIf="geosearching" class="modal is-active">
              <div class="modal-content geo_results">
                <div
                  class="button is-loading"
                  *ngIf="georesults === null"
                ></div>
                <div *ngIf="georesults !== null && georesults.length == 0">
                  Can not find a matching address. Please try a more specific
                  location.
                </div>
                <div *ngIf="georesults !== null && georesults.length > 0">
                  <ul class="">
                    <li>
                      <h5 class="title is-5">Please select your address</h5>
                    </li>
                    <li>
                      <div
                        (click)="setAddress(address)"
                        class="button is-rounded is-small is-fullwidth"
                        *ngFor="let address of georesults"
                      >
                        {{ address.display_name }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <div
                    class="button is-rounded is-outlined is-danger is-small"
                    (click)="georesults = null; geosearching = false"
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </section>
        <!-- ADDRESS MOBILE END -->

        <!-- PROFESSIONAL STATEMENT -->
        <section
          class="profile-section"
          id="profile-statement"
          [ngClass]="{
            editing: modal == 'profile-statement',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <div class="padding-mobile">
            <h4 class="profile-section-title">
              {{ "profile.statement-title" | translate
              }}<i
                id="button-profile-statement"
                *ngIf="
                  currentUser?.id == profile?.user?.id &&
                  modal != 'profile-statement'
                "
                (click)="openModal('profile-statement')"
                class="icon icon-edit"
                [ngClass]="{ required: !profile?.professional_statement }"
              ></i>
            </h4>
            <h6
              class="profile-subtitle error"
              *ngIf="
                profile.professional_statement &&
                profile.professional_statement.length == 220
              "
            >
              {{ "profile.statement-help" | translate }}
            </h6>
            <div *ngIf="modal != 'profile-statement'">
              <!-- prettier-ignore -->
              <p>{{profile?.professional_statement ? profile.professional_statement : ("profile.statement-placeholder" | translate)}}
            </div>
          </div>
          <div
            (onInit)="focus(professional_statement_input)"
            *ngIf="modal == 'profile-statement'"
            class="padding-mobile"
          >
            <div>
              <textarea
                (keyup)="resizeTextArea(professional_statement_input)"
                #professional_statement_input
                [ngClass]="{
                  invalid_statement:
                    profile.professional_statement?.length >= 219
                }"
                maxlength="220"
                id="professional_statement_input"
                [(ngModel)]="profile.professional_statement"
                placeholder="{{
                  'profile.modal.supporttext.placeholder.job-exp' | translate
                }}"
                class="textarea is-primary is-fullwidth"
              ></textarea>
            </div>
          </div>
        </section>
        <app-tour-modal
          *ngIf="modal == 'profile-statement'"
          [progress]="progress"
          (close)="modal = ''"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-map')"
          content="{{ 'profile.modal.supporttext.prof-state' | translate }}"
        ></app-tour-modal>
        <!-- PROFESSIONAL END -->

        <!-- ABOUT START -->
        <section
          class="profile-section"
          id="profile-about"
          [ngClass]="{
            editing: modal == 'profile-about',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <div class="padding-mobile">
            <h4 class="profile-section-title">
              {{ "profile.about-title" | translate }}
              {{
                currentUser?.id == profile?.user?.id ? "" : profile.firstname
              }}
              <i
                id="button-profile-about"
                *ngIf="
                  currentUser?.id == profile?.user?.id &&
                  modal != 'profile-about'
                "
                (click)="openModal('profile-about')"
                class="icon icon-edit"
                [ngClass]="{ required: !profile?.about }"
              ></i>
            </h4>
            <h6
              class="profile-subtitle error"
              *ngIf="
                profile.about &&
                profile.about.length == 2000 &&
                modal == 'profile-about'
              "
            >
              {{ "profile.about-help" | translate }}
            </h6>
            <div *ngIf="modal != 'profile-about'">
              <!-- prettier-ignore -->
              <p>{{profile?.about ? profile.about : ("profile.tell-about" | translate)}}</p>
            </div>
            <div (onInit)="focus(about_input)" *ngIf="modal == 'profile-about'">
              <textarea
                (keyup)="resizeTextArea(about_input)"
                #about_input
                [(ngModel)]="profile.about"
                maxlength="2000"
                placeholder="{{
                  'profile.modal.supporttext.placeholder.yourself' | translate
                }}"
                class="textarea is-primary is-fullwidth"
              ></textarea>
            </div>
          </div>
        </section>
        <app-tour-modal
          *ngIf="modal == 'profile-about'"
          [progress]="progress"
          (close)="modal = ''"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-statement')"
          content="{{ 'profile.modal.supporttext.yourself' | translate }}"
        ></app-tour-modal>
        <!-- ABOUT END -->

        <!-- APPROACH START -->
        <section
          class="profile-section"
          id="profile-approach"
          [ngClass]="{
            editing: modal == 'profile-approach',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <div class="padding-mobile">
            <h4 class="profile-section-title">
              {{ "profile.approach-title" | translate
              }}<i
                id="button-profile-approach"
                *ngIf="
                  currentUser?.id == profile?.user?.id &&
                  modal != 'profile-approach'
                "
                (click)="openModal('profile-approach')"
                class="icon icon-edit"
                [ngClass]="{ required: !profile?.approach }"
              ></i>
            </h4>
            <h6
              class="profile-subtitle error"
              *ngIf="
                profile.approach &&
                profile.approach.length == 2000 &&
                modal == 'profile-approach'
              "
            >
              Maximum of 2000 characters
            </h6>
            <div *ngIf="modal != 'profile-approach'">
              <!-- prettier-ignore -->
              <p>{{profile?.approach ? profile.approach : ("profile.approach" | translate)}}</p>
            </div>
            <div
              *ngIf="modal == 'profile-approach'"
              (onInit)="focus(approach_input)"
              class="p"
            >
              <textarea
                (keyup)="resizeTextArea(approach_input)"
                maxlength="2000"
                #approach_input
                [(ngModel)]="profile.approach"
                placeholder="{{
                  'profile.modal.supporttext.placeholder.yourself' | translate
                }}"
                class="textarea is-primary"
              ></textarea>
            </div>
          </div>
        </section>
        <app-tour-modal
          *ngIf="modal == 'profile-approach'"
          [progress]="progress"
          (close)="modal = ''"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-about')"
          content="{{ 'profile.modal.supporttext.approach' | translate }}"
        ></app-tour-modal>
        <!-- APPROACH END -->

        <span class="profile-divider"></span>

        <h2 class="profile-section-title">
          {{ "profile.qualifications-title" | translate }}
        </h2>

        <!-- EDUCATION START -->
        <section
          class="profile-section"
          id="profile-education"
          [ngClass]="{
            editing: modal == 'profile-education',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <div class="columns">
            <div class="column is-4" *ngIf="modal != 'profile-education'">
              <h3 class="profile-section-subtitle">
                {{ "profile.education-title" | translate }}
                <i
                  id="button-profile-education"
                  *ngIf="
                    currentUser?.id == profile?.user?.id &&
                    modal != 'profile-education'
                  "
                  (click)="openModal('profile-education')"
                  class="icon icon-edit"
                  [ngClass]="{ required: !profile?.education }"
                ></i>
              </h3>
            </div>
            <div class="column">
              <ng-container *ngIf="modal != 'profile-education'">
                <p>{{ profile?.education }}</p>
              </ng-container>
              <div
                (onInit)="focus(education_input)"
                *ngIf="modal == 'profile-education'"
              >
                <h4 class="profile-section-title">
                  {{ "profile.education-title" | translate }}
                </h4>
                <textarea
                  (keyup)="resizeTextArea(education_input)"
                  #education_input
                  [(ngModel)]="profile.education"
                  placeholder="{{
                    'profile.modal.supporttext.placeholder.education'
                      | translate
                  }}"
                  class="textarea is-primary"
                ></textarea>
              </div>
            </div>
          </div>
        </section>
        <app-tour-modal
          [progress]="progress"
          (close)="modal = ''"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-approach')"
          *ngIf="modal == 'profile-education'"
          content="{{ 'profile.modal.supporttext.education' | translate }}"
        ></app-tour-modal>
        <!-- EDUCATION END -->

        <!-- EXPERIENCES START -->
        <section
          class="profile-section"
          id="profile-experiences"
          [ngClass]="{
            editing: modal == 'profile-experiences',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <div class="columns">
            <div class="column is-4" *ngIf="modal != 'profile-experiences'">
              <h3 class="profile-section-subtitle">
                {{ "profile.experience-title" | translate }}
                <i
                  id="button-profile-experiences"
                  *ngIf="
                    currentUser?.id == profile?.user?.id &&
                    modal != 'profile-experiences'
                  "
                  (click)="openModal('profile-experiences')"
                  class="icon icon-edit"
                  [ngClass]="{ required: !profile?.experience }"
                ></i>
              </h3>
            </div>
            <div class="column">
              <ng-container *ngIf="modal != 'profile-experiences'">
                <p>{{ profile?.experience }}</p>
              </ng-container>
              <div
                (onInit)="focus(experience_input)"
                *ngIf="modal == 'profile-experiences'"
              >
                <h4 class="profile-section-title">
                  {{ "profile.experience-title" | translate }}
                </h4>
                <textarea
                  (keyup)="resizeTextArea(experience_input)"
                  #experience_input
                  [(ngModel)]="profile.experience"
                  placeholder="{{
                    'profile.modal.supporttext.placeholder.experience'
                      | translate
                  }}"
                  class="textarea is-primary"
                ></textarea>
              </div>
            </div>
          </div>
        </section>
        <app-tour-modal
          [progress]="progress"
          (close)="modal = ''"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-education')"
          *ngIf="modal == 'profile-experiences'"
          content="{{ 'profile.modal.supporttext.experience' | translate }}"
        ></app-tour-modal>
        <!-- EXPERIENCES END -->

        <!-- PROFESSIONAL START START -->
        <section
          class="profile-section"
          id="profile-professional-start"
          [ngClass]="{
            editing: modal == 'profile-professional-start',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <div class="columns">
            <div class="column is-4" *ngIf="modal != 'profile-professional-start'">
              <h3 class="profile-section-subtitle">
                {{ "profile.professional-start.title" | translate }}
                <i
                  id="button-profile-professional-start"
                  *ngIf="
                    currentUser?.id == profile?.user?.id &&
                    modal != 'profile-professional-start'
                  "
                  (click)="openModal('profile-professional-start')"
                  class="icon icon-edit"
                ></i>
              </h3>
            </div>
            <div class="column">
              <ng-container *ngIf="modal != 'profile-professional-start'">
                <p>{{ profile?.month_year_started ? moment(profile?.month_year_started).format('MMMM YYYY') : '' }}</p>
              </ng-container>
              <div
                *ngIf="modal == 'profile-professional-start'"
              >
                <h4 class="profile-section-title">
                  {{ "profile.professional-start.title" | translate }}
                </h4>
                <div class="columns">
                  <div class="column">
                    <div class="control is-expanded has-padding-15">
                      <ng-select
                        [items]="professionalStartMonths"
                        [(ngModel)]="professionalStartSelectedMonth"
                        (change)="updateProfessionalStartDateIfChanged()"
                        [clearable]="true"
                        [searchable]="false"
                        bindLabel="viewValue"
                        bindValue="value"
                        [placeholder]="'profile.professional-start.placeholder-month' | translate"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="column">
                    <div class="control is-expanded has-padding-15">
                      <ng-select
                        [items]="professionalStartYears"
                        [(ngModel)]="professionalStartSelectedYear"
                        (change)="updateProfessionalStartDateIfChanged()"
                        [clearable]="true"
                        [searchable]="false"
                        bindValue="value"
                        [placeholder]="'profile.professional-start.placeholder-year' | translate"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <app-tour-modal
          [progress]="progress"
          (close)="closeModal()"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-experiences')"
          *ngIf="modal == 'profile-professional-start'"
          content="{{ 'profile.modal.supporttext.professional-start' | translate }}"
        ></app-tour-modal>
        <!-- PROFESSIONAL START END -->

        <span class="profile-divider"></span>

        <h2 class="profile-section-title">
          {{ "profile.session-information-title" | translate }}
        </h2>

        <!-- LANGUAGES START -->
        <section
          class="profile-section profile-languages"
          id="profile-languages"
          [ngClass]="{
            editing: modal == 'profile-languages',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <div class="columns">
            <div class="column is-4" *ngIf="modal != 'profile-languages'">
              <h3 class="profile-section-subtitle">
                {{ "profile.languages-title" | translate }}
                <i
                  id="button-profile-languages"
                  *ngIf="
                    currentUser?.id == profile?.user?.id &&
                    modal != 'profile-languages'
                  "
                  (click)="openModal('profile-languages')"
                  class="icon icon-edit"
                  [ngClass]="{ required: profile?.languages?.length == 0 }"
                ></i>
              </h3>
            </div>
            <div class="column">
              <div *ngIf="modal != 'profile-languages'">
                <p>{{ profile?.languages | languageList }}</p>
              </div>
              <div *ngIf="modal == 'profile-languages'">
                <h4 class="profile-section-title">
                  {{ "profile.languages-title" | translate }}
                </h4>
                <app-dropdown-input
                  [placeholder]="'e.g. English'"
                  [items]="languages"
                  [profile]="profile"
                  [key]="'languages'"
                  [limit]="7"
                  error="You can only specify seven languages"
                ></app-dropdown-input>
              </div>
            </div>
          </div>
        </section>
        <app-tour-modal
          [progress]="progress"
          (close)="modal = ''"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-professional-start')"
          *ngIf="modal == 'profile-languages'"
          content="{{ 'profile.modal.supporttext.language' | translate }}"
        ></app-tour-modal>
        <!-- LANGUAGES END -->

        <!-- INSURANCES START -->
        <section
          class="profile-section profile-insurances"
          id="profile-insurances"
          [ngClass]="{
            editing: modal == 'profile-insurances',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <div class="columns">
            <div class="column is-4" *ngIf="modal != 'profile-insurances'">
              <h3 class="profile-section-subtitle">
                {{ "profile.insurances-title" | translate }}
                <i
                  id="button-profile-insurances"
                  *ngIf="currentUser?.id == profile?.user?.id"
                  (click)="openModal('profile-insurances')"
                  class="icon icon-edit"
                ></i>
              </h3>
            </div>
            <div class="column">
              <div *ngIf="modal != 'profile-insurances'">
                <ul class="insurance-list">
                  <li *ngIf="hasInsurance('private_pay')">
                    {{ "profile.private-payments" | translate }}
                  </li>
                  <li *ngIf="hasInsurance('private_insurance')">
                    {{ "profile.private-insurances" | translate }}
                  </li>
                  <li *ngIf="hasInsurance('public_insurance')">
                    {{ "profile.public-insurances" | translate }}
                  </li>
                  <li *ngIf="hasInsurance('reimbursement')">
                    {{ "profile.reimbursement" | translate }}
                  </li>
                </ul>
              </div>
              <div *ngIf="modal == 'profile-insurances'">
                <h4 class="profile-section-title">
                  {{ "profile.insurances-title" | translate }}
                </h4>
                <div class="field">
                  <input
                    class="is-checkradio"
                    id="private_pay"
                    type="checkbox"
                    name="private_pay"
                    (change)="setInsurance($event)"
                    [checked]="hasInsurance('private_pay')"
                  />
                  <label for="private_pay">{{
                    "profile.private-payments" | translate
                  }}</label>
                </div>
                <div class="field">
                  <input
                    class="is-checkradio"
                    id="private_insurance"
                    type="checkbox"
                    name="private_insurance"
                    (change)="setInsurance($event)"
                    [checked]="hasInsurance('private_insurance')"
                  />
                  <label for="private_insurance">{{
                    "profile.private-insurances" | translate
                  }}</label>
                </div>
                <div class="field">
                  <input
                    class="is-checkradio"
                    id="public_insurance"
                    type="checkbox"
                    name="public_insurance"
                    (change)="setInsurance($event)"
                    [checked]="hasInsurance('public_insurance')"
                  />
                  <label for="public_insurance">{{
                    "profile.public-insurances" | translate
                  }}</label>
                </div>
                <div class="field">
                  <input
                    class="is-checkradio"
                    id="reimbursement"
                    type="checkbox"
                    name="reimbursement"
                    (change)="setInsurance($event)"
                    [checked]="hasInsurance('reimbursement')"
                  />
                  <label for="reimbursement">{{
                    "profile.reimbursement" | translate
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </section>
        <app-tour-modal
          [progress]="progress"
          (close)="modal = 'profile-communication-format'"
          (next)="setTourItem($event)"
          (previous)="setTourItem(false, 'profile-languages')"
          *ngIf="modal == 'profile-insurances'"
          content="{{ 'profile.modal.supporttext.insurence' | translate }}"
        ></app-tour-modal>
        <!-- INSURANCES END -->

        <!-- COMMUNICATION FORMAT START -->
        <section
          class="profile-section profile-communication-format"
          id="profile-communication-format"
          [ngClass]="{
            editing: modal == 'profile-communication-format',
            onboarding: profile && !profile.profile_approved
          }"
        >
          <div class="columns">
            <div class="column is-4" *ngIf="modal != 'profile-communication-format'">
              <h3
                class="profile-section-subtitle"
                *ngIf="modal != 'profile-communication-format'"
              >
                {{ "profile.session-formats.title" | translate }}
                <i
                  id="button-profile-communication-format"
                  *ngIf="currentUser?.id == profile?.user?.id"
                  (click)="openModal('profile-communication-format')"
                  class="icon icon-edit"
                  price_per_session
                ></i>
              </h3>
            </div>
            <div class="column">
              <div *ngIf="modal != 'profile-communication-format'">
                <ul class="formats-list">
                  <li *ngIf="profile.online_supported">
                    {{ "profile.session-formats.online" | translate }}
                  </li>
                  <li *ngIf="profile.in_person_supported">
                    {{ "profile.session-formats.in-person" | translate }}
                  </li>
                  <li *ngIf="profile.text_supported">
                    {{ "profile.session-formats.text" | translate }}
                  </li>
                </ul>
              </div>
              <div *ngIf="modal == 'profile-communication-format'">
                <h4 class="profile-section-title">
                  {{ "profile.session-formats.title" | translate }}
                </h4>
                <div class="field">
                  <input
                    class="is-checkradio"
                    id="online-sessions"
                    type="checkbox"
                    [(ngModel)]="profile.online_supported"
                  />
                  <label for="online-sessions">{{
                    "profile.session-formats.online" | translate
                  }}</label>
                </div>
                <div class="field">
                  <input
                    class="is-checkradio"
                    id="in-person-sessions"
                    type="checkbox"
                    [(ngModel)]="profile.in_person_supported"
                  />
                  <label for="in-person-sessions">{{
                      "profile.session-formats.in-person" | translate
                    }}</label>
                </div>
                <div class="field">
                  <input
                    class="is-checkradio"
                    id="text-sessions"
                    type="checkbox"
                    [(ngModel)]="profile.text_supported"
                  />
                  <label for="text-sessions">{{
                      "profile.session-formats.text" | translate
                    }}</label>
                </div>
                <h6
                  class="profile-subtitle error"
                  *ngIf="
                    !profile.online_supported &&
                    !profile.in_person_supported &&
                    !profile.text_supported"
                >
                  {{ "profile.session-formats.please-select-at-least-one" | translate }}
                </h6>
              </div>
            </div>
          </div>
        </section>
        <app-tour-modal
          [progress]="progress"
          (close)="modal = ''"
          (next)="modal = ''"
          (previous)="setTourItem(false, 'profile-insurances')"
          *ngIf="modal == 'profile-communication-format'"
          content="{{ 'profile.modal.supporttext.session-formats' | translate }}"
        ></app-tour-modal>
        <!-- COMMUNICATION FORMAT END -->

        <div
          *ngIf="profile.profile_approved"
        >
          <span class="profile-divider"></span>

          <section class="profile-section">
            <h2 class="profile-section-localization-title">
              {{ "profile.secondary_language.title" | translate }}
            </h2>
            <div
              class="profile-section-subtitle"
              [innerHTML]="
                sanitizer.bypassSecurityTrustHtml(
                  translate.instant('profile.secondary_language.subtitle', {
                    english_version: createDirectoryLink('en'),
                    german_version: createDirectoryLink('de')
                  })
                )
              "
            ></div>
            <div class="profile-section-subtitle has-margin-top-15">
              {{
              "profile.secondary_language.subtitle_2" | translate
              }}
            </div>
          </section>

          <section class="profile-section">
            <div class="field is-flex is-align-items-center">
              <div
                class="dropdown"
                [ngClass]="{
                  'is-active': isSecondaryLanguageDropdownOpen,
                  'is-loading': true
                }"
              >
                <div class="dropdown-trigger">
                  <button
                    class="button is-rounded"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    (click)="
                      isSecondaryLanguageDropdownOpen =
                        !isSecondaryLanguageDropdownOpen
                    "
                  >
                    <ng-container
                      *ngIf="profile.current_secondary_language === 'none'"
                    >
                      <span>{{
                        "profile.secondary_language.dropdown.none" | translate
                      }}</span>
                    </ng-container>
                    <ng-container
                      *ngIf="profile.current_secondary_language === 'en'"
                    >
                      <span>{{
                        "profile.secondary_language.dropdown.en" | translate
                      }}</span>
                    </ng-container>
                    <ng-container
                      *ngIf="profile.current_secondary_language === 'de'"
                    >
                      <span>{{
                        "profile.secondary_language.dropdown.de" | translate
                      }}</span>
                    </ng-container>
                    <span class="icon is-small">
                      <i
                        class="icon icon-chevron-down-green"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                  <div class="dropdown-content">
                    <a
                      class="dropdown-item"
                      (click)="setSecondaryLanguage($event)"
                      [name]="'none'"
                      >{{
                        "profile.secondary_language.dropdown.none" | translate
                      }}</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="setSecondaryLanguage($event)"
                      [name]="'en'"
                      >{{
                        "profile.secondary_language.dropdown.en" | translate
                      }}</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="setSecondaryLanguage($event)"
                      [name]="'de'"
                      >{{
                        "profile.secondary_language.dropdown.de" | translate
                      }}</a
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="profile.current_secondary_language == 'none'"
              class="profile-section-subtitle"
              [innerHTML]="
                sanitizer.bypassSecurityTrustHtml(
                  translate.instant(
                    'profile.secondary_language.no_secondary_message',
                    {
                      english_version: createProfileLink(currentUser, 'en'),
                      german_version: createProfileLink(currentUser, 'de')
                    }
                  )
                )
              "
            ></div>
            <div *ngIf="secondaryProfilePresent">
              <div *ngIf="secondaryProfileCompleted">
                <div class="secondary-status complete has-margin-top-25">
                  {{
                    "profile.secondary_language.secondary_content_complete"
                      | translate
                  }}
                </div>
                <div
                  *ngIf="profile.current_secondary_language == 'en'"
                  class="profile-section-subtitle"
                  [innerHTML]="
                    sanitizer.bypassSecurityTrustHtml(
                      translate.instant(
                        'profile.secondary_language.complete_congratulations.en',
                        {
                          english_version: createProfileLink(currentUser, 'en'),
                          german_version: createProfileLink(currentUser, 'de')
                        }
                      )
                    )
                  "
                ></div>
                <div
                  *ngIf="profile.current_secondary_language == 'de'"
                  class="profile-section-subtitle"
                  [innerHTML]="
                    sanitizer.bypassSecurityTrustHtml(
                      translate.instant(
                        'profile.secondary_language.complete_congratulations.de',
                        {
                          english_version: createProfileLink(currentUser, 'en'),
                          german_version: createProfileLink(currentUser, 'de')
                        }
                      )
                    )
                  "
                ></div>
              </div>
              <div *ngIf="!secondaryProfileCompleted">
                <div class="secondary-status incomplete has-margin-top-25">
                  {{
                    "profile.secondary_language.secondary_content_not_complete"
                      | translate
                  }}
                </div>
                <div
                  *ngIf="profile.current_secondary_language == 'en'"
                  class="profile-section-subtitle"
                  [innerHTML]="
                    sanitizer.bypassSecurityTrustHtml(
                      translate.instant(
                        'profile.secondary_language.incomplete_teaser.en',
                        {
                          english_version: createProfileLink(currentUser, 'en'),
                          german_version: createProfileLink(currentUser, 'de')
                        }
                      )
                    )
                  "
                ></div>
                <div
                  *ngIf="profile.current_secondary_language == 'de'"
                  class="profile-section-subtitle"
                  [innerHTML]="
                    sanitizer.bypassSecurityTrustHtml(
                      translate.instant(
                        'profile.secondary_language.incomplete_teaser.de',
                        {
                          english_version: createProfileLink(currentUser, 'en'),
                          german_version: createProfileLink(currentUser, 'de')
                        }
                      )
                    )
                  "
                ></div>
              </div>
            </div>
          </section>

          <section
            *ngIf="
              !this.profile.secondary_language_profile_text &&
              this.profile.current_secondary_language != 'none'
            "
          >
            <div
              class="padding-mobile is-fullwidth is-flex is-justify-content-center"
            >
              <mat-spinner mode="indeterminate" diameter="40"></mat-spinner>
            </div>
          </section>

          <!-- SECONDARY TITLE START -->
          <section
            *ngIf="!!this.profile.secondary_language_profile_text"
            class="profile-section profile-title"
            [ngClass]="{
              editing: modal == 'profile-secondary-title',
              onboarding: profile && !profile.profile_approved
            }"
          >
            <div
              *ngIf="modal != 'profile-secondary-title'"
            >
              <h2 class="subtitle is-4">
                <!-- prettier-ignore -->
                {{ profile?.secondary_language_profile_text && profile?.secondary_language_profile_text.jobtitle ? profile?.secondary_language_profile_text.jobtitle : ((
                  profile.current_secondary_language == "en" ? "profile.secondary_language.job_title.title.en" : "profile.secondary_language.job_title.title.de") | translate)
                }}
                <i
                  id="button-profile-secondary-title"
                  *ngIf="currentUser?.id == profile?.user?.id"
                  (click)="openModal('profile-secondary-title')"
                  class="icon icon-edit"
                  [ngClass]="{
                    required:
                      profile?.current_secondary_language &&
                      !profile?.secondary_language_profile_text?.jobtitle
                  }"
                ></i>
              </h2>
            </div>
            <div
              *ngIf="modal == 'profile-secondary-title'"
              class="padding-mobile"
            >
              <h4
                class="profile-section-title is-flex is-flex-direction-row is-align-items-center"
              >
                {{
                  "profile.modal.supporttext.select-your-jobtitle"
                    | translate
                }}
              </h4>
              <div class="control is-expanded has-padding-10">
                <ng-select [items]="profile.current_secondary_language == 'en' ? jobTitlesEn : jobTitlesDe"
                           bindLabel="name"
                           bindValue="name"
                           [(ngModel)]="profile.secondary_language_profile_text.jobtitle"
                           [clearable]="false">
                </ng-select>
              </div>
            </div>
          </section>
          <app-profile-nav-modal
            *ngIf="modal == 'profile-secondary-title'"
            (close)="modal = ''"
            (next)="modal = 'profile-secondary-statement'"
            (previous)="modal = ''"
            content="{{
              'profile.modal.supporttext.offical-title' | translate
            }}"
          ></app-profile-nav-modal>
          <!-- SECONDARY TITLE END -->

          <!-- SECONDARY LANGUAGE PROFESSIONAL STATEMENT -->
          <section
            *ngIf="!!this.profile.secondary_language_profile_text"
            class="profile-section"
            [ngClass]="{
              editing: modal == 'profile-secondary-statement',
              onboarding: profile && !profile.profile_approved
            }"
          >
            <div class="padding-mobile">
              <h4 class="profile-section-title">
                {{
                  (profile.current_secondary_language == "en"
                    ? "profile.secondary_language.professional_statement.title.en"
                    : "profile.secondary_language.professional_statement.title.de"
                  ) | translate
                }}<i
                  id="button-profile-secondary-statement"
                  *ngIf="
                    currentUser?.id == profile?.user?.id &&
                    modal != 'profile-secondary-statement'
                  "
                  (click)="openModal('profile-secondary-statement')"
                  class="icon icon-edit"
                  [ngClass]="{
                    required:
                      profile?.current_secondary_language &&
                      !profile?.secondary_language_profile_text
                        ?.professional_statement
                  }"
                ></i>
              </h4>
              <h6
                class="profile-subtitle error"
                *ngIf="
                  profile.secondary_language_profile_text
                    ?.professional_statement &&
                  profile.secondary_language_profile_text.professional_statement
                    .length == 220
                "
              >
                {{ "profile.statement-help" | translate }}
              </h6>
              <div *ngIf="modal != 'profile-secondary-statement'">
                <!-- prettier-ignore -->
                <p>
                  {{
                  profile?.secondary_language_profile_text?.professional_statement
                          ? profile.secondary_language_profile_text.professional_statement
                          : ("profile.statement-placeholder" | translate)
                  }}
                </p>
                <!-- Closed the <p> tag here -->
              </div>
            </div>
            <!-- Removed the extra </div> tag here -->

            <div
              (onInit)="focus(professional_secondary_statement_input)"
              *ngIf="modal == 'profile-secondary-statement'"
              class="padding-mobile"
            >
              <div id="profile-secondary-statement">
                <textarea
                  (keyup)="
                    resizeTextArea(professional_secondary_statement_input)
                  "
                  #professional_secondary_statement_input
                  [ngClass]="{
                    invalid_statement:
                      profile.secondary_language_profile_text
                        ?.professional_statement?.length >= 219
                  }"
                  maxlength="220"
                  id="professional_secondary_statement_input"
                  [(ngModel)]="
                    profile.secondary_language_profile_text
                      .professional_statement
                  "
                  placeholder="{{
                    (profile.current_secondary_language == 'en'
                      ? 'profile.secondary_language.professional_statement.placeholder.en'
                      : 'profile.secondary_language.professional_statement.placeholder.de'
                    ) | translate
                  }}"
                  class="textarea is-primary is-fullwidth"
                ></textarea>
              </div>
            </div>
          </section>
          <app-profile-nav-modal
            *ngIf="modal == 'profile-secondary-statement'"
            (close)="modal = ''"
            (next)="setTourItem(false, 'profile-secondary-about')"
            (previous)="modal = 'profile-secondary-title'"
            content="{{ 'profile.modal.supporttext.prof-state' | translate }}"
          ></app-profile-nav-modal>
          <!-- SECONDARY LANGUAGE PROFESSIONAL END -->

          <!-- SECONDARY LANGUAGE ABOUT START -->
          <section
            *ngIf="!!this.profile.secondary_language_profile_text"
            class="profile-section"
            id="profile-secondary-about"
            [ngClass]="{
              editing: modal == 'profile-secondary-about',
              onboarding: profile && !profile.profile_approved
            }"
          >
            <div class="padding-mobile">
              <h4 class="profile-section-title">
                {{
                  (profile.current_secondary_language == "en"
                    ? "profile.secondary_language.about.title.en"
                    : "profile.secondary_language.about.title.de"
                  ) | translate
                }}
                {{
                  currentUser?.id == profile?.user?.id ? "" : profile.firstname
                }}
                <i
                  id="button-profile-secondary-about"
                  *ngIf="
                    currentUser?.id == profile?.user?.id &&
                    modal != 'profile-secondary-about'
                  "
                  (click)="openModal('profile-secondary-about')"
                  class="icon icon-edit"
                  [ngClass]="{
                    required: !profile?.secondary_language_profile_text.about
                  }"
                ></i>
              </h4>
              <h6
                class="profile-subtitle error"
                *ngIf="
                  profile.secondary_language_profile_text?.about &&
                  profile.secondary_language_profile_text.about.length ==
                    2000 &&
                  modal == 'profile-secondary-about'
                "
              >
                {{ "profile.about-help" | translate }}
              </h6>
              <div *ngIf="modal != 'profile-secondary-about'">
                <!-- prettier-ignore -->
                <p>{{profile?.secondary_language_profile_text.about ? profile.secondary_language_profile_text.about : ("profile.tell-about" | translate)}}</p>
              </div>
              <div
                (onInit)="focus(secondary_about_input)"
                *ngIf="modal == 'profile-secondary-about'"
              >
                <textarea
                  (keyup)="resizeTextArea(secondary_about_input)"
                  #secondary_about_input
                  [(ngModel)]="profile.secondary_language_profile_text.about"
                  maxlength="2000"
                  placeholder="{{
                    (profile.current_secondary_language == 'en'
                      ? 'profile.secondary_language.about.placeholder.en'
                      : 'profile.secondary_language.about.placeholder.de'
                    ) | translate
                  }}"
                  class="textarea is-primary is-fullwidth"
                ></textarea>
              </div>
            </div>
          </section>
          <app-profile-nav-modal
            *ngIf="modal == 'profile-secondary-about'"
            (close)="modal = ''"
            (next)="setTourItem(false, 'profile-secondary-approach')"
            (previous)="setTourItem(false, 'profile-secondary-statement')"
            content="{{ 'profile.modal.supporttext.yourself' | translate }}"
          ></app-profile-nav-modal>
          <!-- SECONDARY LANGUAGE ABOUT END -->

          <!-- SECONDARY LANGUAGE APPROACH START -->
          <section
            *ngIf="!!this.profile.secondary_language_profile_text"
            class="profile-section"
            id="profile-secondary-approach"
            [ngClass]="{
              editing: modal == 'profile-secondary-approach',
              onboarding: profile && !profile.profile_approved
            }"
          >
            <div class="padding-mobile">
              <h4 class="profile-section-title">
                {{
                  (profile.current_secondary_language == "en"
                    ? "profile.secondary_language.approach.title.en"
                    : "profile.secondary_language.approach.title.de"
                  ) | translate
                }}
                <i
                  id="button-profile-secondary-approach"
                  *ngIf="
                    currentUser?.id == profile?.user?.id &&
                    modal != 'profile-secondary-approach'
                  "
                  (click)="openModal('profile-secondary-approach')"
                  class="icon icon-edit"
                  [ngClass]="{
                    required:
                      !!profile?.secondary_language_profile_text.language &&
                      !profile?.secondary_language_profile_text.approach
                  }"
                ></i>
              </h4>
              <h6
                class="profile-subtitle error"
                *ngIf="
                  profile.secondary_language_profile_text.approach &&
                  profile.secondary_language_profile_text.approach.length ==
                    2000 &&
                  modal == 'profile-secondary-approach'
                "
              >
                Maximum of 2000 characters
              </h6>
              <div *ngIf="modal != 'profile-secondary-approach'">
                <!-- prettier-ignore -->
                <p>{{profile?.secondary_language_profile_text.approach ? profile.secondary_language_profile_text.approach : ("profile.approach" | translate)}}</p>
              </div>
              <div
                *ngIf="modal == 'profile-secondary-approach'"
                (onInit)="focus(secondary_approach_input)"
                class="p"
              >
                <textarea
                  (keyup)="resizeTextArea(secondary_approach_input)"
                  maxlength="2000"
                  #secondary_approach_input
                  [(ngModel)]="profile.secondary_language_profile_text.approach"
                  placeholder="{{
                    (profile.current_secondary_language == 'en'
                      ? 'profile.secondary_language.approach.placeholder.en'
                      : 'profile.secondary_language.approach.placeholder.de'
                    ) | translate
                  }}"
                  class="textarea is-primary"
                ></textarea>
              </div>
            </div>
          </section>
          <app-profile-nav-modal
            *ngIf="modal == 'profile-secondary-approach'"
            (close)="modal = ''"
            (next)="setTourItem(false, 'profile-secondary-education')"
            (previous)="setTourItem(false, 'profile-secondary-about')"
            content="{{ 'profile.modal.supporttext.approach' | translate }}"
          ></app-profile-nav-modal>

          <!-- SECONDARY LANGUAGE APPROACH END -->

          <!-- SECONDARY LANGUAGE EDUCATION START -->
          <section
            *ngIf="!!this.profile.secondary_language_profile_text"
            class="profile-section"
            id="profile-secondary-education"
            [ngClass]="{
              editing: modal == 'profile-secondary-education',
              onboarding: profile && !profile.profile_approved
            }"
          >
            <div class="columns">
              <div
                class="column is-4"
                *ngIf="modal != 'profile-secondary-education'"
              >
                <h3 class="profile-section-subtitle">
                  {{
                    (profile.current_secondary_language == "en"
                      ? "profile.secondary_language.education.title.en"
                      : "profile.secondary_language.education.title.de"
                    ) | translate
                  }}
                  <i
                    id="button-profile-secondary-education"
                    *ngIf="
                      currentUser?.id == profile?.user?.id &&
                      modal != 'profile-secondary-education'
                    "
                    (click)="openModal('profile-secondary-education')"
                    class="icon icon-edit"
                    [ngClass]="{
                      required:
                        !profile?.secondary_language_profile_text.education
                    }"
                  ></i>
                </h3>
              </div>
              <div class="column">
                <ng-container *ngIf="modal != 'profile-secondary-education'">
                  <p>
                    {{ profile?.secondary_language_profile_text.education }}
                  </p>
                </ng-container>
                <div
                  (onInit)="focus(secondary_education_input)"
                  *ngIf="modal == 'profile-secondary-education'"
                >
                  <h4 class="profile-section-title">
                    {{
                      (profile.current_secondary_language == "en"
                        ? "profile.secondary_language.education.title.en"
                        : "profile.secondary_language.education.title.de"
                      ) | translate
                    }}
                  </h4>
                  <textarea
                    (keyup)="resizeTextArea(secondary_education_input)"
                    #secondary_education_input
                    [(ngModel)]="
                      profile.secondary_language_profile_text.education
                    "
                    placeholder="{{
                      (profile.current_secondary_language == 'en'
                        ? 'profile.secondary_language.education.placeholder.en'
                        : 'profile.secondary_language.education.placeholder.de'
                      ) | translate
                    }}"
                    class="textarea is-primary"
                  ></textarea>
                </div>
              </div>
            </div>
          </section>
          <app-profile-nav-modal
            *ngIf="modal == 'profile-secondary-education'"
            (close)="modal = ''"
            (next)="setTourItem(false, 'profile-secondary-experience')"
            (previous)="setTourItem(false, 'profile-secondary-approach')"
            content="{{ 'profile.modal.supporttext.education' | translate }}"
          ></app-profile-nav-modal>
          <!-- SECONDARY LANGUAGE EDUCATION END -->

          <!-- SECONDARY LANGUAGE EXPERIENCES START -->
          <section
            *ngIf="!!this.profile.secondary_language_profile_text"
            class="profile-section"
            id="profile-secondary-experience"
            [ngClass]="{
              editing: modal == 'profile-secondary-experience',
              onboarding: profile && !profile.profile_approved
            }"
          >
            <div class="columns">
              <div
                class="column is-4"
                *ngIf="modal != 'profile-secondary-experience'"
              >
                <h3 class="profile-section-subtitle">
                  {{
                    (profile.current_secondary_language == "en"
                      ? "profile.secondary_language.experience.title.en"
                      : "profile.secondary_language.experience.title.de"
                    ) | translate
                  }}
                  <i
                    id="button-profile-secondary-experience"
                    *ngIf="
                      currentUser?.id == profile?.user?.id &&
                      modal != 'profile-secondary-experience'
                    "
                    (click)="openModal('profile-secondary-experience')"
                    class="icon icon-edit"
                    [ngClass]="{
                      required:
                        !profile?.secondary_language_profile_text.experience
                    }"
                  ></i>
                </h3>
              </div>
              <div class="column">
                <ng-container *ngIf="modal != 'profile-secondary-experience'">
                  <p>
                    {{ profile?.secondary_language_profile_text.experience }}
                  </p>
                </ng-container>
                <div
                  (onInit)="focus(secondary_experience_input)"
                  *ngIf="modal == 'profile-secondary-experience'"
                >
                  <h4 class="profile-section-title">
                    {{
                      (profile.current_secondary_language == "en"
                        ? "profile.secondary_language.experience.title.en"
                        : "profile.secondary_language.experience.title.de"
                      ) | translate
                    }}
                  </h4>
                  <textarea
                    (keyup)="resizeTextArea(secondary_experience_input)"
                    #secondary_experience_input
                    [(ngModel)]="
                      profile.secondary_language_profile_text.experience
                    "
                    placeholder="{{
                      (profile.current_secondary_language == 'en'
                        ? 'profile.secondary_language.experience.placeholder.en'
                        : 'profile.secondary_language.experience.placeholder.de'
                      ) | translate
                    }}"
                    class="textarea is-primary"
                  ></textarea>
                </div>
              </div>
            </div>
          </section>
          <app-profile-nav-modal
            *ngIf="modal == 'profile-secondary-experience'"
            (close)="modal = ''"
            (next)="modal = ''"
            (previous)="setTourItem(false, 'profile-secondary-education')"
            content="{{ 'profile.modal.supporttext.experience' | translate }}"
          ></app-profile-nav-modal>
          <!-- SECONDARY LANGUAGE EXPERIENCES END -->
        </div>
      </main>
    </div>
  </div>
</section>

<!-- Start Modal -->

<div class="modal" [ngClass]="{ 'is-active': progress == 0 && modal == '' }">
  <div class="modal-background"></div>
  <div class="modal-content">
    <ng-container *ngIf="slideShowOnboardingIndex == 0">
      <h3 class="modal-title">
        {{ "profile.modal.welcome-title" | translate }}
      </h3>
      <button class="delete" (click)="modal = 'profile-image'"></button>

      <div class="columns reverse-columns">
        <div class="column content">
          <p>{{ "profile.modal.welcome-text" | translate }}</p>
          <p class="buttons">
            <button
              (click)="slideShowOnboardingIndex = 1"
              class="button ic-button is-rounded is-medium"
            >
              {{ "common.read-on" | translate }}
            </button>
            <button
              class="button ic-button is-rounded is-medium pt-1"
              (click)="goToDashboard()"
            >
              {{ "common.skip" | translate }}
            </button>
          </p>
          <button
            *ngIf="profile.profile_completed"
            [disabled]="true"
            class="button is-primary is-rounded review-button"
          >
            {{ "profile.modal.profile-in-review" | translate }}
          </button>
        </div>
        <div class="column is-offset-1 is-4">
          <img src="assets/svg/woman_desk.svg" class="img-fluid" alt="" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="slideShowOnboardingIndex == 1">
      <h3 class="modal-title">
        {{ "profile.modal.requirements-title" | translate }}
      </h3>
      <button class="delete" (click)="modal = 'profile-image'"></button>
      <p>{{ "profile.modal.requirements-text" | translate }}</p>
      <p>{{ "profile.modal.credentials-text" | translate }}</p>
      <div class="columns reverse-columns">
        <div class="column is-6 content">
          <ul>
            <li>{{ "profile.modal.psychologist" | translate }}</li>
            <li>{{ "profile.modal.psychotherapist" | translate }}</li>
            <li>{{ "profile.modal.psychoanalyst" | translate }}</li>
            <li>{{ "profile.modal.psychiatrist" | translate }}</li>
            <li>{{ "profile.modal.social-worker" | translate }}</li>
            <li>{{ "profile.modal.coach" | translate }}</li>
          </ul>
        </div>
        <div class="column is-4">
          <img
            src="assets/svg/pencil_folder.svg"
            class="img-fluid"
            style="margin-top: 1rem"
            alt=""
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <p>{{ "profile.modal.jurisdictions-without-license" | translate }}</p>
          <p>
            {{ "profile.modal.unsure-about-credentials.0" | translate }}
            <a
              href="https://complicated.life/support/faq/for-therapists"
              target="_blank"
              >{{ "profile.modal.unsure-about-credentials.1" | translate }}</a
            >
            {{ "profile.modal.unsure-about-credentials.2" | translate }}
            <a href="mailto:support@complicated.life" target="_blank">{{
              "profile.modal.unsure-about-credentials.3" | translate
            }}</a>
          </p>
          <p class="buttons">
            <button
              (click)="modal = 'profile-image'"
              class="button ic-button is-rounded is-medium"
            >
              {{ "profile.modal.create-your-profile" | translate }}
            </button>
            <button
              class="button ic-button is-rounded is-medium pt-1"
              (click)="goToDashboard()"
            >
              {{ "common.skip" | translate }}
            </button>
          </p>
        </div>
      </div>
    </ng-container>

    <div class="indicators">
      <div
        class="indicator"
        (click)="slideShowOnboardingIndex = 0"
        [ngClass]="{ 'is-active': slideShowOnboardingIndex == 0 }"
      ></div>
      <div
        class="indicator"
        (click)="slideShowOnboardingIndex = 1"
        [ngClass]="{ 'is-active': slideShowOnboardingIndex == 1 }"
      ></div>
    </div>
  </div>
</div>

<div class="modal-background" *ngIf="modal != ''" (click)="closeModal()"></div>

<!-- End Modal -->

<div
  *ngIf="currentUser"
  id="modal-welcome"
  [class.is-active]="
    progress == 100 &&
    modal == '' &&
    currentUser.type == 'Therapist' &&
    !profile.profile_approved &&
    (!profile.diplomas || profile.diplomas.length == 0) &&
    !profile.profile_completed
  "
  class="modal modal-fx-slideBottom"
>
  <div class="modal-background"></div>
  <div class="modal-content">
    <ng-container *ngIf="slideShowOnboardingEndIndex == 0">
      <div class="columns">
        <div class="column">
          <h3 class="modal-title">
            {{ "profile.modal.complete-title" | translate }}
          </h3>
          <p>
            {{ "profile.modal.complete-title" | translate }}
          </p>
          <button
            (click)="slideShowOnboardingEndIndex = 1"
            class="button has-margin-y-20 ic-button is-rounded is-medium"
          >
            {{ "common.next" | translate }}
          </button>
        </div>
        <div class="column is-4">
          <svg
            class="img-fluid"
            viewBox="0 0 210 271"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <polygon
                id="path-1"
                points="0 0.8555 148.7032 0.8555 148.7032 22 0 22"
              ></polygon>
              <polygon
                id="path-3"
                points="0.1924 0.911 25.01015 0.911 25.01015 25.7354 0.1924 25.7354"
              ></polygon>
            </defs>
            <g
              id="Symbols"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Lamp---Green---Small"
                transform="translate(0.000000, -1.000000)"
              >
                <g id="Group-77">
                  <path
                    d="M49.8346,243.0577 C50.0836,243.0577 50.3376,243.0107 50.5836,242.9117 C51.6066,242.4967 52.1026,241.3317 51.6886,240.3077 L24.3426,172.6887 L24.3426,96.7617 L91.9906,32.1337 C92.7896,31.3707 92.8176,30.1047 92.0556,29.3067 C91.2916,28.5057 90.0266,28.4777 89.2276,29.2417 L20.9606,94.4597 C20.5656,94.8377 20.3426,95.3597 20.3426,95.9067 L20.3426,173.0777 C20.3426,173.3347 20.3926,173.5897 20.4876,173.8277 L47.9796,241.8077 C48.2946,242.5857 49.0436,243.0577 49.8346,243.0577"
                    id="Fill-1"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M71.608,251.9445 C71.608,239.9185 61.859,230.1705 49.834,230.1705 C37.808,230.1705 28.06,239.9185 28.06,251.9445"
                    id="Fill-3"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M28.0592,253.9445 C29.1632,253.9445 30.0592,253.0485 30.0592,251.9445 C30.0592,241.0405 38.9302,232.1705 49.8332,232.1705 C60.7372,232.1705 69.6082,241.0405 69.6082,251.9445 C69.6082,253.0485 70.5032,253.9445 71.6082,253.9445 C72.7122,253.9445 73.6082,253.0485 73.6082,251.9445 C73.6082,238.8355 62.9432,228.1705 49.8332,228.1705 C36.7242,228.1705 26.0592,238.8355 26.0592,251.9445 C26.0592,253.0485 26.9552,253.9445 28.0592,253.9445"
                    id="Fill-5"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M34.0016,173.0778 C34.0016,166.6388 28.7816,161.4188 22.3436,161.4188 C15.9036,161.4188 10.6846,166.6388 10.6846,173.0778 C10.6846,179.5158 15.9036,184.7358 22.3436,184.7358 C28.7816,184.7358 34.0016,179.5158 34.0016,173.0778"
                    id="Fill-7"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M12.6842,173.0778 C12.6842,178.4038 17.0172,182.7358 22.3422,182.7358 C27.6682,182.7358 32.0012,178.4038 32.0012,173.0778 C32.0012,167.7518 27.6682,163.4188 22.3422,163.4188 C17.0172,163.4188 12.6842,167.7518 12.6842,173.0778 M8.6842,173.0778 C8.6842,165.5468 14.8112,159.4188 22.3422,159.4188 C29.8732,159.4188 36.0012,165.5468 36.0012,173.0778 C36.0012,180.6088 29.8732,186.7358 22.3422,186.7358 C14.8112,186.7358 8.6842,180.6088 8.6842,173.0778"
                    id="Fill-9"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M34.0016,95.9064 C34.0016,89.4684 28.7816,84.2484 22.3436,84.2484 C15.9036,84.2484 10.6846,89.4684 10.6846,95.9064 C10.6846,102.3454 15.9036,107.5654 22.3436,107.5654 C28.7816,107.5654 34.0016,102.3454 34.0016,95.9064"
                    id="Fill-11"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M12.6842,95.9064 C12.6842,101.2324 17.0172,105.5654 22.3422,105.5654 C27.6682,105.5654 32.0012,101.2324 32.0012,95.9064 C32.0012,90.5804 27.6682,86.2484 22.3422,86.2484 C17.0172,86.2484 12.6842,90.5804 12.6842,95.9064 M8.6842,95.9064 C8.6842,88.3754 14.8112,82.2484 22.3422,82.2484 C29.8732,82.2484 36.0012,88.3754 36.0012,95.9064 C36.0012,103.4374 29.8732,109.5654 22.3422,109.5654 C14.8112,109.5654 8.6842,103.4374 8.6842,95.9064"
                    id="Fill-13"
                    fill="#74D0BF"
                  ></path>
                  <g id="Group-17" transform="translate(0.000000, 249.089000)">
                    <mask id="mask-2" fill="white">
                      <use xlink:href="#path-1"></use>
                    </mask>
                    <g id="Clip-16"></g>
                    <path
                      d="M129.5572,4.8555 L19.1442,4.8555 C11.4712,4.8555 5.1132,10.5915 4.1312,18.0005 L144.5712,18.0005 C143.5892,10.5915 137.2302,4.8555 129.5572,4.8555 M-0.0008,20.0005 C-0.0008,9.4435 8.5872,0.8555 19.1442,0.8555 L129.5572,0.8555 C140.1142,0.8555 148.7032,9.4435 148.7032,20.0005 C148.7032,21.1045 147.8072,22.0005 146.7032,22.0005 L1.9992,22.0005 C0.8952,22.0005 -0.0008,21.1045 -0.0008,20.0005"
                      id="Fill-15"
                      fill="#74D0BF"
                      mask="url(#mask-2)"
                    ></path>
                  </g>
                  <path
                    d="M80.0113,6.5729 C75.2463,1.8089 67.5233,1.8089 62.7593,6.5729 C57.9953,11.3369 57.9953,19.0609 62.7593,23.8239"
                    id="Fill-18"
                    fill="#D3F0EA"
                  ></path>
                  <g id="Group-22" transform="translate(57.000000, 0.089000)">
                    <mask id="mask-4" fill="white">
                      <use xlink:href="#path-3"></use>
                    </mask>
                    <g id="Clip-21"></g>
                    <path
                      d="M5.7584,25.7354 C6.2704,25.7354 6.7814,25.5404 7.1724,25.1494 C7.9534,24.3684 7.9534,23.1024 7.1724,22.3214 C3.1954,18.3444 3.1954,11.8744 7.1724,7.8984 C9.0994,5.9714 11.6604,4.9104 14.3844,4.9104 C17.1094,4.9104 19.6694,5.9714 21.5964,7.8984 C22.3774,8.6794 23.6434,8.6794 24.4244,7.8984 C25.2054,7.1174 25.2054,5.8514 24.4244,5.0694 C21.7424,2.3874 18.1774,0.9104 14.3844,0.9104 C10.5924,0.9104 7.0264,2.3874 4.3444,5.0694 C-1.1916,10.6064 -1.1916,19.6134 4.3444,25.1494 C4.7354,25.5404 5.2464,25.7354 5.7584,25.7354"
                      id="Fill-20"
                      fill="#74D0BF"
                      mask="url(#mask-4)"
                    ></path>
                  </g>
                  <path
                    d="M138.5445,130.111 L186.2975,82.358 C189.7485,78.906 189.2785,73.183 185.3105,70.341 L96.6255,6.833 C92.2015,3.666 86.1355,4.164 82.2885,8.011 L64.1975,26.102 C60.3505,29.949 59.8525,36.015 63.0205,40.439 L126.5265,129.124 C129.3685,133.092 135.0925,133.563 138.5445,130.111"
                    id="Fill-23"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M83.7018,9.4249 L65.6108,27.5159 C62.4418,30.6859 62.0358,35.6309 64.6448,39.2739 L128.1528,127.9589 C129.1648,129.3719 130.7388,130.2639 132.4708,130.4069 C134.2158,130.5459 135.9018,129.9249 137.1298,128.6969 L184.8838,80.9439 C186.1118,79.7149 186.7348,78.0169 186.5928,76.2849 C186.4508,74.5529 185.5578,72.9789 184.1458,71.9669 L95.4608,8.4589 C93.8538,7.3089 91.9948,6.7449 90.1468,6.7449 C87.8018,6.7449 85.4738,7.6529 83.7018,9.4249 M132.1428,134.3929 C129.2378,134.1539 126.5978,132.6579 124.9008,130.2879 L61.3928,41.6029 C57.6398,36.3619 58.2228,29.2479 62.7828,24.6879 L80.8738,6.5969 C85.4328,2.0379 92.5458,1.4529 97.7888,5.2069 L186.4748,68.7149 C188.8448,70.4119 190.3408,73.0519 190.5798,75.9579 C190.8178,78.8629 189.7728,81.7109 187.7118,83.7719 L139.9578,131.5249 C138.0908,133.3929 135.5758,134.4269 132.9588,134.4269 C132.6878,134.4269 132.4158,134.4159 132.1428,134.3929"
                    id="Fill-25"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M144.0523,59.1959 L142.8643,58.3449"
                    id="Fill-27"
                    fill="#ECF8F6"
                  ></path>
                  <path
                    d="M144.0504,61.1535 C144.6604,61.1535 145.2614,60.8695 145.6444,60.3355 C146.2734,59.4565 146.0714,58.2345 145.1924,57.6045 L144.0034,56.7535 C143.1254,56.1225 141.9024,56.3265 141.2724,57.2055 C140.6434,58.0845 140.8454,59.3065 141.7244,59.9365 L142.9124,60.7875 C143.2574,61.0345 143.6554,61.1535 144.0504,61.1535"
                    id="Fill-29"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M169.2818,77.2628 L154.5968,66.7468"
                    id="Fill-31"
                    fill="#ECF8F6"
                  ></path>
                  <path
                    d="M169.2799,79.2203 C169.8899,79.2203 170.4909,78.9363 170.8739,78.4023 C171.5029,77.5233 171.3009,76.3013 170.4219,75.6713 L155.7369,65.1553 C154.8599,64.5253 153.6359,64.7283 153.0059,65.6073 C152.3769,66.4863 152.5789,67.7083 153.4579,68.3383 L168.1419,78.8543 C168.4869,79.1013 168.8849,79.2203 169.2799,79.2203"
                    id="Fill-33"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M82.2096,67.2365 L63.0206,40.4385 C59.8526,36.0155 60.3506,29.9495 64.1976,26.1025 L82.2886,8.0105 C86.1356,4.1635 92.2016,3.6655 96.6256,6.8335 L123.4236,26.0235 L82.2096,67.2365 Z"
                    id="Fill-35"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M95.4605,8.4591 C91.8145,5.8491 86.8705,6.2571 83.7015,9.4251 L65.6105,27.5161 C62.4415,30.6861 62.0355,35.6301 64.6455,39.2741 L82.4625,64.1551 L120.3405,26.2761 L95.4605,8.4591 Z M82.0455,69.2301 C81.4605,69.1821 80.9255,68.8781 80.5835,68.4011 L61.3935,41.6031 C57.6395,36.3611 58.2235,29.2481 62.7825,24.6881 L80.8735,6.5971 C85.4335,2.0381 92.5455,1.4531 97.7885,5.2071 L124.5865,24.3971 C125.0645,24.7391 125.3675,25.2731 125.4155,25.8591 C125.4635,26.4451 125.2525,27.0221 124.8365,27.4371 L83.6235,68.6501 C83.2475,69.0271 82.7375,69.2361 82.2095,69.2361 C82.1545,69.2361 82.1005,69.2341 82.0455,69.2301 Z"
                    id="Fill-37"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M162.4205,130.7438 C168.6995,130.7438 174.9795,128.3538 179.7595,123.5728 C189.3195,114.0128 189.3195,98.4558 179.7595,88.8958 C178.9785,88.1148 177.7125,88.1148 176.9315,88.8958 C176.1495,89.6768 176.1495,90.9428 176.9315,91.7238 C184.9325,99.7248 184.9325,112.7438 176.9315,120.7448 C168.9295,128.7458 155.9115,128.7458 147.9095,120.7448 C147.1285,119.9638 145.8625,119.9638 145.0815,120.7448 C144.3005,121.5258 144.3005,122.7918 145.0815,123.5728 C149.8615,128.3538 156.1415,130.7438 162.4205,130.7438"
                    id="Fill-39"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M150.8785,140.1871 C151.0615,139.5841 151.6975,139.2451 152.3005,139.4281 C152.9015,139.6121 153.2415,140.2481 153.0585,140.8501 L150.4835,149.3121 C150.3005,149.9151 149.6645,150.2541 149.0625,150.0711 C148.4595,149.8881 148.1205,149.2521 148.3035,148.6491 L150.8785,140.1871 Z"
                    id="Fill-41"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M149.3912,151.6637 C150.5412,151.6637 151.6082,150.9177 151.9592,149.7627 L154.5352,141.2997 C154.9652,139.8857 154.1642,138.3837 152.7502,137.9527 C152.0632,137.7437 151.3372,137.8147 150.7052,138.1517 C150.0732,138.4897 149.6102,139.0537 149.4022,139.7397 L146.8272,148.2017 C146.3982,149.6157 147.1992,151.1167 148.6122,151.5477 C148.8712,151.6257 149.1332,151.6637 149.3912,151.6637"
                    id="Fill-43"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M160.3297,142.0255 C160.3467,141.3965 160.8697,140.9005 161.4987,140.9175 C162.1287,140.9335 162.6247,141.4565 162.6077,142.0865 L162.3737,150.9265 C162.3567,151.5555 161.8337,152.0515 161.2047,152.0355 C160.5747,152.0185 160.0797,151.4955 160.0967,150.8665 L160.3297,142.0255 Z"
                    id="Fill-45"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M161.2369,153.5787 C161.9259,153.5787 162.5789,153.3187 163.0809,152.8427 C163.5999,152.3497 163.8969,151.6837 163.9169,150.9667 L164.1509,142.1267 C164.1699,141.4107 163.9079,140.7287 163.4149,140.2097 C162.9229,139.6897 162.2559,139.3937 161.5399,139.3747 C160.8239,139.3587 160.1429,139.6157 159.6229,140.1087 C159.1029,140.6017 158.8059,141.2677 158.7869,141.9847 L158.5529,150.8257 C158.5349,151.5417 158.7959,152.2227 159.2879,152.7417 C159.7809,153.2627 160.4469,153.5587 161.1639,153.5777 C161.1879,153.5787 161.2129,153.5787 161.2369,153.5787"
                    id="Fill-47"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M169.9459,141.2941 C169.7949,140.6831 170.1679,140.0651 170.7789,139.9141 C171.3899,139.7631 172.0079,140.1351 172.1589,140.7471 L174.2829,149.3351 C174.4339,149.9461 174.0619,150.5641 173.4509,150.7151 C172.8399,150.8661 172.2219,150.4941 172.0709,149.8821 L169.9459,141.2941 Z"
                    id="Fill-49"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M173.5689,149.5114 C173.5689,149.5114 173.5689,149.5114 173.5679,149.5104 L173.5689,149.5114 Z M170.5729,150.2534 L168.4479,141.6654 C168.0919,140.2294 168.9719,138.7714 170.4089,138.4154 C171.8549,138.0594 173.3009,138.9414 173.6569,140.3764 L175.7809,148.9644 C175.9529,149.6594 175.8439,150.3814 175.4739,150.9944 C175.1039,151.6084 174.5169,152.0414 173.8219,152.2134 C173.6059,152.2664 173.3899,152.2914 173.1769,152.2914 C171.9729,152.2914 170.8759,151.4734 170.5729,150.2534 Z"
                    id="Fill-51"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M179.0064,138.0255 C178.6964,137.4775 178.8914,136.7825 179.4394,136.4725 C179.9874,136.1635 180.6824,136.3575 180.9914,136.9055 L185.3404,144.6115 C185.6504,145.1605 185.4564,145.8555 184.9074,146.1645 C184.3594,146.4745 183.6644,146.2805 183.3554,145.7325 L179.0064,138.0255 Z"
                    id="Fill-53"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M184.6988,144.9733 C184.6988,144.9733 184.6988,144.9733 184.6978,144.9723 L184.6988,144.9733 Z M182.0108,146.4913 L177.6628,138.7843 C176.9358,137.4963 177.3918,135.8573 178.6808,135.1293 C179.9678,134.4033 181.6078,134.8593 182.3358,136.1473 L186.6838,143.8533 C187.0368,144.4773 187.1248,145.2023 186.9318,145.8913 C186.7398,146.5823 186.2908,147.1563 185.6658,147.5083 C185.2508,147.7433 184.7988,147.8543 184.3518,147.8543 C183.4158,147.8543 182.5038,147.3633 182.0108,146.4913 Z"
                    id="Fill-55"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M186.8756,132.4508 C186.4316,132.0048 186.4336,131.2838 186.8806,130.8388 C187.3266,130.3948 188.0476,130.3968 188.4916,130.8438 L194.7306,137.1188 C195.1756,137.5648 195.1726,138.2858 194.7266,138.7308 C194.2796,139.1748 193.5586,139.1728 193.1136,138.7258 L186.8756,132.4508 Z"
                    id="Fill-57"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M194.2076,137.6378 C194.2076,137.6378 194.2066,137.6368 194.2066,137.6368 L194.2076,137.6378 Z M192.0196,139.8138 L185.7816,133.5398 C185.2756,133.0318 184.9986,132.3568 185.0006,131.6398 C185.0026,130.9238 185.2836,130.2498 185.7926,129.7448 C186.8416,128.7018 188.5426,128.7068 189.5856,129.7558 L195.8246,136.0298 C196.3306,136.5378 196.6076,137.2138 196.6056,137.9308 C196.6036,138.6458 196.3226,139.3188 195.8136,139.8248 C195.2916,140.3448 194.6066,140.6038 193.9226,140.6038 C193.2326,140.6038 192.5436,140.3408 192.0196,139.8138 Z"
                    id="Fill-59"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M192.9644,125.0128 C192.4184,124.6998 192.2294,124.0048 192.5424,123.4588 C192.8554,122.9128 193.5504,122.7238 194.0964,123.0368 L201.7664,127.4298 C202.3124,127.7428 202.5014,128.4378 202.1884,128.9838 C201.8754,129.5298 201.1804,129.7188 200.6344,129.4058 L192.9644,125.0128 Z"
                    id="Fill-61"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M201.1969,131.1012 C201.4319,131.1012 201.6699,131.0692 201.9029,131.0062 C202.5939,130.8182 203.1719,130.3722 203.5269,129.7502 C203.8839,129.1302 203.9759,128.4062 203.7889,127.7142 C203.6009,127.0242 203.1549,126.4472 202.5339,126.0912 L194.8629,121.6982 C194.2419,121.3412 193.5169,121.2492 192.8279,121.4362 C192.1359,121.6242 191.5589,122.0702 191.2039,122.6922 C190.8469,123.3122 190.7549,124.0362 190.9419,124.7272 C191.1299,125.4182 191.5759,125.9952 192.1969,126.3512 L199.8679,130.7452 C200.2789,130.9802 200.7349,131.1012 201.1969,131.1012"
                    id="Fill-63"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M196.8639,116.213 C196.2539,116.057 195.8859,115.438 196.0419,114.827 C196.1979,114.217 196.8169,113.849 197.4269,114.005 L205.9979,116.193 C206.6079,116.349 206.9759,116.968 206.8199,117.579 C206.6649,118.189 206.0449,118.557 205.4339,118.401 L196.8639,116.213 Z"
                    id="Fill-65"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M205.7213,119.9806 C206.1973,119.9806 206.6653,119.8536 207.0853,119.6046 C207.7013,119.2376 208.1383,118.6536 208.3153,117.9596 C208.4923,117.2666 208.3893,116.5436 208.0233,115.9276 C207.6583,115.3116 207.0743,114.8756 206.3793,114.6976 L197.8093,112.5096 C196.3703,112.1426 194.9133,113.0136 194.5463,114.4446 C194.1813,115.8796 195.0503,117.3426 196.4823,117.7086 L205.0523,119.8966 C205.2743,119.9526 205.4983,119.9806 205.7213,119.9806"
                    id="Fill-67"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M198.2682,106.674 C197.6382,106.686 197.1202,106.187 197.1072,105.557 C197.0952,104.928 197.5942,104.408 198.2242,104.396 L207.0642,104.225 C207.6942,104.213 208.2132,104.713 208.2252,105.342 C208.2382,105.971 207.7382,106.491 207.1092,106.503 L198.2682,106.674 Z"
                    id="Fill-69"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M207.0787,104.9601 L207.0777,104.9601 L207.0787,104.9601 Z M195.5637,105.5861 C195.5367,104.1081 196.7167,102.8821 198.1937,102.8531 L207.0337,102.6821 C207.7987,102.6851 208.4307,102.9341 208.9457,103.4311 C209.4627,103.9281 209.7547,104.5961 209.7677,105.3111 C209.7827,106.0271 209.5167,106.7071 209.0197,107.2231 C208.5227,107.7401 207.8547,108.0321 207.1397,108.0461 L198.2987,108.2171 C198.2807,108.2171 198.2637,108.2171 198.2457,108.2171 C196.7917,108.2171 195.5937,107.0481 195.5637,105.5861 Z"
                    id="Fill-71"
                    fill="#74D0BF"
                  ></path>
                  <path
                    d="M197.0992,97.108 C196.4952,97.287 195.8622,96.944 195.6822,96.34 C195.5022,95.736 195.8462,95.103 196.4502,94.923 L204.9302,92.402 C205.5342,92.223 206.1682,92.566 206.3472,93.17 C206.5272,93.774 206.1832,94.408 205.5792,94.587 L197.0992,97.108 Z"
                    id="Fill-73"
                    fill="#D3F0EA"
                  ></path>
                  <path
                    d="M196.776,98.6984 C197.029,98.6984 197.285,98.6624 197.539,98.5874 L206.019,96.0664 C206.706,95.8624 207.272,95.4034 207.614,94.7724 C207.956,94.1424 208.031,93.4174 207.826,92.7294 C207.624,92.0444 207.164,91.4774 206.534,91.1364 C205.903,90.7944 205.178,90.7194 204.49,90.9234 L196.01,93.4444 C195.323,93.6484 194.756,94.1084 194.416,94.7374 C194.074,95.3674 193.999,96.0934 194.204,96.7804 C194.549,97.9434 195.62,98.6984 196.776,98.6984"
                    id="Fill-75"
                    fill="#74D0BF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="slideShowOnboardingEndIndex == 1">
      <app-upload-modal
        [profile]="profile"
        (done)="goToDashboard()"
      ></app-upload-modal>
    </ng-container>

    <div class="indicators">
      <div
        class="indicator"
        (click)="slideShowOnboardingEndIndex = 0"
        [ngClass]="{ 'is-active': slideShowOnboardingEndIndex == 0 }"
      ></div>
      <div
        class="indicator"
        (click)="slideShowOnboardingEndIndex = 1"
        [ngClass]="{ 'is-active': slideShowOnboardingEndIndex == 1 }"
      ></div>
    </div>
  </div>
</div>

<nav
  class="navbar is-white is-fixed-bottom card bottom-navbar is-hidden-touch"
  style="z-index: 55"
  *ngIf="!profile.profile_approved && progress !== 100 && progress > 5"
>
  <div class="navbar-end is-flex-mobile justify-space-evenly">
    <div class="navbar-item">
      {{ "profile.modal.fill-in-p1" | translate }} {{ progress }}%
      {{ "profile.modal.fill-in-p2" | translate }}
      {{ "profile.progess." + nextProperty | translate }}.
    </div>
    <div class="navbar-item">
      <button
        (click)="sendForReview()"
        *ngIf="!profile.profile_completed && progress > 90"
        class="button is-primary is-rounded review-button"
      >
        {{ "profile.modal.send-for-review" | translate }}
      </button>
      <button
        *ngIf="profile.profile_completed"
        [disabled]="true"
        class="button is-primary is-rounded review-button"
      >
        {{ "profile.modal.profile-in-review" | translate }}
      </button>
    </div>
  </div>
</nav>

<app-footer *ngIf="profile && profile.id && currentUser"></app-footer>

<div
  class="modal modal-fx-slideBottom"
  [ngClass]="{ 'is-active': showDiplomaWindow }"
  *ngIf="currentUser && showDiplomaWindow"
>
  <div class="modal-background" (click)="showDiplomaWindow = false"></div>
  <div class="modal-content">
    <app-upload-modal
      [profile]="currentUser.profile"
      (done)="onDiplomaUploaded($events)"
      [user]="currentUser"
    ></app-upload-modal>
  </div>
</div>

<div
  class="modal ic-modal modal-fx-slideBottom"
  [ngClass]="{ 'is-active': showSettingsWindow }"
  *ngIf="currentUser && showSettingsWindow"
>
  <div class="modal-background"></div>
  <div class="modal-content content">
    <h3 class="modal-title">{{ "profile.directory-settings" | translate }}</h3>
    <button
      class="delete"
      aria-label="close"
      (click)="closeDirectorySettings()"
    ></button>
    <div class="columns is-vcentered">
      <div class="column is-4">
        <span class="label">{{ "calendar.timezone" | translate }}</span>
      </div>
      <div class="column is-5">
        <ng-select
          class="custom"
          [(ngModel)]="profile.timezone"
          [clearable]="false"
          id="timezones"
          *ngIf="timezones"
        >
          <ng-option *ngFor="let tz of timezones" [value]="tz">{{
            tz
          }}</ng-option>
        </ng-select>
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column is-4">
        <span class="label">{{
          "profile.modal.show-joined-date" | translate
        }}</span>
      </div>
      <div class="column">
        <label class="switch">
          <input
            name="enableCalendar"
            [(ngModel)]="profile.showJoined"
            type="checkbox"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column is-4">
        <span class="label">{{
          "profile.modal.show-session-count" | translate
        }}</span>
      </div>
      <div class="column">
        <label class="switch">
          <input
            name="enableCalendar"
            [(ngModel)]="profile.showSessions"
            type="checkbox"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column is-4">
        <span class="label">{{
          "profile.modal.show-last-activity" | translate
        }}</span>
      </div>
      <div class="column">
        <label class="switch">
          <input
            name="enableCalendar"
            [(ngModel)]="profile.showActivity"
            type="checkbox"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column is-4">
        <span class="label">{{
          "profile.modal.show-response-time" | translate
        }}</span>
      </div>
      <div class="column">
        <label class="switch">
          <input
            name="enableCalendar"
            [(ngModel)]="profile.showResponseTime"
            type="checkbox"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column is-4">
        <span class="label">{{
          "profile.modal.index-in-search-engines" | translate
        }}</span>
      </div>
      <div class="column">
        <label class="switch">
          <input
            name="enableSearchEngines"
            [(ngModel)]="profile.showIndex"
            type="checkbox"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="modal-footer buttons">
      <button
        [disabled]="updatingUser"
        [ngClass]="{ 'is-loading': updatingUser }"
        (click)="saveDirectorySettings()"
        class="button is-rounded is-primary"
      >
        {{ "common.save" | translate }}
      </button>
    </div>
  </div>
</div>
