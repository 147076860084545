import { Profile } from 'src/app/entities/profile.model';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NgFor],
})
export class CarouselComponent implements OnInit {
  constructor() {}

  aws: string = environment.aws;
  API_URL: string = environment.apiBase;
  largeView: boolean;

  ngOnInit() {}

  _photos: any;

  ids: string[] = [];
  activeIndex: number = 0;

  @Input()
  public profile: Profile;

  public show(event, id) {
    this.activeIndex = id;
    event.stopPropagation();
  }

  public expandImages(event): void {
    this.largeView = true;
    event.stopPropagation();
  }
}
