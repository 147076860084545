[
  {
    "symbol": "€",
    "name": "Euro",
    "symbol_native": "€",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "EUR",
    "name_plural": "euros"
  },
  {
    "symbol": "£",
    "name": "British Pound",
    "symbol_native": "£",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "GBP",
    "name_plural": "British pounds"
  },
  {
    "symbol": "CHF",
    "name": "Swiss Franc",
    "symbol_native": "CHF",
    "decimal_digits": 2,
    "rounding": 0.05,
    "code": "CHF",
    "name_plural": "Swiss francs"
  },
  {
    "symbol": "$",
    "name": "US Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "USD",
    "name_plural": "US dollars"
  },
  {
    "symbol": "dkr",
    "name": "Danish Krone",
    "symbol_native": "dkr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "DKK",
    "name_plural": "Danish Krones"
  },
  {
    "symbol": "skr",
    "name": "Swedish Krone",
    "symbol_native": "skr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "SEK",
    "name_plural": "Swedish Krones"
  },
  {
    "symbol": "nkr",
    "name": "Norwegian Krone",
    "symbol_native": "nkr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NOK",
    "name_plural": "Norwegian Krones"
  },
  {
    "symbol": "Zł",
    "name": "Polish złoty",
    "symbol_native": "Zł",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "PLN",
    "name_plural": "Polish złoty"
  },
  {
    "symbol": "Kč",
    "name": "Czech krone",
    "symbol_native": "Kč",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CZK",
    "name_plural": "Czech krones"
  },
  {
    "symbol": "L",
    "name": "Romanian leu",
    "symbol_native": "L",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "RON",
    "name_plural": "Romanian leus"
  }
]
