import { environment } from 'src/environments/environment';
import { User } from './../../../entities/user.model';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { InviteClientsService } from './service/invite-clients.service';
import { InviteClient } from 'src/app/entities/invite-client.model';
import { AlertService } from '../alert/service/alert.service';
import { ProfileService } from 'src/app/components/profile/service/profile.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AutosizeModule } from 'ngx-autosize';
import { FormsModule } from '@angular/forms';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-invite-clients',
  templateUrl: './invite-clients.component.html',
  styleUrls: ['./invite-clients.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    ProfilePictureComponent,
    FormsModule,
    AutosizeModule,
    TranslateModule,
  ],
})
export class InviteClientsComponent implements OnInit {
  constructor(
    private inviteService: InviteClientsService,
    private alertService: AlertService,
    private profileService: ProfileService,
    private translationService: TranslateService
  ) {
    this.inviteClient = new InviteClient();
  }

  currentUser: User;

  inviteClient: InviteClient;
  shouldShowModal: boolean;

  isEditingUsername: boolean = false;

  public busy: boolean = false;

  @ViewChild('linkField', { read: ElementRef, static: false })
  linkField: ElementRef;
  shortProfileUrl: string;

  @ViewChild('signupLinkField', { read: ElementRef, static: false })
  signupLinkField: ElementRef;
  signupProfileUrl: string;

  @Input()
  set user(currentUser: User) {
    if (currentUser) {
      this.currentUser = currentUser;
      if (currentUser.type == 'Therapist') {
        this.generateLinks();
      }
    }
  }

  generateLinks() {
    this.shortProfileUrl = `${environment.baseUrl}/${this.currentUser.profile.username}`;
    this.signupProfileUrl = `${environment.baseUrl}/${this.currentUser.profile.username}/contact`;
  }

  ngOnInit() {
    this.inviteService.shouldShowModal.subscribe((flag) => {
      this.shouldShowModal = flag;
    });
  }

  sendInvite() {
    if (!this.inviteClient.to) {
      this.alertService.error('Please fill out an Email address');
      return;
    }
    this.busy = true;
    this.inviteService.inviteClient(this.inviteClient).subscribe((resp) => {
      this.alertService.success(
        `An invitation has been sent to ${this.inviteClient.to}.`,
        'Invite sent!'
      );
      this.busy = false;
      this.inviteClient = new InviteClient();
      this.closeModal();
    });
  }

  closeModal() {
    this.inviteService.closeModal();
  }

  updateUsername() {
    this.profileService
      .updateUsername(this.currentUser.profile.username)
      .subscribe((res: any) => {
        if (res.success) {
          this.translationService
            .get('settings.username.success')
            .subscribe((m) => {
              this.alertService.success(m);
              this.currentUser.profile.username = res.username;
              this.generateLinks();
              this.isEditingUsername = false;
            });
        } else {
          this.translationService
            .get('settings.username.error')
            .subscribe((m) => {
              this.alertService.error(m);
            });
        }
      });
  }

  copy(tag) {
    let field = tag == 'linkField' ? this.linkField : this.signupLinkField;
    let el = field.nativeElement;

    if (!el) {
      return;
    }

    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      var editable = el.contentEditable;
      var readOnly = el.readOnly;
      el.contentEditable = 'true';
      el.readOnly = 'false';
      var range = document.createRange();
      range.selectNodeContents(el);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      el.setSelectionRange(0, 999999);
      el.contentEditable = editable;
      el.readOnly = readOnly;
    } else {
      el.select();
    }
    document.execCommand('copy');
    el.blur();
    this.alertService.success('Link copied to clipboard');
  }
}
