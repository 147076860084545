import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Profile } from 'src/app/entities/profile.model';
import {
  FileSystemFileEntry,
  NgxFileDropEntry,
  NgxFileDropModule,
} from 'ngx-file-drop';
import { User } from 'src/app/entities/user.model';
import { ProfileService } from 'src/app/components/profile/service/profile.service';
import { SharedService } from '../../services/shared.service';
import { FileTypePipe } from '../../pipes/file_type.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { NgClass, NgFor, NgIf, SlicePipe } from '@angular/common';

@Component({
  selector: 'app-upload-modal',
  templateUrl: './upload-diploma-modal.component.html',
  styleUrls: ['./upload-diploma-modal.component.scss'],
  standalone: true,
  imports: [
    NgxFileDropModule,
    NgIf,
    NgFor,
    NgClass,
    MatProgressBar,
    SlicePipe,
    TranslateModule,
    FileTypePipe,
  ],
})
export class UploadDiplomaModalComponent implements OnInit {
  constructor(
    private profileService: ProfileService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.diplomas = JSON.parse(JSON.stringify(this.profile.diplomas));
  }

  @Input()
  profile: Profile;

  @Input()
  user: User;

  @Output()
  done: EventEmitter<any> = new EventEmitter();

  public diplomas: any[] = [];
  public diplomasProgress = {};
  public wrongType: boolean;
  public showDiplomaSizeError: boolean;

  public busy: number;

  complete() {
    if (this.diplomas.length > 0 && this.user) {
      let clone: User = JSON.parse(JSON.stringify(this.user));
      clone.profile.diplomas.push(this.diplomas[0]);
      this.sharedService.setCurrentUser(clone);
    }
    this.done.emit(this.diplomas);
  }

  // updateItem() {
  //   let sub = this.profileService
  //     .updateProfileSubscribable(
  //       this.profile,
  //       this.user ? this.user.id : this.profile.id
  //     )
  //     .subscribe((res) => {
  //       this.diplomas = res.diplomas;
  //       sub.unsubscribe();
  //     });
  // }

  removeDiploma(id): void {
    this.busy = id;
    this.profileService.removeDiplomas(id).subscribe((res) => {
      this.diplomas = this.diplomas.filter((diploma) => diploma.id !== id);
      this.busy = null;
    });
  }

  onFileAddedDiploma(file: any) {
    if (!file.target.files[0]) {
      return;
    }
    file = file.target.files[0];
    this.upload(file);
  }

  onFileDroppedDiploma(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.upload(file);
        });
      }
    }
  }

  upload(file) {
    this.wrongType =
      !file.type.includes('pdf') &&
      !file.type.includes('jpeg') &&
      !file.type.includes('jpg') &&
      !file.type.includes('png');

    this.showDiplomaSizeError = file.size / 1000000 > 20;

    if (this.wrongType || this.showDiplomaSizeError) {
      return;
    }

    this.diplomas.push(file);
    let uploadData = new FormData();

    uploadData.append('diploma', file, file.name);

    this.profileService.uploadDiplomas(uploadData).subscribe((res) => {
      if (res && res.progress) {
        this.diplomasProgress[file.name] = res.progress;
      } else if (res && res.status && res.status == 'done') {
        file.id = res.id;
        this.busy = null;
        this.diplomasProgress[file.name] = null;
      }
    });
  }
}
