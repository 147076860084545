import { Pipe, PipeTransform } from '@angular/core';
import { getFileType } from '../../office/invoice-create-modal/filetype_helper';

@Pipe({
  name: 'file_type',
  standalone: true,
})
export class FileTypePipe implements PipeTransform {
  transform(filename: string): string {
    return getFileType(filename);
  }
}
