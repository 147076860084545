<h2 *ngIf="!isDateRangeSelected"
    mat-dialog-title>{{ "settings.calendar.date-specific-hours.add-hours-dialog.title" | translate }}</h2>
<h2 *ngIf="isDateRangeSelected" mat-dialog-title>{{ selectedDateRange[0] | date }}<span
  *ngIf="selectedDateRange[1]"> - {{ selectedDateRange[1] | date }}</span></h2>
<mat-dialog-content>
  <!-- Date Range Selection Screen -->
  <div *ngIf="!isDateRangeSelected" class="is-flex is-flex-direction-column is-align-items-center flex-gap-15">
    <p
      class="is-fullwidth">{{ "settings.calendar.date-specific-hours.add-hours-dialog.message_select_dates" | translate }}</p>
    <owl-date-time-inline
      [(ngModel)]="selectedDateRange"
      [selectMode]="'range'"
      [pickerType]="'calendar'"
      [firstDayOfWeek]="1"
      [min]="today"
      [ngModelOptions]="{standalone: true}"
      (ngModelChange)="onDateRangeChange($event)"
    ></owl-date-time-inline>
  </div>

  <!-- Working Hours Selection Screen -->
  <div *ngIf="isDateRangeSelected">
    <p>{{ "settings.calendar.date-specific-hours.add-hours-dialog.message_select_hours" | translate }}</p>
    <div class="has-margin-top-15 has-margin-bottom-10 is-flex is-flex-direction-row is-align-items-center flex-gap-15">
      <div class="is-flex-grow-1">
        <ng-container *ngIf="workingHours && workingHours.length > 0; else unavailable">
          <div *ngFor="let block of workingHours; let i = index"
               class="block is-flex is-flex-direction-column has-margin-y-5">
            <app-time-slot-picker
              [startTime]="block.start_hour + ':' + block.start_minute"
              [endTime]="block.end_hour + ':' + block.end_minute"
              [isBusy]="isBusy"
              (startTimeChange)="updateStartTime($event, i)"
              (endTimeChange)="updateEndTime($event, i)"
              (remove)="removeBlock(i)"
            ></app-time-slot-picker>
          </div>
        </ng-container>
        <ng-template #unavailable>
          <div class="is-flex is-align-items-center is-justify-content-center is-fullwidth">
            <span>{{ "settings.calendar.working-hours.unavailable" | translate }}</span>
          </div>
        </ng-template>
      </div>
    </div>
    <button
      matTooltip='{{ "settings.calendar.date-specific-hours.add-hours-dialog.new-interval" | translate }}'
      matTooltipPosition="above"
      mat-icon-button
      aria-label='{{ "settings.calendar.date-specific-hours.add-hours-dialog.new-interval" | translate }}'
      class="add_button"
      (click)="addBlock()"
      [disabled]="workingHours.length >= 5 || isBusy"
    >
      <mat-icon class="button-icon">add</mat-icon>
    </button>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <!-- Date Range Selection Button -->
  <button *ngIf="!isDateRangeSelected" mat-flat-button color="primary" [disabled]="isBusy || !selectedDateRange"
          [matLoadingButton]="isBusy"
          [hideText]="true"
          (click)="onDateRangeSelected()"
          cdkFocusInitial>
    {{ "common.next" | translate }}
  </button>

  <!-- Working Hours Selection Buttons -->
  <button *ngIf="isDateRangeSelected" mat-button mat-dialog-close [disabled]="isBusy">
    {{ "common.cancel" | translate }}
  </button>
  <button *ngIf="isDateRangeSelected" mat-flat-button color="primary" [disabled]="isBusy"
          [matLoadingButton]="isBusy"
          [hideText]="true"
          (click)="onComplete()"
          cdkFocusInitial>
    {{ "common.save" | translate }}
  </button>
</mat-dialog-actions>
