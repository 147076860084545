import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { OfficeService } from '../shared/service/office.service';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OfficeTaxratesComponent } from './office-taxrates/office-taxrates.component';
import { AutosizeModule } from 'ngx-autosize';
import { FormsModule } from '@angular/forms';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'office-invoice-settings',
  templateUrl: './office-invoice-settings.component.html',
  styleUrls: ['./office-invoice-settings.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    AutosizeModule,
    NgClass,
    OfficeTaxratesComponent,
    TranslateModule,
  ],
})
export class OfficeInvoiceSettingsComponent implements OnInit {
  constructor(
    private officeService: OfficeService,
    private router: Router,
    private alertService: AlertService
  ) {}

  invoiceSettings: any;
  isUpdating: boolean = false;
  newCustomfield: any;

  ngOnInit() {
    this.officeService.getInvoiceSettings().subscribe((data) => {
      this.setInvoiceSettings(data);
    });
    this.setNewCustomField();
  }

  update(msg: string) {
    if (this.isUpdating) {
      return;
    }
    this.isUpdating = true;

    var invoiceSettingsCopy = Object.assign({}, this.invoiceSettings);
    invoiceSettingsCopy.customfields = JSON.stringify(
      invoiceSettingsCopy.customfields
    );

    this.officeService
      .updateInvoiceSettings(invoiceSettingsCopy)
      .subscribe((data) => {
        this.isUpdating = false;
        this.setInvoiceSettings(data);
        this.alertService.success(msg);
      });
  }

  addCustomField() {
    this.invoiceSettings.customfields = this.invoiceSettings.customfields || [];
    this.invoiceSettings.customfields.push({
      name: this.newCustomfield.name,
      value: this.newCustomfield.value,
    });
    this.update('office.settings.custom_fields.added');
    this.setNewCustomField();
  }

  removeCustomField(i: number) {
    this.invoiceSettings.customfields.splice(i, 1);
    this.update('office.settings.custom_fields.removed');
  }

  setNewCustomField() {
    this.newCustomfield = { name: '', value: '' };
  }

  setInvoiceSettings(data) {
    data.customfields = JSON.parse(data.customfields);
    this.invoiceSettings = data;
  }
}
