<h2 mat-dialog-title>{{
    "office.settings.subscription.plan_winback_confirmation.title" |
      translate : {
      name: data.planName
    }
  }}</h2>
<mat-dialog-content>
  <p>
    {{
      "office.settings.subscription.plan_winback_confirmation.message" | translate : {
        name: data.planName,
        date: data.date | date : "mediumDate"
      }
    }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{
      "office.settings.subscription.plan_winback_confirmation.cancel" |
        translate
    }}
  </button>
  <button mat-flat-button color="primary"
          [matLoadingButton]="isPerformingWinback"
          [hideText]="true"
          (click)="performWinback()" cdkFocusInitial>
    {{ "office.settings.subscription.plan_winback_confirmation.confirm" |
    translate }}
  </button>
</mat-dialog-actions>
