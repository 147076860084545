import { OnInit, Injectable, Component } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from './services/shared.service';
import { AlertService } from './components/alert/service/alert.service';
import { User } from '../entities/user.model';

@Injectable()
export class BaseClass implements OnInit {
  constructor(
    private _sharedService: SharedService,
    private _alertService: AlertService,
    private _router: Router
  ) {}

  currentUser: User;
  currentComponent: Component;

  ngOnInit(): void {
    this._sharedService.currentUser.subscribe((currentUser) => {
      this.currentUser = currentUser;
    });
    this._sharedService.currentComponent.subscribe((currentComponent) => {
      this.currentComponent = currentComponent;
    });
  }
}
