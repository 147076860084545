<div class="modal" [ngClass]="{ 'is-active': isModalVisible }">
  <div class="modal-background"></div>
  <div class="modal-card">
    <button class="delete" aria-label="close" (click)="closeModal()"></button>

    <ng-container *ngIf="isLoadingClients">
      <div class="modal-card-body content">
        <div class="container is-flex is-justify-content-center is-align-items-center" style="height: 200px;"
        >
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isLoadingClients">
      <div class="steps">
        <div
          class="step-item"
          [ngClass]="{ 'is-active': step == 1, 'is-completed': invoice.client }"
          (click)="setStep(1)"
          *ngIf="!isInConversation"
        >
          <div class="step-marker">
          <span *ngIf="step > 1">
            <span class="icon"
            ><i class="material-icons lightgray">{{
                "invoicing.modal.steps.done" | translate
              }}</i></span
            >
          </span>
            <span *ngIf="step == 1"> 1 </span>
          </div>
          <div class="step-details">
            {{ "invoicing.modal.steps.client" | translate }}
          </div>
        </div>
        <div
          class="step-item"
          [ngClass]="{ 'is-active': step == 2, 'is-completed': step == 3 }"
          (click)="setStep(2)"
        >
          <div class="step-marker">
          <span *ngIf="step > 2">
            <span class="icon"
            ><i class="material-icons lightgray">{{
                "invoicing.modal.steps.done" | translate
              }}</i></span
            >
          </span>
            <span *ngIf="step == 2"> 2 </span>
          </div>
          <div class="step-details">
            {{ "invoicing.modal.steps.appointments" | translate }}
          </div>
        </div>
        <div
          class="step-item"
          [ngClass]="{ 'is-active': step == 3 }"
          (click)="setStep(3)"
        >
          <div class="step-marker">
          <span *ngIf="step > 3">
            <span class="icon"
            ><i class="material-icons lightgray">{{
                "invoicing.modal.steps.done" | translate
              }}</i></span
            >
          </span>
            <span *ngIf="step == 3"> 3 </span>
          </div>
          <div class="step-details">
            {{ "invoicing.modal.steps.extras" | translate }}
          </div>
        </div>
        <div class="step-item" [ngClass]="{ 'is-active': step == 4 }">
          <div class="step-marker">
          <span *ngIf="step > 4">
            <span class="icon"
            ><i class="material-icons lightgray">{{
                "invoicing.modal.steps.done" | translate
              }}</i></span
            >
          </span>
            <span *ngIf="step == 4"> 4 </span>
          </div>
          <div class="step-details">
            {{ "invoicing.modal.steps.review" | translate }}
          </div>
        </div>
      </div>

      <header class="modal-card-head">
        <p class="modal-card-title">
          {{
            "invoicing.modal.steps.new-invoice" | translate
          }}<span *ngIf="invoice.client">
          {{ "invoicing.modal.steps.for" | translate }}
          {{ invoice.client.name }}</span
        >
        </p>
      </header>

      <div [ngSwitch]="step">
        <ng-container *ngSwitchCase="1">
          <section class="modal-card-body content">
            <p>{{ "invoicing.modal.steps.choose-client" | translate }}</p>
            <div class="field">
              <div class="control">
                <div class="select is-fullwidth">
                  <select
                    [(ngModel)]="invoice.client"
                    (ngModelChange)="onClientSelected()"
                  >
                    <option [value]="undefined">
                      {{ "invoicing.modal.steps.client" | translate }}
                    </option>
                    <option [ngValue]="c" *ngFor="let c of clients">
                      {{ c.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <p>{{ "invoicing.modal.steps.or-type-email" | translate }}</p>
            <div class="field">
              <div class="control">
                <input
                  type="text"
                  class="input is-fullwidth addressinput ic-input"
                  [(ngModel)]="clientEmail"
                  placeholder="Email Address"
                />
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button
              class="button is-rounded is-primary is-outlined"
              (click)="closeModal()"
            >
              {{ "common.cancel" | translate }}
            </button>
            <button
              class="button is-rounded is-primary"
              [disabled]="!clientEmail"
              (click)="setStep(2)"
            >
              {{ "common.next" | translate }}
            </button>
          </footer>
        </ng-container>

        <ng-container *ngSwitchCase="2">
          <section class="modal-card-body content">
            <ng-container *ngIf="unbilledAppointments.length > 0">
              <p>
                {{ "invoicing.modal.steps.select-past-appointment" | translate }}
              </p>
              <div class="unbilled-appointments">
                <invoice-unbilled-item
                  [item]="item"
                  *ngFor="let item of unbilledAppointments"
                  (addAppointment)="addAppointment($event)"
                  (removeAppointment)="removeAppointment($event)"
                ></invoice-unbilled-item>
              </div>
            </ng-container>
            <ng-container *ngIf="unbilledOnlineSessions.length > 0">
              <p>
                {{ "invoicing.modal.steps.select-past-video-calls" | translate }}
              </p>
              <div class="unbilled-appointments">
                <invoice-unbilled-item
                  [item]="item"
                  *ngFor="let item of unbilledOnlineSessions"
                  (addOnlineSession)="addOnlineSession($event)"
                  (removeOnlineSession)="removeOnlineSession($event)"
                ></invoice-unbilled-item>
              </div>
            </ng-container>
          </section>
          <footer class="modal-card-foot">
            <button
              class="button is-rounded is-primary is-outlined"
              (click)="setStep(1)"
            >
              {{ "common.back" | translate }}
            </button>
            <button
              class="button is-rounded has-border-black"
              (click)="setStep(3)"
              *ngIf="invoice.invoice_items.length == 0"
            >
              {{ "invoicing.modal.steps.skip-step" | translate }}
            </button>
            <button
              class="button is-rounded is-primary"
              (click)="setStep(3)"
              *ngIf="invoice.invoice_items.length > 0"
            >
              {{ "registration.ok" | translate }}
            </button>
          </footer>
        </ng-container>

        <ng-container *ngSwitchCase="3">
          <section class="modal-card-body content">
            <p>{{ "invoicing.modal.steps.extra-items" | translate }}</p>
            <div class="extra-items">
              <div
                class="extra-item"
                *ngFor="let item of invoiceExtraItems; let i = index"
              >
                <button
                  class="delete is-small"
                  (click)="removeExtraItem(i)"
                ></button>
                <div class="field item-name">
                  <label class="label">{{
                      "invoicing.modal.steps.service" | translate
                    }}</label>
                  <div class="control">
                  <textarea
                    type="text"
                    class="input is-small"
                    [(ngModel)]="item.name"
                    placeholder="Service"
                    autosize
                    [minRows]="1"
                  ></textarea>
                  </div>
                </div>
                <div class="field item-amount">
                  <label class="label">{{ "common.amount" | translate }}</label>
                  <div class="control">
                    <input
                      type="text"
                      class="input is-small"
                      mask="999"
                      repeat="3"
                      [(ngModel)]="item.amount"
                    />
                  </div>
                </div>
                <div class="field item-price">
                  <label class="label">Price</label>
                  <div class="control has-icons-right">
                    <input
                      type="text"
                      class="input is-small"
                      mask="separator.2 "
                      autocomplete="false"
                      [dropSpecialCharacters]="false"
                      [(ngModel)]="item.price"
                    />
                  </div>
                </div>
                <div class="field item-total">
                  <label class="label">{{ "common.total" | translate }}</label>
                  <div class="control">
                    {{
                      item.amount * item.price
                        | currency : (currentUser.profile.currency | uppercase)
                    }}
                  </div>
                </div>
              </div>
              <div class="extra-item add" (click)="addExtraItem()">
                {{ "invoicing.modal.steps.add-item" | translate }}
              </div>
            </div>
          </section>

          <footer class="modal-card-foot">
            <button
              class="button is-rounded is-primary is-outlined"
              (click)="setStep(2)"
            >
              {{ "common.back" | translate }}
            </button>
            <button
              class="button is-rounded is-primary"
              (click)="saveInvoice()"
              [ngClass]="{ 'is-loading': isSavingInvoice }"
            >
            <span *ngIf="invoiceExtraItems.length == 0"
            >{{ "invoicing.modal.steps.skip" | translate }} &&nbsp;</span
            >
              {{ "invoicing.modal.steps.review" | translate }}
            </button>
          </footer>
        </ng-container>
      </div>
    </ng-container>

  </div>
</div>
