<div
  *ngIf="currentUser"
  [@simpleFadeAnimation]="'in'"
  class="container-fluid container-fluid-limited"
>
  <div class="columns is-marginless">
    <div class="column is-3 is-hidden-touch is-marginless">
      <app-sidenav></app-sidenav>
    </div>
    <div class="column">
      <nav class="mobile-navbar">
        <h3 class="subtitle is-4 page-title">
          {{ "referrals.therapist_to_therapist.subtitle" | translate }}
        </h3>
      </nav>
      <div class="card has-border">
        <div class="card-content">
          <div class="content" *ngIf="currentUser.coupon">
            <ng-container *ngIf="totalEarned > 0">
              <h4>
                {{
                  "referrals.therapist_to_therapist.earned_so_far" | translate
                }}
              </h4>
              <div class="columns is-mobile earnings-progress">
                <div class="column is-narrow">
                  <h4>{{ totalEarned }}</h4>
                </div>
                <div class="column">
                  <progress
                    class="progress is-primary"
                    [value]="totalEarned"
                    max="5"
                  ></progress>
                </div>
                <div class="column is-narrow">
                  <h4>
                    {{
                      "referrals.therapist_to_therapist.max_months" | translate
                    }}
                  </h4>
                </div>
              </div>
              <hr />
            </ng-container>
            <p>
              {{ "referrals.therapist_to_therapist.share_message" | translate }}
            </p>
            <p>
              {{
                "referrals.therapist_to_therapist.here_is_your_code" | translate
              }}
            </p>
            <p class="subtitle is-4 has-text-centered">
              <span class="shareUrl">{{ shareUrl }}</span>
            </p>
            <div class="columns">
              <div class="column has-text-centered">
<!--                broken currently, https://linear.app/complicated-life/issue/COM-2021/referral-via-email-is-broken
                        <div-->
<!--                  class="button is-rounded is-primary"-->
<!--                  (click)="showEmailModal()"-->
<!--                >-->
<!--                  {{-->
<!--                    "referrals.therapist_to_therapist.invite_by_email"-->
<!--                      | translate-->
<!--                  }}-->
<!--                </div>-->
<!--                &nbsp;-->
                <ng-container *ngIf="!showShareButton()">
                  <a
                    class="button is-rounded is-primary is-sharebutton"
                    target="_blank"
                    [href]="
                      'https://www.facebook.com/sharer/sharer.php?u=' + shareUrl
                    "
                  >
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
                      />
                    </svg>
                  </a>
                  <a
                    class="button is-rounded is-primary is-sharebutton"
                    target="_blank"
                    [href]="'https://twitter.com/intent/tweet?url=' + shareUrl"
                  >
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
                      />
                    </svg>
                  </a>
                  <a
                    class="button is-rounded is-primary is-sharebutton"
                    target="_blank"
                    [href]="
                      'https://www.linkedin.com/shareArticle?mini=true&url=' +
                      shareUrl
                    "
                  >
                    <svg
                      role="img"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"
                      />
                    </svg>
                  </a>
                </ng-container>
                <a
                  class="button is-rounded"
                  (click)="share()"
                  *ngIf="showShareButton()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"
                    />
                  </svg>
                </a>
              </div>
            </div>

            <ng-container *ngIf="!showMore">
              <p>
                {{
                  "referrals.therapist_to_therapist.track_status.0" | translate
                }}
                <a (click)="showMore = true" class="textlink">{{
                  "referrals.therapist_to_therapist.track_status.1" | translate
                }}</a>
                {{
                  "referrals.therapist_to_therapist.track_status.2" | translate
                }}
              </p>
            </ng-container>
            <ng-container *ngIf="showMore">
              <ng-container *ngIf="referrals">
                <hr />
                <h4>
                  {{
                    "referrals.therapist_to_therapist.your_referrals.title"
                      | translate
                  }}
                </h4>
                <div class="box" *ngFor="let referral of referrals">
                  <div class="columns">
                    <div class="column is-4">
                      <p>
                        <strong class="title is-6">{{ referral.email }}</strong
                        ><br />
                        <small>{{
                          "referrals.therapist_to_therapist.your_referrals.invited_at"
                            | translate : { date: referral.created_at | date }
                        }}</small>
                        <ng-container *ngIf="referral.status == 'subscribed'">
                          <br />
                          <small>{{
                            "referrals.therapist_to_therapist.your_referrals.rewarded_at"
                              | translate : { date: referral.updated_at | date }
                          }}</small>
                        </ng-container>
                      </p>
                    </div>
                    <div class="column is-6">
                      <div class="steps is-small">
                        <div class="step-item is-completed">
                          <div class="step-marker">1</div>
                          <div class="step-details">
                            {{
                              "referrals.therapist_to_therapist.your_referrals.invite_sent"
                                | translate
                            }}
                          </div>
                        </div>
                        <div
                          class="step-item"
                          [ngClass]="{
                            'is-completed': [
                              'signedup',
                              'approved',
                              'subscribed'
                            ].includes(referral.status)
                          }"
                        >
                          <div class="step-marker">2</div>
                          <div class="step-details">
                            {{
                              "referrals.therapist_to_therapist.your_referrals.colleague_registered"
                                | translate
                            }}
                          </div>
                        </div>
                        <div
                          class="step-item"
                          [ngClass]="{
                            'is-completed': ['approved', 'subscribed'].includes(
                              referral.status
                            )
                          }"
                        >
                          <div class="step-marker">3</div>
                          <div class="step-details">
                            {{
                              "referrals.therapist_to_therapist.your_referrals.colleague_approved"
                                | translate
                            }}
                          </div>
                        </div>
                        <div
                          class="step-item"
                          [ngClass]="{
                            'is-completed': referral.status == 'subscribed'
                          }"
                        >
                          <div class="step-marker">4</div>
                          <div class="step-details">
                            {{
                              "referrals.therapist_to_therapist.your_referrals.money_paid_out"
                                | translate
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-2 has-text-right">
                      <span class="title is-5" *ngIf="referral.reward > 0">
                        {{
                          "referrals.therapist_to_therapist.your_referrals.reward_in_months"
                            | translate
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <hr *ngIf="referrals.length == 0" />
              </ng-container>

              <hr />
              <h4>
                {{
                  "referrals.therapist_to_therapist.how_it_works.title"
                    | translate
                }}
              </h4>
              <p>
                {{
                  "referrals.therapist_to_therapist.how_it_works.overview"
                    | translate
                }}
              </p>
              <ul>
                <li>
                  <h6>
                    {{
                      "referrals.therapist_to_therapist.how_it_works.step_1.header"
                        | translate
                    }}
                  </h6>
                  <p>
                    {{
                      "referrals.therapist_to_therapist.how_it_works.step_1.details"
                        | translate
                    }}
                  </p>
                </li>
                <li>
                  <h6>
                    {{
                      "referrals.therapist_to_therapist.how_it_works.step_2.header"
                        | translate
                    }}
                  </h6>
                  <p>
                    {{
                      "referrals.therapist_to_therapist.how_it_works.step_2.details"
                        | translate
                    }}
                  </p>
                </li>
                <li>
                  <h6>
                    {{
                      "referrals.therapist_to_therapist.how_it_works.step_3.header"
                        | translate
                    }}
                  </h6>
                  <p>
                    {{
                      "referrals.therapist_to_therapist.how_it_works.step_3.details"
                        | translate
                    }}
                  </p>
                </li>
                <li>
                  <h6>
                    {{
                      "referrals.therapist_to_therapist.how_it_works.step_4.header"
                        | translate
                    }}
                  </h6>
                  <p>
                    {{
                      "referrals.therapist_to_therapist.how_it_works.step_4.details"
                        | translate
                    }}
                  </p>
                </li>
              </ul>
              <p>
                {{
                  "referrals.therapist_to_therapist.how_it_works.maximum"
                    | translate
                }}
              </p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<referral-modal></referral-modal>
