<h2 mat-dialog-title>{{
    "office.settings.subscription.plan_downgrade_confirmation.title" |
      translate
  }}</h2>
<mat-dialog-content>
  <p>
    {{ "office.settings.subscription.plan_downgrade_confirmation.message" | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="isPerformingDowngrade">
    {{
      "office.settings.subscription.plan_downgrade_confirmation.cancel" |
        translate
    }}
  </button>
  <button mat-flat-button color="primary"
          [matLoadingButton]="isPerformingDowngrade"
          (click)="performDowngrade()" cdkFocusInitial>
    {{
        "office.settings.subscription.plan_downgrade_confirmation.confirm" |
          translate
      }}
  </button>
</mat-dialog-actions>
