<div class="is-hidden-touch" id="circle"></div>
<div class="is-hidden-touch" id="coffee"></div>

<section class="container-fluid container-fluid-limited">
  <div class="card">
    <div class="card-content">
      <h1 class="title has-text-centered">Reset</h1>
      <ng-container *ngIf="!success">
        <form #resetPasswordForm="ngForm">
          <div class="field">
            <div class="control has-icons-right">
              <input
                class="input auth_input is-primary"
                [ngClass]="{
                  'has-text': resetPassword.password,

                  invalid:
                    (password.touched && password.invalid && password.dirty) ||
                    (password.touched &&
                      resetPassword.password?.length < 8 &&
                      password.dirty) ||
                    (!validationService.passwordHasCapital(
                      resetPassword.password
                    ) &&
                      password.touched &&
                      resetPassword.password !== '' &&
                      password.dirty) ||
                    (!validationService.passwordHasNumber(
                      resetPassword.password
                    ) &&
                      password.touched &&
                      resetPassword.password !== '' &&
                      password.dirty),

                  valid:
                    password.touched &&
                    !password.invalid &&
                    resetPassword.password?.length >= 8 &&
                    validationService.passwordHasCapital(
                      resetPassword.password
                    ) &&
                    validationService.passwordHasNumber(resetPassword.password)
                }"
                name="password"
                [(ngModel)]="resetPassword.password"
                type="password"
                required
                #password="ngModel"
              />
              <label
                class="label-auth auth_label"
                [ngClass]="{
                  password_short:
                    resetPassword.password?.length < 8 &&
                    password.touched &&
                    password.dirty &&
                    resetPassword.password !== '',

                  password_capital:
                    !validationService.passwordHasCapital(
                      resetPassword.password
                    ) &&
                    password.touched &&
                    password.dirty &&
                    resetPassword.password !== '',

                  password_number:
                    !validationService.passwordHasNumber(
                      resetPassword.password
                    ) &&
                    password.touched &&
                    password.dirty &&
                    resetPassword.password !== '',

                  password:
                    !password.touched ||
                    (password.touched && !password.dirty) ||
                    (password.touched && password.valid) ||
                    (password.touched && password.pristine) ||
                    (password.touched &&
                      password.dirty &&
                      resetPassword.password == '')
                }"
              ></label>
              <div
                *ngIf="
                  (password.touched && password.invalid) ||
                  (password.touched && resetPassword.password?.length < 8) ||
                  (password.touched &&
                    !validationService.passwordHasCapital(
                      resetPassword.password
                    )) ||
                  (password.touched &&
                    !validationService.passwordHasNumber(
                      resetPassword.password
                    ))
                "
                class="icon is-small is-right"
              >
                <i class="material-icons lightgray">highlight_off</i>
              </div>
              <div
                *ngIf="
                  password.touched &&
                  !password.invalid &&
                  resetPassword.password?.length >= 8 &&
                  validationService.passwordHasCapital(
                    resetPassword.password
                  ) &&
                  validationService.passwordHasNumber(resetPassword.password)
                "
                class="icon is-small is-right"
              >
                <i class="material-icons lightgray">done</i>
              </div>
            </div>
          </div>

          <div class="field">
            <div class="control has-icons-right">
              <!-- Various validations for password -->

              <input
                class="input auth_input is-primary"
                [ngClass]="{
                  'has-text': resetPassword.password_confirmation,

                  invalid:
                    (passwordConfirmation.touched &&
                      passwordConfirmation.invalid &&
                      passwordConfirmation.dirty) ||
                    (passwordConfirmation.touched &&
                      resetPassword.password_confirmation?.length < 8 &&
                      passwordConfirmation.dirty) ||
                    (!validationService.passwordHasCapital(
                      resetPassword.password_confirmation
                    ) &&
                      passwordConfirmation.touched &&
                      resetPassword.password_confirmation !== '' &&
                      passwordConfirmation.dirty) ||
                    (!validationService.passwordHasNumber(
                      resetPassword.password_confirmation
                    ) &&
                      passwordConfirmation.touched &&
                      resetPassword.password_confirmation !== '' &&
                      passwordConfirmation.dirty) ||
                    (!validationService.matchingPasswords(
                      resetPassword.password,
                      resetPassword.password_confirmation
                    ) &&
                      resetPassword.password_confirmation !== '' &&
                      passwordConfirmation.dirty),

                  valid:
                    passwordConfirmation.touched &&
                    !passwordConfirmation.invalid &&
                    resetPassword.password_confirmation?.length >= 8 &&
                    validationService.passwordHasCapital(
                      resetPassword.password_confirmation
                    ) &&
                    validationService.passwordHasNumber(
                      resetPassword.password_confirmation
                    ) &&
                    validationService.matchingPasswords(
                      resetPassword.password,
                      resetPassword.password_confirmation
                    )
                }"
                name="password_confirmation"
                [ngModelOptions]="{ updateOn: 'blur' }"
                [(ngModel)]="resetPassword.password_confirmation"
                type="password"
                required
                #passwordConfirmation="ngModel"
              />

              <!-- Label -->

              <label
                class="label-auth auth_label"
                [ngClass]="{
                  password_short:
                    resetPassword.password_confirmation?.length < 8 &&
                    passwordConfirmation.touched &&
                    passwordConfirmation.dirty &&
                    resetPassword.password_confirmation !== '',

                  password_capital:
                    !validationService.passwordHasCapital(
                      resetPassword.password_confirmation
                    ) &&
                    passwordConfirmation.touched &&
                    passwordConfirmation.dirty &&
                    resetPassword.password_confirmation !== '',

                  password_number:
                    !validationService.passwordHasNumber(
                      resetPassword.password_confirmation
                    ) &&
                    passwordConfirmation.touched &&
                    passwordConfirmation.dirty &&
                    resetPassword.password_confirmation !== '',

                  password_confirmation:
                    !passwordConfirmation.touched ||
                    (passwordConfirmation.touched &&
                      !passwordConfirmation.dirty) ||
                    (passwordConfirmation.touched &&
                      passwordConfirmation.valid) ||
                    (passwordConfirmation.touched &&
                      passwordConfirmation.pristine) ||
                    (passwordConfirmation.touched &&
                      passwordConfirmation.dirty &&
                      resetPassword.password_confirmation == ''),

                  password_matching:
                    password.touched &&
                    password.dirty &&
                    resetPassword.password !== '' &&
                    passwordConfirmation.touched &&
                    passwordConfirmation.dirty &&
                    resetPassword.password_confirmation !== '' &&
                    !validationService.matchingPasswords(
                      resetPassword.password,
                      resetPassword.password_confirmation
                    )
                }"
              ></label>
              <div
                *ngIf="
                  (passwordConfirmation.touched &&
                    passwordConfirmation.invalid) ||
                  (passwordConfirmation.touched &&
                    resetPassword.password_confirmation?.length < 8) ||
                  (passwordConfirmation.touched &&
                    !validationService.passwordHasCapital(
                      resetPassword.password_confirmation
                    )) ||
                  (passwordConfirmation.touched &&
                    !validationService.passwordHasNumber(
                      resetPassword.password_confirmation
                    )) ||
                  (passwordConfirmation.touched &&
                    !validationService.matchingPasswords(
                      resetPassword.password,
                      resetPassword.password_confirmation
                    ))
                "
                class="icon is-small is-right"
              >
                <i class="material-icons lightgray">highlight_off</i>
              </div>
              <div
                *ngIf="
                  passwordConfirmation.touched &&
                  !passwordConfirmation.invalid &&
                  resetPassword.password_confirmation?.length >= 8 &&
                  validationService.passwordHasCapital(
                    resetPassword.password_confirmation
                  ) &&
                  validationService.passwordHasNumber(
                    resetPassword.password_confirmation
                  ) &&
                  validationService.matchingPasswords(
                    resetPassword.password,
                    resetPassword.password_confirmation
                  )
                "
                class="icon is-small is-right"
              >
                <i class="material-icons lightgray">done</i>
              </div>
            </div>
          </div>
        </form>

        <button
          (click)="updatePassword()"
          [class.loading]="loading"
          class="button is-fullwidth ic-button is-rounded is-medium"
        >
          Update
        </button>
        <div class="has-text-centered" style="width: 100%">
          <div [ngClass]="{ 'lds-ripple': loading }">
            <div></div>
            <div></div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="success">
        <p>Your password has been successfully changed.</p>
        <button
          routerLink="/login"
          class="button is-fullwidth ic-button is-rounded is-medium"
        >
          Go to login
        </button>
      </ng-container>
      <ng-container *ngIf="error">
        <p>
          There seems to have been an error. Please try again at a later time.
        </p>
        <button
          routerLink="/login"
          class="button is-fullwidth ic-button is-rounded is-medium"
        >
          Go to login
        </button>
      </ng-container>
    </div>
  </div>
</section>
