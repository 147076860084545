import { Component, Input, OnInit } from '@angular/core';
import { Animation } from '../../../shared/animations/fade-animation';
import {
  CurrencyPipe,
  DatePipe,
  NgClass,
  NgFor,
  NgIf,
  PercentPipe,
} from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { User } from '../../../entities/user.model';
import { ProfileService } from '../../profile/service/profile.service';
import { Profile } from '../../../entities/profile.model';
import { AlertService } from '../../../shared/components/alert/service/alert.service';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
  animations: [Animation.fadeAnimation],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    FormsModule,
    PercentPipe,
    CurrencyPipe,
    DatePipe,
    TranslateModule,
    MatIcon,
  ],
})
export class ProgramsComponent implements OnInit {
  profile: Profile;
  isUpdatingMatchingProgram = false;
  isUpdatingWorkplaceProgram = false;

  constructor(
    private profileService: ProfileService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.profileService.profile.subscribe((prof) => {
      this.profile = prof;
    });
    this.getProfile();
  }

  getProfile() {
    this.profileService.getSelfProfile();
  }

  pauseMatchingProgram() {
    this.isUpdatingMatchingProgram = true;
    this.profileService
      .updatePausedInMatchingProgram(true)
      .subscribe((profile) => {
        this.alertService.success('programs.status_updated');
        this.profileService.setProfile(profile);
        this.isUpdatingMatchingProgram = false;
      });
  }

  resumeMatchingProgram() {
    this.isUpdatingMatchingProgram = true;
    this.profileService
      .updatePausedInMatchingProgram(false)
      .subscribe((profile) => {
        this.alertService.success('programs.status_updated');
        this.profileService.setProfile(profile);
        this.isUpdatingMatchingProgram = false;
      });
  }

  pauseWorkplaceProgram() {
    this.isUpdatingWorkplaceProgram = true;
    this.profileService
      .updatePausedInWorkplaceProgram(true)
      .subscribe((profile) => {
        this.alertService.success('programs.status_updated');
        this.profileService.setProfile(profile);
        this.isUpdatingWorkplaceProgram = false;
      });
  }

  resumeWorkplaceProgram() {
    this.isUpdatingWorkplaceProgram = true;
    this.profileService
      .updatePausedInWorkplaceProgram(false)
      .subscribe((profile) => {
        this.alertService.success('programs.status_updated');
        this.profileService.setProfile(profile);
        this.isUpdatingWorkplaceProgram = false;
      });
  }
}
