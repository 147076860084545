<h3 class="subtitle is-5">{{ "settings.import-external-cal" | translate }}</h3>
<ng-container *ngIf="integrations?.length == 0">
  <p>{{ "settings.import-external-cal-desc" | translate }}</p>
</ng-container>

<ng-container *ngIf="integrations?.length > 0">
  <div class="box content integration" *ngFor="let integration of integrations">
    <h4 class="subtitle is-5">{{ integration.name || "Google Calendar" }}</h4>
    <ng-container
      *ngIf="integration.calendar == 'Google' && integration.authorized"
    >
      <p>{{ "settings.select-cal" | translate }}</p>
      <div class="columns" *ngFor="let cal of integration.google_calendars">
        <div class="column is-1">
          <label class="switch">
            <input
              name="calcheckbox"
              [disabled]="insync"
              (change)="
                toggleCalendarForIntegration(
                  $event.target.checked,
                  cal.id,
                  integration.id
                )
              "
              type="checkbox"
              [checked]="cal.active"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="column">
          <p>{{ cal.name }}</p>
        </div>
      </div>
    </ng-container>
    <input
      class="input is-fullwidth is-small"
      readonly
      type="text"
      *ngIf="integration.calendar != 'Google'"
      [(ngModel)]="integration.calendar"
    />
    <ng-container *ngIf="!integration.authorized">
      <p class="help is-info">
        {{ "settings.unable-to-sync-calendar" | translate }}
      </p>
      <div *ngIf="integration.calendar == 'Google'" class="has-text-right">
        <button
          class="button is-warning is-rounded"
          (click)="linkGoogleCalendar()"
          [disabled]="insync"
          [ngClass]="{ 'is-loading': insync }"
        >
          {{ "settings.try-again" | translate }}
        </button>
      </div>
      <div *ngIf="integration.calendar != 'Google'" class="has-text-right">
        <button
          class="button is-warning is-rounded"
          (click)="reAuthCalendar(integration)"
          [disabled]="insync"
          [ngClass]="{ 'is-loading': insync }"
        >
          {{ "settings.try-again" | translate }}
        </button>
      </div>
    </ng-container>
    <button
      class="button is-danger is-outlined is-small del"
      (click)="deleteIntegration(integration.id)"
      [disabled]="insync"
      [ngClass]="{ 'is-loading': insync }"
    >
      {{ "settings.delete" | translate }}
    </button>
    <!-- <p class="help has-text-danger" *ngIf="currentUser.profile && integration.timezone != currentUser.profile.timezone">The calenders timezone ({{integration.timezone}}) is different from your accounts timezone ({{currentUser.profile.timezone}}). Please consider changing either, as this could affect the display of your appointments and/or availability.</p> -->
  </div>
</ng-container>
<div class="has-text-centered">
  <ng-container *ngIf="!hasLinkedGoogleCalendar">
    <button
      class="button is-primary is-rounded has-margin-y-20"
      (click)="linkGoogleCalendar()"
    >
      {{ "settings.link-google-cal" | translate }}
    </button>
    <p>
      <small
        ><em>{{ "settings.or" | translate }}</em></small
      >
    </p>
  </ng-container>
  <p>
    <strong>{{ "settings.add-cal-url" | translate }}</strong>
  </p>
  <div class="coupon-form field is-grouped">
    <p class="control is-expanded">
      <input
        type="text"
        class="input"
        placeholder="Calendar URL"
        [(ngModel)]="calendarURL"
      />
    </p>
    <p class="control">
      <button
        class="button"
        (click)="integrateCalendarFromURL()"
        [ngClass]="{
          'is-loading': integratingCalendar,
          'is-disabled': integratingCalendar
        }"
      >
        {{ "settings.add-cal" | translate }}
      </button>
    </p>
  </div>
</div>
