<div id="onboardingbox" class="card" *ngIf="shouldShowBox">
  <svg-icon
    class="plant-image is-hidden-touch"
    name="plant-with-cup"
  ></svg-icon>
  <h2 class="onboardingbox-title">
    {{ "onboarding.intro.client" | translate : { name: currentUser.name } }}
  </h2>
  <div class="columns is-multiline">
    <div class="column" *ngIf="!currentUser.email_confirmed">
      <div class="box">
        <h5 class="title is-6">
          {{ "onboarding.boxes.verify_email.title" | translate }}
        </h5>
        <p>
          {{ "onboarding.boxes.verify_email.text" | translate }}
        </p>
        <button
          (click)="resendVerificationEmail()"
          [ngClass]="{ 'is-loading': isRequestingEmail }"
          class="button is-outlined is-small is-primary is-rounded"
        >
          {{ "onboarding.boxes.verify_email.action" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
