import { Component, OnInit } from '@angular/core';
import { SupportRequest } from 'src/app/entities/supportReuest.model';
import { SupportService } from '../../services/support.service';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'shared-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.css'],
  standalone: true,
  imports: [NgClass, NgIf, FormsModule],
})
export class SupportModalComponent implements OnInit {
  constructor(private supportService: SupportService) {}

  showModal: boolean;
  showError: boolean = false;
  isSending: boolean = false;
  isSent: boolean = false;
  state: string;
  supportRequest: SupportRequest;

  ngOnInit() {
    this.supportService.showModal.subscribe((showModal) => {
      this.reset();
      this.showModal = showModal;
    });
  }

  reset() {
    this.state = 'menu';
    this.supportRequest = new SupportRequest();
    this.supportRequest.note = '';
    this.supportRequest.category = 'GENERAL_INQUIRY';
  }

  close() {
    this.supportService.hideReportModal();
  }

  requestSupport() {
    if (this.isSending) {
      return;
    }
    if (this.supportRequest.note == '') {
      this.showError = true;
      return;
    }
    this.showError = false;
    this.isSending = true;
    this.supportService
      .sendSupportRequest(this.supportRequest)
      .subscribe((response) => {
        this.isSending = false;
        this.isSent = true;
      });
  }
}
