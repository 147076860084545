<h2 mat-dialog-title>{{ "office.settings.subscription.plan_upgrade_confirmation.title" | translate
  }}</h2>
<mat-dialog-content>
  <div
    class="content-overlay is-flex is-justify-content-center is-align-items-center"
    [style.visibility]="amountDue == null ? 'visible' : 'hidden'"
  >
    <mat-spinner mode="indeterminate" diameter="40"></mat-spinner>
  </div>
  <div
    class="message-container"
    [style.visibility]="amountDue != null ? 'visible' : 'hidden'"
    style="white-space: pre-wrap"
    [innerHTML]="(data.isYearlyBilling ? 'office.settings.subscription.plan_upgrade_confirmation.message_yearly' : 'office.settings.subscription.plan_upgrade_confirmation.message') | translate : { amount: amountDue | currency : data.profileCurrency, name: data.planName }"
  ></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ "office.settings.subscription.plan_upgrade_confirmation.cancel" |
    translate }}
  </button>
  <button mat-flat-button color="primary"  [disabled]="amountDue == null || isPerformingUpgrade"
          [matLoadingButton]="isPerformingUpgrade"
          (click)="performUpgrade()" cdkFocusInitial>
    {{ "office.settings.subscription.plan_upgrade_confirmation.confirm" |
    translate }}
  </button>
</mat-dialog-actions>
