import { Component, OnInit } from '@angular/core';
import { Invoice } from 'src/app/entities/invoice.model';
import { User } from 'src/app/entities/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { OfficeService } from '../shared/service/office.service';
import { TranslateModule } from '@ngx-translate/core';
import { OfficeInvoiceTableComponent } from '../office-invoice-table/office-invoice-table.component';
import { LoadingAnimationComponent } from '../../shared/components/loading-animation/loading-animation.component';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'office-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    LoadingAnimationComponent,
    OfficeInvoiceTableComponent,
    TranslateModule,
  ],
})
export class InvoiceListComponent implements OnInit {
  constructor(
    private officeService: OfficeService,
    private sharedService: SharedService
  ) {}

  public invoices: Invoice[];
  public filteredInvoices: Invoice[];
  public currentUser: User;
  public filterQuery: string = '';

  ngOnInit() {
    this.sharedService.currentUser.subscribe(
      (user) => (this.currentUser = user)
    );
    this.officeService.getInvoices();
    this.officeService.invoices.subscribe((invoices) => {
      if (invoices) {
        this.invoices = invoices;
        this.filter();
      }
    });
  }

  filter() {
    if (this.filterQuery == '') {
      this.filteredInvoices = this.invoices;
    } else {
      this.filteredInvoices = this.invoices.filter((i) => {
        const haystack = [];
        if (i.client) {
          haystack.push(i.client.name.toLowerCase());
        }
        if (i.client_email) {
          haystack.push(i.client_email.toLowerCase());
        }
        if (i.stripe_invoice_number) {
          haystack.push(i.stripe_invoice_number.toLowerCase());
        }
        haystack.push(i.total);
        const search = haystack.join(' ');
        return search.includes(this.filterQuery.toLowerCase());
      });
    }
  }
}
