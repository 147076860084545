<section class="dashboard-teaser">
  <header class="dashboard-teaser-header">
    <h3 class="dashboard-teaser-title">{{ "messaging.title" | translate }}</h3>
  </header>

  <ng-container *ngIf="hasError">
    <div class="notification is-danger">
      {{ "messaging.could_not_load_latest_messages" | translate }}
    </div>
  </ng-container>

  <ng-container *ngIf="!conversations && showFakeTemplate && !hasError">
  <div class="card" [ngClass]="{ 'no-shadow': false }">
    <app-conversation-preview
      *ngFor="let i of [1, 2, 3]"
      [loading]="true"
    ></app-conversation-preview>
  </div>
  </ng-container>

  <ng-container *ngIf="conversations && currentUser">
    <div class="card" [ngClass]="{ 'no-shadow': false }">
      <ng-container *ngIf="conversations.length == 0">
        <div [ngClass]="{ 'card is-flex justify-center': true }">
          <div class="card-content has-text-centered">
            <p>{{ "messaging.no_conversations" | translate }}</p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngFor="let c of (conversations  | slice : 0 : 3)">
        <app-conversation-preview
          [avatarSrc]="getAvatarSrc(c)"
          [name]="getOtherUserName(c)"
          [message]="getLatestMessage(c)"
          [timestamp]="getLatestMessageTimestamp(c)"
          [unread]="hasUnreadMessages(c)"
          [showTime]="showTime"
          [truncateSize]="truncateSize"
          [showMatchedBadge]="c.is_result_of_match && currentUser.type == 'Therapist'"
          [flagged]="isUserFlagged(c)"
          [otherUserDeleted]="false"
          [decrypted]="isMessageDecrypted(c)"
          [loading]="false"
          (conversationClick)="goToConversation(c)"
        ></app-conversation-preview>
      </ng-container>

      <footer *ngIf="showViewAll && conversations.length > 0" class="card-footer">
        <a routerLink="/home/messaging" class="card-footer-item">
          <span class="icon icon-messages"></span
          >{{ "messaging.view_all" | translate }}
        </a>
      </footer>
    </div>
  </ng-container>
</section>
