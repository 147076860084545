<div
  class="modal modal-fx-slideBottom invite-modal"
  *ngIf="currentUser"
  [ngClass]="{ 'is-active': shouldShowModal }"
>
  <div class="modal-background"></div>
  <div class="modal-content">
    <button class="delete" (click)="closeModal()"></button>
    <div class="columns is-marginless">
      <div
        class="column is-4 therapist-profile"
        *ngIf="currentUser.type == 'Therapist'"
      >
        <div class="columns is-mobile is-multiline">
          <div
            class="column is-4-touch is-12-tablet is-12-desktop is-paddingless"
          >
            <div class="therapist-image">
              <app-profile-picture
                [profile_id]="currentUser.profile.id"
                [variant]="'profile'"
              >
              </app-profile-picture>
            </div>
          </div>
          <div class="column is-8-touch is-12-tablet is-12-desktop">
            <h2 class="therapist-name">
              {{
                currentUser.profile.firstname +
                  " " +
                  currentUser.profile.lastname
              }}
            </h2>
            <h3 class="therapist-jobtitle">
              {{ currentUser.profile.jobtitle }}
            </h3>
          </div>
        </div>
      </div>
      <div class="column contact">
        <ng-container
          *ngIf="
            currentUser.type == 'Client' || currentUser.profile.profile_approved
          "
        >
          <h3 class="modal-title" *ngIf="currentUser.type == 'Therapist'">
            {{ "invite-client.title" | translate }}
          </h3>
          <h3 class="modal-title" *ngIf="currentUser.type == 'Client'">
            {{ "invite-client.tell-friend" | translate }}
          </h3>
          <div class="field">
            <span class="label">{{ "invite-client.email" | translate }}</span>
            <div class="control">
              <input
                [(ngModel)]="inviteClient.to"
                [ngClass]="{ 'has-text': inviteClient.to }"
                type="email"
                class="input is-medium is-fullwidth is-primary"
              />
            </div>
          </div>
          <div class="field">
            <span class="label">{{
              "invite-client.personal-message" | translate
            }}</span>
            <div class="control">
              <textarea
                *ngIf="currentUser.type == 'Therapist'"
                [(ngModel)]="inviteClient.message"
                placeholder="{{
                  'invite-client.therapist-placeholder' | translate
                }}"
                class="textarea"
                autogrow
              ></textarea>
              <textarea
                *ngIf="currentUser.type == 'Client'"
                [(ngModel)]="inviteClient.message"
                placeholder="{{
                  'invite-client.client-placeholder' | translate
                }}"
                class="textarea"
                autogrow
              ></textarea>
            </div>
          </div>
          <button
            [disabled]="busy"
            [ngClass]="{ 'is-loading': busy }"
            (click)="sendInvite()"
            class="button is-rounded is-fullwidth is-medium is-primary"
          >
            {{ "invite-client.invite" | translate }}
          </button>
          <ng-container *ngIf="currentUser.type == 'Therapist'">
            <hr />
            <div class="content">
              <strong>{{
                "invite-client.share-profile-or-sign-up" | translate
              }}</strong>
              <p>{{ "invite-client.clients-discount" | translate }}</p>
              <ng-container
                *ngIf="currentUser.profile.username && !isEditingUsername"
              >
                <div class="is-flex inv-client-link">
                  <span>{{ "invite-client.profile-link" | translate }}</span>
                  <span class="clipboard" (click)="copy('linkField')">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="clipboard"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill=""
                        d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
                      />
                    </svg>
                  </span>
                </div>
                <textarea
                  class="textarea is-rounded is-fullwidth"
                  readonly
                  autosize
                  [minRows]="1"
                  id="linkField"
                  #linkField
                  >{{ shortProfileUrl }}</textarea
                >
                <p></p>
                <div class="is-flex inv-client-link">
                  <span>{{ "invite-client.contact-link" | translate }}</span>
                  <span class="clipboard" (click)="copy('signupLinkField')">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="clipboard"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill=""
                        d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
                      />
                    </svg>
                  </span>
                </div>
                <textarea
                  class="textarea is-rounded is-fullwidth"
                  readonly
                  autosize
                  [minRows]="1"
                  id="signupLinkField"
                  #signupLinkField
                  >{{ signupProfileUrl }}</textarea
                >
                <button
                  class="button is-rounded"
                  (click)="isEditingUsername = true"
                >
                  {{ "invite-client.change-username" | translate }}
                </button>
              </ng-container>
              <ng-container
                *ngIf="!currentUser.profile.username && !isEditingUsername"
              >
                <p>{{ "invite-client.specify-username" | translate }}</p>
                <button
                  class="button is-rounded"
                  (click)="isEditingUsername = true"
                >
                  {{ "invite-client.set-username" | translate }}
                </button>
              </ng-container>
              <ng-container *ngIf="isEditingUsername">
                <span class="label">{{ "common.username" | translate }}</span>
                <input
                  (change)="updateUsername()"
                  type="text"
                  name="editUserName"
                  [(ngModel)]="currentUser.profile.username"
                  class="input"
                />
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="
            currentUser.type == 'Therapist' &&
            !currentUser.profile.profile_approved
          "
        >
          <h3 class="modal-title">{{ "invite-client.sorry" | translate }}</h3>
          <p>{{ "invite-client.need-approved-profile" | translate }}</p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
