import { Injectable } from '@angular/core';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private http: GenericHttpService) {}

  startStripeCheckout(plan_id: any, coupon: string) {
    return this.http.post('/subscription/start-checkout', {
      plan_id: plan_id,
      coupon: coupon,
    });
  }

  verifyStripeCheckoutStatus(coupon: string) {
    return this.http.post('/subscription/verify-checkout', {
      coupon: coupon,
      subscription: null,
    });
  }

  startUpdatingCard() {
    this.http.get('/subscription/update-payment').subscribe((response) => {
      window.location.href = response.url;
    });
  }

  previewUpgrade(plan_id: string) {
    return this.http.get('/subscription/preview-upgrade?plan_id=' + plan_id);
  }

  performUpgrade(plan_id: string) {
    return this.http.post('/subscription/upgrade-now', {
      plan_id,
    });
  }
  schedulePlanChangeAtEndOfBillingCycle(plan_id: string) {
    return this.http.post('/subscription/schedule-plan-change', {
      plan_id,
    });
  }
}
