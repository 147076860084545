import { Profile } from './profile.model';
import { Client } from './client.model';
import { InvoiceItem } from './invoice-item.model';
import { InvoiceClient } from './invoice-client.model';

export class Invoice {
  id: number;
  therapist: Profile;
  profile: Profile;
  client: Client;
  client_email: string;
  client_stripe_id: string;
  status: string;
  invoice_type: string;
  invoice_items: InvoiceItem[] = [];
  client_secret: string;
  total: number;
  delivery: Date[];
  date: Date;
  created_at: Date;
  stripe: any;
  application_fee: number;
  stripe_fee: number;
  fee_rate: number;
  invoiceClient: InvoiceClient;
  business_profile: any;
  stripe_invoice_number: string;
}
