import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OfficeDashboardComponent } from './office-dashboard/office-dashboard.component';
import { OfficeSetupComponent } from './office-account-settings/office-setup.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import { OfficeComponent } from './office/office.component';
import { AuthCanActivate } from '../auth/service/i-c-auth.service';
import { OfficeConnectComponent } from './office-payouts/office-connect.component';

const routes: Routes = [
  {
    path: 'office',
    component: OfficeComponent,
    children: [
      {
        path: 'dashboard',
        component: OfficeDashboardComponent,
        canActivate: AuthCanActivate,
      },
      {
        path: 'invoices',
        component: InvoiceListComponent,
        canActivate: AuthCanActivate,
      },
      {
        path: 'connect',
        component: OfficeConnectComponent,
        canActivate: AuthCanActivate,
      },
      {
        path: 'invoices/:id',
        component: InvoiceDetailComponent,
        canActivate: AuthCanActivate,
      },
      {
        path: 'settings',
        component: OfficeSetupComponent,
        canActivate: AuthCanActivate,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OfficeRoutingModule {}
