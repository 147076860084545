import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../entities/user.model';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { LeanConversationUser } from '../../../entities/lean-conversation-user.model';

@Component({
  selector: 'app-eap-client-banner',
  templateUrl: './eap-client-banner.component.html',
  styleUrls: ['./eap-client-banner.component.scss'],
  standalone: true,
  imports: [TranslateModule, NgIf],
})
export class EapClientBannerComponent {
  @Input() currentUser: User;
  @Input() otherUser: LeanConversationUser;
  constructor() {}
}
