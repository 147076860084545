import { Component, Input, OnInit } from '@angular/core';
import { VideoService } from 'src/app/shared/video/video.service';
import { Appointment } from '../../../../entities/appointment.model';
import { AmplitudeAnalyticsService } from '../../../services/analytics/amplitude-analytics.service';

@Component({
  selector: 'shared-call-button',
  templateUrl: './call-button.component.html',
  styleUrls: ['./call-button.component.css'],
  standalone: true,
})
export class CallButtonComponent implements OnInit {
  @Input()
  appointment: Appointment;

  constructor(
    private videoService: VideoService,
    private analytics: AmplitudeAnalyticsService
  ) {}

  ngOnInit() {}

  joinCall() {
    this.analytics.trackVideoCallInitiated({
      source_page: 'dashboard',
      kind: 'scheduled_private_call',
    });
    this.videoService.navigateToGetstreamVideoCall(
      this.videoService.generateAppointmentCallSlug(
        this.appointment,
        this.appointment.profile.id
      )
    );
  }
}
