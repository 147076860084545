<div
  class="navbar-item"
  *ngIf="currentUser.profile.stripe_connect_active && amounts"
>
  <div class="navbar-link amount is-arrowless" (click)="toggleDropDown()">
    <span class="icon icon-graph-up-arrow"></span>
    <div>
      <span class="is-hidden-touch"
        >{{ "office.navstats.income_short" | translate }} {{ thismonth }}</span
      >
      <strong>{{
        amounts.this_period.total | currency : currentUser.profile.currency
      }}</strong>
    </div>
  </div>
  <div
    class="navbar-dropdown is-hidden-touch"
    [ngClass]="{ 'is-actived': isDropdownVisible }"
  >
    <h4 class="title is-5">{{ "office.navstats.income" | translate }}</h4>
    <div class="columns">
      <div class="column">
        <p class="heading">{{ lastmonth }}</p>
        <p>
          <strong>{{
            amounts.last_period.total | currency : currentUser.profile.currency
          }}</strong>
        </p>
      </div>
      <div class="column">
        <p class="heading">{{ thismonth }}</p>
        <p>
          <strong>{{
            amounts.this_period.total | currency : currentUser.profile.currency
          }}</strong>
        </p>
      </div>
    </div>
    <button
      (click)="toggleDropDown()"
      [routerLink]="['/office/invoices']"
      class="button is-rounded is-small is-fullwidth"
    >
      {{ "office.navstats.see_all_invoices" | translate }}
    </button>
  </div>
</div>
