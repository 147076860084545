<div class="modal" [ngClass]="{ 'is-active': showModal }">
  <div class="modal-background"></div>
  <div class="modal-content">
    <a class="delete" (click)="closeModal()"></a>
    <h3 class="modal-title">{{ "office.payout_modal.title" | translate }}</h3>
    <ng-container *ngIf="!stripeConnectAccount || !balances">
      <shared-loading-animation></shared-loading-animation>
    </ng-container>
    <ng-container *ngIf="stripeConnectAccount && balances">
      <div class="field">
        <label class="label">{{
          "office.payout_modal.label_balance" | translate
        }}</label>
        <div class="control">
          <div class="select is-fullwidth is-rounded">
            <select class="for-control" [(ngModel)]="selected_payout">
              <option
                *ngFor="let balance of balances | keyvalue"
                [value]="balance.key"
              >
                {{
                  balance.value.available / 100
                    | currency : balance.key.toUpperCase()
                }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">{{
          "office.payout_modal.label_account" | translate
        }}</label>
        <div class="control">
          <div class="select is-fullwidth is-rounded">
            <select class="for-control" [(ngModel)]="destination">
              <option *ngFor="let account of bankaccounts" [value]="account.id">
                **** {{ account.last4 }} ({{ account.bank_name }}) -
                {{ account.currency | uppercase }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="content">
        <p>{{ "office.payout_modal.text" | translate }}</p>
      </div>
      <div class="buttons">
        <button class="button is-outlined is-rounded" (click)="closeModal()">
          {{ "common.cancel" | translate }}
        </button>
        <button
          class="button is-primary is-rounded"
          [ngClass]="{ 'is-loading': isRequestingPayout }"
          (click)="requestPayout()"
          [disabled]="!selected_payout"
        >
          {{ "office.payout_modal.confirm" | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</div>
