<section class="dashboard-teaser">
  <header>
    <h3 class="dashboard-teaser-title">
      {{ "office.connect.title" | translate }}
    </h3>
  </header>
  <div class="card">
    <div class="card-content">
      <div class="card" *ngIf="!unableToGetSecret">
        <div class="is-flex is-flex-direction-column flex-gap-20">
        <mat-form-field>
          <mat-select [(value)]="selectedComponent">
            <mat-option [value]="'stripe-connect-payments'">Payments</mat-option>
            <mat-option [value]="'stripe-connect-notification-banner'">Notification Banner (for account restrictions and such)</mat-option>
            <mat-option [value]="'stripe-connect-payouts'">Payouts</mat-option>
            <mat-option [value]="'stripe-connect-documents'">Documents (from account verification, etc.)</mat-option>
          </mat-select>
        </mat-form-field>
        <stripe-connect-payments *ngIf="selectedComponent === 'stripe-connect-payments'"></stripe-connect-payments>
        <stripe-connect-notification-banner *ngIf="selectedComponent === 'stripe-connect-notification-banner'"></stripe-connect-notification-banner>
        <stripe-connect-payouts *ngIf="selectedComponent === 'stripe-connect-payouts'"></stripe-connect-payouts>
        <stripe-connect-documents *ngIf="selectedComponent === 'stripe-connect-documents'"></stripe-connect-documents>
      </div>
      </div>
      <ng-container *ngIf="unableToGetSecret">
        {{ "office.connect.unable_to_show_message" | translate }}
      </ng-container>
    </div>
  </div>
</section>
