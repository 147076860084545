<h2 mat-dialog-title>{{ "booking.cancel_confirm.title" | translate }}</h2>
<mat-dialog-content>
  <p>{{ "booking.cancel_confirm.text" | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ "booking.cancel_confirm.cancel" | translate }}
  </button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
    {{ "booking.cancel_confirm.confirm" | translate }}
  </button>
</mat-dialog-actions>
