import { User } from './../../../entities/user.model';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  AttachmentService,
  CustomAttachmentUploadContext,
  CustomTemplatesService,
  StreamChatModule,
} from 'stream-chat-angular';
import { v4 as uuidv4 } from 'uuid';
import { BookingFormContainerComponent } from '../../booking/booking-form-container/booking-form-container.component';

@Component({
  selector: 'app-stream-message-input',
  templateUrl: './stream-message-input.component.html',
  styleUrls: ['./stream-message-input.component.scss'],
  standalone: true,
  providers: [AttachmentService],
  imports: [
    NgIf,
    StreamChatModule,
    TranslateModule,
    BookingFormContainerComponent,
  ],
})
export class StreamMessageInputComponent implements OnInit, AfterViewInit {
  @Input() conversationArchived = false;
  @Output() readonly valueChange = new EventEmitter<string>();
  @Output() readonly send = new EventEmitter<void>();
  @Output() readonly onCreateBooking = new EventEmitter<void>();
  @Output() readonly onCreateInvoice = new EventEmitter<void>();
  @Output() readonly onArchiveConversation = new EventEmitter<void>();
  @Output() readonly onUnarchiveConversation = new EventEmitter<void>();

  @ViewChild('customAttachmentUpload')
  private customAttachmentUploadContextTemplate!: TemplateRef<CustomAttachmentUploadContext>;

  fileInputId = uuidv4();
  @ViewChild('fileInput') private fileInput!: ElementRef<HTMLInputElement>;

  public currentUser: User;

  constructor(
    private sharedService: SharedService,
    private customTemplateService: CustomTemplatesService
  ) {}

  ngOnInit(): void {
    this.sharedService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });
  }

  ngAfterViewInit(): void {
    this.customTemplateService.customAttachmentUploadTemplate$.next(
      this.customAttachmentUploadContextTemplate
    );
  }

  async filesSelected(
    fileList: FileList | null,
    attachmentService: AttachmentService
  ) {
    await attachmentService.filesSelected(fileList);
    this.clearFileInput();
  }

  private clearFileInput() {
    this.fileInput.nativeElement.value = '';
  }
}
