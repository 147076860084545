<button class="button is-rounded" (click)="requestPayout()">
  <div class="icon">
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          id="Request-payout-button"
          transform="translate(-16.000000, -6.000000)"
          fill="#74D0BF"
        >
          <g id="Request-payout-icon">
            <g transform="translate(16.000000, 6.000000)">
              <path
                d="M10.486,14.886 L10.486,16.3 L8.998,16.3 L8.998,14.824 C7.358,14.48 6.31,13.216 6.325,11.851 L7.974,11.851 C7.974,12.724 8.717,13.532 9.945,13.532 C11.076,13.532 11.722,12.837 11.722,12.013 C11.722,11.318 11.222,10.817 10.187,10.542 L8.911,10.219 C6.746,9.67 6.39,8.409 6.39,7.569 C6.39,6.103 7.543,5.059 8.998,4.77 L8.998,3.393 L10.486,3.393 L10.486,4.755 C12.039,5.022 13.063,6.166 13.047,7.698 L11.399,7.698 C11.416,6.777 10.737,6.115 9.751,6.115 C8.765,6.115 8.054,6.729 8.054,7.553 C8.054,7.876 8.151,8.539 9.331,8.813 L10.591,9.12 C12.32,9.557 13.387,10.51 13.387,11.948 C13.387,13.443 12.233,14.676 10.486,14.886 M9.856,5.32907052e-15 C4.413,5.32907052e-15 2.20268248e-13,4.412 2.20268248e-13,9.855 C2.20268248e-13,15.299 4.413,19.711 9.856,19.711 C15.299,19.711 19.712,15.299 19.712,9.855 C19.712,4.412 15.299,5.32907052e-15 9.856,5.32907052e-15"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
  <span class="is-hidden-mobile">
    {{ "office.actions.request_payout" | translate }}
  </span>
</button>
