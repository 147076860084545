<div class="modal modal-fx-slideBottom" [ngClass]="{ 'is-active': showModal }">
  <div class="modal-background" (click)="hideModal()"></div>
  <div class="modal-content">
    <h3 class="subtitle is-4">Invite a colleague</h3>
    <i class="delete" (click)="hideModal()">close</i>
    <div class="field">
      <label class="label">Email</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Email"
          [(ngModel)]="email"
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Message</label>
      <div class="control">
        <textarea class="textarea" autosize [(ngModel)]="message"></textarea>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <button
          class="button is-primary is-rounded"
          (click)="sendInvite()"
          [ngClass]="{ 'is-loading': isBusy }"
        >
          Send Invite
        </button>
      </div>
    </div>
  </div>
</div>
