import { Injectable } from '@angular/core';
import { GenericHttpService } from '../../../shared/services/Generic HTTP/generic-http.service';
import { Observable, throwError } from 'rxjs';
import { User } from '../../../entities/user.model';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  constructor(private httpService: GenericHttpService) {}
  private favoritesUrl = '/favorites';

  getFavorites(): Observable<User[]> {
    return this.httpService.get(this.favoritesUrl);
  }

  addFavorite(therapistUserId: number): Observable<any> {
    return this.httpService
      .post(`${this.favoritesUrl}`, { favoritee_id: therapistUserId })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          MonitoringService.captureException(error);
          return throwError(error);
        })
      );
  }

  removeFavorite(therapistUserId: number): Observable<any> {
    const url = `/favorites/favoritees/${therapistUserId}`;
    return this.httpService.delete(url).pipe(
      catchError((error: HttpErrorResponse) => {
        MonitoringService.captureException(error);
        return throwError(error);
      })
    );
  }
}
