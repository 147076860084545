import { Component, OnInit } from '@angular/core';
import { ICAuthService } from '../service/i-c-auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  standalone: true,
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: ICAuthService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Record<string, any>) => {
      this.authService.goToLogin({ ...params }).subscribe();
    });
  }
}
