import { Component, OnInit } from '@angular/core';
import { OfficeService } from '../shared/service/office.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
declare let StripeConnect: any;

@Component({
  selector: 'app-office-connect',
  templateUrl: './office-connect.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    NgIf,
    FormsModule,
    MatFormField,
    MatOption,
    MatSelect,
  ],
})
export class OfficeConnectComponent implements OnInit {
  unableToGetSecret: boolean = false;
  // default to payments
  selectedComponent: string = 'stripe-connect-payments';

  constructor(private officeService: OfficeService) {}
  async ngOnInit() {
    this.loadStripeConnectScript();
    this.officeService.stripeConnectClientSecret.subscribe((secret) => {
      if (secret === undefined) {
        // waiting for initial value
        this.unableToGetSecret = false;
      } else if (secret === null) {
        this.unableToGetSecret = true;
      } else if (secret) {
        this.unableToGetSecret = false;
        this.initializeStripeConnect(secret);
      }
    });
  }
  private loadStripeConnectScript() {
    const script = document.createElement('script');
    script.src = 'https://connect-js.stripe.com/v0.1/connect.js';
    script.async = true;
    document.body.appendChild(script);
  }

  initializeStripeConnect(clientSecret: string) {
    StripeConnect.onLoad = () => {
      StripeConnect.init({
        publishableKey: 'pk_test_zmbUixrm6MkRC75rCewcnBfk',
        clientSecret,
        appearance: {
          colors: {
            primary: '#74d0bf',
          },
        },
        refreshClientSecret: async () => {
          return await this.officeService
            .fetchStripeConnectClientSecret()
            .toPromise();
        },
      });
    };
  }
}
