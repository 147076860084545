<div class="onboarding-progress" [ngClass]="{ embedded: embedded }">
  <div class="steps">
    <div
      class="step-item"
      [ngClass]="{
        'is-active': onboardingStep == ONBOARDINGSTEP.fill_out_profile,
        'is-completed': onboardingStep > 0
      }"
    >
      <div class="step-marker">
        <span *ngIf="onboardingStep > 0">
          <span class="icon"><i class="material-icons lightgray">done</i></span>
        </span>
        <span *ngIf="onboardingStep == 0"> 1 </span>
      </div>
      <div class="step-details" [ngClass]="{ 'is-hidden-touch': embedded }">
        {{ "onboarding.steps.fill_out_profile" | translate }}
      </div>
    </div>
    <div
      class="step-item"
      [ngClass]="{
        'is-active': onboardingStep == ONBOARDINGSTEP.subscribing,
        'is-completed': onboardingStep > 1
      }"
    >
      <div class="step-marker">
        <span *ngIf="onboardingStep > 1">
          <span class="icon"><i class="material-icons lightgray">done</i></span>
        </span>
        <span *ngIf="onboardingStep == 1"> 2 </span>
      </div>
      <div class="step-details" [ngClass]="{ 'is-hidden-touch': embedded }">
        {{ "onboarding.steps.subscribing" | translate }}
      </div>
    </div>
    <div
      class="step-item"
      [ngClass]="{
        'is-active': onboardingStep == ONBOARDINGSTEP.waiting_for_approval,
        'is-completed': onboardingStep > 2
      }"
    >
      <div class="step-marker">
        <span *ngIf="onboardingStep > 2">
          <span class="icon"><i class="material-icons lightgray">done</i></span>
        </span>
        <span *ngIf="onboardingStep == 2"> 3 </span>
      </div>
      <div class="step-details" [ngClass]="{ 'is-hidden-touch': embedded }">
        {{ "onboarding.steps.waiting_for_approval" | translate }}
      </div>
    </div>
    <div
      class="step-item"
      [ngClass]="{ 'is-active': onboardingStep == ONBOARDINGSTEP.done }"
    >
      <div class="step-marker">
        <span *ngIf="onboardingStep > 3">
          <span class="icon"><i class="material-icons lightgray">done</i></span>
        </span>
        <span *ngIf="onboardingStep == 3"> 4 </span>
      </div>
      <div class="step-details" [ngClass]="{ 'is-hidden-touch': embedded }">
        {{ "onboarding.steps.done" | translate }}
      </div>
    </div>
  </div>
</div>
