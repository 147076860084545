import { Component, Input, OnInit } from '@angular/core';
import {
  Event,
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { User } from 'src/app/entities/user.model';
import { TranslateModule } from '@ngx-translate/core';
import { OfficeBalanceComponent } from '../office-balance/office-balance.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'office-navigation',
  templateUrl: './office-navigation.component.html',
  styleUrls: ['./office-navigation.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterLinkActive,
    RouterLink,
    OfficeBalanceComponent,
    TranslateModule,
  ],
})
export class OfficeNavigationComponent implements OnInit {
  constructor(private router: Router) {}

  public shouldShowBalance: boolean = false;
  public shouldShowAccountStatus: boolean = false;
  public shouldShowNavigation: boolean = false;
  @Input()
  set currentUser(user: User) {
    this.shouldShowNavigation = !!user.profile.stripe_connect_id;
    this.user = user;
  }
  user: User;

  @Input() showStripeConnectEmbeddedUIs: boolean;

  ngOnInit() {
    this.shouldShowBalance = window.location.pathname === '/office/dashboard';
    this.shouldShowAccountStatus =
      window.location.pathname === '/office/settings';

    this.router.events.subscribe((e: Event) => {
      if (e instanceof NavigationEnd) {
        this.shouldShowBalance = e.url === '/office/dashboard';
        this.shouldShowAccountStatus = e.url === '/office/settings';
      }
    });
  }
}
