<div
  class="modal modal-fx-slideBottom"
  [ngClass]="{ 'is-active': isShowingModal }"
>
  <div class="modal-background" (click)="isShowingModal = false"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <div>
        <h4
          class="modal-card-title"
          *ngIf="!!editingAppointment && !!editingAppointment.id; else newEvent"
        >
          {{ "messaging.booking-modal.update-appointment" | translate }}
        </h4>
        <ng-template #newEvent>
          <h4 class="modal-card-title" *ngIf="!!preselectedClient">
            {{ "messaging.booking-modal.schedule-appointment" | translate }}
          </h4>
          <h4
            class="modal-card-title"
            *ngIf="requestBookingForm && !preselectedClient"
          >
            {{ "messaging.booking-modal.create-event" | translate }}
          </h4>
        </ng-template>

        <button
          class="delete"
          aria-label="close"
          (click)="closeModal()"
        ></button>
      </div>
    </header>

    <ng-container *ngIf="isReady" [ngSwitch]="currentStep$ | async">
      <form [formGroup]="this.requestBookingForm" class="outer-form">
        <app-booking-form-select-client
          *ngSwitchCase="'selectClient'"
          [requestBookingForm]="requestBookingForm"
          [onNext]="this.onClientSelected.bind(this)"
          [onCreateBlocker]="this.onCreateBlocker.bind(this)"
          [onCancel]="this.onCancel.bind(this)"
          [clients]="this.clients"
        ></app-booking-form-select-client>
        <app-booking-form-select-date
          *ngSwitchCase="'selectDate'"
          [requestBookingForm]="requestBookingForm"
          [defaultAppointmentDuration]="profile.appointment_duration"
          [onNext]="this.onDateSelected.bind(this)"
          [onCancel]="this.onCancel.bind(this)"
        ></app-booking-form-select-date>
        <app-booking-form-blocker-summary
          *ngSwitchCase="'createBlocker'"
          [requestBookingForm]="requestBookingForm"
          [timezoneText]="timezoneText"
          [onChangeDate]="this.onChangeDate.bind(this)"
          [onSubmit]="this.submitForm.bind(this)"
          [onCancel]="this.onCancel.bind(this)"
        ></app-booking-form-blocker-summary>
        <app-booking-form-details
          *ngSwitchCase="'createAppointment'"
          [requestBookingForm]="requestBookingForm"
          [onChangeClient]="this.onChangeClient.bind(this)"
          [onChangeDate]="this.onChangeDate.bind(this)"
          [canUseInvoicing]="this.profile.stripe_connect_active"
          [timezoneText]="this.timezoneText"
          [showClient]="!this.preselectedClient"
          [showDuration]="this.skipDateTimeSelection"
          [currency]="this.currentUser.profile.currency"
          [onSubmit]="this.submitForm.bind(this)"
          [onCancel]="this.onCancel.bind(this)"
          [isBusy]="isBusy"
          [isEAPTherapist]="this.isEAPTherapist"
          [isEditingEAPSession]="this.isEditingEAPSession"
          [actionType]="this.submitActionType"
        ></app-booking-form-details>
      </form>
    </ng-container>
    <div
      *ngIf="!isReady"
      class="container is-flex is-justify-content-center is-align-items-center"
    >
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
  </div>
</div>
