import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from '../service/subscription.service';
import { LoadingAnimationComponent } from '../../../shared/components/loading-animation/loading-animation.component';

@Component({
  selector: 'app-subscription-callback',
  templateUrl: './subscription-callback.component.html',
  styleUrls: ['./subscription-callback.component.css'],
  standalone: true,
  imports: [LoadingAnimationComponent],
})
export class SubscriptionCallbackComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    const coupon = this.route.snapshot.queryParams.coupon || null;
    this.subscriptionService
      .verifyStripeCheckoutStatus(coupon)
      .subscribe((reponse) => {
        this.router.navigate(['/home/settings/subscription']);
      });
  }
}
