<div class="dashboard-teaser">
  <header>
    <div class="columns is-mobile align-center">
      <div class="column statistics-title">
        <h3 class="dashboard-teaser-title">
          {{ "statistics.title" | translate }}
        </h3>
        <div
          class="qmarkCircle tooltip is-tooltip-multiline"
          data-tooltip="Here you can observe how many views has your profile got, how many people have seen your profile contacted you in a specific time frame."
        ></div>
      </div>
      <div class="column has-text-right">
        <app-date-range-picker
          (onDateRangeSelected)="onDatePickerChange($event)"
        ></app-date-range-picker>
      </div>
    </div>
  </header>

  <div class="card chart-container">
    <ng-container *ngIf="chartData">
      <div class="stat-tabs">
        <div
          class="stat-tab"
          *ngFor="let data of chartData.datasets; let i = index"
          [ngClass]="{ 'is-selected': !data.hidden }"
          (click)="toggleStat(data)"
        >
          <p class="stat-dimension">
            {{ "statistics." + data.label | translate }}
          </p>
          <h3 class="stat-value">{{ totals[data.label] }}</h3>
          <small class="stat-trend">
            <ng-container *ngIf="trend[data.label] < 0">
              <svg
                width="9px"
                height="13px"
                viewBox="0 0 9 13"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="-"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="My-dashboard---Initial-view-(Therapists)-NEW"
                    transform="translate(-893.000000, -2292.000000)"
                    fill="#EDB2A4"
                  >
                    <g id="Page">
                      <g
                        id="Profile-reach"
                        transform="translate(521.000000, 2165.000000)"
                      >
                        <g
                          id="Statistics-overview"
                          transform="translate(1.000000, 45.000000)"
                        >
                          <g
                            id="Menu"
                            transform="translate(20.000000, 0.000000)"
                          >
                            <g
                              id="New-referrals"
                              transform="translate(327.000000, 0.000000)"
                            >
                              <g
                                id="Percentage"
                                transform="translate(24.000000, 82.000000)"
                              >
                                <polygon
                                  id="Up-arrow"
                                  transform="translate(4.339500, 6.308000) rotate(-180.000000) translate(-4.339500, -6.308000) "
                                  points="4.339 0 0 5.273 2.236 5.273 2.236 12.616 6.444 12.616 6.444 5.273 8.679 5.273"
                                ></polygon>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </ng-container>
            <ng-container *ngIf="trend[data.label] > 0">
              <svg
                width="9px"
                height="13px"
                viewBox="0 0 9 13"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="-"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="My-dashboard---Initial-view-(Therapists)-NEW"
                    transform="translate(-542.000000, -2291.000000)"
                    fill="#74D0BF"
                  >
                    <g id="Page">
                      <g
                        id="Profile-reach"
                        transform="translate(521.000000, 2165.000000)"
                      >
                        <g
                          id="Statistics-overview"
                          transform="translate(1.000000, 45.000000)"
                        >
                          <g
                            id="Menu"
                            transform="translate(20.000000, 0.000000)"
                          >
                            <g
                              id="Profile-views"
                              transform="translate(0.000000, 13.000000)"
                            >
                              <g
                                id="Percentage"
                                transform="translate(0.000000, 68.000000)"
                              >
                                <polygon
                                  id="Up-arrow"
                                  points="4.339 -7.10542736e-15 4.35207426e-14 5.273 2.236 5.273 2.236 12.616 6.444 12.616 6.444 5.273 8.679 5.273"
                                ></polygon>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </ng-container>
            {{ trend[data.label] | percent }}
          </small>
        </div>
      </div>
      <div
        style="display: block; position: relative; padding: 1rem 1rem 2rem"
        *ngIf="!!chartData"
      >
          <canvas
            baseChart
            [data]="chartData"
            [options]="lineChartOptions"
            [type]="lineChartType"
          ></canvas>

      </div>
    </ng-container>
    <div class="card-content" *ngIf="!chartData">
      <shared-loading-animation></shared-loading-animation>
    </div>
    <div
      class="overlay"
      *ngIf="
        user.profile.profile_approved && !user.abilities.can_view_statistics
      "
    >
      <div class="box content">
        <div class="inner-content">
          <p>{{ "statistics.upgrade.content" | translate }}</p>
        </div>
        <div class="inner-content content-btn">
          <button
            class="button is-primary is-rounded"
            [routerLink]="['/home/settings/subscription']"
          >
            {{ "statistics.upgrade.cta" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="overlay" *ngIf="!user.profile.profile_approved">
      <div class="box content">
        <p>{{ "statistics.overlay.content" | translate }}</p>
      </div>
    </div>
  </div>
</div>
