<div
  class="unbilled-appointment"
  [ngClass]="{ 'is-selected': _item.selected }"
  *ngIf="!isHidden"
>
  <p *ngIf="isAppointment">
    <span *ngIf="_item.is_online">Online</span>
    <span *ngIf="!_item.is_online">In-Person</span> Appointment
    <br />
    <i class="icon">
      <svg
        width="18px"
        height="19px"
        viewBox="0 0 18 19"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Symbols"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Dashboard-menu-(Therapists)"
            transform="translate(-23.000000, -614.000000)"
            fill="#5D6872"
          >
            <g id="Menu" transform="translate(0.000000, 389.000000)">
              <g id="Other-menu" transform="translate(23.000000, 219.000000)">
                <g id="Calendar-icon" transform="translate(0.000000, 6.000000)">
                  <g>
                    <polygon
                      id="Fill-3"
                      points="3.68807727 11.7285668 6.16999091 11.7285668 6.16999091 9.24665318 3.68807727 9.24665318"
                    ></polygon>
                    <polygon
                      id="Fill-4"
                      points="7.49299091 11.7285668 9.97490455 11.7285668 9.97490455 9.24665318 7.49299091 9.24665318"
                    ></polygon>
                    <polygon
                      id="Fill-5"
                      points="11.2979045 11.7285668 13.7798182 11.7285668 13.7798182 9.24665318 11.2979045 9.24665318"
                    ></polygon>
                    <polygon
                      id="Fill-6"
                      points="3.68807727 15.3676759 6.16999091 15.3676759 6.16999091 12.8866214 3.68807727 12.8866214"
                    ></polygon>
                    <polygon
                      id="Fill-7"
                      points="7.49299091 15.3676759 9.97490455 15.3676759 9.97490455 12.8866214 7.49299091 12.8866214"
                    ></polygon>
                    <polygon
                      id="Fill-8"
                      points="11.2979045 15.3676759 13.7798182 15.3676759 13.7798182 12.8866214 11.2979045 12.8866214"
                    ></polygon>
                    <path
                      d="M1.64687727,17.0746895 L15.8218773,17.0746895 L15.8218773,7.62468955 L1.64687727,7.62468955 L1.64687727,17.0746895 Z M16.2505636,2.61361227 L14.4791182,2.61361227 L14.4791182,1.21758955 C14.4791182,0.547498636 13.9310182,0.000257727273 13.2617864,0.000257727273 L13.2265636,0.000257727273 C12.5573318,0.000257727273 12.0092318,0.547498636 12.0092318,1.21758955 L12.0092318,2.61361227 L5.45866364,2.61361227 L5.45866364,1.21758955 C5.45866364,0.547498636 4.91056364,0.000257727273 4.24133182,0.000257727273 L4.20610909,0.000257727273 C3.53687727,0.000257727273 2.98877727,0.547498636 2.98877727,1.21758955 L2.98877727,2.61361227 L1.21819091,2.61361227 C0.547240909,2.61361227 0,3.16085318 0,3.83094409 L0,17.4320714 C0,18.1013032 0.547240909,18.6494032 1.21819091,18.6494032 L16.2505636,18.6494032 C16.9206545,18.6494032 17.4687545,18.1013032 17.4687545,17.4320714 L17.4687545,3.83094409 C17.4687545,3.16085318 16.9206545,2.61361227 16.2505636,2.61361227 Z"
                      id="Fill-1"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </i>
    <span>{{ _item.start | date }} at {{ _item.start | date : "h:mm a" }}</span>
  </p>
  <p *ngIf="!isAppointment">
    Online Video Call
    <br />
    <i class="icon">
      <svg
        width="18px"
        height="19px"
        viewBox="0 0 18 19"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Symbols"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Dashboard-menu-(Therapists)"
            transform="translate(-23.000000, -614.000000)"
            fill="#5D6872"
          >
            <g id="Menu" transform="translate(0.000000, 389.000000)">
              <g id="Other-menu" transform="translate(23.000000, 219.000000)">
                <g id="Calendar-icon" transform="translate(0.000000, 6.000000)">
                  <g>
                    <polygon
                      id="Fill-3"
                      points="3.68807727 11.7285668 6.16999091 11.7285668 6.16999091 9.24665318 3.68807727 9.24665318"
                    ></polygon>
                    <polygon
                      id="Fill-4"
                      points="7.49299091 11.7285668 9.97490455 11.7285668 9.97490455 9.24665318 7.49299091 9.24665318"
                    ></polygon>
                    <polygon
                      id="Fill-5"
                      points="11.2979045 11.7285668 13.7798182 11.7285668 13.7798182 9.24665318 11.2979045 9.24665318"
                    ></polygon>
                    <polygon
                      id="Fill-6"
                      points="3.68807727 15.3676759 6.16999091 15.3676759 6.16999091 12.8866214 3.68807727 12.8866214"
                    ></polygon>
                    <polygon
                      id="Fill-7"
                      points="7.49299091 15.3676759 9.97490455 15.3676759 9.97490455 12.8866214 7.49299091 12.8866214"
                    ></polygon>
                    <polygon
                      id="Fill-8"
                      points="11.2979045 15.3676759 13.7798182 15.3676759 13.7798182 12.8866214 11.2979045 12.8866214"
                    ></polygon>
                    <path
                      d="M1.64687727,17.0746895 L15.8218773,17.0746895 L15.8218773,7.62468955 L1.64687727,7.62468955 L1.64687727,17.0746895 Z M16.2505636,2.61361227 L14.4791182,2.61361227 L14.4791182,1.21758955 C14.4791182,0.547498636 13.9310182,0.000257727273 13.2617864,0.000257727273 L13.2265636,0.000257727273 C12.5573318,0.000257727273 12.0092318,0.547498636 12.0092318,1.21758955 L12.0092318,2.61361227 L5.45866364,2.61361227 L5.45866364,1.21758955 C5.45866364,0.547498636 4.91056364,0.000257727273 4.24133182,0.000257727273 L4.20610909,0.000257727273 C3.53687727,0.000257727273 2.98877727,0.547498636 2.98877727,1.21758955 L2.98877727,2.61361227 L1.21819091,2.61361227 C0.547240909,2.61361227 0,3.16085318 0,3.83094409 L0,17.4320714 C0,18.1013032 0.547240909,18.6494032 1.21819091,18.6494032 L16.2505636,18.6494032 C16.9206545,18.6494032 17.4687545,18.1013032 17.4687545,17.4320714 L17.4687545,3.83094409 C17.4687545,3.16085318 16.9206545,2.61361227 16.2505636,2.61361227 Z"
                      id="Fill-1"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </i>
    <span
      >{{ _item.created_at | date }} at
      {{ _item.created_at | date : "h:mm a" }}</span
    >
  </p>
  <ng-container *ngIf="!isIgnoring">
    <button class="delete" (click)="isIgnoring = true"></button>
    <button
      class="button toggle is-primary is-rounded"
      (click)="toggle()"
    ></button>
  </ng-container>
  <ng-container *ngIf="isIgnoring">
    <div>
      <p>Ignore in billing?</p>
      <button class="button is-rounded" (click)="isIgnoring = false">
        cancel
      </button>
      <button class="button is-primary is-rounded" (click)="ignore()">
        yes
      </button>
    </div>
  </ng-container>
</div>
