import { Category } from './category.model';
import { Language } from './language.model';
import { City } from './ciy.model';
import { SessionOffer } from './session-offer.model';

export class Profile {
  id: number;
  slug: string;
  link: string;
  full_name: string;
  username: string;
  jobtitle: string;
  gender: string;
  identities: string[];
  firstname: string;
  lastname: string;
  professional_statement: string;
  categories: Category[];
  website: string;
  address: string;
  lat: number;
  lng: number;
  city: City;
  district: string;
  country: string;
  country_shortcode: string;
  zip: string;
  street: string;
  number: string;
  about: string;
  approach: string;
  education: string;
  experience: string;
  languages: Language[];
  insurances: string[];
  online_supported: boolean;
  in_person_supported: boolean;
  text_supported: boolean;
  month_year_started: Date;
  access: string;
  has_limited_profile: boolean;
  stripe_connect_id: string;
  stripe_connect_active: boolean;
  stripe_connect_has_currently_due_requirements: boolean;
  stripe_connect_restriction_deadline: Date;
  stripe_connect_has_eventually_due_requirements: boolean;
  stripe_connect_has_errors: boolean;
  stripe_connect_payout_interval: string;
  price_per_session: number;
  currency: string;
  appointment_duration: number;
  appointment_break_duration: number;
  min_appointment_notification_time: number;
  session_offers: SessionOffer[];
  default_taxrate: number;
  photos: any[];
  images: any;
  _images: any;
  image: any;
  user: any;
  diplomas: any[];
  profile_approved: boolean;
  profile_completed: boolean;
  calendar_enabled: boolean;
  calendar_feed_enabled: boolean;
  calendar_feed: string;
  eap: boolean;

  availability: string;
  is_available: boolean;
  statusmessage: string;
  statusmessage_updated_at: Date;

  tzname: string;
  timezone: any;
  showJoined: boolean;
  showSessions: boolean;
  showActivity: boolean;
  showResponseTime: boolean;
  ignoreProfile: boolean;
  ignoreCalendar: boolean;
  showPrice: boolean;
  ukraine_only: boolean;

  showIndex: boolean;

  current_secondary_language: string;
  secondary_language_profile_text: SecondaryLanguageProfileText;

  matching_program_status: 'active' | 'paused' | 'not_active';
  eap_program_status: 'active' | 'paused' | 'not_active';
}

export class SecondaryLanguageProfileText {
  jobtitle: string;
  language: string;
  professional_statement: string;
  about: string;
  approach: string;
  education: string;
  experience: string;
}
