import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProfileService } from '../../../components/profile/service/profile.service';
import { Profile } from '../../../entities/profile.model';
import { FormsModule } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, FormsModule],
})
export class DropdownInputComponent implements OnInit {
  constructor(private profileService: ProfileService) {}

  ngOnInit() {
    this.initItems.forEach((item) => {
      this._selectedItems.push(item);
    });

    if (this.key !== 'jobtitle' && this.key !== 'secondary_jobtitle') {
      this._selectedItems = this.profile[this.key];
    }
    if (
      this._selectedItems === null ||
      typeof this._selectedItems == 'undefined'
    ) {
      this._selectedItems = [];
    }
    // this.sort()
  }

  @Input()
  placeholder: string;

  @Input()
  items: any[];

  @Input()
  key: string;

  @Input()
  limit: number = 3;

  @Input()
  error: string = `You can only add 3 items.`;
  showError: boolean = false;

  @Input()
  profile: Profile;

  @Output()
  componentDone: EventEmitter<any> = new EventEmitter();

  @Input()
  initItems: any[] = [];

  public _selectedItems: any[] = [];
  public search: string = '';

  onInputChange(value: string) {
    if (this.key == 'jobtitle') {
      this.profile.jobtitle = value;
    } else if (this.key == 'secondary_jobtitle') {
      this.profile.secondary_language_profile_text.jobtitle = value;
    }
  }

  // onKeyDown(event: KeyboardEvent) {
  //   if (event.code == 'Enter' && this.search !== '' && this.key == 'jobtitle') {
  //     this._selectedItems.push({ name: this.search });
  //     this.update();
  //   }
  // }

  add(item: any) {
    if (this._selectedItems.length < this.limit) {
      this._selectedItems.push(item);
      this.search = '';
      this.update();
    } else {
      this.showError = true;
    }
  }
  remove(item: any) {
    this._selectedItems.splice(this._selectedItems.indexOf(item), 1);
    this.update();
  }

  update() {
    this.showError = false;

    if (this.key == 'jobtitle') {
      this._selectedItems.push({ name: this.search });
      this.profile[this.key] = this._selectedItems[0].name;
      this.componentDone.emit();
    } else if (this.key == 'secondary_jobtitle') {
      this._selectedItems.push({ name: this.search });
      this.profile.secondary_language_profile_text.jobtitle =
        this._selectedItems[0].name;
      this.componentDone.emit();
    } else {
      this.profile[this.key] = this._selectedItems;
      if (this.profile[this.key].length === this.limit) {
        this.componentDone.emit();
      }
    }

    this.profileService.updateItem(this.profile, this.profile.user.id);
  }

  sort() {
    this._selectedItems = this._selectedItems.sort(function (a, b) {
      return a.name > b.name ? -1 : 1;
    });
  }

  shouldDisplay(item) {
    if (!item.name) return;
    var selected = this.initItems.find((initItem) => initItem.id == item.id);
    return (
      (item.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
        this.search == '') &&
      !selected
    );
  }

  onKeyDown(event: KeyboardEvent) {
    // Keydown

    var items_list = document.getElementById('items_list');

    if (event.code == 'ArrowDown') {
      if (!document.getElementsByClassName('highlighted')[0]) {
        if (items_list && items_list.children) {
          document
            .getElementById('items_list')
            .children[0].classList.add('highlighted');
        }
      } else {
        let current = document.getElementsByClassName('highlighted')[0];
        if (current.nextElementSibling) {
          current.classList.remove('highlighted');
          current.nextElementSibling.classList.add('highlighted');
        }
      }
    }

    // Key up
    else if (event.code == 'ArrowUp') {
      let current = document.getElementsByClassName('highlighted')[0];
      if (current.previousElementSibling) {
        current.classList.remove('highlighted');
        current.previousElementSibling.classList.add('highlighted');
      }
    }

    // Enter
    else if (
      event.code == 'Enter' &&
      document.getElementsByClassName('highlighted')[0]
    ) {
      let current = document.getElementsByClassName('highlighted')[0];
      this._selectedItems.push({ name: current.id });
      this.update();
    } else if (
      event.code == 'Enter' &&
      !document.getElementsByClassName('highlighted')[0]
    ) {
      if (this.key == 'jobtitle' || this.key == 'secondary_jobtitle') {
        this._selectedItems.push({ name: this.search });
        this.update();
        this.search = '';
      } else {
        if (items_list) {
          let item = items_list.children[0];
          if (item) {
            this._selectedItems.push({ name: item.id });
          }
        }
        this.update();
        this.search = '';
      }
    }
  }
}
