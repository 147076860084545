<button class="button is-rounded" (click)="createNewInvoice()">
  <div class="icon">
    <svg
      width="15px"
      height="17px"
      viewBox="0 0 15 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          id="Activate-invoicing-button"
          transform="translate(-16.000000, -8.000000)"
          fill="#74D0BF"
        >
          <g id="Invoice-icon">
            <g transform="translate(16.000000, 8.000000)">
              <g>
                <polygon
                  id="Fill-1"
                  points="9.4923 11.5436 9.4923 16.5076 14.4563 11.5436"
                ></polygon>
                <path
                  d="M0,0.0002 L0,16.5072 L8.692,16.5072 L8.692,11.5432 L8.692,10.7432 L9.492,10.7432 L14.456,10.7432 L14.456,0.0002 L0,0.0002 Z M3.459,3.5932 L3.459,3.1472 L10.958,3.1472 L10.958,4.0392 L3.459,4.0392 L3.459,3.5932 Z M3.459,6.7182 L3.459,6.2722 L10.958,6.2722 L10.958,7.1642 L3.459,7.1642 L3.459,6.7182 Z M3.459,9.8432 L3.459,9.3972 L6.375,9.3972 L6.375,9.8432 L6.375,10.2892 L3.459,10.2892 L3.459,9.8432 Z"
                  id="Fill-2"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
  <span class="is-hidden-mobile">
    {{ "office.actions.create_invoice" | translate }}
  </span>
</button>
