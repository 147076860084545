<ng-container *ngIf="currentUser.profile.stripe_connect_id">
  <section class="office-dashboard-section">
    <header>
      <h3>{{ "office.dashboard.unpaid_invoices" | translate }}</h3>
    </header>
    <div class="card">
      <office-invoice-table
        *ngIf="openInvoices"
        [invoices]="openInvoices"
        [showHeader]="false"
        [showFooter]="false"
      ></office-invoice-table>
      <div *ngIf="openInvoices?.length == 0" class="card-content">
        <p>{{ "office.invoice.list.no_open_invoices" | translate }}</p>
      </div>
      <div class="card-footer">
        <a
          class="card-footer-item hover-light-gray"
          [routerLink]="['/office/invoices']"
        >
          <i class="icon icon-invoice"></i>
          {{ "office.dashboard.view_all_invoices" | translate }}
        </a>
      </div>
    </div>
  </section>

  <section class="office-dashboard-section">
    <header>
      <h3>{{ "office.dashboard.payment_history" | translate }}</h3>
    </header>
    <div class="card">
      <ng-container *ngIf="events && events.length > 0">
        <div class="card-content event" *ngFor="let event of events">
          <div class="columns">
            <div class="column is-3">
              <i class="icon icon-calendar"></i
              >{{ event.created | date : "mediumDate" }}
            </div>
            <div
              class="column"
              [innerHTML]="
                'office.events.' + event.type | translate : event.data
              "
            ></div>
          </div>
        </div>
      </ng-container>
      <div class="card-content" *ngIf="!events">
        <shared-loading-animation></shared-loading-animation>
      </div>
      <div class="card-content" *ngIf="events && events.length == 0">
        <p>{{ "office.payment_history_empty" | translate }}</p>
      </div>
    </div>
  </section>
</ng-container>
