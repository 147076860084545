<section class="dashboard-teaser">
  <header class="dashboard-teaser-header">
    <div class="columns is-mobile align-center">
      <div class="column">
        <h3 class="dashboard-teaser-title">
          {{ "common.invoices" | translate }}
        </h3>
      </div>
      <div class="column is-6">
        <input
          *ngIf="invoices?.length > 0"
          type="text"
          [(ngModel)]="filterQuery"
          class="input is-rounded"
          (ngModelChange)="filter()"
          [placeholder]="'office.invoice.list.placeholder' | translate"
        />
      </div>
    </div>
  </header>
  <div class="card">
    <div class="card-content" *ngIf="!invoices">
      <shared-loading-animation></shared-loading-animation>
    </div>
    <ng-container *ngIf="invoices">
      <div class="card-content" *ngIf="invoices.length == 0">
        <p>{{ "office.invoice.list.no_invoices" | translate }}</p>
      </div>
      <ng-container *ngIf="invoices.length > 0 && filteredInvoices">
        <office-invoice-table
          [invoices]="filteredInvoices"
          *ngIf="filteredInvoices.length > 0"
        ></office-invoice-table>
        <div *ngIf="filteredInvoices.length == 0" class="card-content">
          <p>{{ "office.invoice.list.no_match" | translate }}</p>
        </div>
      </ng-container>
    </ng-container>
  </div>
</section>
