import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProfileService } from '../../service/profile.service';
import { Profile } from 'src/app/entities/profile.model';
import { User } from 'src/app/entities/user.model';
import { SlideInOutAnimation } from 'src/app/shared/animations/slideInOut.animation';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-nav-modal',
  templateUrl: './profile-nav-modal.component.html',
  styleUrls: ['./profile-nav-modal.component.scss'],
  animations: [SlideInOutAnimation],
  standalone: true,
  imports: [TranslateModule],
})
export class ProfileNavModalComponent implements OnInit {
  constructor(
    private profileService: ProfileService,
    private sharedService: SharedService
  ) {
    this.profile = new Profile();
  }

  profile: Profile;
  currentUser: User;

  ngOnInit() {
    this.profileService.profile.subscribe((prof) => {
      if (prof) {
        this.profile = prof;
      }
    });
    this.sharedService.currentUser.subscribe(
      (user) => (this.currentUser = user)
    );
  }

  @Input()
  disableNext: boolean;

  @Input()
  content: string;

  @Input()
  hidden: boolean;

  @Output()
  close = new EventEmitter();

  @Output()
  next = new EventEmitter();

  @Output()
  previous = new EventEmitter();

  goToNext = () => {
    this.profileService.updateItem(this.profile, this.profile.user.id);
    this.next.emit(true);
  };

  goToPrevious = () => this.previous.emit();

  closeTourModal = () => this.close.emit();
}
