import { Injectable } from '@angular/core';

import { default as countries } from './countries.json';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private countries = countries;

  constructor() {}

  public getCountries() {
    return this.countries;
  }

  public lookup(code: string) {
    if (!code) {
      return '';
    }
    return this.countries.find(
      (c) => c.code.toLowerCase() == code.toLowerCase()
    );
  }
}
