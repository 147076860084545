<div id="in-app-alerts">
  <div
    [@simpleFadeAnimation]="'in'"
    class="ic-notification {{ cssClass(alert) }}"
    *ngFor="let alert of alerts"
  >
    <button class="delete" (click)="removeAlert(alert)"></button>
    <p *ngIf="alert.title">
      <strong>{{ alert.title | translate }}</strong>
    </p>
    <p>{{ alert.message | translate }}</p>
    <div *ngIf="alert.buttons" class="buttons is-right has-margin-top-15">
      <button
        class="button is-outlined is-small is-rounded"
        *ngFor="let button of alert.buttons"
        (click)="button.action(); removeAlert(alert)"
      >
        {{ button.text | translate}}
      </button>
    </div>
  </div>
</div>
