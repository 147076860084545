<button class="button is-rounded" (click)="joinCall()">
  <div class="icon">
    <svg
      width="22px"
      height="13px"
      viewBox="0 0 22 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          id="My-dashboard---Initial-view-(Therapists)-NEW"
          transform="translate(-1093.000000, -1058.000000)"
        >
          <rect fill="#FFFFFF" x="0" y="0" width="1500" height="3358"></rect>
          <g id="Page" fill="#74D0BF">
            <g
              id="Next-appointments"
              transform="translate(521.000000, 990.000000)"
            >
              <g
                id="Avatar-/-Name-/-Time"
                transform="translate(21.000000, 57.000000)"
              >
                <g id="Date" transform="translate(185.000000, 0.000000)">
                  <g id="Join-call" transform="translate(348.000000, 1.000000)">
                    <g
                      id="Join-call-icon"
                      transform="translate(18.000000, 10.000000)"
                    >
                      <g transform="translate(0.000000, 0.000000)">
                        <path
                          d="M20.7636929,1.45052143 C20.3151929,1.19052143 19.7617643,1.19145 19.3132643,1.45052143 L16.1663357,3.59552143 L16.1663357,9.39166429 L19.3132643,11.5366643 C19.53705,11.6666643 19.7877643,11.7316643 20.0384786,11.7316643 C20.2882643,11.7316643 20.5389786,11.6666643 20.7636929,11.5366643 C21.2121929,11.2775929 21.4889071,10.7993786 21.4889071,10.2803071 L21.4889071,2.70687857 C21.4889071,2.18873571 21.2131214,1.70959286 20.7636929,1.45052143"
                          id="Fill-1"
                        ></path>
                        <path
                          d="M10.8667,9.81982857 L10.8667,10.0779714 L10.8667,10.2887571 C10.8667,10.5469 10.6410571,10.7586143 10.3643429,10.7586143 L4.72791429,10.7586143 C4.45212857,10.7586143 4.22648571,10.5469 4.22648571,10.2887571 L4.22648571,10.0779714 L4.22648571,9.81982857 L4.22648571,9.75111429 C4.22648571,9.72697143 4.2302,9.70468571 4.23391429,9.68147143 C4.2887,8.53932857 5.00927143,7.55225714 6.03905714,7.05547143 C6.47734286,7.31082857 6.9927,7.46125714 7.54612857,7.46125714 C8.09955714,7.46125714 8.61584286,7.31082857 9.05412857,7.05547143 C10.0839143,7.55225714 10.8044857,8.53932857 10.8592714,9.68147143 C10.8629857,9.70468571 10.8667,9.72697143 10.8667,9.75111429 L10.8667,9.81982857 Z M5.4587,3.95682857 C5.4587,2.88154286 6.39841429,2.00311429 7.54612857,2.00311429 C8.69477143,2.00311429 9.63355714,2.88154286 9.63355714,3.95682857 L9.63355714,4.52047143 C9.63355714,5.52611429 8.81177143,6.35997143 7.7662,6.46304286 C7.69377143,6.47047143 7.62041429,6.47418571 7.54612857,6.47418571 C7.47184286,6.47418571 7.39941429,6.47047143 7.32698571,6.46304286 C6.28141429,6.35997143 5.4587,5.52611429 5.4587,4.52047143 L5.4587,3.95682857 Z M13.7220571,0.000185714286 L1.37112857,0.000185714286 C0.617128571,0.000185714286 -0.000371428571,0.616757143 -0.000371428571,1.37075714 L-0.000371428571,11.6166143 C-0.000371428571,12.3706143 0.617128571,12.9871857 1.37112857,12.9871857 L13.7220571,12.9871857 C14.4760571,12.9871857 15.0926286,12.3706143 15.0926286,11.6166143 L15.0926286,1.37075714 C15.0926286,0.616757143 14.4760571,0.000185714286 13.7220571,0.000185714286 L13.7220571,0.000185714286 Z"
                          id="Fill-3"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
  <span> Join call </span>
</button>
