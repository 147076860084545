<div
  id="popover"
  [@slideInOut]="hidden ? 'out' : 'in'"
  class="popover bs-popover-bottom"
  role="tooltip"
>
  <div class="arrow is-hidden-touch"></div>
  <div class="popover-body">
    <div class="columns is-mobile">
      <div class="column content">
        <p>{{ content }}</p>
        <div class="columns is-mobile">
          <div class="column">
            <button class="button is-rounded" (click)="closeTourModal()">
              {{ "profile.modal.supporttext.button.skip" | translate }}
            </button>
          </div>
          <div class="column has-text-right prevnext">
            <button
              class="button is-half-rounded-left"
              (click)="goToPrevious()"
            >
              {{ "profile.modal.supporttext.button.back" | translate }}
            </button>
            <button
              class="button is-primary is-half-rounded-right"
              [disabled]="disableNext"
              (click)="goToNext()"
            >
              {{ "profile.modal.supporttext.button.next" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
