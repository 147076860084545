<div
  class="eap-session-allowance-exceeded has-padding-x-20"
  *ngIf="eapNotAllowedReason"
>{{
    "messaging.eap-over-allowance" | translate : {
      reason: eapNotAllowedReason
    }
  }}
  <span
    matTooltipClass="mat-tooltip"
    class="mat-tooltip-trigger-icon-wrapper"
    matTooltip="{{
                    'messaging.eap-over-allowance-message' | translate
                  }}"
  >
                  <mat-icon class="mat-tooltip-trigger-icon">help</mat-icon>
                </span>
</div>
