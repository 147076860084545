<div
  class="dropdown button is-rounded"
  [ngClass]="{ 'is-active': isDropdownOpen }"
>
  <div (click)="isDropdownOpen = true" class="is-flex dropdown-trigger">
    <div class="text-container">
      <span>{{ "daterangepicker." + activeRange | translate }}</span>
      <!-- <small>{{ from | date: "mediumDate" }} - {{ to | date:
                "mediumDate"
                }}</small> -->
    </div>
    <i class="material-icons ic-color">keyboard_arrow_down</i>
  </div>
  <div class="dropdown is-right" [ngClass]="{ 'is-active': isDropdownOpen }">
    <div class="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a
          *ngFor="let range of ranges"
          [ngClass]="{ 'is-active': range == activeRange }"
          (click)="setActiveRange(range)"
          class="dropdown-item"
          >{{ "daterangepicker." + range | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
