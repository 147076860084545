import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Message } from '../../../entities/message.model';
import { MessagingService } from '../service/messaging.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, TranslateModule],
})
export class ImageModalComponent implements OnInit {
  constructor(private messageService: MessagingService) {}
  public _message: Message;

  @Input()
  set message(message: Message) {
    this._message = message;
  }

  @Output()
  modalState = new EventEmitter<boolean>();

  public messages: Array<any> = [];
  public imageIndex: number = 0;

  ngOnInit() {}

  closeModal() {
    this.modalState.next(false);
  }

  getImages() {
    this.messageService.getMedia(this._message.id).subscribe((messages) => {
      this.updateNavigation();
    });
  }

  setImage(message: any) {
    this._message = message;
    this.updateNavigation();
  }

  updateNavigation() {
    this.imageIndex = this.messages
      .map(({ id }) => id)
      .indexOf(this._message.id);
  }

  navigate(offset: number) {
    this.imageIndex = this.imageIndex + offset;
    this.setImage(this.messages[this.imageIndex]);
  }

  getThumbnailOffset() {
    if (!this.messages) {
      return '';
    }
    // get current offset? Which image (index) is selected ?
    var currentOffset = (this.messages.length - this.imageIndex) * 90 - 45;
    return 'translateX(' + currentOffset + 'px)';
  }

  getS3Filename(url) {
    url = decodeURIComponent(url);
    var filename = url.match(/filename="(.*?)"/);
    if (filename) {
      return filename[1];
    } else {
      return null;
    }
  }
}
