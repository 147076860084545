<div>
  <h3 class="subtitle is-4 is-hidden-touch">
    {{ "office.settings.subscription.title" | translate }}
  </h3>

  <div
    class="card has-border"
    [@simpleFadeAnimation]="'in'"
    *ngIf="subscriptionStateLoaded"
  >
    <div class="card-content">
      <ng-container *ngIf="!isAllowedToSubscribe">
        <p>
          {{
            "office.settings.subscription.please_wait_until_approved"
              | translate
          }}
        </p>
        <p *ngIf="currentUser.profile.diplomas.length == 0">
          {{ "office.settings.subscription.still_need_diplomas" | translate }}
        </p>
        <p>
          {{ "office.settings.subscription.approval_takes_time" | translate }}
        </p>
      </ng-container>

      <ng-container *ngIf="isAllowedToSubscribe">
        <ng-container *ngIf="subscriptionStatus.has_active_subscription">
          <div class="columns">
            <div class="column">
              <h5 class="subtitle is-5">
                <span>{{
                  "office.settings.subscription.title" | translate
                }}</span>
              </h5>
              <div class="box content plan-box">
                <div
                  *ngIf="
                    subscriptionStatus.upcoming_plan_change_name &&
                    !subscriptionStatus.subscription.canceled
                  "
                  class="notification is-warning"
                >
                  {{
                    "office.settings.subscription.subscription_downgrade"
                      | translate
                        : {
                            current_plan: subscriptionStatus.subscription.plan_name,
                            next_plan: subscriptionStatus.upcoming_plan_change_name,
                            date:
                              subscriptionStatus.subscription.current_period_end
                              | date : "mediumDate"
                          }
                  }}
                </div>
                <div
                  *ngIf="
                    subscriptionStatus.upcoming_cycle_change_months == 12 &&
                    !subscriptionStatus.subscription.canceled
                  "
                  class="notification is-warning"
                >
                  {{
                    "office.settings.subscription.subscription_billing_cycle_change_to_yearly"
                      | translate
                        : {
                            date:
                              subscriptionStatus.subscription.current_period_end
                              | date : "mediumDate"
                          }
                  }}
                </div>
                <div
                  *ngIf="
                    subscriptionStatus.upcoming_cycle_change_months == 1 &&
                    !subscriptionStatus.subscription.canceled
                  "
                  class="notification is-warning"
                >
                  {{
                    "office.settings.subscription.subscription_billing_cycle_change_to_monthly"
                      | translate
                        : {
                            date:
                              subscriptionStatus.subscription.current_period_end
                              | date : "mediumDate"
                          }
                  }}
                </div>
                <div
                  *ngIf="subscriptionStatus.subscription.canceled"
                  class="notification is-warning"
                >
                  {{
                    "office.settings.subscription.subscription_canceled"
                      | translate
                  }}
                  {{ subscriptionStatus.subscription.canceled_at | date : "mediumDate" }}.
                </div>
                <h6>
                  {{ subscriptionStatus.subscription.plan_name }} -
                  {{ subscriptionStatus.subscription.payment_plan_name }}
                </h6>
                <div class="content">
                  <div
                    class="info content"
                    [innerHtml]="subscriptionStatus.subscription.plan_description"
                  ></div>
                  <h6>
                    {{
                      "office.settings.subscription.fees_and_commission"
                        | translate
                    }}
                  </h6>
                  <ul>
                    <li>
                      {{ subscriptionStatus.subscription.initial_fee_rate | percent : "1.0-2" }}
                      {{
                        "office.settings.subscription.commission_new_client"
                          | translate
                      }}
                    </li>
                    <li>
                      {{ subscriptionStatus.subscription.recurring_fee_rate | percent : "1.0-2" }}
                      {{
                        "office.settings.subscription.processing_fee"
                          | translate
                      }}
                    </li>
                  </ul>
                </div>

                <div class="columns">
                  <div class="column">
                    <label class="heading">{{
                      "office.settings.subscription.subscribed" | translate
                    }}</label>
                    <p>
                      {{ subscriptionStatus.subscription.start_date | date : "mediumDate" }}
                    </p>
                  </div>
                  <div class="column">
                    <label class="heading">
                      <span *ngIf="subscriptionStatus.subscription.canceled">{{
                        "office.settings.subscription.end_date" | translate
                      }}</span>
                      <span
                        *ngIf="
                          subscriptionStatus.upcoming_plan_change_name &&
                          !subscriptionStatus.subscription.canceled
                        "
                        >{{
                          "office.settings.subscription.next_plan_start"
                            | translate
                              : {
                                  plan_name:
                                    subscriptionStatus.upcoming_plan_change_name
                                }
                        }}</span
                      >
                      <span
                        *ngIf="
                          !subscriptionStatus.subscription.canceled &&
                          !subscriptionStatus.upcoming_plan_change_name
                        "
                        >{{
                          "office.settings.subscription.next_payment"
                            | translate
                        }}
                        -
                        {{
                          subscriptionStatus.upcoming_amount_due / 100
                            | currency : "€"
                        }}</span
                      >
                    </label>
                    <p
                      *ngIf="
                        !subscriptionStatus.subscription.canceled &&
                        subscriptionStatus.upcoming_plan_change_name
                      "
                    >
                      {{
                        subscriptionStatus.subscription.current_period_end | date : "mediumDate"
                      }}
                    </p>
                    <p
                      *ngIf="
                        !subscriptionStatus.subscription.canceled &&
                        !subscriptionStatus.upcoming_plan_change_name
                      "
                    >
                      {{ subscriptionStatus.subscription.current_period_end | date }}
                    </p>
                    <p *ngIf="subscriptionStatus.subscription.canceled">
                      {{ subscriptionStatus.subscription.current_period_end | date }}
                    </p>
                  </div>
                </div>

                <ng-container
                  *ngIf="
                    subscriptionStatus.subscription.canceled ||
                      subscriptionStatus.upcoming_plan_change_name ||
                      !!subscriptionStatus.upcoming_cycle_change_months;
                    else plan_change_btns
                  "
                >
                  <div class="resubscribe-btn has-margin-top-15">
                    <button
                      class="button is-primary is-rounded"
                      [ngClass]="{
                        'is-loading': cancellingScheduledSubscriptionChange
                      }"
                      (click)="cancelScheduledSubscriptionChange()"
                    >
                      <span>{{
                        (subscriptionStatus.upcoming_cycle_change_months
                          ? "office.settings.subscription.cancel_planned_billing_cycle_change"
                          : "office.settings.subscription.cancel_planned_downgrade"
                        ) | translate
                      }}</span>
                    </button>
                  </div>
                </ng-container>
                <ng-template #plan_change_btns>
                  <div class="change-plan-btn" has-margin-top-15>
                    <button
                      class="button is-warning is-small is-rounded"
                      (click)="showPlanChangeOptions()"
                    >
                      <span>{{
                        "office.settings.subscription.change_plan" | translate
                      }}</span>
                    </button>

                    <ng-container *ngIf="subscriptionStatus.can_downgrade_to_free">
                      <button
                        class="button is-danger is-outlined is-small is-rounded free-btn"
                        (click)="
                          showDowngradeScreen(downgradeScreenType.Survey)
                        "
                        title="{{
                          'office.settings.subscription.hover_text' | translate
                        }}"
                        [ngClass]="{ 'is-loading': downgradingSubscription }"
                      >
                        {{
                          "office.settings.subscription.downgrade_subscription"
                            | translate
                        }}
                      </button>
                    </ng-container>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="column">
              <ng-container *ngIf="subscriptionBilling">
                <h5 class="subtitle is-5">
                  <span>{{
                    "office.settings.subscription.billing_details" | translate
                  }}</span>
                </h5>
                <div class="box content address-box">
                  <label class="heading">{{
                    "office.settings.subscription.address" | translate
                  }}</label>
                  <p
                    *ngIf="subscriptionBilling.address"
                    [innerHTML]="
                      formatAddress(subscriptionBilling.address).join('<br/>')
                    "
                  ></p>
                  <label class="heading">{{
                    "office.settings.subscription.tax_id" | translate
                  }}</label>
                  <p>
                    <ng-container *ngIf="subscriptionBilling.tax_id; else noTaxID">
                      <span>{{ subscriptionBilling.tax_id }}</span>
                    </ng-container>
                    <ng-template #noTaxID>
                      <span>–</span>
                    </ng-template>
                  </p>
                  <div class="has-margin-bottom-25">
                    {{
                      "office.settings.subscription.customer_portal_list_header"
                        | translate
                    }}
                    <ul>
                      <li>
                        {{
                          "office.settings.subscription.customer_portal_list_item_1"
                            | translate
                        }}
                      </li>
                      <li>
                        {{
                          "office.settings.subscription.customer_portal_list_item_2"
                            | translate
                        }}
                      </li>
                      <li>
                        {{
                          "office.settings.subscription.customer_portal_list_item_3"
                            | translate
                        }}
                      </li>
                      <li>
                        {{
                        "office.settings.subscription.customer_portal_list_item_4"
                                | translate
                        }}
                      </li>
                    </ul>
                  </div>
                  <div class="buttons">
                    <button
                      class="button is-primary is-rounded"
                      (click)="goToCustomerPortal()"
                    >
                      {{
                        "office.settings.subscription.go_to_customer_portal"
                          | translate
                      }}
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="subscriptionStatus &&  subscriptionStatus.subscription && !subscriptionStatus.subscription.canceled && coupons?.length > 0">
          <hr />
          <h3 class="subtitle is-5">
            {{ "office.settings.subscription.coupons" | translate }}
          </h3>
          <ul >
            <li *ngFor="let c of coupons" class="coupon">
              <div class="columns">
                <div class="column">
                  <h6 class="title is-6">{{ c.coupon.name }}</h6>
                  <p>
                    <small>{{ c.coupon.description }}</small>
                  </p>
                </div>
                <div class="column is-narrow">
                  <label class="heading">{{
                    "office.settings.subscription.used" | translate
                  }}</label>
                  <small>{{ c.created_at | date : "mediumDate" }}</small>
                </div>
              </div>
            </li>
          </ul>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{ 'is-active': shouldCancelSubscription }">
  <div class="modal-background"></div>
  <div class="modal-content">
    <a class="delete" (click)="shouldCancelSubscription = false"></a>
    <h2 class="modal-title">
      {{ "office.settings.subscription.cancel_subscription" | translate }}
    </h2>
    <p>{{ "office.settings.subscription.sure_to_cancel" | translate }}</p>
    <p>{{ "office.settings.subscription.sure_to_cancel_2" | translate }}</p>
    <div class="buttons">
      <button
        class="button is-primary is-rounded"
        (click)="shouldCancelSubscription = false"
        style="margin-right: 1rem"
      >
        {{ "office.settings.subscription.no" | translate }}
      </button>
      <button
        class="button is-danger is-rounded"
        (click)="cancelSubscription()"
        [ngClass]="{ 'is-loading': cancelingSubscription }"
      >
        {{ "office.settings.subscription.cancel_subscription" | translate }}
      </button>
    </div>
  </div>
</div>

<div
  class="modal"
  [ngClass]="{
    'is-active': currentDowngradeScreen == downgradeScreenType.Survey
  }"
>
  <div class="modal-background"></div>
  <div class="modal-content">
    <a class="delete" (click)="cancelDowngradeFlow()"></a>
    <h2 class="modal-title">
      {{ "office.settings.subscription.downgrade.survey.title" | translate }}
    </h2>
    <p>
      {{ "office.settings.subscription.downgrade.survey.message" | translate }}
    </p>
    <div class="field">
      <input
        id="not_enough_leads"
        type="checkbox"
        class="is-checkradio"
        (change)="toggleDowngradeReason($event)"
        value="not_enough_leads"
        [checked]="currentDowngradeReasons.has('not_enough_leads')"
      />
      <label for="not_enough_leads">
        {{
          "office.settings.subscription.downgrade.survey.option_not_enough_leads"
            | translate
        }}
      </label>
    </div>
    <div class="field">
      <input
        id="no_more_leads_needed"
        type="checkbox"
        class="is-checkradio"
        (change)="toggleDowngradeReason($event)"
        value="not_enough_leads"
        [checked]="currentDowngradeReasons.has('no_more_leads_needed')"
      />
      <label for="no_more_leads_needed">
        {{
          "office.settings.subscription.downgrade.survey.option_no_more_leads_needed"
            | translate
        }}
      </label>
    </div>
    <div class="field">
      <input
        id="tools_not_useful"
        type="checkbox"
        class="is-checkradio"
        (change)="toggleDowngradeReason($event)"
        value="not_enough_leads"
        [checked]="currentDowngradeReasons.has('tools_not_useful')"
      />
      <label for="tools_not_useful">
        {{
          "office.settings.subscription.downgrade.survey.option_tools_not_useful"
            | translate
        }}
      </label>
    </div>
    <div class="field">
      <input
        id="tools_buggy"
        type="checkbox"
        class="is-checkradio"
        (change)="toggleDowngradeReason($event)"
        value="not_enough_leads"
        [checked]="currentDowngradeReasons.has('tools_buggy')"
      />
      <label for="tools_buggy">
        {{
          "office.settings.subscription.downgrade.survey.option_tools_buggy"
            | translate
        }}
      </label>
    </div>
    <div class="field">
      <input
        id="other"
        type="checkbox"
        class="is-checkradio"
        (change)="toggleDowngradeReason($event)"
        value="not_enough_leads"
        [checked]="currentDowngradeReasons.has('other')"
      />
      <label for="other">
        {{
          "office.settings.subscription.downgrade.survey.option_other"
            | translate
        }}
      </label>
    </div>
    <textarea
      class="textarea"
      maxlength="140"
      name="other"
      [(ngModel)]="currentDowngradeComment"
      placeholder="{{
        'office.settings.subscription.downgrade.survey.comment_placeholder'
          | translate
      }}"
    ></textarea>
    <div class="buttons has-margin-top-25">
      <button
        class="button is-light is-rounded has-margin-left-auto"
        (click)="cancelDowngradeFlow()"
        style="margin-right: 1rem"
      >
        {{ "office.settings.subscription.downgrade.survey.cancel" | translate }}
      </button>
      <button
        class="button is-primary is-rounded has-margin-left-5"
        [disabled]="!currentDowngradeReasons"
        (click)="completeSurvey()"
      >
        {{ "office.settings.subscription.downgrade.survey.next" | translate }}
      </button>
    </div>
  </div>
</div>

<div
  class="modal"
  [ngClass]="{
    'is-active': currentDowngradeScreen == downgradeScreenType.Confirmation
  }"
  *ngIf="!!subscriptionStatus"
>
  <div class="modal-background"></div>
  <div class="modal-content">
    <a class="delete" (click)="cancelDowngradeFlow()"></a>
    <h2 class="modal-title">
      {{
        "office.settings.subscription.downgrade.confirmation.title" | translate
      }}
    </h2>
    <p>
      {{
        "office.settings.subscription.downgrade.confirmation.sure_to_downgrade"
          | translate : { name: subscriptionStatus && subscriptionStatus.subscription ? subscriptionStatus.subscription.plan_name : "" }
      }}
    </p>
    <p>
      {{
        "office.settings.subscription.downgrade.confirmation.free_features.0"
          | translate
      }}
      <a
        href="https://complicated.life/for-therapists#calculator"
        target="_blank"
        >{{
          "office.settings.subscription.downgrade.confirmation.free_features.1"
            | translate
        }}</a
      >
      {{
        "office.settings.subscription.downgrade.confirmation.free_features.2"
          | translate
      }}
    </p>
    <div class="buttons has-margin-top-25">
      <button
        class="button is-primary is-rounded has-margin-left-auto"
        (click)="cancelDowngradeFlow()"
        style="margin-right: 1rem"
      >
        {{
          "office.settings.subscription.downgrade.confirmation.keep_membership"
            | translate
        }}
      </button>
      <button
        class="button is-danger is-rounded is-outlined has-margin-left-5"
        (click)="confirmDowngrade()"
        [ngClass]="{
          'is-loading': downgradingSubscription,
          'is-disabled': downgradingSubscription
        }"
      >
        {{
          "office.settings.subscription.downgrade.confirmation.downgrade_to_free"
            | translate
        }}
      </button>
    </div>
  </div>
</div>

<div
  class="modal"
  [ngClass]="{
    'is-active': currentDowngradeScreen == downgradeScreenType.CouponOfferBasic3
  }"
  *ngIf="!!subscriptionStatus && subscriptionStatus.subscription"
>
  <div class="modal-background"></div>
  <div class="modal-content">
    <a class="delete" (click)="cancelDowngradeFlow()"></a>
    <h2 class="retention-modal-title">
      {{
        "office.settings.subscription.downgrade.retention_offer.basic_offer_3.title" | translate
      }}
    </h2>
    <p style="white-space: pre-wrap" [innerHTML]="'office.settings.subscription.downgrade.retention_offer.basic_offer_3.offer_message_one' | translate"></p>
    <p style="white-space: pre-wrap">
      {{
        "office.settings.subscription.downgrade.retention_offer.basic_offer_3.offer_message_two" | translate : { next_payment_start: subscriptionStatus.subscription.current_period_end | date }
      }}
    </p>
    <div class="buttons has-margin-top-25">
      <button
        class="button is-primary is-rounded has-margin-left-auto"
        (click)="acceptOffer()"
        [ngClass]="{
          'is-loading': acceptingOffer,
          'is-disabled': acceptingOffer
        }"
      >
        {{
          "office.settings.subscription.downgrade.retention_offer.basic_offer_3.accept_offer_cta"
            | translate
        }}
      </button>
      <button
        class="has-margin-left-5 button is-danger is-rounded is-outlined"
        (click)="rejectOffer()"
        [ngClass]="{
          'is-loading': downgradingSubscription,
          'is-disabled': downgradingSubscription
        }"
      >
        {{
          "office.settings.subscription.downgrade.retention_offer.basic_offer_3.decline_offer_cta"
            | translate
        }}
      </button>
    </div>
  </div>
</div>

<div
  class="modal"
  [ngClass]="{
    'is-active': currentDowngradeScreen == downgradeScreenType.CouponOfferBasic6
  }"
  *ngIf="!!subscriptionStatus && subscriptionStatus.subscription"
>
  <div class="modal-background"></div>
  <div class="modal-content">
    <a class="delete" (click)="cancelDowngradeFlow()"></a>
    <h2 class="retention-modal-title">
      {{
        "office.settings.subscription.downgrade.retention_offer.basic_offer_6.title" | translate
      }}
    </h2>
    <p style="white-space: pre-wrap" [innerHTML]="'office.settings.subscription.downgrade.retention_offer.basic_offer_6.offer_message_one' | translate"></p>
    <p style="white-space: pre-wrap">
      {{
        "office.settings.subscription.downgrade.retention_offer.basic_offer_6.offer_message_two" | translate : { next_payment_start: subscriptionStatus.subscription.current_period_end | date }
      }}
    </p>
    <div class="buttons has-margin-top-25">
      <button
        class="button is-primary is-rounded has-margin-left-auto"
        (click)="acceptOffer()"
        [ngClass]="{
          'is-loading': acceptingOffer,
          'is-disabled': acceptingOffer
        }"
      >
        {{
          "office.settings.subscription.downgrade.retention_offer.basic_offer_6.accept_offer_cta"
            | translate
        }}
      </button>
      <button
        class="has-margin-left-5 button is-danger is-rounded is-outlined"
        (click)="rejectOffer()"
        [ngClass]="{
          'is-loading': downgradingSubscription,
          'is-disabled': downgradingSubscription
        }"
      >
        {{
          "office.settings.subscription.downgrade.retention_offer.basic_offer_6.decline_offer_cta"
            | translate
        }}
      </button>
    </div>
  </div>
</div>

<div
  class="modal"
  [ngClass]="{
    'is-active': currentDowngradeScreen == downgradeScreenType.CouponOfferBasic6Low
  }"
  *ngIf="!!subscriptionStatus && subscriptionStatus.subscription"
>
  <div class="modal-background"></div>
  <div class="modal-content">
    <a class="delete" (click)="cancelDowngradeFlow()"></a>
    <h2 class="retention-modal-title">
      {{
        "office.settings.subscription.downgrade.retention_offer.basic_offer_6_low.title" | translate
      }}
    </h2>
    <p style="white-space: pre-wrap" [innerHTML]="'office.settings.subscription.downgrade.retention_offer.basic_offer_6_low.offer_message_one' | translate"></p>
    <p style="white-space: pre-wrap">
      {{
        "office.settings.subscription.downgrade.retention_offer.basic_offer_6_low.offer_message_two" | translate : { next_payment_start: subscriptionStatus.subscription.current_period_end | date }
      }}
    </p>
    <div class="buttons has-margin-top-25">
      <button
        class="button is-primary is-rounded has-margin-left-auto"
        (click)="acceptOffer()"
        [ngClass]="{
          'is-loading': acceptingOffer,
          'is-disabled': acceptingOffer
        }"
      >
        {{
          "office.settings.subscription.downgrade.retention_offer.basic_offer_6_low.accept_offer_cta"
            | translate
        }}
      </button>
      <button
        class="has-margin-left-5 button is-danger is-rounded is-outlined"
        (click)="rejectOffer()"
        [ngClass]="{
          'is-loading': downgradingSubscription,
          'is-disabled': downgradingSubscription
        }"
      >
        {{
          "office.settings.subscription.downgrade.retention_offer.basic_offer_6_low.decline_offer_cta"
            | translate
        }}
      </button>
    </div>
  </div>
</div>


