import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericHttpService } from '../Generic HTTP/generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  constructor(private http: GenericHttpService) {}

  getAll(): Observable<any> {
    return this.http.get('/announcements');
  }

  markAsRead(id: number): Observable<any> {
    return this.http.put('/announcements/' + id, {});
  }
}
