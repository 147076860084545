import { User } from './user.model';
import { Profile } from './profile.model';
import { PaymentType } from './PaymentType';
import { LeanConversationUser } from './lean-conversation-user.model';

export class Appointment {
  start: Date;
  end: Date;
  client: User | LeanConversationUser;
  profile: Profile;
  note: string;
  title: string;
  draggable: boolean;
  id: number;
  status: string;
  notified_at: Date;
  is_online: boolean;
  is_couples_session: boolean;
  payment_type: PaymentType;
  uid: string;
  selected: boolean;
  duration: number;
  prepay_price: number;
  invoice: AppointmentInvoice;
  //  This will not be populated if this comes from Getstream Attachment, in which
  //  case you have to look at the profile that owns the appointment
  can_use_video: boolean;

  constructor(start: Date) {
    this.start = start;
  }
}

export class AppointmentInvoice {
  id: number;
  status: string;
  total: number;
}
