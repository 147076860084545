<section class="dashboard-teaser">
  <header>
    <h3 class="dashboard-teaser-title">
      {{ "office.settings.custom_fields.title" | translate }}
    </h3>
  </header>
  <div class="card">
    <div class="card-content">
      <p>{{ "office.settings.custom_fields.copy" | translate }}</p>
    </div>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>{{ "office.settings.custom_fields.description" | translate }}</th>
          <th>{{ "office.settings.custom_fields.value" | translate }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngIf="invoiceSettings">
        <tr
          *ngFor="
            let customfield of invoiceSettings.customfields;
            let i = index
          "
        >
          <td>
            {{ customfield.name }}
          </td>
          <td>
            {{ customfield.value }}
          </td>
          <td>
            <button class="delete" (click)="removeCustomField(i)"></button>
          </td>
        </tr>
        <tr>
          <td>
            <div class="field">
              <div class="control">
                <input
                  type="text"
                  class="input"
                  [(ngModel)]="newCustomfield.name"
                  placeholder="National Tax ID"
                  maxlength="30"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="field">
              <div class="control">
                <input
                  type="text"
                  class="input"
                  [(ngModel)]="newCustomfield.value"
                  placeholder="123/456/789"
                  maxlength="30"
                />
              </div>
            </div>
          </td>
          <td>
            <button
              class="button is-primary is-small ml-auto is-rounded"
              (click)="addCustomField()"
              [disabled]="
                newCustomfield.name == '' || newCustomfield.value == ''
              "
            >
              {{ "office.settings.custom_fields.add" | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

<section class="dashboard-teaser">
  <header>
    <h3 class="dashboard-teaser-title">
      {{ "office.settings.footer_text.title" | translate }}
    </h3>
  </header>
  <div class="card">
    <div class="card-content">
      <p>{{ "office.settings.footer_text.copy" | translate }}</p>
    </div>
    <table class="table is-fullwidth" *ngIf="invoiceSettings">
      <tbody>
        <tr>
          <td>
            <div class="field">
              <div class="control">
                <textarea
                  class="textarea"
                  placeholder=""
                  maxlength="4000"
                  autosize
                  [minRows]="1"
                  [(ngModel)]="invoiceSettings.footer"
                ></textarea>
              </div>
            </div>
          </td>
          <td width="120">
            <button
              (click)="update('Footer text updated.')"
              class="button is-primary is-small is-rounded"
              [ngClass]="{ 'is-loading': isUpdating }"
            >
              {{ "office.settings.footer_text.update" | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

<office-taxrates></office-taxrates>
