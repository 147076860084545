import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { ProfileComponent } from '../components/profile/profile.component';
import { Animation } from '../shared/animations/fade-animation';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { APP_BASE_HREF, NgClass, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { SettingsService } from '../components/settings/service/settings.service';
import { VideoService } from '../shared/video/video.service';
import { ICAuthService } from '../auth/service/i-c-auth.service';
import { SvgIconRegistryService } from 'angular-svg-icon';
import moment from 'moment';
import { AlertComponent } from '../shared/components/alert/alert.component';
import { HeaderComponent } from '../frame/header/header.component';
import {
  ChannelService,
  ChatClientService,
  StreamI18nService,
} from 'stream-chat-angular';
import { SharedService } from '../shared/services/shared.service';
import { ChatService } from '../components/messaging/service/chat.service';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { MonitoringService } from '../shared/services/monitoring/monitoring.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [Animation.fadeAnimation],
  standalone: true,
  imports: [NgClass, HeaderComponent, AlertComponent, RouterOutlet],
})
export class AppComponent implements OnInit {
  constructor(
    private auth: ICAuthService,
    private videoService: VideoService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private settings: SettingsService,
    private chatClientService: ChatClientService,
    private channelService: ChannelService,
    private chatService: ChatService,
    private sharedService: SharedService,
    private streamI18nService: StreamI18nService,
    @Inject(ViewContainerRef) viewContainerRef,
    private svgIconRegistryService: SvgIconRegistryService,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      moment.locale(event.lang);
    });
    this.translate.setDefaultLang('en');
    registerLocaleData(localeDe);

    this.translate.getTranslation('en').subscribe(() => {
      this.streamI18nService.setTranslation('en');
    });

    this.route.queryParams.subscribe((params) => {
      if (params.locale) {
        this.settings.useLanguage(params.locale);
      }
    });
    this.settings.setLanguageOnStartup();
    this.videoService.setVideoContainer(viewContainerRef);
    this.addCommonSvgIcons();
  }

  user: boolean;
  isProfile: boolean = false;
  currentComponent: Component;

  ngOnInit() {
    this.auth.initAuth();
    this.auth.isLoggedIn.subscribe(async (userAuthenticated) => {
      if (userAuthenticated) {
        this.sharedService.currentUser.subscribe(async (user) => {
          if (user && environment.getstreamApiKey) {
            await this.chatClientService.init(
              environment.getstreamApiKey,
              user.external_id.toString(),
              async () => {
                try {
                  const response = await firstValueFrom(
                    this.chatService.getChatToken()
                  );
                  return response.token;
                } catch (error) {
                  MonitoringService.captureMessage('Error getting chat token', {
                    extra: error,
                  });
                  console.log('ERROR ' + error);
                  return null;
                }
              }
            );
            await this.channelService.init(
              {
                type: 'messaging',
                members: { $in: [user.external_id.toString()] },
              },
              null,
              null,
              false
            );
          }
        });
      } else {
        if (!this.chatClientService.chatClient?.user) {
          return;
        }
        await this.chatClientService.disconnectUser();
      }
    });
  }

  onActivate(comp: Component) {
    this.currentComponent = comp;
    this.isProfile = this.currentComponent instanceof ProfileComponent;
  }

  addCommonSvgIcons() {
    this.svgIconRegistryService.addSvg(
      'badge-w',
      `${this.baseHref}assets/images/svg/badge-w.svg`
    );
    this.svgIconRegistryService.addSvg(
      'badge-g',
      `${this.baseHref}assets/images/svg/badge-g.svg`
    );
    this.svgIconRegistryService.addSvg(
      'plant-with-cup',
      `${this.baseHref}assets/images/svg/plant-with-cup.svg`
    );
    this.svgIconRegistryService.addSvg(
      'profile-picture',
      `${this.baseHref}assets/images/svg/profile_picture`
    );
    this.svgIconRegistryService.addSvg(
      'green-check',
      `${this.baseHref}assets/images/svg/check-green.svg`
    );
  }
}
