<div class="input-container">
    <button
      title="Suggest Appointment"
      id="calendarButton"
      class="button is-text is-icon  input-action"
      (click)="this.onCreateBooking.emit()"
      *ngIf="currentUser?.abilities?.can_create_appointments"
    >
      <i class="icon icon-calendar"></i>
    </button>
    <button
      title="Create Invoice"
      *ngIf="
        currentUser &&
        currentUser.type == 'Therapist' &&
        currentUser.profile.stripe_connect_active == true
      "
      class="button is-text is-icon is-hidden-touch  input-action"
      (click)="this.onCreateInvoice.emit()"
    >
      <i class="icon icon-invoice"></i>
    </button>
  <stream-message-input class="stream-input"></stream-message-input>
</div>

<!-- Message inputs use separate AttachmentService instances and component-tree based DI lookup doesn't work with content injection so we need to provide the AttachmentService as an input -->
<ng-template
  #customAttachmentUpload
  let-attachmentService="attachmentService"
>
  <div
    class="str-chat__file-input-container button is-text input-action"
    data-testid="file-upload-button"
  >
    <input
      #fileInput
      type="file"
      class="str-chat__file-input"
      data-testid="file-input"
      [multiple]="true"
      id="{{ fileInputId }}"
      (change)="filesSelected(fileInput.files, attachmentService)"
    />
    <label class="str-chat__file-input-label" for="{{ fileInputId }}">
        <i class="icon icon-attachment"></i>
    </label>
  </div>
</ng-template>

<app-booking-form-container
        *ngIf="currentUser && currentUser.type == 'Therapist'"
        [currentUser]="currentUser"
        #bookingModal
></app-booking-form-container>
