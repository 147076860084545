<div
  class="ic-carousel"
  [ngClass]="{ large: largeView }"
  (click)="largeView = false"
>
  <div class="slides">
    <img
      class="is-rounded active"
      (click)="expandImages($event)"
      [src]="
        API_URL + '/imgprxyindex/' + profile.id + '/' + activeIndex + '/512/512'
      "
    />
  </div>
  <div class="indicators" *ngIf="profile._images.length > 1">
    <ng-container *ngFor="let img of profile._images; let i = index">
      <img
        class="is-rounded"
        (click)="show($event, i)"
        [src]="API_URL + '/imgprxyindex/' + profile.id + '/' + i + '/50/50'"
      />
    </ng-container>
  </div>
</div>
