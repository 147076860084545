<div class="attachment-status" [ngSwitch]="appointment.status">
  <span *ngSwitchCase="'inquiry'">
    <span *ngIf="isTherapist; else isClient">
      {{ "booking.status.therapist.inquiry" | translate }}
    </span>
    <ng-template #isClient>
      <span>
        {{ "booking.status.client.inquiry" | translate }}
      </span>
    </ng-template>
  </span>
  <ng-container *ngSwitchCase="'pending'">
    <div *ngIf="isTherapist; else isClient">
      <span *ngIf="appointment.invoice; else noInvoice">{{
          "booking.status.therapist.waiting_for_confirmation_and_payment"
            | translate
            : {
              price:
                appointment.invoice.total
                  | currency : (appointment.profile.currency | uppercase)
            }
        }}</span>
      <ng-template #noInvoice>
        {{
          "booking.status.therapist.waiting_for_confirmation"| translate
        }}
      </ng-template>
    </div>
    <ng-template #isClient>
      <span *ngIf="appointment.invoice; else noInvoice">{{
          "booking.status.client.waiting_for_confirmation_and_payment"
            | translate
            : {
              price:
                appointment.invoice.total
                  | currency : (appointment.profile.currency | uppercase)
            }
        }}</span>
      <ng-template #noInvoice>{{
          "booking.status.client.waiting_for_confirmation"
            | translate
        }}
      </ng-template>
    </ng-template>
  </ng-container>
  <span *ngSwitchCase="'declined'">{{
      "booking.status.declined" | translate
    }}</span>
  <span *ngSwitchCase="'canceled'">{{
      "booking.status.canceled" | translate
    }}</span>
  <ng-container *ngSwitchCase="'accepted'">
    <span
      *ngIf="
        appointment.invoice && appointment.invoice.status == 'paid';
        else accepted
      "
    >{{
        "booking.status.accepted_and_paid"
          | translate
          : {
            price:
              appointment.invoice.total
                | currency : (appointment.profile.currency | uppercase)
          }
      }}</span
    >
    <ng-template #accepted>{{
        "booking.status.accepted" | translate
      }}
    </ng-template>
  </ng-container>
</div>
