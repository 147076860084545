import { ProfileService } from 'src/app/components/profile/service/profile.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/entities/user.model';
import { ICAuthService } from 'src/app/auth/service/i-c-auth.service';
import { SettingsService } from '../../settings/service/settings.service';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { environment } from 'src/environments/environment';
import { TherapistOnboardingEventuallyApproveSteps } from './therapist-onboarding-eventually-approve-steps';
import { OfficeService } from 'src/app/office/shared/service/office.service';
import { TranslateModule } from '@ngx-translate/core';
import { UploadDiplomaModalComponent } from '../../../shared/components/upload-diploma-modal/upload-diploma-modal.component';
import { RouterLink } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TherapistsOnboardingEventuallyApproveProgressBarComponent } from '../../../shared/components/therapists-onboarding-eventually-approve-progress-bar/therapists-onboarding-eventually-approve-progress-bar.component';
import { NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'app-therapist-onboarding-eventually-approve-box',
  templateUrl: './therapist-onboarding-eventually-approve-box.html',
  styleUrls: ['./therapist-onboarding-eventually-approve-box.scss'],
  standalone: true,
  imports: [
    NgIf,
    TherapistsOnboardingEventuallyApproveProgressBarComponent,
    AngularSvgIconModule,
    NgSwitch,
    NgSwitchCase,
    RouterLink,
    NgClass,
    UploadDiplomaModalComponent,
    TranslateModule,
  ],
})
export class TherapistOnboardingEventuallyApproveBoxComponent
  implements OnInit
{
  constructor(
    private settingsService: SettingsService,
    private alertService: AlertService,
    private auth: ICAuthService,
    private profileService: ProfileService,
    private officeService: OfficeService
  ) {}

  env = environment;
  _currentUser: User;

  onboardingStep: TherapistOnboardingEventuallyApproveSteps;
  ONBOARDINGSTEP = TherapistOnboardingEventuallyApproveSteps;

  showUploadModal: boolean;
  requestedMail: boolean;
  subscriptions: Array<any> = [];
  subscriptionLoaded: boolean = false;

  ngOnInit() {
    this.auth.getCurrentUser().subscribe((user: any) => {
      this._currentUser = user;

      if (user && user.type === 'Therapist') {
        this.settingsService.getActiveSubscriptions().subscribe((sub: any) => {
          this.subscriptions = sub;
          this.subscriptionLoaded = true;
          this.assignOnboardingStep();
        });
      }
    });
  }

  assignOnboardingStep() {
    const isProfileCompleted = this._currentUser.profile.profile_completed;
    const isNotSubscribed = this.subscriptions.length === 0;
    const isProfileApproved = this._currentUser.profile.profile_approved;

    if (!isProfileCompleted) {
      this.onboardingStep =
        TherapistOnboardingEventuallyApproveSteps.fill_out_profile;
      return;
    }
    if (isNotSubscribed) {
      this.onboardingStep =
        TherapistOnboardingEventuallyApproveSteps.subscribing;
      return;
    }
    if (!isProfileApproved) {
      this.onboardingStep =
        TherapistOnboardingEventuallyApproveSteps.waiting_for_approval;
      return;
    }
    this.onboardingStep = TherapistOnboardingEventuallyApproveSteps.done;
  }

  canClose() {
    return this._currentUser.email_confirmed;
  }

  shouldDisplay() {
    if (!this.subscriptionLoaded) {
      return false;
    }
    if (this._currentUser.type == 'Client') {
      return false;
    }
    // Fallback: Email not verified yet?
    if (!this._currentUser.email_confirmed) {
      return true;
    }
    if (this._currentUser.profile.ignoreProfile) {
      return false;
    }
    // Fallback: Calendar not setup
    if (
      this._currentUser.profile.calendar_enabled &&
      this.onboardingStep != TherapistOnboardingEventuallyApproveSteps.done
    ) {
      return true;
    }
    if (!this._currentUser.profile.calendar_enabled) {
      if (this._currentUser.profile.ignoreCalendar) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  resendWelcomeMail() {
    var self = this;
    this.auth.resendWelcomeMail().subscribe(() => {
      self.alertService.success('An email has been sent to your account');
      self.requestedMail = true;
    });
  }

  setIgnoreCalendar() {
    this._currentUser.profile.ignoreCalendar = true;
    this.profileService.updateItem(
      this._currentUser.profile,
      this._currentUser.profile.id
    );
  }

  onDiplomasUploaded(diplomas: any) {
    this._currentUser.profile.diplomas = diplomas;
    this.showUploadModal = false;
  }
}
