<div class="modal" [ngClass]="{ 'is-active': _message }">
  <div class="modal-background" (click)="closeModal()"></div>
  <div class="modal-content">
    <button
      class="modal-close is-large"
      aria-label="close"
      (click)="closeModal()"
    ></button>
    <button
      class="left-arrow button is-rounded"
      *ngIf="imageIndex > 0"
      (click)="navigate(-1)"
    >
      {{ "common.back" | translate }}
    </button>
    <button
      class="right-arrow button is-rounded"
      *ngIf="imageIndex < messages.length - 1"
      (click)="navigate(1)"
    >
      {{ "common.next" | translate }}
    </button>
    <div>
      <img class="image" [src]="_message.file_attachment" />
    </div>
  </div>
  <p class="image-title">{{ getS3Filename(_message.file_attachment) }}</p>
</div>
