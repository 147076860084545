import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import moment from 'moment';
import { User } from 'src/app/entities/user.model';
import { SharedService } from '../../services/shared.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  standalone: true,
  imports: [NgClass, NgFor, TranslateModule],
})
export class DateRangePickerComponent implements OnInit {
  constructor(private sharedService: SharedService) {}

  public isDropdownOpen: boolean = false;
  public activeRange: string;

  public ranges: Array<string> = [
    'last_7_days',
    'last_14_days',
    'this_month',
    'last_month',
    'this_year',
    'last_year',
    'all_time',
  ];

  public to: Date;
  public from: Date;

  public currentUser: User;

  @Output() onDateRangeSelected = new EventEmitter<Array<string>>();

  ngOnInit() {
    this.setActiveRange(this.ranges[0]);
    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  setActiveRange(range: string) {
    this.activeRange = range;

    switch (this.activeRange) {
      case 'last_7_days':
        var f = moment().subtract(7, 'days');
        var t = moment();
        break;
      case 'last_14_days':
        var f = moment().subtract(14, 'days');
        var t = moment();
        break;
      case 'this_month':
        var f = moment().startOf('month');
        var t = moment();
        break;
      case 'last_month':
        var f = moment().subtract(1, 'month').startOf('month');
        var t = moment().subtract(1, 'month').endOf('month');
        break;
      case 'this_year':
        var f = moment().startOf('year');
        var t = moment();
        break;
      case 'last_year':
        var f = moment().startOf('year').subtract(1, 'year');
        var t = moment().endOf('year').subtract(1, 'year');
        break;
      case 'all_time':
        var start: any = this.currentUser.created_at;
        var f = moment(start.split('T')[0]);
        var t = moment();
        break;
    }
    this.from = f.toDate();
    this.to = t.toDate();

    this.onDateRangeSelected.emit([
      f.format('YYYY-MM-DD'),
      t.format('YYYY-MM-DD'),
    ]);
    this.isDropdownOpen = false;
  }
}
