import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICAuthService } from '../service/i-c-auth.service';
import { environment } from '../../../environments/environment';

@Component({
  templateUrl: './logout.component.html',
  standalone: true,
})
export class LogoutComponent implements OnInit {
  constructor(private authService: ICAuthService, private router: Router) {}

  ngOnInit(): void {
    this.authService.signOut();
    if (!environment.isAuth0Enabled) {
      this.router.navigate(['/login']);
    }
  }
}
