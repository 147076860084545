<shared-loading-animation
  *ngIf="!balances || !openInvoices"
></shared-loading-animation>

<nav class="level balance" *ngIf="balances && openInvoices">
  <div class="level-item has-text-left">
    <div routerLink="/office/invoices" class="cursor-pointer">
      <p class="balance-label">
        {{ "office.balance.unpaid_invoices" | translate }} ({{
          openInvoices.length
        }})
      </p>
      <p class="balance">
        {{
          openInvoicesSum
            | currency : (currentUser.profile.currency | uppercase)
        }}
      </p>
    </div>
  </div>
  <div class="level-item has-text-left">
    <svg
      width="14px"
      height="22px"
      viewBox="0 0 14 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          id="My-dashboard---Initial-view-(Therapists)-NEW"
          transform="translate(-759.000000, -881.000000)"
          stroke="#EBEAEA"
          stroke-width="3"
        >
          <g id="Page">
            <g
              id="Account-balance"
              transform="translate(519.000000, 798.000000)"
            >
              <g id="Totals" transform="translate(58.000000, 70.000000)">
                <polyline
                  id="Stroke-1"
                  transform="translate(188.182500, 24.358500) rotate(-180.000000) translate(-188.182500, -24.358500) "
                  points="193.365 15 183 24.3575369 193.365 33.717"
                ></polyline>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
  <div class="level-item has-text-centered">
    <div>
      <p class="balance-label">
        {{ "office.balance.processing_invoices" | translate }}
      </p>
      <p class="balance" *ngFor="let currency of currencies">
        {{
          balances[currency].pending / 100 | currency : currency.toUpperCase()
        }}
      </p>
    </div>
  </div>
  <div class="level-item has-text-left">
    <svg
      width="14px"
      height="22px"
      viewBox="0 0 14 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          id="My-dashboard---Initial-view-(Therapists)-NEW"
          transform="translate(-759.000000, -881.000000)"
          stroke="#EBEAEA"
          stroke-width="3"
        >
          <g id="Page">
            <g
              id="Account-balance"
              transform="translate(519.000000, 798.000000)"
            >
              <g id="Totals" transform="translate(58.000000, 70.000000)">
                <polyline
                  id="Stroke-1"
                  transform="translate(188.182500, 24.358500) rotate(-180.000000) translate(-188.182500, -24.358500) "
                  points="193.365 15 183 24.3575369 193.365 33.717"
                ></polyline>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
  <div class="level-item has-text-centered">
    <div>
      <p class="balance-label">
        {{ "office.balance.account_balance" | translate }}
      </p>
      <p class="balance is-account" *ngFor="let currency of currencies">
        {{
          balances[currency].available / 100 | currency : currency.toUpperCase()
        }}
      </p>
    </div>
  </div>
</nav>
