import { Injectable } from '@angular/core';
import { GenericHttpService } from '../../../shared/services/Generic HTTP/generic-http.service';
import { Observable } from 'rxjs';
import moment from 'moment';
import { DateSpecificAvailability } from '../../../entities/date-specific-availability';
import { AvailabilitySlot } from '../../../entities/availability-slot.model';

@Injectable({
  providedIn: 'root',
})
export class CalendarSettingsService {
  constructor(private http: GenericHttpService) {}

  getUpcomingDateSpecificAvailabilities(): Observable<
    DateSpecificAvailability[]
  > {
    return this.http.get<DateSpecificAvailability[]>(
      '/me/upcoming_date_specific_availabilities'
    );
  }

  createDateSpecificAvailabilities(
    availabilities: DateSpecificAvailability[]
  ): Observable<DateSpecificAvailability[]> {
    return this.http.post<DateSpecificAvailability[]>(
      '/me/date_specific_availabilities',
      { date_specific_availabilities: availabilities }
    );
  }

  deleteDateSpecificAvailabilities(
    dates: string[] // dates in 'YYYY-MM-DD' format
  ): Observable<void> {
    return this.http.post('/me/date_specific_availabilities/delete_by_dates', {
      dates,
    });
  }

  private formatAvailability(
    date: Date,
    slots: AvailabilitySlot[]
  ): DateSpecificAvailability {
    return {
      date: moment(date).format('YYYY-MM-DD'),
      availability_slots: slots,
    };
  }

  createAvailabilitiesForDateRange(
    startDate: Date,
    endDate: Date | null,
    slots: AvailabilitySlot[]
  ): DateSpecificAvailability[] {
    // If endDate is null, return availability for only startDate
    if (endDate === null) {
      return [this.formatAvailability(startDate, slots)];
    }

    const dates = [];
    let currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate)) {
      console.log('pushing date', currentDate.toDate());
      dates.push(currentDate.toDate());
      currentDate = currentDate.add(1, 'days');
    }

    return dates.map((date) => this.formatAvailability(date, slots));
  }
}
