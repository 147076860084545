import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private _http: GenericHttpService) {}

  getChatToken(): Observable<any> {
    return this._http.post('/chat/generate_token', {});
  }
}
