<form [formGroup]="requestBookingForm">
  <section class="modal-card-body content">
    <div class="box date-review-box">
      <strong class="review-date">
        <i class="icon icon-calendar"></i>
        {{ blockerTimestamp | date : "dd.MM.yyyy" }} at
        {{ blockerTimestamp | date : "HH:mm" }}
        <small *ngIf="timezoneText">({{ timezoneText }})</small>
      </strong>
      <button class="button is-small is-rounded" (click)="onChangeDate()">
        {{ "messaging.booking-modal.change" | translate }}
      </button>
    </div>

    <div class="field">
      <label class="label has-text-left">{{
        "messaging.booking-modal.duration" | translate
      }}</label>
      <div class="duration">
        <p class="control has-icons-right">
          <input class="input" formControlName="duration" [mask]="'009'" />
          <span class="icon is-small is-right">{{
            "common.min" | translate
          }}</span>
        </p>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <span class="label">{{ "calendar.full-day" | translate }}</span>
        <label class="switch">
          <input name="fullDay" formControlName="isFullDay" type="checkbox"  />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="field">
      <span class="label">{{ "my-clients.note" | translate }}</span>
      <div class="control">
        <textarea
          type="text"
          class="input is-small"
          id="note"
          formControlName="note"
          autosize
          [minRows]="1"
        ></textarea>
      </div>
    </div>
  </section>

  <footer class="modal-card-foot">
    <button class="button is-rounded" (click)="onCancel()">
      {{ "messaging.cancel" | translate }}
    </button>
    <button
      class="button is-primary is-rounded"
      (click)="onSubmit()"
      [disabled]="requestBookingForm.invalid"
    >
      {{ "messaging.booking-modal.schedule" | translate }}
    </button>
  </footer>
</form>
