<footer class="footer has-wave is-hidden-touch">
  <div class="container-fluid container-fluid-limited">
    <div class="columns sitemap">
      <div class="column">
        <h6 class="title is-6">{{ "footer.find-your-way" | translate }}</h6>
        <a target="_blank" href="https://complicated.life/find-a-therapist">{{
          "common.find-a-therapist" | translate
        }}</a>
        <a target="_blank" href="https://complicated.life/our-story">{{
          "footer.our-story" | translate
        }}</a>
        <a target="_blank" href="https://complicated.life/blog">{{
          "footer.blog" | translate
        }}</a>
      </div>
      <div class="column">
        <h6 class="title is-6">{{ "footer.company" | translate }}</h6>
        <a href="https://complicated.life/privacy">{{
          "footer.privacy-policy" | translate
        }}</a>
        <a href="https://complicated.life/terms">{{
          "footer.terms" | translate
        }}</a>
        <a href="https://complicated.life/cookies">{{
          "footer.cookies" | translate
        }}</a>
        <a href="https://complicated.life/imprint">{{
          "footer.imprint" | translate
        }}</a>
      </div>
    </div>
    <p class="copyright title is-6">
      &copy;{{ now | date : "yyyy" }} - It’s Complicated.
    </p>
  </div>
</footer>
