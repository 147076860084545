<ng-container *ngIf="currentUser && !currentUser.profile.profile_approved">
  <h3 class="subtitle is-5">
    {{ "settings.share-profile-and-cal" | translate }}
  </h3>
  <div class="content">
    <p>{{ "settings.once-approved" | translate }}</p>
  </div>
</ng-container>

<ng-container
  *ngIf="
    currentUser &&
    currentUser.profile.profile_approved &&
    currentUser.profile.city
  "
>
  <ng-container>
    <h3 class="subtitle is-5">{{ "settings.share-profile" | translate }}</h3>
    <div class="content">
      <p>{{ "settings.share-profile-on-social-media" | translate }}</p>
      <div class="buttons">
        <!-- <button class="button" *ngIf="window.navigator.canShare()" (click)="window.navigator.share(shareData)">Share</button> -->
        <button
          class="button is-rounded is-small"
          (click)="shareTo('twitter')"
          style="background-color: #1da1f2; color: white; border: none"
        >
          twitter
        </button>
        <button
          class="button is-rounded is-small"
          (click)="shareTo('facebook')"
          style="background-color: #1877f2; color: white; border: none"
        >
          facebook
        </button>
        <a
          class="button is-rounded is-small is-hidden-desktop"
          [attr.href]="'fb-messenger://share/?link=' + currentUser.profile.link"
          style="background-color: #3b5998; color: white; border: none"
          >Messenger</a
        >
        <a
          class="button is-rounded is-small is-hidden-desktop"
          [attr.href]="'whatsapp://send?' + currentUser.profile.link"
          style="background-color: #25d366; color: white; border: none"
          >WhatsApp</a
        >
      </div>
      <p>{{ "settings.copy-profile-link" | translate }}</p>
      <div class="code">
        <textarea
          readonly
          class="textarea is-rounded is-fullwidth"
          #linkField
          autosize
          [minRows]="1"
          >{{ currentUser.profile.link }}</textarea
        >
        <span class="clipboard" (click)="copy('linkField')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="clipboard"
            width="2em"
            height="2em"
            viewBox="0 0 24 24"
          >
            <path
              fill=""
              d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
            />
          </svg>
        </span>
      </div>
    </div>
    <hr />
    <h3 class="subtitle is-5">{{ "settings.embed-profile" | translate }}</h3>
    <div class="content">
      <p>
        {{ "settings.embed-your-profile" | translate}}
      </p>
      <div
        class="profile-embed"
        [innerHTML]="profileEmbed | safe : 'html'"
        #profileEmbedContainer
      ></div>
      <div class="code">
        <textarea
          readonly
          class="textarea is-rounded is-fullwidth"
          #profileEmbedField
          rows="3"
          >{{ profileEmbed }}</textarea
        >
        <span class="clipboard" (click)="copy('profileEmbedField')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="clipboard"
            width="2em"
            height="2em"
            viewBox="0 0 24 24"
          >
            <path
              fill=""
              d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
            />
          </svg>
        </span>
      </div>
    </div>
    <hr />
  </ng-container>

  <div class="content">
    <p>{{ "settings.profile-badge-desc" | translate }}</p>
    <div class="columns">
      <div class="column is-4">
        <svg-icon src="assets/images/badge-w.svg" class="blah"/>
      </div>
      <div class="column">
        <div class="code">
          <textarea
            readonly
            class="textarea is-rounded is-fullwidth"
            #badgeFieldWhite
            rows="2"
            >{{ badgeFrameWhite }}</textarea
          >
          <span class="clipboard" (click)="copy('badgeFieldWhite')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="clipboard"
              width="2em"
              height="2em"
              viewBox="0 0 24 24"
            >
              <path
                fill=""
                d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <svg-icon src="assets/images/badge-g.svg" />
      </div>
      <div class="column">
        <div class="code">
          <textarea
            readonly
            class="textarea is-rounded is-fullwidth"
            #badgeFieldGreen
            rows="2"
            >{{ badgeFrameGreen }}</textarea
          >
          <span
            class="clipboard"
            (click)="copy('badgeFieldGreen')"
            data-tooltip="Copy"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="clipboard"
              width="2em"
              height="2em"
              viewBox="0 0 24 24"
            >
              <path
                fill=""
                d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>

  <hr />
  <h3 class="subtitle is-5">{{ "settings.contact-button" | translate }}</h3>
  <div class="content">
    <p>{{ "settings.contact-button-desc" | translate }}</p>
    <div class="columns">
      <div class="column is-4">
        <button
          class="button is-primary is-rounded is-fullwidth contact-button"
        >
          {{ contactLabel }}
        </button>
      </div>
      <div class="column">
        <div class="field">
          <div class="control">
            <input
              type="text"
              [(ngModel)]="contactLabel"
              class="input"
              placeholder="Contact"
              (ngModelChange)="setContactEmbed()"
            />
          </div>
        </div>
        <div class="code">
          <textarea
            readonly
            #contactField
            class="textarea is-rounded is-fullwidth"
            rows="4"
            >{{ contactEmbed }}</textarea
          >
          <span class="clipboard" (click)="copy('contactField')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="clipboard"
              width="2em"
              height="2em"
              viewBox="0 0 24 24"
            >
              <path
                fill=""
                d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
