import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { CalendarIntegrationService } from './service/calendar-integration.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { User } from 'src/app/entities/user.model';
import { DOCUMENT, NgClass, NgFor, NgIf } from '@angular/common';
import { SharedService } from '../../../shared/services/shared.service';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

declare var google: any;

@Component({
  selector: 'app-calendar-integrations',
  templateUrl: './calendar-integrations.component.html',
  styleUrls: ['./calendar-integrations.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, FormsModule, NgClass, TranslateModule],
})
export class CalendarIntegrationsComponent implements OnInit, AfterViewInit {
  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private calendarIntegrationService: CalendarIntegrationService,
    private alertService: AlertService,
    private sharedService: SharedService
  ) {}

  public hasLinkedGoogleCalendar = false;
  public integrations: Array<any>;
  public calendarURL: string;
  public integratingCalendar = false;
  public insync = false;

  @Input()
  public currentUser: User;

  ngOnInit() {
    this.getIntegrations();
    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  ngAfterViewInit() {
    const script1 = this.renderer2.createElement('script');
    script1.src = `https://accounts.google.com/gsi/client`;
    script1.async = `true`;
    script1.defer = `true`;
    this.renderer2.appendChild(this.document.body, script1);
  }

  getIntegrations() {
    this.calendarIntegrationService.getAll().subscribe((integrations) => {
      if (Array.isArray(integrations)) {
        this.integrations = integrations;
        this.hasLinkedGoogleCalendar = false;
        this.integrations.forEach((i) => {
          if (i.calendar === 'Google') {
            this.hasLinkedGoogleCalendar = true;
          }
        });
      }
    });
  }

  deleteIntegration(id) {
    this.insync = true;
    this.calendarIntegrationService.delete(id).subscribe((data) => {
      this.insync = false;
      this.alertService.success('settings.calendar-removed');
      this.getIntegrations();
    });
  }

  // noinspection JSUnusedGlobalSymbols  : in development
  reAuthCalendar(integration) {
    this.insync = true;
    this.calendarIntegrationService.delete(integration.id).subscribe((data) => {
      this.calendarIntegrationService
        .integrateCalendarFromURL(integration.calendar)
        .subscribe((response) => {
          this.insync = false;
          this.getIntegrations();
        });
    });
  }

  // noinspection JSUnusedGlobalSymbols : in development
  reauthorize(integration) {
    this.insync = true;
    this.calendarIntegrationService
      .reauthorize(integration.id)
      .subscribe((response) => {
        this.insync = false;
        if (response.success) {
          this.alertService.success('settings.google-calendar-link-success');
        } else {
          MonitoringService.captureMessage(
            'Google Calendar Integration Failed',
            { extra: response }
          );
          this.alertService.error('settings.google-calendar-link-failed');
        }
      });
  }

  // noinspection JSUnusedGlobalSymbols  : in development
  toggleCalendarForIntegration(
    flag: boolean,
    id: string,
    integrationID: string
  ) {
    this.insync = true;
    const integration = this.integrations.filter(
      (i) => i.id == integrationID
    )[0];
    integration.google_calendars.forEach((cal) => {
      if (cal.id == id) {
        cal.active = flag;
      }
    });
    this.calendarIntegrationService
      .updateGoogleCalendarSelection(
        integrationID,
        integration.google_calendars
      )
      .subscribe((data) => {
        this.insync = false;
        if (data.success) {
          this.alertService.success(
            'settings.calendar-selection-update-success'
          );
        } else {
          this.alertService.error('settings.calendar-selection-update-failed');
          this.getIntegrations();
        }
      });
  }

  // noinspection JSUnusedGlobalSymbols  : in development
  linkGoogleCalendar() {
    const client = google.accounts.oauth2.initCodeClient({
      client_id: environment.googleClientId,
      scope: 'https://www.googleapis.com/auth/calendar.readonly',
      ux_mode: 'popup',
      callback: (response: any) => this.googleCalendarCallback(response),
    });
    client.requestCode();
  }

  googleCalendarCallback(params) {
    this.insync = true;
    this.calendarIntegrationService.integrateGoogleCalendar(params).subscribe(
      (response) => {
        this.insync = false;
        this.getIntegrations();
        if (response.success) {
          this.alertService.success('settings.google-calendar-link-success');
        } else {
          MonitoringService.captureMessage(
            'Google Calendar Integration Failed',
            { extra: response }
          );
          this.alertService.error('settings.google-calendar-link-failed');
        }
      },
      (error) => {
        MonitoringService.captureException(error);
        this.insync = false;
        this.alertService.error('settings.google-calendar-link-failed');
      }
    );
  }

  integrateCalendarFromURL() {
    if (this.calendarURL.match('(https?://calendar\\.google\\.com)')) {
      this.alertService.error('settings.please-use-link-google-calendar');
    } else {
      this.integratingCalendar = true;
      this.calendarIntegrationService
        .integrateCalendarFromURL(this.calendarURL)
        .subscribe((response) => {
          this.integratingCalendar = false;
          this.calendarURL = '';
          this.getIntegrations();
        });
    }
  }
}
