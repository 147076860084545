import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';
import { OfficeService } from '../../../office/shared/service/office.service';
import { AmplitudeAnalyticsService } from '../../../shared/services/analytics/amplitude-analytics.service';
import { ClientsService } from '../../my-clients/service/clients.service';
import {
  ChannelService,
  ChatClientService,
  CustomTemplatesService,
  DefaultStreamChatGenerics,
  getChannelDisplayText,
  StreamChatModule,
  StreamI18nService,
} from 'stream-chat-angular';
import { AlertService } from '../../../shared/components/alert/service/alert.service';
import { MessagingService } from '../service/messaging.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BookingFormContainerComponent } from '../../booking/booking-form-container/booking-form-container.component';
import { environment } from '../../../../environments/environment';
import { PaymentType } from 'src/app/entities/PaymentType';
import { User } from '../../../entities/user.model';
import { SidenavComponent } from '../../../frame/sidenav/sidenav.component';
import { ConversationHeaderComponent } from '../conversation-header/conversation-header.component';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { StreamMessageInputComponent } from '../stream-message-input/stream-message-input.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { PaymentModalComponent } from '../../../shared/components/payment-modal/payment-modal.component';
import { Animation } from '../../../shared/animations/fade-animation';
import { Attachment, Channel } from 'stream-chat';
import { Conversation } from '../../../entities/conversation.model';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LeanConversationUser } from '../../../entities/lean-conversation-user.model';
import { BookingAttachmentComponent } from '../booking-attachment/booking-attachment.component';
import { InvoiceAttachmentComponent } from '../invoice-attachment/invoice-attachment.component';
import { Appointment } from '../../../entities/appointment.model';
import { EapNotAllowedBannerComponent } from '../eap-not-allowed-banner/eap-not-allowed-banner.component';
import { EapClientBannerComponent } from '../eap-client-banner/eap-client-banner.component';
import { SupportService } from '../../../shared/services/support.service';
import { getOtherMemberIfOneToOneChannel } from '../../../shared/helpers/stream_chat_helper';
import { filter, map } from 'rxjs/operators';
import { StreamConversationLoadingComponent } from '../stream-conversation-loading/stream-conversation-loading.component';
import { DefaultUserType } from 'stream-chat-angular/lib/types';
import { LogAttachmentComponent } from '../log-attachment/log-attachment.component';

@Component({
  selector: 'app-stream-conversation',
  templateUrl: './stream-conversation.component.html',
  styleUrls: ['./stream-conversation.component.scss'],
  animations: [Animation.fadeAnimation],
  standalone: true,
  imports: [
    SidenavComponent,
    StreamChatModule,
    ConversationHeaderComponent,
    NgIf,
    AsyncPipe,
    StreamMessageInputComponent,
    NgClass,
    TranslateModule,
    FormsModule,
    BookingFormContainerComponent,
    PaymentModalComponent,
    NgForOf,
    BookingAttachmentComponent,
    InvoiceAttachmentComponent,
    EapClientBannerComponent,
    EapNotAllowedBannerComponent,
    StreamConversationLoadingComponent,
    LogAttachmentComponent,
  ],
})
export class StreamConversationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  protected readonly PaymentType = PaymentType;
  channel: Channel<DefaultStreamChatGenerics> | undefined;

  @ViewChild('customAttachmentListTemplate', { static: true })
  customAttachmentListTemplate!: TemplateRef<any>;

  @ViewChild('messageActionsBoxTemplate', { static: true })
  customMessageActionsBoxTemplate!: TemplateRef<any>;

  public conversation: Conversation;
  public currentUser: User;
  public otherUser: LeanConversationUser;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private sharedService: SharedService,
    private officeService: OfficeService,
    private analytics: AmplitudeAnalyticsService,
    private clientService: ClientsService,
    private chatClientService: ChatClientService,
    private channelService: ChannelService,
    private alertService: AlertService,
    private messagingService: MessagingService,
    private activatedRoute: ActivatedRoute,
    private customTemplatesService: CustomTemplatesService,
    private supportService: SupportService,
    private ngZone: NgZone,
    private router: Router,
    private translate: TranslateService,
    private streamI18nService: StreamI18nService
  ) {}

  @ViewChild('acceptBookingButton', { static: false })
  acceptBookingButton: ElementRef;

  @ViewChild('bookingModal', { static: false })
  bookingModal: BookingFormContainerComponent;

  public env = environment;
  public currentTimezone: string =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  public showTimezone: boolean = false;
  public isSupportChat: boolean = false;
  public wantsToArchiveConversation: boolean = false;
  public wantsToReportUser: boolean = false;
  public isReportingUser: boolean = false;
  public otherUserIsOnline: boolean = false;
  public otherUserCanReceiveMessages: boolean = false;
  public otherUserIsDeactivated: boolean = false;
  public otherUserIsUnderReview: boolean;
  private isOnlineSubscription: Subscription | undefined;
  public report: any = {
    includeMessages: true,
    description: '',
  };

  isInitializing$: Observable<boolean>;
  isActiveChannel$: Observable<boolean>;
  isError$: Observable<boolean>;

  private conversationErrorSubject = new BehaviorSubject<boolean>(false);
  public conversationError$ = this.conversationErrorSubject.asObservable();

  ngOnInit() {
    // Subscribe to current user
    this.subscriptions.add(
      this.sharedService.currentUser.subscribe((user) => {
        const isFirstLoad = !this.currentUser;
        this.currentUser = user;
        if (this.currentUser) {
          this.showTimezone =
            this.currentTimezone !== this.currentUser.profile.timezone;
        }
        if (isFirstLoad) {
          this.loadConversation();
        }
      })
    );

    this.translate.getTranslation('en').subscribe(() => {
      this.streamI18nService.setTranslation('en');
      this.streamI18nService.setTranslation('de', streamTranslationsDE);
    });

    this.isInitializing$ = combineLatest([
      this.channelService.channelQueryState$,
      this.channelService.activeChannel$,
    ]).pipe(
      map(([state, activeChannel]) => {
        return !activeChannel && state?.state === 'in-progress';
      })
    );

    this.isActiveChannel$ = this.channelService.activeChannel$.pipe(
      map((c) => !!c && c.id === this.channel?.id)
    );

    this.isError$ = combineLatest([
      this.channelService.channelQueryState$,
      this.channelService.activeChannel$,
      this.conversationError$,
    ]).pipe(
      map(([state, activeChannel, conversationError]) => {
        return (
          (!activeChannel && state?.state === 'error') || conversationError
        );
      })
    );
  }

  loadConversation() {
    this.messagingService
      .getConversation(this.activatedRoute.snapshot.params.id)
      .subscribe(
        (res) => {
          if (!res || !res['data'] || !res['data']['attributes']) {
            MonitoringService.captureMessage('Conversation not found: ', {
              extra: {
                response: res,
              },
            });
            this.conversationErrorSubject.next(true); // Emit error state
            return;
          }
          this.conversation = res['data']['attributes'];
          this.otherUser =
            this.conversation.sender.id === this.currentUser.id
              ? this.conversation.recipient
              : this.conversation.sender;

          this.isSupportChat = this.otherUser.id === environment.supportUserId;
          // load channel
          this.channel = this.chatClientService.chatClient.getChannelByMembers(
            'messaging',
            {
              members: [
                this.currentUser.external_id,
                this.otherUser.external_id,
              ],
            }
          );

          this.channel.watch({ presence: true }).then(() => {
            this.channelService.setAsActiveChannel(this.channel);
            const otherMember = getOtherMemberIfOneToOneChannel(
              this.currentUser,
              this.channel
            );

            this.otherUserIsDeactivated =
              !!otherMember.deactivated_at || this.otherUser.blocked;
            this.otherUserIsUnderReview =
              !this.otherUserIsDeactivated && !!this.otherUser.flagged;
            this.otherUserCanReceiveMessages =
              !this.otherUserIsDeactivated && !this.otherUserIsUnderReview;
            this.listenForOnlineChanges(otherMember);
          });
        },
        () => {
          this.conversationErrorSubject.next(true); // Emit error state on failure
        }
      );
  }

  ngAfterViewInit(): void {
    this.customTemplatesService.attachmentListTemplate$.next(
      this.customAttachmentListTemplate
    );
    this.customTemplatesService.messageActionsBoxTemplate$.next(
      this.customMessageActionsBoxTemplate
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe(); // Unsubscribe from all subscriptions
  }

  private async listenForOnlineChanges(otherMember: DefaultUserType) {
    // tslint:disable-next-line:max-line-length
    // Swiped most of this from here https://github.com/GetStream/stream-chat-angular/blob/8710aa79b1ffe378cf01e0bc46d94aa05e492bd0/projects/stream-chat-angular/src/lib/avatar/avatar.component.ts#L157
    // We could just use their components in the future dashboard
    if (otherMember) {
      this.otherUserIsOnline = !!otherMember.online || false;
      this.isOnlineSubscription = this.chatClientService.events$
        .pipe(filter((e) => e.eventType === 'user.presence.changed'))
        .subscribe((event) => {
          if (event.event.user?.id === otherMember.id) {
            this.ngZone.run(() => {
              this.otherUserIsOnline = event.event.user?.online || false;
            });
          }
        });
    } else {
      this.otherUserIsOnline = false;
      this.isOnlineSubscription?.unsubscribe();
    }
  }

  get title() {
    if (!this.channel) {
      return '';
    }
    return getChannelDisplayText(
      this.channel,
      this.chatClientService.chatClient.user!
    );
  }

  reportUser() {
    if (this.isReportingUser) {
      return;
    }
    this.isReportingUser = true;

    this.clientService
      .reportClient(
        this.otherUser,
        this.report.description,
        this.latestMessages(10)
      )
      .subscribe((response) => {
        if (response.status === 'ok') {
          this.alertService.success('User reported');
          if (this.otherUser) {
            this.otherUser.flagged = true;
          }
        }
        this.isReportingUser = false;
        this.report.description = '';
        this.wantsToReportUser = false;
      });
  }

  latestMessages(count: number) {
    let min =
      this.channelService.activeChannel.state.latestMessages.length - count;
    if (min < 0) {
      min = 0;
    }
    const max =
      this.channelService.activeChannel.state.latestMessages.length + 1;
    const messageSlice =
      this.channelService.activeChannel.state.latestMessages.slice(min, max);

    // sort by pk desc
    messageSlice.sort(
      (a, b) => b.created_at.getTime() - a.created_at.getTime()
    );

    return messageSlice;
  }

  onCreateBooking() {
    this.analytics.trackScheduleAppointmentStarted({
      source_page: 'messages_page',
    });

    if (this.otherUser) {
      this.bookingModal.showForNewClientBooking(this.otherUser);
    }
  }

  onCreateInvoice() {
    this.officeService.toggleCreateInvoiceModal(true);
  }

  onSetConversationArchived(archived: boolean) {
    if (archived) {
      this.wantsToArchiveConversation = true;
    } else {
      this.archiveConversation(false);
    }
  }

  archiveConversation(value: boolean) {
    this.messagingService
      .archiveConversation(this.conversation.id, value)
      .subscribe(
        () => {
          if (value) {
            this.alertService.success(
              'messaging.conversation-hidden',
              null,
              true
            );
          } else {
            this.alertService.success(
              'messaging.conversation-un-hidden',
              null,
              true
            );
          }
          this.router.navigate(['/home/messaging/']);
        },
        () => {
          this.alertService.error(
            'common.generic_error.message',
            'common.generic_error.title'
          );
        }
      );
  }

  public editAppointment(appointment: Appointment) {
    // stream attachments don't have the client, here we can assume it's the other user
    // in the new dashboard, the editing modal should be able to load the appointment from the server
    const updatedAppointment = {
      ...appointment,
      client: this.otherUser,
    };
    this.bookingModal.showForEditingAppointment(updatedAppointment);
  }

  hasSpecialAttachments(
    attachments: Attachment<DefaultStreamChatGenerics>[]
  ): boolean {
    return attachments.some(
      (attachment) =>
        this.isInvoice(attachment) ||
        this.isAppointment(attachment) ||
        this.isOnlineSessionLog(attachment)
    );
  }

  isInvoice(attachment: Attachment<DefaultStreamChatGenerics>): boolean {
    return attachment.type === 'invoice';
  }

  isAppointment(attachment: Attachment<DefaultStreamChatGenerics>): boolean {
    return attachment.type === 'appointment';
  }

  isOnlineSessionLog(
    attachment: Attachment<DefaultStreamChatGenerics>
  ): boolean {
    return attachment.type === 'online_session_log';
  }

  requestSupport() {
    this.supportService.showReportModal();
  }

  canUseVideoCallForAppointment(attachment: any): boolean {
    return attachment.profile_id.toString() ===
      this.currentUser.profile.id.toString()
      ? this.currentUser.abilities.can_start_video_call
      : this.otherUser.can_start_video_calls;
  }
}

// tslint:disable-next-line:max-line-length
// Translated from https://raw.githubusercontent.com/GetStream/stream-chat-angular/refs/heads/master/projects/stream-chat-angular/src/assets/i18n/en.ts
const streamTranslationsDE = {
  '1 reply': '1 Antwort',
  'Attach files': 'Dateien anhängen',
  Cancel: 'Abbrechen',
  'Channel Missing': 'Kanal fehlt',
  Close: 'Schließen',
  'Close emoji picker': 'Emoji-Auswahl schließen',
  'Commands matching': 'Übereinstimmende Befehle',
  'Connection failure, reconnecting now...':
    'Verbindungsfehler, wird neu verbunden...',
  Delete: 'Löschen',
  Delivered: 'Zugestellt',
  'Edit Message': 'Nachricht bearbeiten',
  'Edit message request failed': 'Bearbeiten der Nachricht fehlgeschlagen',
  'Emoji matching': 'Übereinstimmende Emojis',
  'Empty message...': 'Leere Nachricht...',
  'Error adding flag': 'Fehler beim Hinzufügen der Markierung',
  'Error connecting to chat, refresh the page to try again.':
    'Fehler bei der Verbindung zum Chat, Seite neu laden und erneut versuchen',
  'Error deleting message': 'Fehler beim Löschen der Nachricht',
  'Error loading reactions': 'Fehler beim Laden der Reaktionen',
  'Error muting a user ...': 'Fehler beim Stummschalten eines Benutzers...',
  'Error pinning message': 'Fehler beim Anheften der Nachricht',
  'Error removing message pin': 'Fehler beim Entfernen der Nachricht',
  'Error unmuting a user ...': 'Fehler beim Aufheben der Stummschaltung...',
  'Error uploading file': 'Fehler beim Hochladen der Datei "{{ name }}"',
  'Error uploading file, maximum file size exceeded':
    'Fehler beim Hochladen von "{{ name }}", maximale Dateigröße {{ limit }} überschritten',
  'Error uploading file, extension not supported':
    'Fehler beim Hochladen von "{{ name }}", Dateityp {{ ext }} wird nicht unterstützt',
  'Error deleting attachment': 'Fehler beim Löschen des Anhangs',
  'Error · Unsent': 'Nachricht konnte nicht gesendet werden',
  'Error: {{ errorMessage }}': 'Fehler: {{ errorMessage }}',
  Flag: 'Markieren',
  'Message Failed': 'Nachricht fehlgeschlagen',
  'Message Failed · Unauthorized': 'Nicht berechtigt, Nachricht zu senden',
  'Message Failed · Click to try again':
    'Nachricht konnte nicht gesendet werden, zum erneuten Versuch klicken',
  'Message deleted': 'Nachricht gelöscht',
  'Message has been successfully flagged':
    'Nachricht wurde erfolgreich markiert',
  'Message pinned': 'Nachricht angeheftet',
  'Message unpinned': 'Nachricht nicht mehr angeheftet',
  Mute: 'Stummschalten',
  New: 'Neu',
  'New Messages!': 'Neue Nachrichten!',
  'No results found': 'Keine Ergebnisse gefunden',
  'Nothing yet...': 'Noch nichts...',
  'Only visible to you': 'Nur für dich sichtbar',
  'Open emoji picker': 'Emoji-Auswahl öffnen',
  'People matching': 'Übereinstimmende Personen',
  'Pick your emoji': 'Wähle dein Emoji',
  Pin: 'Anheften',
  'Pinned by': 'Angeheftet von',
  Reply: 'Antwort zitieren',
  'Reply to Message': 'Auf Nachricht antworten',
  Search: 'Suchen',
  'Searching...': 'Suche...',
  Send: 'Senden',
  'Send message request failed': 'Senden der Nachricht fehlgeschlagen',
  'Sending...': 'Senden...',
  'Slow Mode ON': 'Langsamer Modus an',
  'Start of a new thread': 'Beginn eines neuen Threads',
  'This message was deleted...': 'Diese Nachricht wurde gelöscht...',
  Thread: 'Thread-Antwort',
  'Type your message': 'Gib deine Nachricht ein',
  Unmute: 'Stummschaltung aufheben',
  Unpin: 'Lösen',
  'Wait until all attachments have uploaded':
    'Warte, bis alle Anhänge hochgeladen wurden',
  'You have no channels currently': 'Du hast derzeit keine Kanäle',
  "You've reached the maximum number of files":
    'Du hast die maximale Anzahl von Dateien erreicht',
  live: 'live',
  'this content could not be displayed':
    'Dieser Inhalt konnte nicht angezeigt werden',
  '{{ commaSeparatedUsers }} and {{ moreCount }} more':
    '{{ commaSeparatedUsers }} und {{ moreCount }} mehr',
  '{{ commaSeparatedUsers }}, and {{ lastUser }}':
    '{{ commaSeparatedUsers }} und {{ lastUser }}',
  '{{ firstUser }} and {{ secondUser }}':
    '{{ firstUser }} und {{ secondUser }}',
  '{{ imageCount }} more': '{{ imageCount }} mehr',
  '{{ memberCount }} members': '{{ memberCount }} Mitglieder',
  '{{ replyCount }} replies': '{{ replyCount }} Antworten',
  '{{ user }} has been muted': '{{ user }} wurde stummgeschaltet',
  '{{ user }} has been unmuted': '{{ user }} wurde wieder freigeschaltet',
  '{{ watcherCount }} online': '{{ watcherCount }} online',
  '🏙 Attachment...': '🏙 Anhang...',
  'Connection error': 'Verbindungsfehler',
  'Load more': 'Mehr laden',
  failed: 'fehlgeschlagen',
  retry: 'wiederholen',
  test: 'erfolgreich',
  'Sending links is not allowed in this conversation':
    'Das Senden von Links ist in dieser Unterhaltung nicht erlaubt',
  "You can't send messages in this channel":
    'Du kannst in diesem Kanal keine Nachrichten senden',
  "You can't send thread replies in this channel":
    'Du kannst in diesem Kanal keine Antworten senden',
  'Message not found': 'Nachricht nicht gefunden',
  'No chats here yet…': 'Noch keine Chats hier...',
  'user is typing': '{{ user }} tippt',
  'users are typing': '{{ users }} tippen',
  'Error loading channels': 'Fehler beim Laden der Kanäle',
  'See original (automatically translated)':
    'Original anzeigen (automatisch übersetzt)',
  'See translation': 'Übersetzung anzeigen',
  'Mark as unread': 'Als ungelesen markieren',
  'Error marking message as unread': 'Fehler beim Markieren als ungelesen',
  'Error, only the first {{count}} message can be marked as unread':
    'Fehler, nur die ersten {{count}} Nachrichten können als ungelesen markiert werden',
  'Unread messages': 'Ungelesene Nachrichten',
  '{{count}} unread messages': '{{count}} ungelesene Nachrichten',
  '{{count}} unread message': '{{count}} ungelesene Nachricht',
  'This message did not meet our content guidelines':
    'Diese Nachricht entspricht nicht unseren Inhaltsrichtlinien',
  'Send Anyway': 'Trotzdem senden',
  Edited: 'Bearbeitet',
  'Error playing audio': 'Fehler beim Abspielen der Audiodatei',
  'Copy text': 'Text kopieren',
  'Please grant permission to use microhpone':
    'Bitte erteile die Erlaubnis, das Mikrofon zu benutzen',
  'Error starting recording': 'Fehler beim Starten der Aufnahme',
  'An error has occurred during recording':
    'Während der Aufnahme ist ein Fehler aufgetreten',
  'Media recording not supported': 'Medienaufzeichnung wird nicht unterstützt',
};
