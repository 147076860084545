import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';
import { SubscriptionService } from '../service/subscription.service';
import { AlertService } from '../../../shared/components/alert/service/alert.service';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { MatLoadingButtonDirective } from '../../../shared/directives/mat-loading-button-directive';

@Component({
  selector: 'plan-start-confirm-dialog',
  templateUrl: 'plan-start-confirm-dialog.html',
  styleUrls: ['./plan-start-confirm-dialog.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgClass,
    FormsModule,
    NgIf,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    TranslateModule,
    MatLoadingButtonDirective,
  ],
})
export class PlanStartConfirmDialog {
  public coupon: string = '';
  public couponIsValidating: boolean = false;
  public couponIsValid: boolean;
  public couponMessage: string;
  public isLoadingCheckoutLink: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { paymentPlanId: string; planName: string },
    public dialogRef: MatDialogRef<PlanStartConfirmDialog>,
    public settingsService: SettingsService,
    public subscriptionService: SubscriptionService,
    public alertService: AlertService
  ) {}

  verifyCoupon() {
    // This logic is more or less copied from the old implementation. It assumes all couponse are >= 5 characters and
    // it tries to validate the coupon on every keypress after 5 characters.
    // But this is not a heavily used feature so it should be fine for now
    if (this.coupon.length === 0) {
      this.couponIsValid = true;
      this.couponMessage = '';
    } else if (this.coupon.length > 5) {
      this.couponIsValidating = true;
      this.settingsService.checkCoupon(this.coupon).subscribe((response) => {
        this.couponIsValidating = false;
        if (response.status === true) {
          this.couponMessage =
            response.message + '. ' + response.coupon.description;
        } else {
          this.couponMessage = response.message;
        }
        this.couponIsValid = response.status;
      });
    } else {
      this.couponIsValid = false;
      this.couponMessage = '';
    }
  }

  goToCheckout() {
    this.isLoadingCheckoutLink = true;
    this.subscriptionService
      .startStripeCheckout(this.data.paymentPlanId, this.coupon)
      .subscribe((response) => {
        this.isLoadingCheckoutLink = false;
        if (response.url) {
          window.location.href = response.url;
        } else {
          MonitoringService.captureMessage('Error creating checkout url', {
            extra: response,
          });
          this.alertService.error(
            'office.settings.subscription.plan_start_confirmation.error'
          );
        }
      });
  }
}
