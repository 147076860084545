<div class="working-hours-container">
  <div class="working-hours" >
    <h3 class="subtitle is-5">{{ "settings.calendar.working-hours.title" | translate }}</h3>
    <p>{{ "settings.calendar.working-hours.description" | translate }}</p>
    <div class="calendar-grid">
      <ng-container *ngFor="let weekday of weekdays; let i = index">
        <div class="day-spacer"></div>
        <ng-container *ngFor="let slot of (availabilitySlotsByDay[i + 1] || []); let j = index">
          <div *ngIf="j === 0" class="day-name has-text-weight-bold">
            <span>{{ weekday }}</span>
          </div>
          <div class="slot">
            <span>{{ slot.start_hour | number : '2.0' }}:{{ slot.start_minute | number : '2.0' }} – {{ slot.end_hour | number : '2.0' }}:{{ slot.end_minute | number : '2.0' }}</span>
          </div>
          <div class="remove-button-container">
            <button
              matTooltip="{{ 'settings.calendar.working-hours.remove-slot' | translate }}"
              matTooltipPosition="above"
              mat-icon-button
              (click)="deleteSlot(slot.id)"
            >
              <mat-icon class="button-icon">clear</mat-icon>
            </button>
          </div>
          <div *ngIf="j === 0" class="add-button-container">
            <button
              matTooltip="{{ 'settings.calendar.working-hours.add-slot' | translate }}"
              matTooltipPosition="above"
              mat-icon-button
              (click)="createSlotForWeekday(i + 1)"
            >
              <mat-icon class="button-icon">add</mat-icon>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="firstLoadRegularHours && !(availabilitySlotsByDay[i + 1] && availabilitySlotsByDay[i + 1].length)">
          <div class="day-name has-text-weight-bold">
            <span>{{ weekday }}</span>
          </div>
          <div class="slot">
            <span class="unavailable-text">{{ 'settings.calendar.working-hours.unavailable' | translate }}</span>
          </div>
          <div class="add-button-container">
            <button
              matTooltip="{{ 'settings.calendar.working-hours.add-slot' | translate }}"
              matTooltipPosition="above"
              mat-icon-button
              (click)="createSlotForWeekday(i + 1)"
            >
              <mat-icon class="button-icon">add</mat-icon>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="is-divider-vertical"></div>
  <div class="specific-working-hours">
    <h3 class="subtitle is-5">{{ "settings.calendar.date-specific-hours.title" | translate }}</h3>
    <p>{{ "settings.calendar.date-specific-hours.description" | translate }}</p>
    <div class="is-flex is-align-items-start is-justify-content-start has-margin-top-25">
      <button
        *ngIf="currentUser && currentUser.type == 'Therapist'"
        (click)="openAddSpecificWorkingHoursDialog()"
        class="button is-rounded is-small"
      >
        <mat-icon class="button-icon">add</mat-icon>
        <span class="is-hidden-touch has-margin-left-5">
        {{ "settings.calendar.date-specific-hours.add-hours-cta" | translate }}
      </span>
      </button>
    </div>
    <div class="specific-calendar-grid">
      <ng-container *ngFor="let group of dateSpecificAvailabilities">
        <div class="day-spacer"></div>
        <div class="day-name has-text-weight-bold">
          <span>{{ formatDateRange(group) }}</span>
        </div>
        <ng-container *ngFor="let slot of group.slots; let i = index">
          <div class="slot">
            <span>{{ slot.start_hour | number : '2.0' }}:{{ slot.start_minute | number : '2.0' }} – {{ slot.end_hour | number : '2.0' }}:{{ slot.end_minute | number : '2.0' }}</span>
          </div>
          <div class="remove-button-container" *ngIf="i == 0">
            <button
              matTooltip="{{ 'settings.calendar.date-specific-hours.remove-date' | translate }}"
              matTooltipPosition="above"
              mat-icon-button
              (click)="deleteDateSpecificAvailability(group)"
            >
              <mat-icon class="button-icon">clear</mat-icon>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf=" firstLoadSpecificHours && !group.slots.length">
          <div class="slot">
            <span class="unavailable-text">{{ 'settings.calendar.date-specific-hours.unavailable' | translate }}</span>
          </div>
          <div class="remove-button-container">
            <button
              matTooltip="{{ 'settings.calendar.date-specific-hours.remove-date' | translate }}"
              matTooltipPosition="above"
              mat-icon-button
              (click)="deleteDateSpecificAvailability(group)"
            >
              <mat-icon class="button-icon">clear</mat-icon>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
