import { AvailabilitySlot } from 'src/app/entities/availability-slot.model';
import moment from 'moment';

export class SlotHelper {
  static setInitialSlotTimes(
    existingSlots: AvailabilitySlot[]
  ): AvailabilitySlot {
    const newSlot = {
      start_hour: 9,
      start_minute: 0,
      end_hour: 17,
      end_minute: 0,
    };

    if (existingSlots.length > 0) {
      const lastSlot = existingSlots[existingSlots.length - 1];
      const lastSlotEndHour = lastSlot.end_hour;
      const lastSlotEndMinute = lastSlot.end_minute;
      newSlot.start_hour = lastSlotEndHour;
      newSlot.start_minute = lastSlotEndMinute;

      if (newSlot.start_minute + 30 < 60) {
        newSlot.start_minute += 30;
      } else {
        newSlot.start_hour += 1;
        newSlot.start_minute = (newSlot.start_minute + 30) % 60;
      }

      newSlot.end_hour = newSlot.start_hour + 1;
      newSlot.end_minute = newSlot.start_minute;

      if (
        newSlot.end_hour > 24 ||
        (newSlot.end_hour === 24 && newSlot.end_minute > 0)
      ) {
        newSlot.start_hour = 0;
        newSlot.start_minute = 0;
        newSlot.end_hour = 1;
        newSlot.end_minute = 0;
      }
    }

    return newSlot;
  }

  static isSlotOverlapping(
    newSlot: AvailabilitySlot,
    existingSlots: AvailabilitySlot[]
  ): boolean {
    const newStart = moment({
      hour: newSlot.start_hour,
      minute: newSlot.start_minute,
    });
    const newEnd = moment({
      hour: newSlot.end_hour,
      minute: newSlot.end_minute,
    });

    return existingSlots.some((slot) => {
      const start = moment({
        hour: slot.start_hour,
        minute: slot.start_minute,
      });
      const end = moment({ hour: slot.end_hour, minute: slot.end_minute });
      return (
        newStart.isBetween(start, end, null, '[)') ||
        newEnd.isBetween(start, end, null, '(]') ||
        start.isBetween(newStart, newEnd, null, '[)') ||
        end.isBetween(newStart, newEnd, null, '(]')
      );
    });
  }
}
